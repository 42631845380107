.chip {
  align-self: flex-start !important;
  background-color: $chip-bg;
  color: $chip-color;
  padding: 5px 0;
  border-radius: $border-radius;
  margin-right: .5rem;
  font-size: $font-size-sm;
  line-height: $line-height-xs;
  font-weight: normal;
  text-wrap: nowrap;
  .icon-check {
    height: 20px;
    width: 20px;
    border-radius: $border-radius-sm;
    margin-right: 0.25rem;
    svg {
      vertical-align: baseline;
    }
  }

  .chip-icon {
    cursor: pointer;
    margin-left: $spacer;
    margin-right: $spacer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--sm {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    font-size: $font-size-sm;
    line-height: $line-height-xs;
    height: 26px;
    padding: 3px 8px;
    margin-right: 5px;
    .chip-svg-icon {
      display: block;
      width: 12px;
      margin-right: 5px;
    }
  }
  &--xs {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    height: 20px;
    padding: 0 6px;
    margin-right: 5px;
    .chip-svg-icon {
      display: block;
      width: 12px;
      margin-right: 5px;
    }
  }
}
.mui-root {
  background-color: $input-bg;
  .MuiInputBase-input {
    font-family: $font-family-base;
    font-size: $font-size-base;
    &::-webkit-input-placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }
    height: $input-line-height !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    color: $body-color;
  }
  .MuiTouchRipple-root {display: none !important;}
  .MuiChip-root {
    transition: none !important;
    background-color: $chip-bg;
    color: $chip-color;
    &:hover {
      background-color: $chip-hover-bg;
      color: $chip-color;
    }
    border-radius: $border-radius;
    font-size: $font-size-sm;
    line-height: normal;
    box-shadow: none !important;
  }
  .MuiChip-clickable:hover,
  .MuiChip-clickable:focus {
  }
  .MuiChip-deleteIcon {
    width: 18px;
    font-size: $font-size-xs;
    margin-top: 1px;
    height: 18px;
  }
  .MuiChip-label {
    color: $body-color;
    padding-right: 0;
  }
}

.WAMuiChipInput-underline-14:before {
  display: none;
}
.WAMuiChipInput-underline-14:after {
  display: none;
}

.MuiFormControl-root {

  border: 1px solid $input-border-color !important;
  &.invalid {
    border: 1px solid $danger !important;
  }
  padding: 0 6px !important;
  border-radius: $border-radius;
}

.WAMuiChipInput-chipContainer-4 {
  gap: $spacer;
}

.MuiButtonBase-root.chip {
  height: 22px !important;
  margin: 10px 4px 0 0 !important;
}
.WAMuiChipInput-chipContainer-4, .MuiFormControl-root>div {
  min-height: $input-height !important;
  row-gap: 4px;
}

.MuiChip-label {
  padding-left: 10px !important;
}

.input-container--with-right-icon {
  .MuiInputBase-input {
    padding-right: 30px;
  }
}
