$arrow: 6px;
.__react_component_tooltip {
  &.type-dark {
    font-family: $btn-font-family;
    font-size: $font-size-sm;
    color: $tooltip-text !important;
    text-transform: none;

    &.show {
      opacity: 1;
    }

    i {
      font-style: normal;
      color: $primary;
    }

    background-color: $tooltip-bg;

    &.place-top {
      &:after {
        border-top-color: $tooltip-bg;
        border-right-width: $arrow;
        border-left-width: $arrow;
        margin-left: -$arrow;
      }
    }

    &.place-bottom {
      &:after {
        border-bottom-color: $tooltip-bg;
        border-right-width: $arrow;
        border-left-width: $arrow;
        margin-left: -$arrow;
      }
    }

    &.place-right {
      &:after {
        border-right-color: $tooltip-bg;
        border-top-width: $arrow;
        border-bottom-width: $arrow;
        margin-top: -5px;
      }
    }

    &.place-left {
      &:after {
        border-left-color: $tooltip-bg;
        border-top-width: $arrow;
        border-bottom-width: $arrow;
        margin-top: -5px;
      }
    }
  }
}
