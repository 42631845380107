
@import "../3rdParty/index";
.w-ascen {
  $primary: #F9801C;
  $primary400: #FB923C;
  $primary300: #FDBA74;
  $primary200: #FED7AA;
  $primary100: #FFEDD5;

  $primaryAlpha8: rgba($primary,0.08);
  $primaryAlpha16: rgba($primary,0.16);

  $purple: $primary;
  $purple900:$primary;

  $secondary: #1E60BB;
  $secondary400: #2682e0;
  $secondary300: #44a0f1;
  $secondary200: #64b0f3;
  $secondary100: #bbdcfa;

  $dark500: #000000;
  $body-color: #000000;

  $secondaryAlpha8: rgba($secondary,0.08);
  $secondaryAlpha16: rgba($secondary,0.16);

  $action: $secondary;
  $action400: $secondary400;
  $action300: $secondary300;
  $action200: $secondary200;
  $action100: $secondary100;

  $collect: $secondary;
  $collect400: $secondary;
  $forward: $secondary;
  $forward400: $secondary;
  $scan: $secondary;
  $phone: $secondary;
  $scan400: $secondary;

  $danger:#C1214C;

  $btn-primary-hover-background: $primary400;
  $btn-primary-active-background: $primary;

  $btn-font-weight: 600;

  @import "./set-variables";
  @import "form_variables";
  @import "../project/index";
  @import "../3rdParty/index";
  //theme is set at this point, do it here
  @each $name, $hex in $colors {
    @if type-of($hex) == 'color' {
      --bs-#{$name}-rgb: #{red($hex)}, #{green($hex)}, #{blue($hex)};
    }
  }
}
