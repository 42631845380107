
$light100: white;
$light200: #F8F9FA;
$light300: #EBECF0;
$light400: #E4E4E6;
$light500: #DBDBDE;
$light600: #D2D2D6;
$light700: #C5C5C7;
$light800: #8B8B8F;

$dark100: #D2D2D2;
$dark200: #A5A5A5;
$dark300: #777779;
$dark400: #4A4A4C;
$dark500: #1D1D1F;

$body-bg: $light200;

$primary: #BC2CC0;
$primary400: #C956CD;
$primary300: #D780D9;
$primary200: #E4ABE6;
$primary100: #F2D5F2;
$primaryAlpha8: rgba($primary,0.08);
$primaryAlpha16: rgba($primary,0.16);

$secondary: #3E2155;
$secondary400: #654D77;
$secondary300: #8B7A99;
$secondary200: #B2A6BB;
$secondary100: #D8D3DD;
$secondaryAlpha8: rgba($secondary,0.08);
$secondaryAlpha16: rgba($secondary,0.16);

$action: #1977EC;
$action400: #4792F0;
$action300: #75ADF4;
$action200: #A3C9F7;
$action100: #D1E4FB;
$actionAlpha8: rgba($action, 0.08);
$actionAlpha16: rgba($action, 0.16);

$purple: #A42F89;
$purple900:#5C3281;

$warning: #FECD34;
$warning400: #FED75D;
$warning700: #fec321;
$warning900: #fdb51b;

$info: #3EB8E5;
$info400: #65C6EA;
$info400: #65C6EA;
$info300: #8BD4EF;
$info200: #B2E3F5;
$info100: #D8F1FA;

$success: #30DB5B;
$success400: #59E27C;
$successAlpha16: rgba($success, 0.16);

$danger: #FF453A;
$danger900: #D70015;
$danger400: #fb5349;
$dropdown-item-danger-active-bg: rgba($danger,0.08);

$hr-border-color: $light400;
$hr-opacity: 1;

$link-color: $action;
$link-hover-color: $action400;
$link-color-dark: $action;
$link-hover-color-dark: $action400;

$input-border-color: $light700;
$text-muted: #6E6E73;
$blue-grey: #A7ACBD;
$blue-grey-dark: #6D7685;
// Then add them to your custom theme-colors map, together with any additional colors you might nee
//Products
$collect: #8530F7;
$collect400: #9D59F9;
$forward: #FF4F7B;
$forward400: #FF7295;
$scan: #3EB8E5;
$phone: #736FDB;
$scan400: #65C6EA;
@import "set-variables";

@import "ascen-mode";
