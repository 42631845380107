$tab-btn-font-weight:$font-weight-semibold;
$tab-btn-line-height:34px;

.tabs-nav {
  position: relative;

  top: 2px;
  button,
  button.btn,
  button.btn-primary {
    border-radius: 0;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid $input-border-color;
    min-width: 200px;
    background-color: transparent;
    color: $body-color !important;
    &:hover {
      background-color: transparent;
      box-shadow: none;
      border: none;
      border-bottom: 1px solid $input-border-color;
    }
    &.btn:active {
      box-shadow: none;
      background: transparent;
      color: $body-color;
    }
    min-height: 34px;
    font-weight: $tab-btn-font-weight;
    line-height: $tab-btn-line-height;
    padding: 0 0 1px 0;
    &.tab-active {
      font-weight: $font-weight-semibold;
      background: transparent;
      border-bottom: 2px solid $primary;
      margin-bottom: -1px;
    }
    .counter-value {
      font-size: $font-size-base;
      background-color: $primary;
      display: flex;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      font-weight: $tab-btn-font-weight;
    }
  }
}

.tabs {
  & .pill {
    background-color:$primary;
    border-radius: $border-radius-lg;
    display: inline-block;
    button,
    button.btn,
    button.btn-primary {
      padding: 0px 16px;
      margin: 4px;
      border-radius: $border-radius-lg;
      line-height: $btn-line-height-sm;
      &.tab-active {
        font-weight: bold !important;
        background: transparent !important;
        background-color: $primary !important;
        color: $text-muted !important;
        font-weight: 500 !important;
      }
    }
  }
}

.tab-item {
  display: none;
  &.tab-active {
    display: block;
  }
}

.tab-icon {
  font-size: 1.4em;
  display: block;
}

.btn-tab {
  position: relative;
  .unread {
    position: absolute;
    right: 0;
    top: 9px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 16px;
    height: 16px;
  }
}
