/*! purgecss start ignore */
.animated {
  @include transition(all 0.2s ease-in-out)
}
.no-animation {
  @include transition(none)
}
.animated-fast {
  @include transition(all 0.15s ease-in-out)
}
.transform-center {
  transform-origin: center;
}
.rotate180 {
  transform: rotate(180deg);
}
/*! purgecss end ignore */
