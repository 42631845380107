
$scrollbar-track: $dark500;
$scrollbar-thumb: transparent;
$scrollbar-thumb-hover: $dark300;

$scrollbar-track-dark: $dark100;
$scrollbar-thumb-dark: $dark300;
$scrollbar-thumb-dark-hover: $dark300;

$border-radius:6px;
@mixin customScroll {
  /* width */
  &::-webkit-scrollbar {
    height: 12px;
    margin: 12px;
    width: 12px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    @include transition(all 0.15s ease-in-out);
    background-clip: content-box;
    border: 3px solid transparent;
    background-color: $scrollbar-thumb;
    border-radius: $border-radius;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-thumb-hover;
    }
  }
}
@mixin customScrollDark {
  /* width */
  &::-webkit-scrollbar {
    height: 12px;
    margin: 12px;
    width: 12px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: $scrollbar-track-dark;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 3px solid transparent;
    background-color: $scrollbar-thumb-dark;
    border-radius: $border-radius;
    &:hover {
      background-color: $scrollbar-thumb-dark-hover;
    }
  }
}

.custom-scroll {
  @include customScroll()
}
