$font-sizes: (
        md: $font-size-base,
        lg: $font-size-lg,
        xs: $font-size-xs,
        sm: $font-size-sm,
        xss: $font-size-xss,
        display-1: $font-size-display-1, // 38px
        display-2: $font-size-display-2, // 28px
        display-3: $font-size-display-3, // 24px
);


.logo {
  font-size: 20px;
  font-weight: 800;
  letter-spacing: -1px;
  &--small {
    font-size: $h1-font-size;
  }
}
