.shadow-1 {
  box-shadow: $shadow-1;
}
.shadow-2 {
  box-shadow: $shadow-2;
}
.shadow-3 {
  box-shadow: $shadow-3;
}
.shadow-4 {
  box-shadow: $shadow-4;
}
