.invalid input {
  border-color: $danger;
}
.input-container {
  .input-icon {
    position: absolute;
    line-height: $input-line-height;
    top:0;
  }
  .icon-right {
    right:$spacer*2;
  }
  .icon-left {
    left:$spacer*4;
  }
  &--with-left-icon {
    input {
      padding-left: 42px;
    }
  }
  &--with-right-icon {
    input {
      padding-right: 42px;
    }
  }
}
.form-control {
  font-size: $input-font-size;
  line-height: $input-line-height - 2;
  &:disabled {
    opacity: $input-disabled-opacity !important;
  }
}
.icon-disabled {
  opacity: $input-disabled-opacity !important;
}
.lh-input {
  line-height: $input-line-height !important;
}

.form-check-input:focus {
  background-color: $form-check-input-focus-bg-color;
}

.form-check-input[type="checkbox"] {
  &:checked {
    &:focus,&:active{
      background-color: $form-check-input-checked-focus-bg-color;
      filter: none;
    }
  }
}

.form-control {
  background-color: $input-bg;
}


.form-check-input[type="radio"] {
  &:checked {
    background-color: $form-check-radio-checked-bg-color;
  }
}

.form-check-label.disabled {
  opacity: $input-disabled-opacity;
}

.input-icon {
  color: $input-icon-color;
  * {color: $input-icon-color;}
  path {
    fill: $input-icon-color;
  }
}

.invalid {
  .form-control {
    border-color: $danger !important;
  }
}

.outline {
  border: 1px solid $light800;
}
.outline-dark {
  border: 1px solid $body-color;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 background-color: $primaryAlpha8 !important;
  -webkit-box-shadow: 0 0 0px 1000px $input-bg-autofill inset;

  transition: background-color 5000s ease-in-out 0s;

}

.logo-fill {
  fill:$body-color;
}
td.fitwidth {
    width: 1px;
    white-space: nowrap;
}

.autocomplete-content {
  max-height: 200px;
  overflow-y: auto;
}

.hover-show:hover {
  .d-hover-flex {
    display: flex !important;
  }
}

.mh-100 {
  min-height: 100%;
}
.lh-auto {
  line-height: normal !important;
}

.text-underline {
  text-decoration: underline;
}

.form-label {
  font-weight: $font-weight-semibold;
  font-size: $fs16;
}
.hover-opacity-100:hover {
  opacity: 1 !important;
}

.border-dash {
  border-style: dashed;
}
.border-danger {
  border-color: $danger;
}
.minh-100 {
  min-height: 100%;
}
.card-footer {
  position: relative;
  left: 0;
  right: 0;
}
.cursor-zoom {
  cursor: zoom-in !important;
}
.letter-space {
  letter-spacing: 1px;
}
