table {
  width: 100%;
  // border-collapse: separate;
  border-spacing: 0px;
}

thead {
  font-size: $th-font-size;
  font-weight: $font-weight-semibold;
  color: $th-color;
}

th {
  padding: $th-padding;
  font-weight: $font-weight-semibold;
}
td {
  padding: $td-padding;
}
td, th {
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}
thead {
}
tr {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: $light700;
  }
  &:last-child::after {
    display: none;
  }
}
thead {
  tr {
    &:last-child::after {
      display: block;
    }
  }
}

tr {
  &:last-child td{
    padding-bottom: 0;
  }
}
