@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

$border-radius-xl: 38px;
$border-radius-lg: 18px;
$border-radius: 8px;
$border-radius-sm: 6px;
.rounded-lg {
  border-radius: $border-radius-lg !important;
}
.rounded-xl {
  border-radius: $border-radius-xl !important;
}
.rounded-sm {
  border-radius: $border-radius-sm !important;
}

@import "grid_variables";
@import "font-variables";
@import "form_variables";
@import "colour-variables";
