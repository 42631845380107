@import "bootstrap";
@import "nprogress";
@import "hljs";
@import "rcswitch";
@import "react-select";
@import "loqate";
@import "json";
@import "calendar";
@import "tooltip";
@import "phone-input";
@import "~react-pdf/dist/esm/Page/AnnotationLayer.css";
@import "~react-pdf/dist/esm/Page/TextLayer.css";
.react-pdf__Page__canvas,
.react-pdf__Page__textContent {
  margin: auto;
}
.react-pdf__Document {
  .pdf-controls {
    transition: opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.25); /* Semi-transparent black */
    opacity: 0;
    height: 84px;

    &:hover {
      opacity: 1;
    }
  }
}

.pointer-events-none {
  .textLayer {
    pointer-events: none;
  }
}

[data-rmiz-ghost] {
  display: none !important;
}

[data-rmiz-btn-unzoom] {
  background-color: $primary !important;
  outline-color: transparent;
}
