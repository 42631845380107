.btn {
  text-wrap: nowrap;
  &.btn-primary {
    color: $btn-primary-color !important;
    &:hover {
      color: $btn-primary-color;
      background-color: $btn-primary-hover-background;
      border-color: $btn-primary-hover-background;
    }
    &:active,&:focus,&:focus-visible {
      background-color: $btn-primary-active-background;
      border-color: $btn-primary-active-background;
    }
  }
  &.btn-danger {
    color: white;
    &:hover,&:active {
      color: white;
    }
  }
  &.btn-secondary {
    color: $body-color !important;
    border-color: $body-color;
    border-width: 1.5px;
    line-height: $btn-line-height - 2;
    background-color: transparent !important;
    &:hover {
      color: white !important;
      svg path {
        fill: white;
      }
      background-color: $body-color !important;
    }
  }
  line-height: $btn-line-height - 1.5;
  height: $btn-line-height;
  &.btn-sm {
    border-radius: $btn-border-radius-sm;
    height: $btn-line-height-sm+1.5;
  }
  &.btn-xs {
    border-radius: $btn-border-radius-xs;
    line-height: $btn-line-height-xs - 2;
    height: $btn-line-height-xs;
    min-height: $btn-line-height-xs+1.5;
    font-size: $fs14;
  }
  &.btn-lg {
    padding-top: 0;
    padding-bottom: 0;
    line-height: $btn-line-height-lg+1;
    height: $btn-line-height-lg+1.5;
  }
  &.btn-outline-light {
    border-color: white;
    color: white;
    &:hover {
      background-color: transparent;
      color: white;
    }
  }
  &.btn-outline-primary {
    &:hover {
      background-color: $primaryAlpha8;
    }
    &:active {
      background-color: $primaryAlpha16 !important;
      color:$primary !important;
    }
    &:hover, &:active {
      color: $primary;
    }
  }

  &.btn-outline-secondary {
    &:hover {
      background-color: $primaryAlpha8;
    }
    &:active {
      background-color: $primaryAlpha16 !important;
      color:$primary !important;
    }
    &:hover, &:active {
      color: $primary;
    }
  }

  &.btn-tertiary {
    color: $dark500;
    background-color: $light300;
    height: 24px !important;
    min-height: auto !important;
    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
    line-height: normal;
    &:hover {
      background-color: $light200;
    }
  }

  &.btn-icon {
    padding: 0;
    min-width: $btn-line-height+2;
    display: flex;
    align-items: center;
    justify-content: center;
    &.btn-sm {
      min-width: $btn-line-height-sm+2;
    }
    &.btn-lg {
      min-width: $btn-line-height-lg+2;
    }
    &.btn-xs {
      min-width: $btn-line-height-xs+2;
    }
  }
}
.btn-pagination {
  font-size: $font-size-xs;
  line-height: normal !important;

  border-radius: $border-radius-sm;
  border: 1px solid $text-muted;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 28px;
  min-height: 28px;
  font-weight: $font-weight-semibold;
  &:disabled {
    opacity: 0.5;
  }
  &--active {
    background-color: $text-muted;
    color: $white;


  }}

  .icon-btn {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($dark500,0.7);
    border-radius: 20px;
    color: $white;
    border: 0px

  }
