.badge {
  padding: 3px $spacer*3;
  color:$badge-color;
  border-radius: $border-radius-sm;
  &.bg-secondary {
    color:$btn-secondary-color;
  }
  &--counter {
    height: 20px;
    min-width: 20px;
    padding:1px 0 0 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $font-weight-semibold;
  }
  &--dot {
    height: 12px;
    min-width: 12px;
    padding:0;
    border-radius: 6px;
  }
}
