$colors: (
        card: white,

        body: $body-color,
        primary: $primary,
        primary100: $primary100,
        primary200: $primary200,
        primary300: $primary300,
        primary400: $primary400,
        primaryAlpha8: $primaryAlpha8,
        primaryAlpha16: $primaryAlpha16,

        secondary: $secondary,
        secondary400: $secondary400,
        secondaryAlpha8: $secondaryAlpha8,
        secondaryAlpha16: $secondaryAlpha16,

        info: $info,
        action: $action,
        action100: $action100,
        action200: $action200,

        warning: $warning,
        warning400: $warning400,
        warning700: $warning700,
        warning900: $warning900,

        success: $success,
        success400: $success400,

        danger: $danger,
        danger400: $danger400,
        danger900: $danger900,

        white: white,
        light: $light500,
        light100: $light100,
        light200: $light200,
        light300: $light300,
        light400: $light400,
        light600: $light600,
        light700: $light700,
        light800: $light800,
        blue-grey-dark: $blue-grey-dark,
        dark: $dark500,
        dark100: $dark100,
        dark200: $dark200,
        dark300: $dark300,
        dark400: $dark400,
        darkMuted: $text-muted,
        blue-grey: $blue-grey,
        phone: $phone,
        forward: $forward,
        scan: $scan,
        purple900: $purple900,
        transparent: transparent,
);

$theme-colors: $colors;



$body-secondary-color: $dark300; //text-muted
$body-color: $dark500;
$body-color-dark: $light300;

$btn-primary-color: white;
$btn-primary-hover-background: $primary400;
$btn-primary-active-background: $primary;
$btn-secondary-color: white;
$btn-secondary-hover-background: $secondary400;
$btn-secondary-active-background: $secondary;

$switch-bg: $success;
$switch-bg-off: $light600;

$badge-color:white;
$form-check-input-bg: $action;
$form-check-input-border-color: $input-border-color;
$form-check-input-checked-border-color: $action;
$form-check-focus-border-color: $action;
$form-check-input-checked-bg-color:$action;
$form-check-input-focus-border:$action;
$form-check-input-focus-bg-color:$actionAlpha16;
$form-check-input-checked-focus-bg-color:$successAlpha16;
$form-check-input-focus-box-shadow: none;
$form-check-radio-checked-bg-color: $action;
$form-check-input-checked-color: $success;

$form-check-input-checked-bg-image: url('data:image/svg+xml,<svg width="22" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7566 6.36121C15.0965 6.82168 15.0782 7.54493 14.7155 7.97662L9.91555 13.6909C9.56936 14.103 9.03065 14.103 8.68445 13.6909L6.28446 10.8338C5.92183 10.4021 5.90346 9.67883 6.24342 9.21836C6.58338 8.75788 7.15293 8.73455 7.51555 9.16625L9.3 11.2906L13.4845 6.3091C13.8471 5.87741 14.4166 5.90074 14.7566 6.36121Z" fill="#{$form-check-input-checked-color}"/></svg>');
$form-check-radio-checked-bg-image: url('data:image/svg+xml,<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4993 21.0846C6.20647 21.0846 1.91602 16.7942 1.91602 11.5013C1.91602 6.20843 6.20647 1.91797 11.4993 1.91797C16.7922 1.91797 21.0827 6.20843 21.0827 11.5013C21.0827 16.7942 16.7922 21.0846 11.4993 21.0846Z" fill="#1977EC"/><path d="M9.81621 15.7994L5.75 11.7332L7.10508 10.3781L9.81621 13.0893L15.2365 7.66797L16.5926 9.02305L9.81621 15.7994Z" fill="white"/></svg>');

$input-focus-border-color: $primary;
$input-bg: transparent;
$input-bg-autofill: #E7F0FE;
$dropdown-link-hover-bg: $primaryAlpha8;
$dropdown-link-active-bg: $primary;
$dropdown-link-active-text: white;

$th-bg: white;
$th-color: $text-muted;
$th-border: $input-border-color;

$border-color: $light500;
$input-icon-color: $body-secondary-color;


$aside-bg:$light200;
$aside-section-bg:black;

$aside-item-active:$action;
$aside-text:white;
$aside-text-inactive:$body-color;
$aside-item-hover-bg:$light300;
$aside-item-hover-color: $body-color;
$navbar-bg: $light200;

$tooltip-bg: $dark500;
$tooltip-text: white;


$chip-color: $light700;
$chip-bg: $light400;
$chip-hover-bg: $light400;

$dropdown-bg: $light100;
$shadow-1: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
$shadow-2: 0px 1px 10px 0px rgba(167, 172, 189, 0.15);
$shadow-3: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
$shadow-4: 0px 8px 35px 0px rgba(0, 0, 0, 0.16);
@each $name, $hex in $theme-colors {
  .hover-bg-#{$name}:hover{ //workaround for lack of text-Theme support in dark mode
    background-color: $hex !important;
    &.bg-opacity-10 {
      background-color: rgba($hex,0.1) !important;
    }
  }
}
@each $name, $hex in $theme-colors {
  .text-#{$name}{ //workaround for lack of text-Theme support in dark mode
    color: $hex !important;
  }
}
@each $name, $hex in $theme-colors {
  .fill-#{$name} { //workaround for lack of text-Theme support in dark mode
    fill: $hex !important;
    path {
      fill: $hex !important;
    }
  }
}
.hover-primary:hover, .hover-target:hover>.hover-primary {
  color: $primary!important;
}

.border.border-color-transparent {
  border-color: transparent !important;
}
