@import "~react-calendar/dist/Calendar.css";

.react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
  display: none !important;
}

.react-calendar {
  &__navigation__label {
    display: flex;
    gap:8px;
  }
  padding: 0 $spacer*2 $spacer*2 $spacer*2;
  border: none;
  width: 300px;
  &.react-calendar--doubleView {
    width: 600px;
  }
  color: $body-color;
  background-color: $dropdown-bg;
  abbr[title] {
    cursor: default;
    text-decoration: none;
  }
  button, .react-calendar__month-view__days__day--weekend {
    background-color: $dropdown-bg !important;
    padding: $spacer;
    border-radius: 0 !important;
    justify-content: center;
    display: flex;
    align-items: center;
    &.react-calendar__tile--now {
      >* {
        color: $primary;
        font-weight: $font-weight-semibold;
      }
    }
    &.react-calendar__tile--hasActive,&.react-calendar__tile--active {
      >* {
        background-color: $primary !important;
        color: white;
      }
    }

    >* {
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: $border-radius;
      height: 28px;
      color: $body-color;
      user-select: none;
    }
    &.react-calendar__tile--range {
      background-color: $primaryAlpha16 !important;
      &.react-calendar__tile--rangeBothEnds {
        background-color: $dropdown-bg !important;
      }
      >* {
        background-color: transparent !important;
        color: $body-color !important;
      }
      &.react-calendar__tile--rangeStart {

        border-top-left-radius: $border-radius !important;
      }
      &.react-calendar__tile--rangeEnd {
        border-bottom-right-radius: $border-radius !important;
      }
      &.react-calendar__tile--rangeStart, &.react-calendar__tile--rangeEnd {
        >* {
          background-color: $primary !important;
          color: white !important;
        }
      }
    }
    &:hover,&:focus {
      >* {
        background-color: $primaryAlpha16 !important;
      }
    }
  }
  .react-calendar__year-view__months__month {
    padding: 0;
    >* {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .react-calendar__decade-view__years__year, .react-calendar__century-view__decades__decade {
    padding: 8px;
  }
  .react-calendar__navigation {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: $spacer;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: $text-muted;
  }

  .react-calendar__tile--hover {
    background-color: $primaryAlpha16 !important;
  }
  .react-calendar__tile--hover {
    background-color: $primaryAlpha16 !important;
  }

  .react-calendar__month-view__days__day >* {
    height: 28px !important;
    width: 28px !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }
}
