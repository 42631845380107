.json-container {
  .fa {
    width: 14px;
    margin-left: -10px;
    padding-right: 2px;
  }
  background: $dropdown-bg !important;
  color: $body-color;
  .label,.punctuation,.collapsedContent {
    color:$body-color
  }
  .stringValue {
    color: $primary;
  }
}
