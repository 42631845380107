/*! purgecss start ignore */
.address-search-box {
  max-width: 500px;
}
.react-loqate-default-list {
  -webkit-padding-start: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  background-color: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  list-style-type: none;
  margin-block-end: 0;
  margin-block-start: 0;
  padding-inline-start: 0;
  max-height: 247px;
  overflow-y: scroll !important;
  position: absolute !important;
  top:75px!important;
  left:0 !important;
  border: 1px solid #939393;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
  margin-top: -4px;
  z-index: 1;
}

.react-loqate-list-item {
  @extend .dropdown-item;
  @extend .fs-sm;
  cursor: pointer;
  user-select: none;
  padding: $dropdown-item-padding-x $dropdown-item-padding-y;
  &:first-of-type {
    border-radius: 0 !important;
  }
}


/*! purgecss end ignore */
