/*!
 * Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-duotone: 'Font Awesome 6 Duotone';
  --fa-font-duotone: normal 900 1em/1 'Font Awesome 6 Duotone'; }

@font-face {
  font-family: 'Font Awesome 6 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('#{$fa-font-path}/fa-duotone-900.woff2') format('woff2'), url('#{$fa-font-path}/fa-duotone-900.ttf') format('truetype'); }

.fad,
.fa-duotone {
  position: relative;
  font-weight: 900;
  letter-spacing: normal; }

.fad::before,
.fa-duotone::before {
  position: absolute;
  color: var(--fa-primary-color, inherit);
  opacity: var(--fa-primary-opacity, 1); }

.fad::after,
.fa-duotone::after {
  color: var(--fa-secondary-color, inherit);
  opacity: var(--fa-secondary-opacity, 0.4); }

.fa-swap-opacity .fad::before,
.fa-swap-opacity .fa-duotone::before,
.fad.fa-swap-opacity::before,
.fa-duotone.fa-swap-opacity::before {
  opacity: var(--fa-secondary-opacity, 0.4); }

.fa-swap-opacity .fad::after,
.fa-swap-opacity .fa-duotone::after,
.fad.fa-swap-opacity::after,
.fa-duotone.fa-swap-opacity::after {
  opacity: var(--fa-primary-opacity, 1); }

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff); }

.fad.fa-stack-1x, .fad.fa-stack-2x,
.fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
  position: absolute; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen readers do not read off random characters that represent icons */

.fad.fa-0::after, .fa-duotone.fa-0::after {
  content: '\30\30'; }

.fad.fa-1::after, .fa-duotone.fa-1::after {
  content: '\31\31'; }

.fad.fa-2::after, .fa-duotone.fa-2::after {
  content: '\32\32'; }

.fad.fa-3::after, .fa-duotone.fa-3::after {
  content: '\33\33'; }

.fad.fa-4::after, .fa-duotone.fa-4::after {
  content: '\34\34'; }

.fad.fa-5::after, .fa-duotone.fa-5::after {
  content: '\35\35'; }

.fad.fa-6::after, .fa-duotone.fa-6::after {
  content: '\36\36'; }

.fad.fa-7::after, .fa-duotone.fa-7::after {
  content: '\37\37'; }

.fad.fa-8::after, .fa-duotone.fa-8::after {
  content: '\38\38'; }

.fad.fa-9::after, .fa-duotone.fa-9::after {
  content: '\39\39'; }

.fad.fa-fill-drip::after, .fa-duotone.fa-fill-drip::after {
  content: '\f576\f576'; }

.fad.fa-arrows-to-circle::after, .fa-duotone.fa-arrows-to-circle::after {
  content: '\e4bd\e4bd'; }

.fad.fa-circle-chevron-right::after, .fa-duotone.fa-circle-chevron-right::after {
  content: '\f138\f138'; }

.fad.fa-chevron-circle-right::after, .fa-duotone.fa-chevron-circle-right::after {
  content: '\f138\f138'; }

.fad.fa-wagon-covered::after, .fa-duotone.fa-wagon-covered::after {
  content: '\f8ee\f8ee'; }

.fad.fa-line-height::after, .fa-duotone.fa-line-height::after {
  content: '\f871\f871'; }

.fad.fa-bagel::after, .fa-duotone.fa-bagel::after {
  content: '\e3d7\e3d7'; }

.fad.fa-transporter-7::after, .fa-duotone.fa-transporter-7::after {
  content: '\e2a8\e2a8'; }

.fad.fa-at::after, .fa-duotone.fa-at::after {
  content: '\40\40'; }

.fad.fa-rectangles-mixed::after, .fa-duotone.fa-rectangles-mixed::after {
  content: '\e323\e323'; }

.fad.fa-phone-arrow-up-right::after, .fa-duotone.fa-phone-arrow-up-right::after {
  content: '\e224\e224'; }

.fad.fa-phone-arrow-up::after, .fa-duotone.fa-phone-arrow-up::after {
  content: '\e224\e224'; }

.fad.fa-phone-outgoing::after, .fa-duotone.fa-phone-outgoing::after {
  content: '\e224\e224'; }

.fad.fa-trash-can::after, .fa-duotone.fa-trash-can::after {
  content: '\f2ed\f2ed'; }

.fad.fa-trash-alt::after, .fa-duotone.fa-trash-alt::after {
  content: '\f2ed\f2ed'; }

.fad.fa-circle-l::after, .fa-duotone.fa-circle-l::after {
  content: '\e114\e114'; }

.fad.fa-head-side-goggles::after, .fa-duotone.fa-head-side-goggles::after {
  content: '\f6ea\f6ea'; }

.fad.fa-head-vr::after, .fa-duotone.fa-head-vr::after {
  content: '\f6ea\f6ea'; }

.fad.fa-text-height::after, .fa-duotone.fa-text-height::after {
  content: '\f034\f034'; }

.fad.fa-user-xmark::after, .fa-duotone.fa-user-xmark::after {
  content: '\f235\f235'; }

.fad.fa-user-times::after, .fa-duotone.fa-user-times::after {
  content: '\f235\f235'; }

.fad.fa-face-hand-yawn::after, .fa-duotone.fa-face-hand-yawn::after {
  content: '\e379\e379'; }

.fad.fa-gauge-simple-min::after, .fa-duotone.fa-gauge-simple-min::after {
  content: '\f62d\f62d'; }

.fad.fa-tachometer-slowest::after, .fa-duotone.fa-tachometer-slowest::after {
  content: '\f62d\f62d'; }

.fad.fa-stethoscope::after, .fa-duotone.fa-stethoscope::after {
  content: '\f0f1\f0f1'; }

.fad.fa-coffin::after, .fa-duotone.fa-coffin::after {
  content: '\f6c6\f6c6'; }

.fad.fa-message::after, .fa-duotone.fa-message::after {
  content: '\f27a\f27a'; }

.fad.fa-comment-alt::after, .fa-duotone.fa-comment-alt::after {
  content: '\f27a\f27a'; }

.fad.fa-salad::after, .fa-duotone.fa-salad::after {
  content: '\f81e\f81e'; }

.fad.fa-bowl-salad::after, .fa-duotone.fa-bowl-salad::after {
  content: '\f81e\f81e'; }

.fad.fa-info::after, .fa-duotone.fa-info::after {
  content: '\f129\f129'; }

.fad.fa-robot-astromech::after, .fa-duotone.fa-robot-astromech::after {
  content: '\e2d2\e2d2'; }

.fad.fa-ring-diamond::after, .fa-duotone.fa-ring-diamond::after {
  content: '\e5ab\e5ab'; }

.fad.fa-fondue-pot::after, .fa-duotone.fa-fondue-pot::after {
  content: '\e40d\e40d'; }

.fad.fa-theta::after, .fa-duotone.fa-theta::after {
  content: '\f69e\f69e'; }

.fad.fa-face-hand-peeking::after, .fa-duotone.fa-face-hand-peeking::after {
  content: '\e481\e481'; }

.fad.fa-square-user::after, .fa-duotone.fa-square-user::after {
  content: '\e283\e283'; }

.fad.fa-down-left-and-up-right-to-center::after, .fa-duotone.fa-down-left-and-up-right-to-center::after {
  content: '\f422\f422'; }

.fad.fa-compress-alt::after, .fa-duotone.fa-compress-alt::after {
  content: '\f422\f422'; }

.fad.fa-explosion::after, .fa-duotone.fa-explosion::after {
  content: '\e4e9\e4e9'; }

.fad.fa-file-lines::after, .fa-duotone.fa-file-lines::after {
  content: '\f15c\f15c'; }

.fad.fa-file-alt::after, .fa-duotone.fa-file-alt::after {
  content: '\f15c\f15c'; }

.fad.fa-file-text::after, .fa-duotone.fa-file-text::after {
  content: '\f15c\f15c'; }

.fad.fa-wave-square::after, .fa-duotone.fa-wave-square::after {
  content: '\f83e\f83e'; }

.fad.fa-ring::after, .fa-duotone.fa-ring::after {
  content: '\f70b\f70b'; }

.fad.fa-building-un::after, .fa-duotone.fa-building-un::after {
  content: '\e4d9\e4d9'; }

.fad.fa-dice-three::after, .fa-duotone.fa-dice-three::after {
  content: '\f527\f527'; }

.fad.fa-tire-pressure-warning::after, .fa-duotone.fa-tire-pressure-warning::after {
  content: '\f633\f633'; }

.fad.fa-wifi-fair::after, .fa-duotone.fa-wifi-fair::after {
  content: '\f6ab\f6ab'; }

.fad.fa-wifi-2::after, .fa-duotone.fa-wifi-2::after {
  content: '\f6ab\f6ab'; }

.fad.fa-calendar-days::after, .fa-duotone.fa-calendar-days::after {
  content: '\f073\f073'; }

.fad.fa-calendar-alt::after, .fa-duotone.fa-calendar-alt::after {
  content: '\f073\f073'; }

.fad.fa-mp3-player::after, .fa-duotone.fa-mp3-player::after {
  content: '\f8ce\f8ce'; }

.fad.fa-anchor-circle-check::after, .fa-duotone.fa-anchor-circle-check::after {
  content: '\e4aa\e4aa'; }

.fad.fa-tally-4::after, .fa-duotone.fa-tally-4::after {
  content: '\e297\e297'; }

.fad.fa-rectangle-history::after, .fa-duotone.fa-rectangle-history::after {
  content: '\e4a2\e4a2'; }

.fad.fa-building-circle-arrow-right::after, .fa-duotone.fa-building-circle-arrow-right::after {
  content: '\e4d1\e4d1'; }

.fad.fa-volleyball::after, .fa-duotone.fa-volleyball::after {
  content: '\f45f\f45f'; }

.fad.fa-volleyball-ball::after, .fa-duotone.fa-volleyball-ball::after {
  content: '\f45f\f45f'; }

.fad.fa-sun-haze::after, .fa-duotone.fa-sun-haze::after {
  content: '\f765\f765'; }

.fad.fa-text-size::after, .fa-duotone.fa-text-size::after {
  content: '\f894\f894'; }

.fad.fa-ufo::after, .fa-duotone.fa-ufo::after {
  content: '\e047\e047'; }

.fad.fa-fork::after, .fa-duotone.fa-fork::after {
  content: '\f2e3\f2e3'; }

.fad.fa-utensil-fork::after, .fa-duotone.fa-utensil-fork::after {
  content: '\f2e3\f2e3'; }

.fad.fa-arrows-up-to-line::after, .fa-duotone.fa-arrows-up-to-line::after {
  content: '\e4c2\e4c2'; }

.fad.fa-mobile-signal::after, .fa-duotone.fa-mobile-signal::after {
  content: '\e1ef\e1ef'; }

.fad.fa-barcode-scan::after, .fa-duotone.fa-barcode-scan::after {
  content: '\f465\f465'; }

.fad.fa-sort-down::after, .fa-duotone.fa-sort-down::after {
  content: '\f0dd\f0dd'; }

.fad.fa-sort-desc::after, .fa-duotone.fa-sort-desc::after {
  content: '\f0dd\f0dd'; }

.fad.fa-folder-arrow-down::after, .fa-duotone.fa-folder-arrow-down::after {
  content: '\e053\e053'; }

.fad.fa-folder-download::after, .fa-duotone.fa-folder-download::after {
  content: '\e053\e053'; }

.fad.fa-circle-minus::after, .fa-duotone.fa-circle-minus::after {
  content: '\f056\f056'; }

.fad.fa-minus-circle::after, .fa-duotone.fa-minus-circle::after {
  content: '\f056\f056'; }

.fad.fa-face-icicles::after, .fa-duotone.fa-face-icicles::after {
  content: '\e37c\e37c'; }

.fad.fa-shovel::after, .fa-duotone.fa-shovel::after {
  content: '\f713\f713'; }

.fad.fa-door-open::after, .fa-duotone.fa-door-open::after {
  content: '\f52b\f52b'; }

.fad.fa-films::after, .fa-duotone.fa-films::after {
  content: '\e17a\e17a'; }

.fad.fa-right-from-bracket::after, .fa-duotone.fa-right-from-bracket::after {
  content: '\f2f5\f2f5'; }

.fad.fa-sign-out-alt::after, .fa-duotone.fa-sign-out-alt::after {
  content: '\f2f5\f2f5'; }

.fad.fa-face-glasses::after, .fa-duotone.fa-face-glasses::after {
  content: '\e377\e377'; }

.fad.fa-nfc::after, .fa-duotone.fa-nfc::after {
  content: '\e1f7\e1f7'; }

.fad.fa-atom::after, .fa-duotone.fa-atom::after {
  content: '\f5d2\f5d2'; }

.fad.fa-soap::after, .fa-duotone.fa-soap::after {
  content: '\e06e\e06e'; }

.fad.fa-icons::after, .fa-duotone.fa-icons::after {
  content: '\f86d\f86d'; }

.fad.fa-heart-music-camera-bolt::after, .fa-duotone.fa-heart-music-camera-bolt::after {
  content: '\f86d\f86d'; }

.fad.fa-microphone-lines-slash::after, .fa-duotone.fa-microphone-lines-slash::after {
  content: '\f539\f539'; }

.fad.fa-microphone-alt-slash::after, .fa-duotone.fa-microphone-alt-slash::after {
  content: '\f539\f539'; }

.fad.fa-closed-captioning-slash::after, .fa-duotone.fa-closed-captioning-slash::after {
  content: '\e135\e135'; }

.fad.fa-calculator-simple::after, .fa-duotone.fa-calculator-simple::after {
  content: '\f64c\f64c'; }

.fad.fa-calculator-alt::after, .fa-duotone.fa-calculator-alt::after {
  content: '\f64c\f64c'; }

.fad.fa-bridge-circle-check::after, .fa-duotone.fa-bridge-circle-check::after {
  content: '\e4c9\e4c9'; }

.fad.fa-sliders-up::after, .fa-duotone.fa-sliders-up::after {
  content: '\f3f1\f3f1'; }

.fad.fa-sliders-v::after, .fa-duotone.fa-sliders-v::after {
  content: '\f3f1\f3f1'; }

.fad.fa-location-minus::after, .fa-duotone.fa-location-minus::after {
  content: '\f609\f609'; }

.fad.fa-map-marker-minus::after, .fa-duotone.fa-map-marker-minus::after {
  content: '\f609\f609'; }

.fad.fa-pump-medical::after, .fa-duotone.fa-pump-medical::after {
  content: '\e06a\e06a'; }

.fad.fa-fingerprint::after, .fa-duotone.fa-fingerprint::after {
  content: '\f577\f577'; }

.fad.fa-ski-boot::after, .fa-duotone.fa-ski-boot::after {
  content: '\e3cc\e3cc'; }

.fad.fa-standard-definition::after, .fa-duotone.fa-standard-definition::after {
  content: '\e28a\e28a'; }

.fad.fa-rectangle-sd::after, .fa-duotone.fa-rectangle-sd::after {
  content: '\e28a\e28a'; }

.fad.fa-h1::after, .fa-duotone.fa-h1::after {
  content: '\f313\f313'; }

.fad.fa-hand-point-right::after, .fa-duotone.fa-hand-point-right::after {
  content: '\f0a4\f0a4'; }

.fad.fa-magnifying-glass-location::after, .fa-duotone.fa-magnifying-glass-location::after {
  content: '\f689\f689'; }

.fad.fa-search-location::after, .fa-duotone.fa-search-location::after {
  content: '\f689\f689'; }

.fad.fa-message-bot::after, .fa-duotone.fa-message-bot::after {
  content: '\e3b8\e3b8'; }

.fad.fa-forward-step::after, .fa-duotone.fa-forward-step::after {
  content: '\f051\f051'; }

.fad.fa-step-forward::after, .fa-duotone.fa-step-forward::after {
  content: '\f051\f051'; }

.fad.fa-face-smile-beam::after, .fa-duotone.fa-face-smile-beam::after {
  content: '\f5b8\f5b8'; }

.fad.fa-smile-beam::after, .fa-duotone.fa-smile-beam::after {
  content: '\f5b8\f5b8'; }

.fad.fa-light-ceiling::after, .fa-duotone.fa-light-ceiling::after {
  content: '\e016\e016'; }

.fad.fa-message-exclamation::after, .fa-duotone.fa-message-exclamation::after {
  content: '\f4a5\f4a5'; }

.fad.fa-comment-alt-exclamation::after, .fa-duotone.fa-comment-alt-exclamation::after {
  content: '\f4a5\f4a5'; }

.fad.fa-bowl-scoop::after, .fa-duotone.fa-bowl-scoop::after {
  content: '\e3de\e3de'; }

.fad.fa-bowl-shaved-ice::after, .fa-duotone.fa-bowl-shaved-ice::after {
  content: '\e3de\e3de'; }

.fad.fa-square-x::after, .fa-duotone.fa-square-x::after {
  content: '\e286\e286'; }

.fad.fa-utility-pole-double::after, .fa-duotone.fa-utility-pole-double::after {
  content: '\e2c4\e2c4'; }

.fad.fa-flag-checkered::after, .fa-duotone.fa-flag-checkered::after {
  content: '\f11e\f11e'; }

.fad.fa-chevrons-up::after, .fa-duotone.fa-chevrons-up::after {
  content: '\f325\f325'; }

.fad.fa-chevron-double-up::after, .fa-duotone.fa-chevron-double-up::after {
  content: '\f325\f325'; }

.fad.fa-football::after, .fa-duotone.fa-football::after {
  content: '\f44e\f44e'; }

.fad.fa-football-ball::after, .fa-duotone.fa-football-ball::after {
  content: '\f44e\f44e'; }

.fad.fa-user-vneck::after, .fa-duotone.fa-user-vneck::after {
  content: '\e461\e461'; }

.fad.fa-school-circle-exclamation::after, .fa-duotone.fa-school-circle-exclamation::after {
  content: '\e56c\e56c'; }

.fad.fa-crop::after, .fa-duotone.fa-crop::after {
  content: '\f125\f125'; }

.fad.fa-angles-down::after, .fa-duotone.fa-angles-down::after {
  content: '\f103\f103'; }

.fad.fa-angle-double-down::after, .fa-duotone.fa-angle-double-down::after {
  content: '\f103\f103'; }

.fad.fa-users-rectangle::after, .fa-duotone.fa-users-rectangle::after {
  content: '\e594\e594'; }

.fad.fa-people-roof::after, .fa-duotone.fa-people-roof::after {
  content: '\e537\e537'; }

.fad.fa-square-arrow-right::after, .fa-duotone.fa-square-arrow-right::after {
  content: '\f33b\f33b'; }

.fad.fa-arrow-square-right::after, .fa-duotone.fa-arrow-square-right::after {
  content: '\f33b\f33b'; }

.fad.fa-location-plus::after, .fa-duotone.fa-location-plus::after {
  content: '\f60a\f60a'; }

.fad.fa-map-marker-plus::after, .fa-duotone.fa-map-marker-plus::after {
  content: '\f60a\f60a'; }

.fad.fa-lightbulb-exclamation-on::after, .fa-duotone.fa-lightbulb-exclamation-on::after {
  content: '\e1ca\e1ca'; }

.fad.fa-people-line::after, .fa-duotone.fa-people-line::after {
  content: '\e534\e534'; }

.fad.fa-beer-mug-empty::after, .fa-duotone.fa-beer-mug-empty::after {
  content: '\f0fc\f0fc'; }

.fad.fa-beer::after, .fa-duotone.fa-beer::after {
  content: '\f0fc\f0fc'; }

.fad.fa-crate-empty::after, .fa-duotone.fa-crate-empty::after {
  content: '\e151\e151'; }

.fad.fa-diagram-predecessor::after, .fa-duotone.fa-diagram-predecessor::after {
  content: '\e477\e477'; }

.fad.fa-transporter::after, .fa-duotone.fa-transporter::after {
  content: '\e042\e042'; }

.fad.fa-calendar-circle-user::after, .fa-duotone.fa-calendar-circle-user::after {
  content: '\e471\e471'; }

.fad.fa-arrow-up-long::after, .fa-duotone.fa-arrow-up-long::after {
  content: '\f176\f176'; }

.fad.fa-long-arrow-up::after, .fa-duotone.fa-long-arrow-up::after {
  content: '\f176\f176'; }

.fad.fa-person-carry-box::after, .fa-duotone.fa-person-carry-box::after {
  content: '\f4cf\f4cf'; }

.fad.fa-person-carry::after, .fa-duotone.fa-person-carry::after {
  content: '\f4cf\f4cf'; }

.fad.fa-fire-flame-simple::after, .fa-duotone.fa-fire-flame-simple::after {
  content: '\f46a\f46a'; }

.fad.fa-burn::after, .fa-duotone.fa-burn::after {
  content: '\f46a\f46a'; }

.fad.fa-person::after, .fa-duotone.fa-person::after {
  content: '\f183\f183'; }

.fad.fa-male::after, .fa-duotone.fa-male::after {
  content: '\f183\f183'; }

.fad.fa-laptop::after, .fa-duotone.fa-laptop::after {
  content: '\f109\f109'; }

.fad.fa-file-csv::after, .fa-duotone.fa-file-csv::after {
  content: '\f6dd\f6dd'; }

.fad.fa-menorah::after, .fa-duotone.fa-menorah::after {
  content: '\f676\f676'; }

.fad.fa-union::after, .fa-duotone.fa-union::after {
  content: '\f6a2\f6a2'; }

.fad.fa-chevrons-left::after, .fa-duotone.fa-chevrons-left::after {
  content: '\f323\f323'; }

.fad.fa-chevron-double-left::after, .fa-duotone.fa-chevron-double-left::after {
  content: '\f323\f323'; }

.fad.fa-circle-heart::after, .fa-duotone.fa-circle-heart::after {
  content: '\f4c7\f4c7'; }

.fad.fa-heart-circle::after, .fa-duotone.fa-heart-circle::after {
  content: '\f4c7\f4c7'; }

.fad.fa-truck-plane::after, .fa-duotone.fa-truck-plane::after {
  content: '\e58f\e58f'; }

.fad.fa-record-vinyl::after, .fa-duotone.fa-record-vinyl::after {
  content: '\f8d9\f8d9'; }

.fad.fa-bring-forward::after, .fa-duotone.fa-bring-forward::after {
  content: '\f856\f856'; }

.fad.fa-square-p::after, .fa-duotone.fa-square-p::after {
  content: '\e279\e279'; }

.fad.fa-face-grin-stars::after, .fa-duotone.fa-face-grin-stars::after {
  content: '\f587\f587'; }

.fad.fa-grin-stars::after, .fa-duotone.fa-grin-stars::after {
  content: '\f587\f587'; }

.fad.fa-sigma::after, .fa-duotone.fa-sigma::after {
  content: '\f68b\f68b'; }

.fad.fa-camera-movie::after, .fa-duotone.fa-camera-movie::after {
  content: '\f8a9\f8a9'; }

.fad.fa-bong::after, .fa-duotone.fa-bong::after {
  content: '\f55c\f55c'; }

.fad.fa-clarinet::after, .fa-duotone.fa-clarinet::after {
  content: '\f8ad\f8ad'; }

.fad.fa-truck-flatbed::after, .fa-duotone.fa-truck-flatbed::after {
  content: '\e2b6\e2b6'; }

.fad.fa-spaghetti-monster-flying::after, .fa-duotone.fa-spaghetti-monster-flying::after {
  content: '\f67b\f67b'; }

.fad.fa-pastafarianism::after, .fa-duotone.fa-pastafarianism::after {
  content: '\f67b\f67b'; }

.fad.fa-arrow-down-up-across-line::after, .fa-duotone.fa-arrow-down-up-across-line::after {
  content: '\e4af\e4af'; }

.fad.fa-leaf-heart::after, .fa-duotone.fa-leaf-heart::after {
  content: '\f4cb\f4cb'; }

.fad.fa-house-building::after, .fa-duotone.fa-house-building::after {
  content: '\e1b1\e1b1'; }

.fad.fa-cheese-swiss::after, .fa-duotone.fa-cheese-swiss::after {
  content: '\f7f0\f7f0'; }

.fad.fa-spoon::after, .fa-duotone.fa-spoon::after {
  content: '\f2e5\f2e5'; }

.fad.fa-utensil-spoon::after, .fa-duotone.fa-utensil-spoon::after {
  content: '\f2e5\f2e5'; }

.fad.fa-jar-wheat::after, .fa-duotone.fa-jar-wheat::after {
  content: '\e517\e517'; }

.fad.fa-envelopes-bulk::after, .fa-duotone.fa-envelopes-bulk::after {
  content: '\f674\f674'; }

.fad.fa-mail-bulk::after, .fa-duotone.fa-mail-bulk::after {
  content: '\f674\f674'; }

.fad.fa-file-circle-exclamation::after, .fa-duotone.fa-file-circle-exclamation::after {
  content: '\e4eb\e4eb'; }

.fad.fa-bow-arrow::after, .fa-duotone.fa-bow-arrow::after {
  content: '\f6b9\f6b9'; }

.fad.fa-cart-xmark::after, .fa-duotone.fa-cart-xmark::after {
  content: '\e0dd\e0dd'; }

.fad.fa-hexagon-xmark::after, .fa-duotone.fa-hexagon-xmark::after {
  content: '\f2ee\f2ee'; }

.fad.fa-times-hexagon::after, .fa-duotone.fa-times-hexagon::after {
  content: '\f2ee\f2ee'; }

.fad.fa-xmark-hexagon::after, .fa-duotone.fa-xmark-hexagon::after {
  content: '\f2ee\f2ee'; }

.fad.fa-circle-h::after, .fa-duotone.fa-circle-h::after {
  content: '\f47e\f47e'; }

.fad.fa-hospital-symbol::after, .fa-duotone.fa-hospital-symbol::after {
  content: '\f47e\f47e'; }

.fad.fa-merge::after, .fa-duotone.fa-merge::after {
  content: '\e526\e526'; }

.fad.fa-pager::after, .fa-duotone.fa-pager::after {
  content: '\f815\f815'; }

.fad.fa-cart-minus::after, .fa-duotone.fa-cart-minus::after {
  content: '\e0db\e0db'; }

.fad.fa-address-book::after, .fa-duotone.fa-address-book::after {
  content: '\f2b9\f2b9'; }

.fad.fa-contact-book::after, .fa-duotone.fa-contact-book::after {
  content: '\f2b9\f2b9'; }

.fad.fa-pan-frying::after, .fa-duotone.fa-pan-frying::after {
  content: '\e42c\e42c'; }

.fad.fa-grid::after, .fa-duotone.fa-grid::after {
  content: '\e195\e195'; }

.fad.fa-grid-3::after, .fa-duotone.fa-grid-3::after {
  content: '\e195\e195'; }

.fad.fa-football-helmet::after, .fa-duotone.fa-football-helmet::after {
  content: '\f44f\f44f'; }

.fad.fa-hand-love::after, .fa-duotone.fa-hand-love::after {
  content: '\e1a5\e1a5'; }

.fad.fa-trees::after, .fa-duotone.fa-trees::after {
  content: '\f724\f724'; }

.fad.fa-strikethrough::after, .fa-duotone.fa-strikethrough::after {
  content: '\f0cc\f0cc'; }

.fad.fa-page::after, .fa-duotone.fa-page::after {
  content: '\e428\e428'; }

.fad.fa-k::after, .fa-duotone.fa-k::after {
  content: '\4b\4b'; }

.fad.fa-diagram-previous::after, .fa-duotone.fa-diagram-previous::after {
  content: '\e478\e478'; }

.fad.fa-gauge-min::after, .fa-duotone.fa-gauge-min::after {
  content: '\f628\f628'; }

.fad.fa-tachometer-alt-slowest::after, .fa-duotone.fa-tachometer-alt-slowest::after {
  content: '\f628\f628'; }

.fad.fa-folder-grid::after, .fa-duotone.fa-folder-grid::after {
  content: '\e188\e188'; }

.fad.fa-eggplant::after, .fa-duotone.fa-eggplant::after {
  content: '\e16c\e16c'; }

.fad.fa-ram::after, .fa-duotone.fa-ram::after {
  content: '\f70a\f70a'; }

.fad.fa-landmark-flag::after, .fa-duotone.fa-landmark-flag::after {
  content: '\e51c\e51c'; }

.fad.fa-lips::after, .fa-duotone.fa-lips::after {
  content: '\f600\f600'; }

.fad.fa-pencil::after, .fa-duotone.fa-pencil::after {
  content: '\f303\f303'; }

.fad.fa-pencil-alt::after, .fa-duotone.fa-pencil-alt::after {
  content: '\f303\f303'; }

.fad.fa-backward::after, .fa-duotone.fa-backward::after {
  content: '\f04a\f04a'; }

.fad.fa-caret-right::after, .fa-duotone.fa-caret-right::after {
  content: '\f0da\f0da'; }

.fad.fa-comments::after, .fa-duotone.fa-comments::after {
  content: '\f086\f086'; }

.fad.fa-paste::after, .fa-duotone.fa-paste::after {
  content: '\f0ea\f0ea'; }

.fad.fa-file-clipboard::after, .fa-duotone.fa-file-clipboard::after {
  content: '\f0ea\f0ea'; }

.fad.fa-desktop-arrow-down::after, .fa-duotone.fa-desktop-arrow-down::after {
  content: '\e155\e155'; }

.fad.fa-code-pull-request::after, .fa-duotone.fa-code-pull-request::after {
  content: '\e13c\e13c'; }

.fad.fa-pumpkin::after, .fa-duotone.fa-pumpkin::after {
  content: '\f707\f707'; }

.fad.fa-clipboard-list::after, .fa-duotone.fa-clipboard-list::after {
  content: '\f46d\f46d'; }

.fad.fa-pen-field::after, .fa-duotone.fa-pen-field::after {
  content: '\e211\e211'; }

.fad.fa-blueberries::after, .fa-duotone.fa-blueberries::after {
  content: '\e2e8\e2e8'; }

.fad.fa-truck-ramp-box::after, .fa-duotone.fa-truck-ramp-box::after {
  content: '\f4de\f4de'; }

.fad.fa-truck-loading::after, .fa-duotone.fa-truck-loading::after {
  content: '\f4de\f4de'; }

.fad.fa-note::after, .fa-duotone.fa-note::after {
  content: '\e1ff\e1ff'; }

.fad.fa-arrow-down-to-square::after, .fa-duotone.fa-arrow-down-to-square::after {
  content: '\e096\e096'; }

.fad.fa-user-check::after, .fa-duotone.fa-user-check::after {
  content: '\f4fc\f4fc'; }

.fad.fa-cloud-xmark::after, .fa-duotone.fa-cloud-xmark::after {
  content: '\e35f\e35f'; }

.fad.fa-vial-virus::after, .fa-duotone.fa-vial-virus::after {
  content: '\e597\e597'; }

.fad.fa-book-blank::after, .fa-duotone.fa-book-blank::after {
  content: '\f5d9\f5d9'; }

.fad.fa-book-alt::after, .fa-duotone.fa-book-alt::after {
  content: '\f5d9\f5d9'; }

.fad.fa-golf-flag-hole::after, .fa-duotone.fa-golf-flag-hole::after {
  content: '\e3ac\e3ac'; }

.fad.fa-message-arrow-down::after, .fa-duotone.fa-message-arrow-down::after {
  content: '\e1db\e1db'; }

.fad.fa-comment-alt-arrow-down::after, .fa-duotone.fa-comment-alt-arrow-down::after {
  content: '\e1db\e1db'; }

.fad.fa-face-unamused::after, .fa-duotone.fa-face-unamused::after {
  content: '\e39f\e39f'; }

.fad.fa-sheet-plastic::after, .fa-duotone.fa-sheet-plastic::after {
  content: '\e571\e571'; }

.fad.fa-circle-9::after, .fa-duotone.fa-circle-9::after {
  content: '\e0f6\e0f6'; }

.fad.fa-blog::after, .fa-duotone.fa-blog::after {
  content: '\f781\f781'; }

.fad.fa-user-ninja::after, .fa-duotone.fa-user-ninja::after {
  content: '\f504\f504'; }

.fad.fa-pencil-slash::after, .fa-duotone.fa-pencil-slash::after {
  content: '\e215\e215'; }

.fad.fa-bowling-pins::after, .fa-duotone.fa-bowling-pins::after {
  content: '\f437\f437'; }

.fad.fa-person-arrow-up-from-line::after, .fa-duotone.fa-person-arrow-up-from-line::after {
  content: '\e539\e539'; }

.fad.fa-down-right::after, .fa-duotone.fa-down-right::after {
  content: '\e16b\e16b'; }

.fad.fa-scroll-torah::after, .fa-duotone.fa-scroll-torah::after {
  content: '\f6a0\f6a0'; }

.fad.fa-torah::after, .fa-duotone.fa-torah::after {
  content: '\f6a0\f6a0'; }

.fad.fa-webhook::after, .fa-duotone.fa-webhook::after {
  content: '\e5d5\e5d5'; }

.fad.fa-blinds-open::after, .fa-duotone.fa-blinds-open::after {
  content: '\f8fc\f8fc'; }

.fad.fa-fence::after, .fa-duotone.fa-fence::after {
  content: '\e303\e303'; }

.fad.fa-up::after, .fa-duotone.fa-up::after {
  content: '\f357\f357'; }

.fad.fa-arrow-alt-up::after, .fa-duotone.fa-arrow-alt-up::after {
  content: '\f357\f357'; }

.fad.fa-broom-ball::after, .fa-duotone.fa-broom-ball::after {
  content: '\f458\f458'; }

.fad.fa-quidditch::after, .fa-duotone.fa-quidditch::after {
  content: '\f458\f458'; }

.fad.fa-quidditch-broom-ball::after, .fa-duotone.fa-quidditch-broom-ball::after {
  content: '\f458\f458'; }

.fad.fa-drumstick::after, .fa-duotone.fa-drumstick::after {
  content: '\f6d6\f6d6'; }

.fad.fa-square-v::after, .fa-duotone.fa-square-v::after {
  content: '\e284\e284'; }

.fad.fa-face-awesome::after, .fa-duotone.fa-face-awesome::after {
  content: '\e409\e409'; }

.fad.fa-gave-dandy::after, .fa-duotone.fa-gave-dandy::after {
  content: '\e409\e409'; }

.fad.fa-dial-off::after, .fa-duotone.fa-dial-off::after {
  content: '\e162\e162'; }

.fad.fa-toggle-off::after, .fa-duotone.fa-toggle-off::after {
  content: '\f204\f204'; }

.fad.fa-face-smile-horns::after, .fa-duotone.fa-face-smile-horns::after {
  content: '\e391\e391'; }

.fad.fa-box-archive::after, .fa-duotone.fa-box-archive::after {
  content: '\f187\f187'; }

.fad.fa-archive::after, .fa-duotone.fa-archive::after {
  content: '\f187\f187'; }

.fad.fa-grapes::after, .fa-duotone.fa-grapes::after {
  content: '\e306\e306'; }

.fad.fa-person-drowning::after, .fa-duotone.fa-person-drowning::after {
  content: '\e545\e545'; }

.fad.fa-dial-max::after, .fa-duotone.fa-dial-max::after {
  content: '\e15e\e15e'; }

.fad.fa-circle-m::after, .fa-duotone.fa-circle-m::after {
  content: '\e115\e115'; }

.fad.fa-calendar-image::after, .fa-duotone.fa-calendar-image::after {
  content: '\e0d4\e0d4'; }

.fad.fa-circle-caret-down::after, .fa-duotone.fa-circle-caret-down::after {
  content: '\f32d\f32d'; }

.fad.fa-caret-circle-down::after, .fa-duotone.fa-caret-circle-down::after {
  content: '\f32d\f32d'; }

.fad.fa-arrow-down-9-1::after, .fa-duotone.fa-arrow-down-9-1::after {
  content: '\f886\f886'; }

.fad.fa-sort-numeric-desc::after, .fa-duotone.fa-sort-numeric-desc::after {
  content: '\f886\f886'; }

.fad.fa-sort-numeric-down-alt::after, .fa-duotone.fa-sort-numeric-down-alt::after {
  content: '\f886\f886'; }

.fad.fa-face-grin-tongue-squint::after, .fa-duotone.fa-face-grin-tongue-squint::after {
  content: '\f58a\f58a'; }

.fad.fa-grin-tongue-squint::after, .fa-duotone.fa-grin-tongue-squint::after {
  content: '\f58a\f58a'; }

.fad.fa-shish-kebab::after, .fa-duotone.fa-shish-kebab::after {
  content: '\f821\f821'; }

.fad.fa-spray-can::after, .fa-duotone.fa-spray-can::after {
  content: '\f5bd\f5bd'; }

.fad.fa-alarm-snooze::after, .fa-duotone.fa-alarm-snooze::after {
  content: '\f845\f845'; }

.fad.fa-scarecrow::after, .fa-duotone.fa-scarecrow::after {
  content: '\f70d\f70d'; }

.fad.fa-truck-monster::after, .fa-duotone.fa-truck-monster::after {
  content: '\f63b\f63b'; }

.fad.fa-gift-card::after, .fa-duotone.fa-gift-card::after {
  content: '\f663\f663'; }

.fad.fa-w::after, .fa-duotone.fa-w::after {
  content: '\57\57'; }

.fad.fa-code-pull-request-draft::after, .fa-duotone.fa-code-pull-request-draft::after {
  content: '\e3fa\e3fa'; }

.fad.fa-square-b::after, .fa-duotone.fa-square-b::after {
  content: '\e264\e264'; }

.fad.fa-elephant::after, .fa-duotone.fa-elephant::after {
  content: '\f6da\f6da'; }

.fad.fa-earth-africa::after, .fa-duotone.fa-earth-africa::after {
  content: '\f57c\f57c'; }

.fad.fa-globe-africa::after, .fa-duotone.fa-globe-africa::after {
  content: '\f57c\f57c'; }

.fad.fa-rainbow::after, .fa-duotone.fa-rainbow::after {
  content: '\f75b\f75b'; }

.fad.fa-circle-notch::after, .fa-duotone.fa-circle-notch::after {
  content: '\f1ce\f1ce'; }

.fad.fa-tablet-screen-button::after, .fa-duotone.fa-tablet-screen-button::after {
  content: '\f3fa\f3fa'; }

.fad.fa-tablet-alt::after, .fa-duotone.fa-tablet-alt::after {
  content: '\f3fa\f3fa'; }

.fad.fa-paw::after, .fa-duotone.fa-paw::after {
  content: '\f1b0\f1b0'; }

.fad.fa-message-question::after, .fa-duotone.fa-message-question::after {
  content: '\e1e3\e1e3'; }

.fad.fa-cloud::after, .fa-duotone.fa-cloud::after {
  content: '\f0c2\f0c2'; }

.fad.fa-trowel-bricks::after, .fa-duotone.fa-trowel-bricks::after {
  content: '\e58a\e58a'; }

.fad.fa-square-3::after, .fa-duotone.fa-square-3::after {
  content: '\e258\e258'; }

.fad.fa-face-flushed::after, .fa-duotone.fa-face-flushed::after {
  content: '\f579\f579'; }

.fad.fa-flushed::after, .fa-duotone.fa-flushed::after {
  content: '\f579\f579'; }

.fad.fa-hospital-user::after, .fa-duotone.fa-hospital-user::after {
  content: '\f80d\f80d'; }

.fad.fa-microwave::after, .fa-duotone.fa-microwave::after {
  content: '\e01b\e01b'; }

.fad.fa-chf-sign::after, .fa-duotone.fa-chf-sign::after {
  content: '\e602\e602'; }

.fad.fa-tent-arrow-left-right::after, .fa-duotone.fa-tent-arrow-left-right::after {
  content: '\e57f\e57f'; }

.fad.fa-cart-circle-arrow-up::after, .fa-duotone.fa-cart-circle-arrow-up::after {
  content: '\e3f0\e3f0'; }

.fad.fa-trash-clock::after, .fa-duotone.fa-trash-clock::after {
  content: '\e2b0\e2b0'; }

.fad.fa-gavel::after, .fa-duotone.fa-gavel::after {
  content: '\f0e3\f0e3'; }

.fad.fa-legal::after, .fa-duotone.fa-legal::after {
  content: '\f0e3\f0e3'; }

.fad.fa-sprinkler-ceiling::after, .fa-duotone.fa-sprinkler-ceiling::after {
  content: '\e44c\e44c'; }

.fad.fa-browsers::after, .fa-duotone.fa-browsers::after {
  content: '\e0cb\e0cb'; }

.fad.fa-trillium::after, .fa-duotone.fa-trillium::after {
  content: '\e588\e588'; }

.fad.fa-music-slash::after, .fa-duotone.fa-music-slash::after {
  content: '\f8d1\f8d1'; }

.fad.fa-truck-ramp::after, .fa-duotone.fa-truck-ramp::after {
  content: '\f4e0\f4e0'; }

.fad.fa-binoculars::after, .fa-duotone.fa-binoculars::after {
  content: '\f1e5\f1e5'; }

.fad.fa-microphone-slash::after, .fa-duotone.fa-microphone-slash::after {
  content: '\f131\f131'; }

.fad.fa-box-tissue::after, .fa-duotone.fa-box-tissue::after {
  content: '\e05b\e05b'; }

.fad.fa-circle-c::after, .fa-duotone.fa-circle-c::after {
  content: '\e101\e101'; }

.fad.fa-star-christmas::after, .fa-duotone.fa-star-christmas::after {
  content: '\f7d4\f7d4'; }

.fad.fa-chart-bullet::after, .fa-duotone.fa-chart-bullet::after {
  content: '\e0e1\e0e1'; }

.fad.fa-motorcycle::after, .fa-duotone.fa-motorcycle::after {
  content: '\f21c\f21c'; }

.fad.fa-tree-christmas::after, .fa-duotone.fa-tree-christmas::after {
  content: '\f7db\f7db'; }

.fad.fa-tire-flat::after, .fa-duotone.fa-tire-flat::after {
  content: '\f632\f632'; }

.fad.fa-sunglasses::after, .fa-duotone.fa-sunglasses::after {
  content: '\f892\f892'; }

.fad.fa-badge::after, .fa-duotone.fa-badge::after {
  content: '\f335\f335'; }

.fad.fa-message-pen::after, .fa-duotone.fa-message-pen::after {
  content: '\f4a4\f4a4'; }

.fad.fa-comment-alt-edit::after, .fa-duotone.fa-comment-alt-edit::after {
  content: '\f4a4\f4a4'; }

.fad.fa-message-edit::after, .fa-duotone.fa-message-edit::after {
  content: '\f4a4\f4a4'; }

.fad.fa-bell-concierge::after, .fa-duotone.fa-bell-concierge::after {
  content: '\f562\f562'; }

.fad.fa-concierge-bell::after, .fa-duotone.fa-concierge-bell::after {
  content: '\f562\f562'; }

.fad.fa-pen-ruler::after, .fa-duotone.fa-pen-ruler::after {
  content: '\f5ae\f5ae'; }

.fad.fa-pencil-ruler::after, .fa-duotone.fa-pencil-ruler::after {
  content: '\f5ae\f5ae'; }

.fad.fa-arrow-progress::after, .fa-duotone.fa-arrow-progress::after {
  content: '\e5df\e5df'; }

.fad.fa-chess-rook-piece::after, .fa-duotone.fa-chess-rook-piece::after {
  content: '\f448\f448'; }

.fad.fa-chess-rook-alt::after, .fa-duotone.fa-chess-rook-alt::after {
  content: '\f448\f448'; }

.fad.fa-square-root::after, .fa-duotone.fa-square-root::after {
  content: '\f697\f697'; }

.fad.fa-album-collection-circle-plus::after, .fa-duotone.fa-album-collection-circle-plus::after {
  content: '\e48e\e48e'; }

.fad.fa-people-arrows::after, .fa-duotone.fa-people-arrows::after {
  content: '\e068\e068'; }

.fad.fa-people-arrows-left-right::after, .fa-duotone.fa-people-arrows-left-right::after {
  content: '\e068\e068'; }

.fad.fa-face-angry-horns::after, .fa-duotone.fa-face-angry-horns::after {
  content: '\e368\e368'; }

.fad.fa-mars-and-venus-burst::after, .fa-duotone.fa-mars-and-venus-burst::after {
  content: '\e523\e523'; }

.fad.fa-tombstone::after, .fa-duotone.fa-tombstone::after {
  content: '\f720\f720'; }

.fad.fa-square-caret-right::after, .fa-duotone.fa-square-caret-right::after {
  content: '\f152\f152'; }

.fad.fa-caret-square-right::after, .fa-duotone.fa-caret-square-right::after {
  content: '\f152\f152'; }

.fad.fa-scissors::after, .fa-duotone.fa-scissors::after {
  content: '\f0c4\f0c4'; }

.fad.fa-cut::after, .fa-duotone.fa-cut::after {
  content: '\f0c4\f0c4'; }

.fad.fa-list-music::after, .fa-duotone.fa-list-music::after {
  content: '\f8c9\f8c9'; }

.fad.fa-sun-plant-wilt::after, .fa-duotone.fa-sun-plant-wilt::after {
  content: '\e57a\e57a'; }

.fad.fa-toilets-portable::after, .fa-duotone.fa-toilets-portable::after {
  content: '\e584\e584'; }

.fad.fa-hockey-puck::after, .fa-duotone.fa-hockey-puck::after {
  content: '\f453\f453'; }

.fad.fa-mustache::after, .fa-duotone.fa-mustache::after {
  content: '\e5bc\e5bc'; }

.fad.fa-hyphen::after, .fa-duotone.fa-hyphen::after {
  content: '\2d\2d'; }

.fad.fa-table::after, .fa-duotone.fa-table::after {
  content: '\f0ce\f0ce'; }

.fad.fa-user-chef::after, .fa-duotone.fa-user-chef::after {
  content: '\e3d2\e3d2'; }

.fad.fa-message-image::after, .fa-duotone.fa-message-image::after {
  content: '\e1e0\e1e0'; }

.fad.fa-comment-alt-image::after, .fa-duotone.fa-comment-alt-image::after {
  content: '\e1e0\e1e0'; }

.fad.fa-users-medical::after, .fa-duotone.fa-users-medical::after {
  content: '\f830\f830'; }

.fad.fa-sensor-triangle-exclamation::after, .fa-duotone.fa-sensor-triangle-exclamation::after {
  content: '\e029\e029'; }

.fad.fa-sensor-alert::after, .fa-duotone.fa-sensor-alert::after {
  content: '\e029\e029'; }

.fad.fa-magnifying-glass-arrow-right::after, .fa-duotone.fa-magnifying-glass-arrow-right::after {
  content: '\e521\e521'; }

.fad.fa-tachograph-digital::after, .fa-duotone.fa-tachograph-digital::after {
  content: '\f566\f566'; }

.fad.fa-digital-tachograph::after, .fa-duotone.fa-digital-tachograph::after {
  content: '\f566\f566'; }

.fad.fa-face-mask::after, .fa-duotone.fa-face-mask::after {
  content: '\e37f\e37f'; }

.fad.fa-pickleball::after, .fa-duotone.fa-pickleball::after {
  content: '\e435\e435'; }

.fad.fa-star-sharp-half::after, .fa-duotone.fa-star-sharp-half::after {
  content: '\e28c\e28c'; }

.fad.fa-users-slash::after, .fa-duotone.fa-users-slash::after {
  content: '\e073\e073'; }

.fad.fa-clover::after, .fa-duotone.fa-clover::after {
  content: '\e139\e139'; }

.fad.fa-meat::after, .fa-duotone.fa-meat::after {
  content: '\f814\f814'; }

.fad.fa-reply::after, .fa-duotone.fa-reply::after {
  content: '\f3e5\f3e5'; }

.fad.fa-mail-reply::after, .fa-duotone.fa-mail-reply::after {
  content: '\f3e5\f3e5'; }

.fad.fa-star-and-crescent::after, .fa-duotone.fa-star-and-crescent::after {
  content: '\f699\f699'; }

.fad.fa-empty-set::after, .fa-duotone.fa-empty-set::after {
  content: '\f656\f656'; }

.fad.fa-house-fire::after, .fa-duotone.fa-house-fire::after {
  content: '\e50c\e50c'; }

.fad.fa-square-minus::after, .fa-duotone.fa-square-minus::after {
  content: '\f146\f146'; }

.fad.fa-minus-square::after, .fa-duotone.fa-minus-square::after {
  content: '\f146\f146'; }

.fad.fa-helicopter::after, .fa-duotone.fa-helicopter::after {
  content: '\f533\f533'; }

.fad.fa-bird::after, .fa-duotone.fa-bird::after {
  content: '\e469\e469'; }

.fad.fa-compass::after, .fa-duotone.fa-compass::after {
  content: '\f14e\f14e'; }

.fad.fa-square-caret-down::after, .fa-duotone.fa-square-caret-down::after {
  content: '\f150\f150'; }

.fad.fa-caret-square-down::after, .fa-duotone.fa-caret-square-down::after {
  content: '\f150\f150'; }

.fad.fa-heart-half-stroke::after, .fa-duotone.fa-heart-half-stroke::after {
  content: '\e1ac\e1ac'; }

.fad.fa-heart-half-alt::after, .fa-duotone.fa-heart-half-alt::after {
  content: '\e1ac\e1ac'; }

.fad.fa-file-circle-question::after, .fa-duotone.fa-file-circle-question::after {
  content: '\e4ef\e4ef'; }

.fad.fa-laptop-code::after, .fa-duotone.fa-laptop-code::after {
  content: '\f5fc\f5fc'; }

.fad.fa-joystick::after, .fa-duotone.fa-joystick::after {
  content: '\f8c5\f8c5'; }

.fad.fa-grill-fire::after, .fa-duotone.fa-grill-fire::after {
  content: '\e5a4\e5a4'; }

.fad.fa-rectangle-vertical-history::after, .fa-duotone.fa-rectangle-vertical-history::after {
  content: '\e237\e237'; }

.fad.fa-swatchbook::after, .fa-duotone.fa-swatchbook::after {
  content: '\f5c3\f5c3'; }

.fad.fa-prescription-bottle::after, .fa-duotone.fa-prescription-bottle::after {
  content: '\f485\f485'; }

.fad.fa-bars::after, .fa-duotone.fa-bars::after {
  content: '\f0c9\f0c9'; }

.fad.fa-navicon::after, .fa-duotone.fa-navicon::after {
  content: '\f0c9\f0c9'; }

.fad.fa-keyboard-left::after, .fa-duotone.fa-keyboard-left::after {
  content: '\e1c3\e1c3'; }

.fad.fa-people-group::after, .fa-duotone.fa-people-group::after {
  content: '\e533\e533'; }

.fad.fa-hourglass-end::after, .fa-duotone.fa-hourglass-end::after {
  content: '\f253\f253'; }

.fad.fa-hourglass-3::after, .fa-duotone.fa-hourglass-3::after {
  content: '\f253\f253'; }

.fad.fa-heart-crack::after, .fa-duotone.fa-heart-crack::after {
  content: '\f7a9\f7a9'; }

.fad.fa-heart-broken::after, .fa-duotone.fa-heart-broken::after {
  content: '\f7a9\f7a9'; }

.fad.fa-face-beam-hand-over-mouth::after, .fa-duotone.fa-face-beam-hand-over-mouth::after {
  content: '\e47c\e47c'; }

.fad.fa-droplet-percent::after, .fa-duotone.fa-droplet-percent::after {
  content: '\f750\f750'; }

.fad.fa-humidity::after, .fa-duotone.fa-humidity::after {
  content: '\f750\f750'; }

.fad.fa-square-up-right::after, .fa-duotone.fa-square-up-right::after {
  content: '\f360\f360'; }

.fad.fa-external-link-square-alt::after, .fa-duotone.fa-external-link-square-alt::after {
  content: '\f360\f360'; }

.fad.fa-face-kiss-beam::after, .fa-duotone.fa-face-kiss-beam::after {
  content: '\f597\f597'; }

.fad.fa-kiss-beam::after, .fa-duotone.fa-kiss-beam::after {
  content: '\f597\f597'; }

.fad.fa-corn::after, .fa-duotone.fa-corn::after {
  content: '\f6c7\f6c7'; }

.fad.fa-roller-coaster::after, .fa-duotone.fa-roller-coaster::after {
  content: '\e324\e324'; }

.fad.fa-photo-film-music::after, .fa-duotone.fa-photo-film-music::after {
  content: '\e228\e228'; }

.fad.fa-radar::after, .fa-duotone.fa-radar::after {
  content: '\e024\e024'; }

.fad.fa-sickle::after, .fa-duotone.fa-sickle::after {
  content: '\f822\f822'; }

.fad.fa-film::after, .fa-duotone.fa-film::after {
  content: '\f008\f008'; }

.fad.fa-coconut::after, .fa-duotone.fa-coconut::after {
  content: '\e2f6\e2f6'; }

.fad.fa-ruler-horizontal::after, .fa-duotone.fa-ruler-horizontal::after {
  content: '\f547\f547'; }

.fad.fa-shield-cross::after, .fa-duotone.fa-shield-cross::after {
  content: '\f712\f712'; }

.fad.fa-cassette-tape::after, .fa-duotone.fa-cassette-tape::after {
  content: '\f8ab\f8ab'; }

.fad.fa-square-terminal::after, .fa-duotone.fa-square-terminal::after {
  content: '\e32a\e32a'; }

.fad.fa-people-robbery::after, .fa-duotone.fa-people-robbery::after {
  content: '\e536\e536'; }

.fad.fa-lightbulb::after, .fa-duotone.fa-lightbulb::after {
  content: '\f0eb\f0eb'; }

.fad.fa-caret-left::after, .fa-duotone.fa-caret-left::after {
  content: '\f0d9\f0d9'; }

.fad.fa-comment-middle::after, .fa-duotone.fa-comment-middle::after {
  content: '\e149\e149'; }

.fad.fa-trash-can-list::after, .fa-duotone.fa-trash-can-list::after {
  content: '\e2ab\e2ab'; }

.fad.fa-block::after, .fa-duotone.fa-block::after {
  content: '\e46a\e46a'; }

.fad.fa-circle-exclamation::after, .fa-duotone.fa-circle-exclamation::after {
  content: '\f06a\f06a'; }

.fad.fa-exclamation-circle::after, .fa-duotone.fa-exclamation-circle::after {
  content: '\f06a\f06a'; }

.fad.fa-school-circle-xmark::after, .fa-duotone.fa-school-circle-xmark::after {
  content: '\e56d\e56d'; }

.fad.fa-arrow-right-from-bracket::after, .fa-duotone.fa-arrow-right-from-bracket::after {
  content: '\f08b\f08b'; }

.fad.fa-sign-out::after, .fa-duotone.fa-sign-out::after {
  content: '\f08b\f08b'; }

.fad.fa-face-frown-slight::after, .fa-duotone.fa-face-frown-slight::after {
  content: '\e376\e376'; }

.fad.fa-circle-chevron-down::after, .fa-duotone.fa-circle-chevron-down::after {
  content: '\f13a\f13a'; }

.fad.fa-chevron-circle-down::after, .fa-duotone.fa-chevron-circle-down::after {
  content: '\f13a\f13a'; }

.fad.fa-sidebar-flip::after, .fa-duotone.fa-sidebar-flip::after {
  content: '\e24f\e24f'; }

.fad.fa-unlock-keyhole::after, .fa-duotone.fa-unlock-keyhole::after {
  content: '\f13e\f13e'; }

.fad.fa-unlock-alt::after, .fa-duotone.fa-unlock-alt::after {
  content: '\f13e\f13e'; }

.fad.fa-temperature-list::after, .fa-duotone.fa-temperature-list::after {
  content: '\e299\e299'; }

.fad.fa-cloud-showers-heavy::after, .fa-duotone.fa-cloud-showers-heavy::after {
  content: '\f740\f740'; }

.fad.fa-headphones-simple::after, .fa-duotone.fa-headphones-simple::after {
  content: '\f58f\f58f'; }

.fad.fa-headphones-alt::after, .fa-duotone.fa-headphones-alt::after {
  content: '\f58f\f58f'; }

.fad.fa-sitemap::after, .fa-duotone.fa-sitemap::after {
  content: '\f0e8\f0e8'; }

.fad.fa-pipe-section::after, .fa-duotone.fa-pipe-section::after {
  content: '\e438\e438'; }

.fad.fa-space-station-moon-construction::after, .fa-duotone.fa-space-station-moon-construction::after {
  content: '\e034\e034'; }

.fad.fa-space-station-moon-alt::after, .fa-duotone.fa-space-station-moon-alt::after {
  content: '\e034\e034'; }

.fad.fa-circle-dollar-to-slot::after, .fa-duotone.fa-circle-dollar-to-slot::after {
  content: '\f4b9\f4b9'; }

.fad.fa-donate::after, .fa-duotone.fa-donate::after {
  content: '\f4b9\f4b9'; }

.fad.fa-memory::after, .fa-duotone.fa-memory::after {
  content: '\f538\f538'; }

.fad.fa-face-sleeping::after, .fa-duotone.fa-face-sleeping::after {
  content: '\e38d\e38d'; }

.fad.fa-road-spikes::after, .fa-duotone.fa-road-spikes::after {
  content: '\e568\e568'; }

.fad.fa-fire-burner::after, .fa-duotone.fa-fire-burner::after {
  content: '\e4f1\e4f1'; }

.fad.fa-squirrel::after, .fa-duotone.fa-squirrel::after {
  content: '\f71a\f71a'; }

.fad.fa-arrow-up-to-line::after, .fa-duotone.fa-arrow-up-to-line::after {
  content: '\f341\f341'; }

.fad.fa-arrow-to-top::after, .fa-duotone.fa-arrow-to-top::after {
  content: '\f341\f341'; }

.fad.fa-flag::after, .fa-duotone.fa-flag::after {
  content: '\f024\f024'; }

.fad.fa-face-cowboy-hat::after, .fa-duotone.fa-face-cowboy-hat::after {
  content: '\e36e\e36e'; }

.fad.fa-hanukiah::after, .fa-duotone.fa-hanukiah::after {
  content: '\f6e6\f6e6'; }

.fad.fa-chart-scatter-3d::after, .fa-duotone.fa-chart-scatter-3d::after {
  content: '\e0e8\e0e8'; }

.fad.fa-display-chart-up::after, .fa-duotone.fa-display-chart-up::after {
  content: '\e5e3\e5e3'; }

.fad.fa-square-code::after, .fa-duotone.fa-square-code::after {
  content: '\e267\e267'; }

.fad.fa-feather::after, .fa-duotone.fa-feather::after {
  content: '\f52d\f52d'; }

.fad.fa-volume-low::after, .fa-duotone.fa-volume-low::after {
  content: '\f027\f027'; }

.fad.fa-volume-down::after, .fa-duotone.fa-volume-down::after {
  content: '\f027\f027'; }

.fad.fa-xmark-to-slot::after, .fa-duotone.fa-xmark-to-slot::after {
  content: '\f771\f771'; }

.fad.fa-times-to-slot::after, .fa-duotone.fa-times-to-slot::after {
  content: '\f771\f771'; }

.fad.fa-vote-nay::after, .fa-duotone.fa-vote-nay::after {
  content: '\f771\f771'; }

.fad.fa-box-taped::after, .fa-duotone.fa-box-taped::after {
  content: '\f49a\f49a'; }

.fad.fa-box-alt::after, .fa-duotone.fa-box-alt::after {
  content: '\f49a\f49a'; }

.fad.fa-comment-slash::after, .fa-duotone.fa-comment-slash::after {
  content: '\f4b3\f4b3'; }

.fad.fa-swords::after, .fa-duotone.fa-swords::after {
  content: '\f71d\f71d'; }

.fad.fa-cloud-sun-rain::after, .fa-duotone.fa-cloud-sun-rain::after {
  content: '\f743\f743'; }

.fad.fa-album::after, .fa-duotone.fa-album::after {
  content: '\f89f\f89f'; }

.fad.fa-circle-n::after, .fa-duotone.fa-circle-n::after {
  content: '\e118\e118'; }

.fad.fa-compress::after, .fa-duotone.fa-compress::after {
  content: '\f066\f066'; }

.fad.fa-wheat-awn::after, .fa-duotone.fa-wheat-awn::after {
  content: '\e2cd\e2cd'; }

.fad.fa-wheat-alt::after, .fa-duotone.fa-wheat-alt::after {
  content: '\e2cd\e2cd'; }

.fad.fa-ankh::after, .fa-duotone.fa-ankh::after {
  content: '\f644\f644'; }

.fad.fa-hands-holding-child::after, .fa-duotone.fa-hands-holding-child::after {
  content: '\e4fa\e4fa'; }

.fad.fa-asterisk::after, .fa-duotone.fa-asterisk::after {
  content: '\2a\2a'; }

.fad.fa-key-skeleton-left-right::after, .fa-duotone.fa-key-skeleton-left-right::after {
  content: '\e3b4\e3b4'; }

.fad.fa-comment-lines::after, .fa-duotone.fa-comment-lines::after {
  content: '\f4b0\f4b0'; }

.fad.fa-luchador-mask::after, .fa-duotone.fa-luchador-mask::after {
  content: '\f455\f455'; }

.fad.fa-luchador::after, .fa-duotone.fa-luchador::after {
  content: '\f455\f455'; }

.fad.fa-mask-luchador::after, .fa-duotone.fa-mask-luchador::after {
  content: '\f455\f455'; }

.fad.fa-square-check::after, .fa-duotone.fa-square-check::after {
  content: '\f14a\f14a'; }

.fad.fa-check-square::after, .fa-duotone.fa-check-square::after {
  content: '\f14a\f14a'; }

.fad.fa-shredder::after, .fa-duotone.fa-shredder::after {
  content: '\f68a\f68a'; }

.fad.fa-book-open-cover::after, .fa-duotone.fa-book-open-cover::after {
  content: '\e0c0\e0c0'; }

.fad.fa-book-open-alt::after, .fa-duotone.fa-book-open-alt::after {
  content: '\e0c0\e0c0'; }

.fad.fa-sandwich::after, .fa-duotone.fa-sandwich::after {
  content: '\f81f\f81f'; }

.fad.fa-peseta-sign::after, .fa-duotone.fa-peseta-sign::after {
  content: '\e221\e221'; }

.fad.fa-square-parking-slash::after, .fa-duotone.fa-square-parking-slash::after {
  content: '\f617\f617'; }

.fad.fa-parking-slash::after, .fa-duotone.fa-parking-slash::after {
  content: '\f617\f617'; }

.fad.fa-train-tunnel::after, .fa-duotone.fa-train-tunnel::after {
  content: '\e454\e454'; }

.fad.fa-heading::after, .fa-duotone.fa-heading::after {
  content: '\f1dc\f1dc'; }

.fad.fa-header::after, .fa-duotone.fa-header::after {
  content: '\f1dc\f1dc'; }

.fad.fa-ghost::after, .fa-duotone.fa-ghost::after {
  content: '\f6e2\f6e2'; }

.fad.fa-face-anguished::after, .fa-duotone.fa-face-anguished::after {
  content: '\e369\e369'; }

.fad.fa-hockey-sticks::after, .fa-duotone.fa-hockey-sticks::after {
  content: '\f454\f454'; }

.fad.fa-abacus::after, .fa-duotone.fa-abacus::after {
  content: '\f640\f640'; }

.fad.fa-film-simple::after, .fa-duotone.fa-film-simple::after {
  content: '\f3a0\f3a0'; }

.fad.fa-film-alt::after, .fa-duotone.fa-film-alt::after {
  content: '\f3a0\f3a0'; }

.fad.fa-list::after, .fa-duotone.fa-list::after {
  content: '\f03a\f03a'; }

.fad.fa-list-squares::after, .fa-duotone.fa-list-squares::after {
  content: '\f03a\f03a'; }

.fad.fa-tree-palm::after, .fa-duotone.fa-tree-palm::after {
  content: '\f82b\f82b'; }

.fad.fa-square-phone-flip::after, .fa-duotone.fa-square-phone-flip::after {
  content: '\f87b\f87b'; }

.fad.fa-phone-square-alt::after, .fa-duotone.fa-phone-square-alt::after {
  content: '\f87b\f87b'; }

.fad.fa-cart-plus::after, .fa-duotone.fa-cart-plus::after {
  content: '\f217\f217'; }

.fad.fa-gamepad::after, .fa-duotone.fa-gamepad::after {
  content: '\f11b\f11b'; }

.fad.fa-border-center-v::after, .fa-duotone.fa-border-center-v::after {
  content: '\f89d\f89d'; }

.fad.fa-circle-dot::after, .fa-duotone.fa-circle-dot::after {
  content: '\f192\f192'; }

.fad.fa-dot-circle::after, .fa-duotone.fa-dot-circle::after {
  content: '\f192\f192'; }

.fad.fa-clipboard-medical::after, .fa-duotone.fa-clipboard-medical::after {
  content: '\e133\e133'; }

.fad.fa-face-dizzy::after, .fa-duotone.fa-face-dizzy::after {
  content: '\f567\f567'; }

.fad.fa-dizzy::after, .fa-duotone.fa-dizzy::after {
  content: '\f567\f567'; }

.fad.fa-egg::after, .fa-duotone.fa-egg::after {
  content: '\f7fb\f7fb'; }

.fad.fa-up-to-line::after, .fa-duotone.fa-up-to-line::after {
  content: '\f34d\f34d'; }

.fad.fa-arrow-alt-to-top::after, .fa-duotone.fa-arrow-alt-to-top::after {
  content: '\f34d\f34d'; }

.fad.fa-house-medical-circle-xmark::after, .fa-duotone.fa-house-medical-circle-xmark::after {
  content: '\e513\e513'; }

.fad.fa-watch-fitness::after, .fa-duotone.fa-watch-fitness::after {
  content: '\f63e\f63e'; }

.fad.fa-clock-nine-thirty::after, .fa-duotone.fa-clock-nine-thirty::after {
  content: '\e34d\e34d'; }

.fad.fa-campground::after, .fa-duotone.fa-campground::after {
  content: '\f6bb\f6bb'; }

.fad.fa-folder-plus::after, .fa-duotone.fa-folder-plus::after {
  content: '\f65e\f65e'; }

.fad.fa-jug::after, .fa-duotone.fa-jug::after {
  content: '\f8c6\f8c6'; }

.fad.fa-futbol::after, .fa-duotone.fa-futbol::after {
  content: '\f1e3\f1e3'; }

.fad.fa-futbol-ball::after, .fa-duotone.fa-futbol-ball::after {
  content: '\f1e3\f1e3'; }

.fad.fa-soccer-ball::after, .fa-duotone.fa-soccer-ball::after {
  content: '\f1e3\f1e3'; }

.fad.fa-snow-blowing::after, .fa-duotone.fa-snow-blowing::after {
  content: '\f761\f761'; }

.fad.fa-paintbrush::after, .fa-duotone.fa-paintbrush::after {
  content: '\f1fc\f1fc'; }

.fad.fa-paint-brush::after, .fa-duotone.fa-paint-brush::after {
  content: '\f1fc\f1fc'; }

.fad.fa-lock::after, .fa-duotone.fa-lock::after {
  content: '\f023\f023'; }

.fad.fa-arrow-down-from-line::after, .fa-duotone.fa-arrow-down-from-line::after {
  content: '\f345\f345'; }

.fad.fa-arrow-from-top::after, .fa-duotone.fa-arrow-from-top::after {
  content: '\f345\f345'; }

.fad.fa-gas-pump::after, .fa-duotone.fa-gas-pump::after {
  content: '\f52f\f52f'; }

.fad.fa-signal-bars-slash::after, .fa-duotone.fa-signal-bars-slash::after {
  content: '\f694\f694'; }

.fad.fa-signal-alt-slash::after, .fa-duotone.fa-signal-alt-slash::after {
  content: '\f694\f694'; }

.fad.fa-monkey::after, .fa-duotone.fa-monkey::after {
  content: '\f6fb\f6fb'; }

.fad.fa-rectangle-pro::after, .fa-duotone.fa-rectangle-pro::after {
  content: '\e235\e235'; }

.fad.fa-pro::after, .fa-duotone.fa-pro::after {
  content: '\e235\e235'; }

.fad.fa-house-night::after, .fa-duotone.fa-house-night::after {
  content: '\e010\e010'; }

.fad.fa-hot-tub-person::after, .fa-duotone.fa-hot-tub-person::after {
  content: '\f593\f593'; }

.fad.fa-hot-tub::after, .fa-duotone.fa-hot-tub::after {
  content: '\f593\f593'; }

.fad.fa-globe-pointer::after, .fa-duotone.fa-globe-pointer::after {
  content: '\e60e\e60e'; }

.fad.fa-blanket::after, .fa-duotone.fa-blanket::after {
  content: '\f498\f498'; }

.fad.fa-map-location::after, .fa-duotone.fa-map-location::after {
  content: '\f59f\f59f'; }

.fad.fa-map-marked::after, .fa-duotone.fa-map-marked::after {
  content: '\f59f\f59f'; }

.fad.fa-house-flood-water::after, .fa-duotone.fa-house-flood-water::after {
  content: '\e50e\e50e'; }

.fad.fa-comments-question-check::after, .fa-duotone.fa-comments-question-check::after {
  content: '\e14f\e14f'; }

.fad.fa-tree::after, .fa-duotone.fa-tree::after {
  content: '\f1bb\f1bb'; }

.fad.fa-arrows-cross::after, .fa-duotone.fa-arrows-cross::after {
  content: '\e0a2\e0a2'; }

.fad.fa-backpack::after, .fa-duotone.fa-backpack::after {
  content: '\f5d4\f5d4'; }

.fad.fa-square-small::after, .fa-duotone.fa-square-small::after {
  content: '\e27e\e27e'; }

.fad.fa-folder-arrow-up::after, .fa-duotone.fa-folder-arrow-up::after {
  content: '\e054\e054'; }

.fad.fa-folder-upload::after, .fa-duotone.fa-folder-upload::after {
  content: '\e054\e054'; }

.fad.fa-bridge-lock::after, .fa-duotone.fa-bridge-lock::after {
  content: '\e4cc\e4cc'; }

.fad.fa-crosshairs-simple::after, .fa-duotone.fa-crosshairs-simple::after {
  content: '\e59f\e59f'; }

.fad.fa-sack-dollar::after, .fa-duotone.fa-sack-dollar::after {
  content: '\f81d\f81d'; }

.fad.fa-pen-to-square::after, .fa-duotone.fa-pen-to-square::after {
  content: '\f044\f044'; }

.fad.fa-edit::after, .fa-duotone.fa-edit::after {
  content: '\f044\f044'; }

.fad.fa-square-sliders::after, .fa-duotone.fa-square-sliders::after {
  content: '\f3f0\f3f0'; }

.fad.fa-sliders-h-square::after, .fa-duotone.fa-sliders-h-square::after {
  content: '\f3f0\f3f0'; }

.fad.fa-car-side::after, .fa-duotone.fa-car-side::after {
  content: '\f5e4\f5e4'; }

.fad.fa-message-middle-top::after, .fa-duotone.fa-message-middle-top::after {
  content: '\e1e2\e1e2'; }

.fad.fa-comment-middle-top-alt::after, .fa-duotone.fa-comment-middle-top-alt::after {
  content: '\e1e2\e1e2'; }

.fad.fa-lightbulb-on::after, .fa-duotone.fa-lightbulb-on::after {
  content: '\f672\f672'; }

.fad.fa-knife::after, .fa-duotone.fa-knife::after {
  content: '\f2e4\f2e4'; }

.fad.fa-utensil-knife::after, .fa-duotone.fa-utensil-knife::after {
  content: '\f2e4\f2e4'; }

.fad.fa-share-nodes::after, .fa-duotone.fa-share-nodes::after {
  content: '\f1e0\f1e0'; }

.fad.fa-share-alt::after, .fa-duotone.fa-share-alt::after {
  content: '\f1e0\f1e0'; }

.fad.fa-display-chart-up-circle-dollar::after, .fa-duotone.fa-display-chart-up-circle-dollar::after {
  content: '\e5e6\e5e6'; }

.fad.fa-wave-sine::after, .fa-duotone.fa-wave-sine::after {
  content: '\f899\f899'; }

.fad.fa-heart-circle-minus::after, .fa-duotone.fa-heart-circle-minus::after {
  content: '\e4ff\e4ff'; }

.fad.fa-circle-w::after, .fa-duotone.fa-circle-w::after {
  content: '\e12c\e12c'; }

.fad.fa-circle-calendar::after, .fa-duotone.fa-circle-calendar::after {
  content: '\e102\e102'; }

.fad.fa-calendar-circle::after, .fa-duotone.fa-calendar-circle::after {
  content: '\e102\e102'; }

.fad.fa-hourglass-half::after, .fa-duotone.fa-hourglass-half::after {
  content: '\f252\f252'; }

.fad.fa-hourglass-2::after, .fa-duotone.fa-hourglass-2::after {
  content: '\f252\f252'; }

.fad.fa-microscope::after, .fa-duotone.fa-microscope::after {
  content: '\f610\f610'; }

.fad.fa-sunset::after, .fa-duotone.fa-sunset::after {
  content: '\f767\f767'; }

.fad.fa-sink::after, .fa-duotone.fa-sink::after {
  content: '\e06d\e06d'; }

.fad.fa-calendar-exclamation::after, .fa-duotone.fa-calendar-exclamation::after {
  content: '\f334\f334'; }

.fad.fa-truck-container-empty::after, .fa-duotone.fa-truck-container-empty::after {
  content: '\e2b5\e2b5'; }

.fad.fa-hand-heart::after, .fa-duotone.fa-hand-heart::after {
  content: '\f4bc\f4bc'; }

.fad.fa-bag-shopping::after, .fa-duotone.fa-bag-shopping::after {
  content: '\f290\f290'; }

.fad.fa-shopping-bag::after, .fa-duotone.fa-shopping-bag::after {
  content: '\f290\f290'; }

.fad.fa-arrow-down-z-a::after, .fa-duotone.fa-arrow-down-z-a::after {
  content: '\f881\f881'; }

.fad.fa-sort-alpha-desc::after, .fa-duotone.fa-sort-alpha-desc::after {
  content: '\f881\f881'; }

.fad.fa-sort-alpha-down-alt::after, .fa-duotone.fa-sort-alpha-down-alt::after {
  content: '\f881\f881'; }

.fad.fa-mitten::after, .fa-duotone.fa-mitten::after {
  content: '\f7b5\f7b5'; }

.fad.fa-reply-clock::after, .fa-duotone.fa-reply-clock::after {
  content: '\e239\e239'; }

.fad.fa-reply-time::after, .fa-duotone.fa-reply-time::after {
  content: '\e239\e239'; }

.fad.fa-person-rays::after, .fa-duotone.fa-person-rays::after {
  content: '\e54d\e54d'; }

.fad.fa-right::after, .fa-duotone.fa-right::after {
  content: '\f356\f356'; }

.fad.fa-arrow-alt-right::after, .fa-duotone.fa-arrow-alt-right::after {
  content: '\f356\f356'; }

.fad.fa-circle-f::after, .fa-duotone.fa-circle-f::after {
  content: '\e10e\e10e'; }

.fad.fa-users::after, .fa-duotone.fa-users::after {
  content: '\f0c0\f0c0'; }

.fad.fa-face-pleading::after, .fa-duotone.fa-face-pleading::after {
  content: '\e386\e386'; }

.fad.fa-eye-slash::after, .fa-duotone.fa-eye-slash::after {
  content: '\f070\f070'; }

.fad.fa-flask-vial::after, .fa-duotone.fa-flask-vial::after {
  content: '\e4f3\e4f3'; }

.fad.fa-police-box::after, .fa-duotone.fa-police-box::after {
  content: '\e021\e021'; }

.fad.fa-cucumber::after, .fa-duotone.fa-cucumber::after {
  content: '\e401\e401'; }

.fad.fa-head-side-brain::after, .fa-duotone.fa-head-side-brain::after {
  content: '\f808\f808'; }

.fad.fa-hand::after, .fa-duotone.fa-hand::after {
  content: '\f256\f256'; }

.fad.fa-hand-paper::after, .fa-duotone.fa-hand-paper::after {
  content: '\f256\f256'; }

.fad.fa-person-biking-mountain::after, .fa-duotone.fa-person-biking-mountain::after {
  content: '\f84b\f84b'; }

.fad.fa-biking-mountain::after, .fa-duotone.fa-biking-mountain::after {
  content: '\f84b\f84b'; }

.fad.fa-utensils-slash::after, .fa-duotone.fa-utensils-slash::after {
  content: '\e464\e464'; }

.fad.fa-print-magnifying-glass::after, .fa-duotone.fa-print-magnifying-glass::after {
  content: '\f81a\f81a'; }

.fad.fa-print-search::after, .fa-duotone.fa-print-search::after {
  content: '\f81a\f81a'; }

.fad.fa-folder-bookmark::after, .fa-duotone.fa-folder-bookmark::after {
  content: '\e186\e186'; }

.fad.fa-om::after, .fa-duotone.fa-om::after {
  content: '\f679\f679'; }

.fad.fa-pi::after, .fa-duotone.fa-pi::after {
  content: '\f67e\f67e'; }

.fad.fa-flask-round-potion::after, .fa-duotone.fa-flask-round-potion::after {
  content: '\f6e1\f6e1'; }

.fad.fa-flask-potion::after, .fa-duotone.fa-flask-potion::after {
  content: '\f6e1\f6e1'; }

.fad.fa-face-shush::after, .fa-duotone.fa-face-shush::after {
  content: '\e38c\e38c'; }

.fad.fa-worm::after, .fa-duotone.fa-worm::after {
  content: '\e599\e599'; }

.fad.fa-house-circle-xmark::after, .fa-duotone.fa-house-circle-xmark::after {
  content: '\e50b\e50b'; }

.fad.fa-plug::after, .fa-duotone.fa-plug::after {
  content: '\f1e6\f1e6'; }

.fad.fa-calendar-circle-exclamation::after, .fa-duotone.fa-calendar-circle-exclamation::after {
  content: '\e46e\e46e'; }

.fad.fa-square-i::after, .fa-duotone.fa-square-i::after {
  content: '\e272\e272'; }

.fad.fa-chevron-up::after, .fa-duotone.fa-chevron-up::after {
  content: '\f077\f077'; }

.fad.fa-face-saluting::after, .fa-duotone.fa-face-saluting::after {
  content: '\e484\e484'; }

.fad.fa-gauge-simple-low::after, .fa-duotone.fa-gauge-simple-low::after {
  content: '\f62c\f62c'; }

.fad.fa-tachometer-slow::after, .fa-duotone.fa-tachometer-slow::after {
  content: '\f62c\f62c'; }

.fad.fa-face-persevering::after, .fa-duotone.fa-face-persevering::after {
  content: '\e385\e385'; }

.fad.fa-circle-camera::after, .fa-duotone.fa-circle-camera::after {
  content: '\e103\e103'; }

.fad.fa-camera-circle::after, .fa-duotone.fa-camera-circle::after {
  content: '\e103\e103'; }

.fad.fa-hand-spock::after, .fa-duotone.fa-hand-spock::after {
  content: '\f259\f259'; }

.fad.fa-spider-web::after, .fa-duotone.fa-spider-web::after {
  content: '\f719\f719'; }

.fad.fa-circle-microphone::after, .fa-duotone.fa-circle-microphone::after {
  content: '\e116\e116'; }

.fad.fa-microphone-circle::after, .fa-duotone.fa-microphone-circle::after {
  content: '\e116\e116'; }

.fad.fa-book-arrow-up::after, .fa-duotone.fa-book-arrow-up::after {
  content: '\e0ba\e0ba'; }

.fad.fa-popsicle::after, .fa-duotone.fa-popsicle::after {
  content: '\e43e\e43e'; }

.fad.fa-command::after, .fa-duotone.fa-command::after {
  content: '\e142\e142'; }

.fad.fa-blinds::after, .fa-duotone.fa-blinds::after {
  content: '\f8fb\f8fb'; }

.fad.fa-stopwatch::after, .fa-duotone.fa-stopwatch::after {
  content: '\f2f2\f2f2'; }

.fad.fa-saxophone::after, .fa-duotone.fa-saxophone::after {
  content: '\f8dc\f8dc'; }

.fad.fa-square-2::after, .fa-duotone.fa-square-2::after {
  content: '\e257\e257'; }

.fad.fa-field-hockey-stick-ball::after, .fa-duotone.fa-field-hockey-stick-ball::after {
  content: '\f44c\f44c'; }

.fad.fa-field-hockey::after, .fa-duotone.fa-field-hockey::after {
  content: '\f44c\f44c'; }

.fad.fa-arrow-up-square-triangle::after, .fa-duotone.fa-arrow-up-square-triangle::after {
  content: '\f88b\f88b'; }

.fad.fa-sort-shapes-up-alt::after, .fa-duotone.fa-sort-shapes-up-alt::after {
  content: '\f88b\f88b'; }

.fad.fa-face-scream::after, .fa-duotone.fa-face-scream::after {
  content: '\e38b\e38b'; }

.fad.fa-square-m::after, .fa-duotone.fa-square-m::after {
  content: '\e276\e276'; }

.fad.fa-camera-web::after, .fa-duotone.fa-camera-web::after {
  content: '\f832\f832'; }

.fad.fa-webcam::after, .fa-duotone.fa-webcam::after {
  content: '\f832\f832'; }

.fad.fa-comment-arrow-down::after, .fa-duotone.fa-comment-arrow-down::after {
  content: '\e143\e143'; }

.fad.fa-lightbulb-cfl::after, .fa-duotone.fa-lightbulb-cfl::after {
  content: '\e5a6\e5a6'; }

.fad.fa-window-frame-open::after, .fa-duotone.fa-window-frame-open::after {
  content: '\e050\e050'; }

.fad.fa-face-kiss::after, .fa-duotone.fa-face-kiss::after {
  content: '\f596\f596'; }

.fad.fa-kiss::after, .fa-duotone.fa-kiss::after {
  content: '\f596\f596'; }

.fad.fa-bridge-circle-xmark::after, .fa-duotone.fa-bridge-circle-xmark::after {
  content: '\e4cb\e4cb'; }

.fad.fa-period::after, .fa-duotone.fa-period::after {
  content: '\2e\2e'; }

.fad.fa-face-grin-tongue::after, .fa-duotone.fa-face-grin-tongue::after {
  content: '\f589\f589'; }

.fad.fa-grin-tongue::after, .fa-duotone.fa-grin-tongue::after {
  content: '\f589\f589'; }

.fad.fa-up-to-dotted-line::after, .fa-duotone.fa-up-to-dotted-line::after {
  content: '\e457\e457'; }

.fad.fa-thought-bubble::after, .fa-duotone.fa-thought-bubble::after {
  content: '\e32e\e32e'; }

.fad.fa-skeleton-ribs::after, .fa-duotone.fa-skeleton-ribs::after {
  content: '\e5cb\e5cb'; }

.fad.fa-raygun::after, .fa-duotone.fa-raygun::after {
  content: '\e025\e025'; }

.fad.fa-flute::after, .fa-duotone.fa-flute::after {
  content: '\f8b9\f8b9'; }

.fad.fa-acorn::after, .fa-duotone.fa-acorn::after {
  content: '\f6ae\f6ae'; }

.fad.fa-video-arrow-up-right::after, .fa-duotone.fa-video-arrow-up-right::after {
  content: '\e2c9\e2c9'; }

.fad.fa-grate-droplet::after, .fa-duotone.fa-grate-droplet::after {
  content: '\e194\e194'; }

.fad.fa-seal-exclamation::after, .fa-duotone.fa-seal-exclamation::after {
  content: '\e242\e242'; }

.fad.fa-chess-bishop::after, .fa-duotone.fa-chess-bishop::after {
  content: '\f43a\f43a'; }

.fad.fa-message-sms::after, .fa-duotone.fa-message-sms::after {
  content: '\e1e5\e1e5'; }

.fad.fa-coffee-beans::after, .fa-duotone.fa-coffee-beans::after {
  content: '\e13f\e13f'; }

.fad.fa-hat-witch::after, .fa-duotone.fa-hat-witch::after {
  content: '\f6e7\f6e7'; }

.fad.fa-face-grin-wink::after, .fa-duotone.fa-face-grin-wink::after {
  content: '\f58c\f58c'; }

.fad.fa-grin-wink::after, .fa-duotone.fa-grin-wink::after {
  content: '\f58c\f58c'; }

.fad.fa-clock-three-thirty::after, .fa-duotone.fa-clock-three-thirty::after {
  content: '\e357\e357'; }

.fad.fa-ear-deaf::after, .fa-duotone.fa-ear-deaf::after {
  content: '\f2a4\f2a4'; }

.fad.fa-deaf::after, .fa-duotone.fa-deaf::after {
  content: '\f2a4\f2a4'; }

.fad.fa-deafness::after, .fa-duotone.fa-deafness::after {
  content: '\f2a4\f2a4'; }

.fad.fa-hard-of-hearing::after, .fa-duotone.fa-hard-of-hearing::after {
  content: '\f2a4\f2a4'; }

.fad.fa-alarm-clock::after, .fa-duotone.fa-alarm-clock::after {
  content: '\f34e\f34e'; }

.fad.fa-eclipse::after, .fa-duotone.fa-eclipse::after {
  content: '\f749\f749'; }

.fad.fa-face-relieved::after, .fa-duotone.fa-face-relieved::after {
  content: '\e389\e389'; }

.fad.fa-road-circle-check::after, .fa-duotone.fa-road-circle-check::after {
  content: '\e564\e564'; }

.fad.fa-dice-five::after, .fa-duotone.fa-dice-five::after {
  content: '\f523\f523'; }

.fad.fa-octagon-minus::after, .fa-duotone.fa-octagon-minus::after {
  content: '\f308\f308'; }

.fad.fa-minus-octagon::after, .fa-duotone.fa-minus-octagon::after {
  content: '\f308\f308'; }

.fad.fa-square-rss::after, .fa-duotone.fa-square-rss::after {
  content: '\f143\f143'; }

.fad.fa-rss-square::after, .fa-duotone.fa-rss-square::after {
  content: '\f143\f143'; }

.fad.fa-face-zany::after, .fa-duotone.fa-face-zany::after {
  content: '\e3a4\e3a4'; }

.fad.fa-tricycle::after, .fa-duotone.fa-tricycle::after {
  content: '\e5c3\e5c3'; }

.fad.fa-land-mine-on::after, .fa-duotone.fa-land-mine-on::after {
  content: '\e51b\e51b'; }

.fad.fa-square-arrow-up-left::after, .fa-duotone.fa-square-arrow-up-left::after {
  content: '\e263\e263'; }

.fad.fa-i-cursor::after, .fa-duotone.fa-i-cursor::after {
  content: '\f246\f246'; }

.fad.fa-chart-mixed-up-circle-dollar::after, .fa-duotone.fa-chart-mixed-up-circle-dollar::after {
  content: '\e5d9\e5d9'; }

.fad.fa-salt-shaker::after, .fa-duotone.fa-salt-shaker::after {
  content: '\e446\e446'; }

.fad.fa-stamp::after, .fa-duotone.fa-stamp::after {
  content: '\f5bf\f5bf'; }

.fad.fa-file-plus::after, .fa-duotone.fa-file-plus::after {
  content: '\f319\f319'; }

.fad.fa-draw-square::after, .fa-duotone.fa-draw-square::after {
  content: '\f5ef\f5ef'; }

.fad.fa-toilet-paper-under-slash::after, .fa-duotone.fa-toilet-paper-under-slash::after {
  content: '\e2a1\e2a1'; }

.fad.fa-toilet-paper-reverse-slash::after, .fa-duotone.fa-toilet-paper-reverse-slash::after {
  content: '\e2a1\e2a1'; }

.fad.fa-stairs::after, .fa-duotone.fa-stairs::after {
  content: '\e289\e289'; }

.fad.fa-drone-front::after, .fa-duotone.fa-drone-front::after {
  content: '\f860\f860'; }

.fad.fa-drone-alt::after, .fa-duotone.fa-drone-alt::after {
  content: '\f860\f860'; }

.fad.fa-glass-empty::after, .fa-duotone.fa-glass-empty::after {
  content: '\e191\e191'; }

.fad.fa-dial-high::after, .fa-duotone.fa-dial-high::after {
  content: '\e15c\e15c'; }

.fad.fa-user-helmet-safety::after, .fa-duotone.fa-user-helmet-safety::after {
  content: '\f82c\f82c'; }

.fad.fa-user-construction::after, .fa-duotone.fa-user-construction::after {
  content: '\f82c\f82c'; }

.fad.fa-user-hard-hat::after, .fa-duotone.fa-user-hard-hat::after {
  content: '\f82c\f82c'; }

.fad.fa-i::after, .fa-duotone.fa-i::after {
  content: '\49\49'; }

.fad.fa-hryvnia-sign::after, .fa-duotone.fa-hryvnia-sign::after {
  content: '\f6f2\f6f2'; }

.fad.fa-hryvnia::after, .fa-duotone.fa-hryvnia::after {
  content: '\f6f2\f6f2'; }

.fad.fa-arrow-down-left-and-arrow-up-right-to-center::after, .fa-duotone.fa-arrow-down-left-and-arrow-up-right-to-center::after {
  content: '\e092\e092'; }

.fad.fa-pills::after, .fa-duotone.fa-pills::after {
  content: '\f484\f484'; }

.fad.fa-face-grin-wide::after, .fa-duotone.fa-face-grin-wide::after {
  content: '\f581\f581'; }

.fad.fa-grin-alt::after, .fa-duotone.fa-grin-alt::after {
  content: '\f581\f581'; }

.fad.fa-tooth::after, .fa-duotone.fa-tooth::after {
  content: '\f5c9\f5c9'; }

.fad.fa-basketball-hoop::after, .fa-duotone.fa-basketball-hoop::after {
  content: '\f435\f435'; }

.fad.fa-objects-align-bottom::after, .fa-duotone.fa-objects-align-bottom::after {
  content: '\e3bb\e3bb'; }

.fad.fa-v::after, .fa-duotone.fa-v::after {
  content: '\56\56'; }

.fad.fa-sparkles::after, .fa-duotone.fa-sparkles::after {
  content: '\f890\f890'; }

.fad.fa-squid::after, .fa-duotone.fa-squid::after {
  content: '\e450\e450'; }

.fad.fa-leafy-green::after, .fa-duotone.fa-leafy-green::after {
  content: '\e41d\e41d'; }

.fad.fa-circle-arrow-up-right::after, .fa-duotone.fa-circle-arrow-up-right::after {
  content: '\e0fc\e0fc'; }

.fad.fa-calendars::after, .fa-duotone.fa-calendars::after {
  content: '\e0d7\e0d7'; }

.fad.fa-bangladeshi-taka-sign::after, .fa-duotone.fa-bangladeshi-taka-sign::after {
  content: '\e2e6\e2e6'; }

.fad.fa-bicycle::after, .fa-duotone.fa-bicycle::after {
  content: '\f206\f206'; }

.fad.fa-hammer-war::after, .fa-duotone.fa-hammer-war::after {
  content: '\f6e4\f6e4'; }

.fad.fa-circle-d::after, .fa-duotone.fa-circle-d::after {
  content: '\e104\e104'; }

.fad.fa-spider-black-widow::after, .fa-duotone.fa-spider-black-widow::after {
  content: '\f718\f718'; }

.fad.fa-staff-snake::after, .fa-duotone.fa-staff-snake::after {
  content: '\e579\e579'; }

.fad.fa-rod-asclepius::after, .fa-duotone.fa-rod-asclepius::after {
  content: '\e579\e579'; }

.fad.fa-rod-snake::after, .fa-duotone.fa-rod-snake::after {
  content: '\e579\e579'; }

.fad.fa-staff-aesculapius::after, .fa-duotone.fa-staff-aesculapius::after {
  content: '\e579\e579'; }

.fad.fa-pear::after, .fa-duotone.fa-pear::after {
  content: '\e20c\e20c'; }

.fad.fa-head-side-cough-slash::after, .fa-duotone.fa-head-side-cough-slash::after {
  content: '\e062\e062'; }

.fad.fa-triangle::after, .fa-duotone.fa-triangle::after {
  content: '\f2ec\f2ec'; }

.fad.fa-apartment::after, .fa-duotone.fa-apartment::after {
  content: '\e468\e468'; }

.fad.fa-truck-medical::after, .fa-duotone.fa-truck-medical::after {
  content: '\f0f9\f0f9'; }

.fad.fa-ambulance::after, .fa-duotone.fa-ambulance::after {
  content: '\f0f9\f0f9'; }

.fad.fa-pepper::after, .fa-duotone.fa-pepper::after {
  content: '\e432\e432'; }

.fad.fa-piano::after, .fa-duotone.fa-piano::after {
  content: '\f8d4\f8d4'; }

.fad.fa-gun-squirt::after, .fa-duotone.fa-gun-squirt::after {
  content: '\e19d\e19d'; }

.fad.fa-wheat-awn-circle-exclamation::after, .fa-duotone.fa-wheat-awn-circle-exclamation::after {
  content: '\e598\e598'; }

.fad.fa-snowman::after, .fa-duotone.fa-snowman::after {
  content: '\f7d0\f7d0'; }

.fad.fa-user-alien::after, .fa-duotone.fa-user-alien::after {
  content: '\e04a\e04a'; }

.fad.fa-shield-check::after, .fa-duotone.fa-shield-check::after {
  content: '\f2f7\f2f7'; }

.fad.fa-mortar-pestle::after, .fa-duotone.fa-mortar-pestle::after {
  content: '\f5a7\f5a7'; }

.fad.fa-road-barrier::after, .fa-duotone.fa-road-barrier::after {
  content: '\e562\e562'; }

.fad.fa-chart-candlestick::after, .fa-duotone.fa-chart-candlestick::after {
  content: '\e0e2\e0e2'; }

.fad.fa-briefcase-blank::after, .fa-duotone.fa-briefcase-blank::after {
  content: '\e0c8\e0c8'; }

.fad.fa-school::after, .fa-duotone.fa-school::after {
  content: '\f549\f549'; }

.fad.fa-igloo::after, .fa-duotone.fa-igloo::after {
  content: '\f7ae\f7ae'; }

.fad.fa-bracket-round::after, .fa-duotone.fa-bracket-round::after {
  content: '\28\28'; }

.fad.fa-parenthesis::after, .fa-duotone.fa-parenthesis::after {
  content: '\28\28'; }

.fad.fa-joint::after, .fa-duotone.fa-joint::after {
  content: '\f595\f595'; }

.fad.fa-horse-saddle::after, .fa-duotone.fa-horse-saddle::after {
  content: '\f8c3\f8c3'; }

.fad.fa-mug-marshmallows::after, .fa-duotone.fa-mug-marshmallows::after {
  content: '\f7b7\f7b7'; }

.fad.fa-filters::after, .fa-duotone.fa-filters::after {
  content: '\e17e\e17e'; }

.fad.fa-bell-on::after, .fa-duotone.fa-bell-on::after {
  content: '\f8fa\f8fa'; }

.fad.fa-angle-right::after, .fa-duotone.fa-angle-right::after {
  content: '\f105\f105'; }

.fad.fa-dial-med::after, .fa-duotone.fa-dial-med::after {
  content: '\e15f\e15f'; }

.fad.fa-horse::after, .fa-duotone.fa-horse::after {
  content: '\f6f0\f6f0'; }

.fad.fa-q::after, .fa-duotone.fa-q::after {
  content: '\51\51'; }

.fad.fa-monitor-waveform::after, .fa-duotone.fa-monitor-waveform::after {
  content: '\f611\f611'; }

.fad.fa-monitor-heart-rate::after, .fa-duotone.fa-monitor-heart-rate::after {
  content: '\f611\f611'; }

.fad.fa-link-simple::after, .fa-duotone.fa-link-simple::after {
  content: '\e1cd\e1cd'; }

.fad.fa-whistle::after, .fa-duotone.fa-whistle::after {
  content: '\f460\f460'; }

.fad.fa-g::after, .fa-duotone.fa-g::after {
  content: '\47\47'; }

.fad.fa-wine-glass-crack::after, .fa-duotone.fa-wine-glass-crack::after {
  content: '\f4bb\f4bb'; }

.fad.fa-fragile::after, .fa-duotone.fa-fragile::after {
  content: '\f4bb\f4bb'; }

.fad.fa-slot-machine::after, .fa-duotone.fa-slot-machine::after {
  content: '\e3ce\e3ce'; }

.fad.fa-notes-medical::after, .fa-duotone.fa-notes-medical::after {
  content: '\f481\f481'; }

.fad.fa-car-wash::after, .fa-duotone.fa-car-wash::after {
  content: '\f5e6\f5e6'; }

.fad.fa-escalator::after, .fa-duotone.fa-escalator::after {
  content: '\e171\e171'; }

.fad.fa-comment-image::after, .fa-duotone.fa-comment-image::after {
  content: '\e148\e148'; }

.fad.fa-temperature-half::after, .fa-duotone.fa-temperature-half::after {
  content: '\f2c9\f2c9'; }

.fad.fa-temperature-2::after, .fa-duotone.fa-temperature-2::after {
  content: '\f2c9\f2c9'; }

.fad.fa-thermometer-2::after, .fa-duotone.fa-thermometer-2::after {
  content: '\f2c9\f2c9'; }

.fad.fa-thermometer-half::after, .fa-duotone.fa-thermometer-half::after {
  content: '\f2c9\f2c9'; }

.fad.fa-dong-sign::after, .fa-duotone.fa-dong-sign::after {
  content: '\e169\e169'; }

.fad.fa-donut::after, .fa-duotone.fa-donut::after {
  content: '\e406\e406'; }

.fad.fa-doughnut::after, .fa-duotone.fa-doughnut::after {
  content: '\e406\e406'; }

.fad.fa-capsules::after, .fa-duotone.fa-capsules::after {
  content: '\f46b\f46b'; }

.fad.fa-poo-storm::after, .fa-duotone.fa-poo-storm::after {
  content: '\f75a\f75a'; }

.fad.fa-poo-bolt::after, .fa-duotone.fa-poo-bolt::after {
  content: '\f75a\f75a'; }

.fad.fa-tally-1::after, .fa-duotone.fa-tally-1::after {
  content: '\e294\e294'; }

.fad.fa-face-frown-open::after, .fa-duotone.fa-face-frown-open::after {
  content: '\f57a\f57a'; }

.fad.fa-frown-open::after, .fa-duotone.fa-frown-open::after {
  content: '\f57a\f57a'; }

.fad.fa-square-dashed::after, .fa-duotone.fa-square-dashed::after {
  content: '\e269\e269'; }

.fad.fa-square-j::after, .fa-duotone.fa-square-j::after {
  content: '\e273\e273'; }

.fad.fa-hand-point-up::after, .fa-duotone.fa-hand-point-up::after {
  content: '\f0a6\f0a6'; }

.fad.fa-money-bill::after, .fa-duotone.fa-money-bill::after {
  content: '\f0d6\f0d6'; }

.fad.fa-arrow-up-big-small::after, .fa-duotone.fa-arrow-up-big-small::after {
  content: '\f88e\f88e'; }

.fad.fa-sort-size-up::after, .fa-duotone.fa-sort-size-up::after {
  content: '\f88e\f88e'; }

.fad.fa-barcode-read::after, .fa-duotone.fa-barcode-read::after {
  content: '\f464\f464'; }

.fad.fa-baguette::after, .fa-duotone.fa-baguette::after {
  content: '\e3d8\e3d8'; }

.fad.fa-bowl-soft-serve::after, .fa-duotone.fa-bowl-soft-serve::after {
  content: '\e46b\e46b'; }

.fad.fa-face-holding-back-tears::after, .fa-duotone.fa-face-holding-back-tears::after {
  content: '\e482\e482'; }

.fad.fa-square-up::after, .fa-duotone.fa-square-up::after {
  content: '\f353\f353'; }

.fad.fa-arrow-alt-square-up::after, .fa-duotone.fa-arrow-alt-square-up::after {
  content: '\f353\f353'; }

.fad.fa-train-subway-tunnel::after, .fa-duotone.fa-train-subway-tunnel::after {
  content: '\e2a3\e2a3'; }

.fad.fa-subway-tunnel::after, .fa-duotone.fa-subway-tunnel::after {
  content: '\e2a3\e2a3'; }

.fad.fa-square-exclamation::after, .fa-duotone.fa-square-exclamation::after {
  content: '\f321\f321'; }

.fad.fa-exclamation-square::after, .fa-duotone.fa-exclamation-square::after {
  content: '\f321\f321'; }

.fad.fa-semicolon::after, .fa-duotone.fa-semicolon::after {
  content: '\3b\3b'; }

.fad.fa-bookmark::after, .fa-duotone.fa-bookmark::after {
  content: '\f02e\f02e'; }

.fad.fa-fan-table::after, .fa-duotone.fa-fan-table::after {
  content: '\e004\e004'; }

.fad.fa-align-justify::after, .fa-duotone.fa-align-justify::after {
  content: '\f039\f039'; }

.fad.fa-battery-low::after, .fa-duotone.fa-battery-low::after {
  content: '\e0b1\e0b1'; }

.fad.fa-battery-1::after, .fa-duotone.fa-battery-1::after {
  content: '\e0b1\e0b1'; }

.fad.fa-credit-card-front::after, .fa-duotone.fa-credit-card-front::after {
  content: '\f38a\f38a'; }

.fad.fa-brain-arrow-curved-right::after, .fa-duotone.fa-brain-arrow-curved-right::after {
  content: '\f677\f677'; }

.fad.fa-mind-share::after, .fa-duotone.fa-mind-share::after {
  content: '\f677\f677'; }

.fad.fa-umbrella-beach::after, .fa-duotone.fa-umbrella-beach::after {
  content: '\f5ca\f5ca'; }

.fad.fa-helmet-un::after, .fa-duotone.fa-helmet-un::after {
  content: '\e503\e503'; }

.fad.fa-location-smile::after, .fa-duotone.fa-location-smile::after {
  content: '\f60d\f60d'; }

.fad.fa-map-marker-smile::after, .fa-duotone.fa-map-marker-smile::after {
  content: '\f60d\f60d'; }

.fad.fa-arrow-left-to-line::after, .fa-duotone.fa-arrow-left-to-line::after {
  content: '\f33e\f33e'; }

.fad.fa-arrow-to-left::after, .fa-duotone.fa-arrow-to-left::after {
  content: '\f33e\f33e'; }

.fad.fa-bullseye::after, .fa-duotone.fa-bullseye::after {
  content: '\f140\f140'; }

.fad.fa-sushi::after, .fa-duotone.fa-sushi::after {
  content: '\e48a\e48a'; }

.fad.fa-nigiri::after, .fa-duotone.fa-nigiri::after {
  content: '\e48a\e48a'; }

.fad.fa-message-captions::after, .fa-duotone.fa-message-captions::after {
  content: '\e1de\e1de'; }

.fad.fa-comment-alt-captions::after, .fa-duotone.fa-comment-alt-captions::after {
  content: '\e1de\e1de'; }

.fad.fa-trash-list::after, .fa-duotone.fa-trash-list::after {
  content: '\e2b1\e2b1'; }

.fad.fa-bacon::after, .fa-duotone.fa-bacon::after {
  content: '\f7e5\f7e5'; }

.fad.fa-option::after, .fa-duotone.fa-option::after {
  content: '\e318\e318'; }

.fad.fa-raccoon::after, .fa-duotone.fa-raccoon::after {
  content: '\e613\e613'; }

.fad.fa-hand-point-down::after, .fa-duotone.fa-hand-point-down::after {
  content: '\f0a7\f0a7'; }

.fad.fa-arrow-up-from-bracket::after, .fa-duotone.fa-arrow-up-from-bracket::after {
  content: '\e09a\e09a'; }

.fad.fa-head-side-gear::after, .fa-duotone.fa-head-side-gear::after {
  content: '\e611\e611'; }

.fad.fa-trash-plus::after, .fa-duotone.fa-trash-plus::after {
  content: '\e2b2\e2b2'; }

.fad.fa-objects-align-top::after, .fa-duotone.fa-objects-align-top::after {
  content: '\e3c0\e3c0'; }

.fad.fa-folder::after, .fa-duotone.fa-folder::after {
  content: '\f07b\f07b'; }

.fad.fa-folder-blank::after, .fa-duotone.fa-folder-blank::after {
  content: '\f07b\f07b'; }

.fad.fa-face-anxious-sweat::after, .fa-duotone.fa-face-anxious-sweat::after {
  content: '\e36a\e36a'; }

.fad.fa-credit-card-blank::after, .fa-duotone.fa-credit-card-blank::after {
  content: '\f389\f389'; }

.fad.fa-file-waveform::after, .fa-duotone.fa-file-waveform::after {
  content: '\f478\f478'; }

.fad.fa-file-medical-alt::after, .fa-duotone.fa-file-medical-alt::after {
  content: '\f478\f478'; }

.fad.fa-microchip-ai::after, .fa-duotone.fa-microchip-ai::after {
  content: '\e1ec\e1ec'; }

.fad.fa-mug::after, .fa-duotone.fa-mug::after {
  content: '\f874\f874'; }

.fad.fa-plane-up-slash::after, .fa-duotone.fa-plane-up-slash::after {
  content: '\e22e\e22e'; }

.fad.fa-radiation::after, .fa-duotone.fa-radiation::after {
  content: '\f7b9\f7b9'; }

.fad.fa-pen-circle::after, .fa-duotone.fa-pen-circle::after {
  content: '\e20e\e20e'; }

.fad.fa-bag-seedling::after, .fa-duotone.fa-bag-seedling::after {
  content: '\e5f2\e5f2'; }

.fad.fa-chart-simple::after, .fa-duotone.fa-chart-simple::after {
  content: '\e473\e473'; }

.fad.fa-crutches::after, .fa-duotone.fa-crutches::after {
  content: '\f7f8\f7f8'; }

.fad.fa-circle-parking::after, .fa-duotone.fa-circle-parking::after {
  content: '\f615\f615'; }

.fad.fa-parking-circle::after, .fa-duotone.fa-parking-circle::after {
  content: '\f615\f615'; }

.fad.fa-mars-stroke::after, .fa-duotone.fa-mars-stroke::after {
  content: '\f229\f229'; }

.fad.fa-leaf-oak::after, .fa-duotone.fa-leaf-oak::after {
  content: '\f6f7\f6f7'; }

.fad.fa-square-bolt::after, .fa-duotone.fa-square-bolt::after {
  content: '\e265\e265'; }

.fad.fa-vial::after, .fa-duotone.fa-vial::after {
  content: '\f492\f492'; }

.fad.fa-gauge::after, .fa-duotone.fa-gauge::after {
  content: '\f624\f624'; }

.fad.fa-dashboard::after, .fa-duotone.fa-dashboard::after {
  content: '\f624\f624'; }

.fad.fa-gauge-med::after, .fa-duotone.fa-gauge-med::after {
  content: '\f624\f624'; }

.fad.fa-tachometer-alt-average::after, .fa-duotone.fa-tachometer-alt-average::after {
  content: '\f624\f624'; }

.fad.fa-wand-magic-sparkles::after, .fa-duotone.fa-wand-magic-sparkles::after {
  content: '\e2ca\e2ca'; }

.fad.fa-magic-wand-sparkles::after, .fa-duotone.fa-magic-wand-sparkles::after {
  content: '\e2ca\e2ca'; }

.fad.fa-lambda::after, .fa-duotone.fa-lambda::after {
  content: '\f66e\f66e'; }

.fad.fa-e::after, .fa-duotone.fa-e::after {
  content: '\45\45'; }

.fad.fa-pizza::after, .fa-duotone.fa-pizza::after {
  content: '\f817\f817'; }

.fad.fa-bowl-chopsticks-noodles::after, .fa-duotone.fa-bowl-chopsticks-noodles::after {
  content: '\e2ea\e2ea'; }

.fad.fa-h3::after, .fa-duotone.fa-h3::after {
  content: '\f315\f315'; }

.fad.fa-pen-clip::after, .fa-duotone.fa-pen-clip::after {
  content: '\f305\f305'; }

.fad.fa-pen-alt::after, .fa-duotone.fa-pen-alt::after {
  content: '\f305\f305'; }

.fad.fa-bridge-circle-exclamation::after, .fa-duotone.fa-bridge-circle-exclamation::after {
  content: '\e4ca\e4ca'; }

.fad.fa-badge-percent::after, .fa-duotone.fa-badge-percent::after {
  content: '\f646\f646'; }

.fad.fa-user::after, .fa-duotone.fa-user::after {
  content: '\f007\f007'; }

.fad.fa-sensor::after, .fa-duotone.fa-sensor::after {
  content: '\e028\e028'; }

.fad.fa-comma::after, .fa-duotone.fa-comma::after {
  content: '\2c\2c'; }

.fad.fa-school-circle-check::after, .fa-duotone.fa-school-circle-check::after {
  content: '\e56b\e56b'; }

.fad.fa-toilet-paper-under::after, .fa-duotone.fa-toilet-paper-under::after {
  content: '\e2a0\e2a0'; }

.fad.fa-toilet-paper-reverse::after, .fa-duotone.fa-toilet-paper-reverse::after {
  content: '\e2a0\e2a0'; }

.fad.fa-light-emergency::after, .fa-duotone.fa-light-emergency::after {
  content: '\e41f\e41f'; }

.fad.fa-arrow-down-to-arc::after, .fa-duotone.fa-arrow-down-to-arc::after {
  content: '\e4ae\e4ae'; }

.fad.fa-dumpster::after, .fa-duotone.fa-dumpster::after {
  content: '\f793\f793'; }

.fad.fa-van-shuttle::after, .fa-duotone.fa-van-shuttle::after {
  content: '\f5b6\f5b6'; }

.fad.fa-shuttle-van::after, .fa-duotone.fa-shuttle-van::after {
  content: '\f5b6\f5b6'; }

.fad.fa-building-user::after, .fa-duotone.fa-building-user::after {
  content: '\e4da\e4da'; }

.fad.fa-light-switch::after, .fa-duotone.fa-light-switch::after {
  content: '\e017\e017'; }

.fad.fa-square-caret-left::after, .fa-duotone.fa-square-caret-left::after {
  content: '\f191\f191'; }

.fad.fa-caret-square-left::after, .fa-duotone.fa-caret-square-left::after {
  content: '\f191\f191'; }

.fad.fa-highlighter::after, .fa-duotone.fa-highlighter::after {
  content: '\f591\f591'; }

.fad.fa-wave-pulse::after, .fa-duotone.fa-wave-pulse::after {
  content: '\f5f8\f5f8'; }

.fad.fa-heart-rate::after, .fa-duotone.fa-heart-rate::after {
  content: '\f5f8\f5f8'; }

.fad.fa-key::after, .fa-duotone.fa-key::after {
  content: '\f084\f084'; }

.fad.fa-hat-santa::after, .fa-duotone.fa-hat-santa::after {
  content: '\f7a7\f7a7'; }

.fad.fa-tamale::after, .fa-duotone.fa-tamale::after {
  content: '\e451\e451'; }

.fad.fa-box-check::after, .fa-duotone.fa-box-check::after {
  content: '\f467\f467'; }

.fad.fa-bullhorn::after, .fa-duotone.fa-bullhorn::after {
  content: '\f0a1\f0a1'; }

.fad.fa-steak::after, .fa-duotone.fa-steak::after {
  content: '\f824\f824'; }

.fad.fa-location-crosshairs-slash::after, .fa-duotone.fa-location-crosshairs-slash::after {
  content: '\f603\f603'; }

.fad.fa-location-slash::after, .fa-duotone.fa-location-slash::after {
  content: '\f603\f603'; }

.fad.fa-person-dolly::after, .fa-duotone.fa-person-dolly::after {
  content: '\f4d0\f4d0'; }

.fad.fa-globe::after, .fa-duotone.fa-globe::after {
  content: '\f0ac\f0ac'; }

.fad.fa-synagogue::after, .fa-duotone.fa-synagogue::after {
  content: '\f69b\f69b'; }

.fad.fa-file-chart-column::after, .fa-duotone.fa-file-chart-column::after {
  content: '\f659\f659'; }

.fad.fa-file-chart-line::after, .fa-duotone.fa-file-chart-line::after {
  content: '\f659\f659'; }

.fad.fa-person-half-dress::after, .fa-duotone.fa-person-half-dress::after {
  content: '\e548\e548'; }

.fad.fa-folder-image::after, .fa-duotone.fa-folder-image::after {
  content: '\e18a\e18a'; }

.fad.fa-calendar-pen::after, .fa-duotone.fa-calendar-pen::after {
  content: '\f333\f333'; }

.fad.fa-calendar-edit::after, .fa-duotone.fa-calendar-edit::after {
  content: '\f333\f333'; }

.fad.fa-road-bridge::after, .fa-duotone.fa-road-bridge::after {
  content: '\e563\e563'; }

.fad.fa-face-smile-tear::after, .fa-duotone.fa-face-smile-tear::after {
  content: '\e393\e393'; }

.fad.fa-message-plus::after, .fa-duotone.fa-message-plus::after {
  content: '\f4a8\f4a8'; }

.fad.fa-comment-alt-plus::after, .fa-duotone.fa-comment-alt-plus::after {
  content: '\f4a8\f4a8'; }

.fad.fa-location-arrow::after, .fa-duotone.fa-location-arrow::after {
  content: '\f124\f124'; }

.fad.fa-c::after, .fa-duotone.fa-c::after {
  content: '\43\43'; }

.fad.fa-tablet-button::after, .fa-duotone.fa-tablet-button::after {
  content: '\f10a\f10a'; }

.fad.fa-person-dress-fairy::after, .fa-duotone.fa-person-dress-fairy::after {
  content: '\e607\e607'; }

.fad.fa-rectangle-history-circle-user::after, .fa-duotone.fa-rectangle-history-circle-user::after {
  content: '\e4a4\e4a4'; }

.fad.fa-building-lock::after, .fa-duotone.fa-building-lock::after {
  content: '\e4d6\e4d6'; }

.fad.fa-chart-line-up::after, .fa-duotone.fa-chart-line-up::after {
  content: '\e0e5\e0e5'; }

.fad.fa-mailbox::after, .fa-duotone.fa-mailbox::after {
  content: '\f813\f813'; }

.fad.fa-truck-bolt::after, .fa-duotone.fa-truck-bolt::after {
  content: '\e3d0\e3d0'; }

.fad.fa-pizza-slice::after, .fa-duotone.fa-pizza-slice::after {
  content: '\f818\f818'; }

.fad.fa-money-bill-wave::after, .fa-duotone.fa-money-bill-wave::after {
  content: '\f53a\f53a'; }

.fad.fa-chart-area::after, .fa-duotone.fa-chart-area::after {
  content: '\f1fe\f1fe'; }

.fad.fa-area-chart::after, .fa-duotone.fa-area-chart::after {
  content: '\f1fe\f1fe'; }

.fad.fa-house-flag::after, .fa-duotone.fa-house-flag::after {
  content: '\e50d\e50d'; }

.fad.fa-circle-three-quarters-stroke::after, .fa-duotone.fa-circle-three-quarters-stroke::after {
  content: '\e5d4\e5d4'; }

.fad.fa-person-circle-minus::after, .fa-duotone.fa-person-circle-minus::after {
  content: '\e540\e540'; }

.fad.fa-scalpel::after, .fa-duotone.fa-scalpel::after {
  content: '\f61d\f61d'; }

.fad.fa-ban::after, .fa-duotone.fa-ban::after {
  content: '\f05e\f05e'; }

.fad.fa-cancel::after, .fa-duotone.fa-cancel::after {
  content: '\f05e\f05e'; }

.fad.fa-bell-exclamation::after, .fa-duotone.fa-bell-exclamation::after {
  content: '\f848\f848'; }

.fad.fa-circle-bookmark::after, .fa-duotone.fa-circle-bookmark::after {
  content: '\e100\e100'; }

.fad.fa-bookmark-circle::after, .fa-duotone.fa-bookmark-circle::after {
  content: '\e100\e100'; }

.fad.fa-egg-fried::after, .fa-duotone.fa-egg-fried::after {
  content: '\f7fc\f7fc'; }

.fad.fa-face-weary::after, .fa-duotone.fa-face-weary::after {
  content: '\e3a1\e3a1'; }

.fad.fa-uniform-martial-arts::after, .fa-duotone.fa-uniform-martial-arts::after {
  content: '\e3d1\e3d1'; }

.fad.fa-camera-rotate::after, .fa-duotone.fa-camera-rotate::after {
  content: '\e0d8\e0d8'; }

.fad.fa-sun-dust::after, .fa-duotone.fa-sun-dust::after {
  content: '\f764\f764'; }

.fad.fa-comment-text::after, .fa-duotone.fa-comment-text::after {
  content: '\e14d\e14d'; }

.fad.fa-spray-can-sparkles::after, .fa-duotone.fa-spray-can-sparkles::after {
  content: '\f5d0\f5d0'; }

.fad.fa-air-freshener::after, .fa-duotone.fa-air-freshener::after {
  content: '\f5d0\f5d0'; }

.fad.fa-signal-bars::after, .fa-duotone.fa-signal-bars::after {
  content: '\f690\f690'; }

.fad.fa-signal-alt::after, .fa-duotone.fa-signal-alt::after {
  content: '\f690\f690'; }

.fad.fa-signal-alt-4::after, .fa-duotone.fa-signal-alt-4::after {
  content: '\f690\f690'; }

.fad.fa-signal-bars-strong::after, .fa-duotone.fa-signal-bars-strong::after {
  content: '\f690\f690'; }

.fad.fa-diamond-exclamation::after, .fa-duotone.fa-diamond-exclamation::after {
  content: '\e405\e405'; }

.fad.fa-star::after, .fa-duotone.fa-star::after {
  content: '\f005\f005'; }

.fad.fa-dial-min::after, .fa-duotone.fa-dial-min::after {
  content: '\e161\e161'; }

.fad.fa-repeat::after, .fa-duotone.fa-repeat::after {
  content: '\f363\f363'; }

.fad.fa-cross::after, .fa-duotone.fa-cross::after {
  content: '\f654\f654'; }

.fad.fa-page-caret-down::after, .fa-duotone.fa-page-caret-down::after {
  content: '\e429\e429'; }

.fad.fa-file-caret-down::after, .fa-duotone.fa-file-caret-down::after {
  content: '\e429\e429'; }

.fad.fa-box::after, .fa-duotone.fa-box::after {
  content: '\f466\f466'; }

.fad.fa-venus-mars::after, .fa-duotone.fa-venus-mars::after {
  content: '\f228\f228'; }

.fad.fa-clock-seven-thirty::after, .fa-duotone.fa-clock-seven-thirty::after {
  content: '\e351\e351'; }

.fad.fa-arrow-pointer::after, .fa-duotone.fa-arrow-pointer::after {
  content: '\f245\f245'; }

.fad.fa-mouse-pointer::after, .fa-duotone.fa-mouse-pointer::after {
  content: '\f245\f245'; }

.fad.fa-clock-four-thirty::after, .fa-duotone.fa-clock-four-thirty::after {
  content: '\e34b\e34b'; }

.fad.fa-signal-bars-good::after, .fa-duotone.fa-signal-bars-good::after {
  content: '\f693\f693'; }

.fad.fa-signal-alt-3::after, .fa-duotone.fa-signal-alt-3::after {
  content: '\f693\f693'; }

.fad.fa-cactus::after, .fa-duotone.fa-cactus::after {
  content: '\f8a7\f8a7'; }

.fad.fa-lightbulb-gear::after, .fa-duotone.fa-lightbulb-gear::after {
  content: '\e5fd\e5fd'; }

.fad.fa-maximize::after, .fa-duotone.fa-maximize::after {
  content: '\f31e\f31e'; }

.fad.fa-expand-arrows-alt::after, .fa-duotone.fa-expand-arrows-alt::after {
  content: '\f31e\f31e'; }

.fad.fa-charging-station::after, .fa-duotone.fa-charging-station::after {
  content: '\f5e7\f5e7'; }

.fad.fa-shapes::after, .fa-duotone.fa-shapes::after {
  content: '\f61f\f61f'; }

.fad.fa-triangle-circle-square::after, .fa-duotone.fa-triangle-circle-square::after {
  content: '\f61f\f61f'; }

.fad.fa-plane-tail::after, .fa-duotone.fa-plane-tail::after {
  content: '\e22c\e22c'; }

.fad.fa-gauge-simple-max::after, .fa-duotone.fa-gauge-simple-max::after {
  content: '\f62b\f62b'; }

.fad.fa-tachometer-fastest::after, .fa-duotone.fa-tachometer-fastest::after {
  content: '\f62b\f62b'; }

.fad.fa-circle-u::after, .fa-duotone.fa-circle-u::after {
  content: '\e127\e127'; }

.fad.fa-shield-slash::after, .fa-duotone.fa-shield-slash::after {
  content: '\e24b\e24b'; }

.fad.fa-square-phone-hangup::after, .fa-duotone.fa-square-phone-hangup::after {
  content: '\e27a\e27a'; }

.fad.fa-phone-square-down::after, .fa-duotone.fa-phone-square-down::after {
  content: '\e27a\e27a'; }

.fad.fa-arrow-up-left::after, .fa-duotone.fa-arrow-up-left::after {
  content: '\e09d\e09d'; }

.fad.fa-transporter-1::after, .fa-duotone.fa-transporter-1::after {
  content: '\e043\e043'; }

.fad.fa-peanuts::after, .fa-duotone.fa-peanuts::after {
  content: '\e431\e431'; }

.fad.fa-shuffle::after, .fa-duotone.fa-shuffle::after {
  content: '\f074\f074'; }

.fad.fa-random::after, .fa-duotone.fa-random::after {
  content: '\f074\f074'; }

.fad.fa-person-running::after, .fa-duotone.fa-person-running::after {
  content: '\f70c\f70c'; }

.fad.fa-running::after, .fa-duotone.fa-running::after {
  content: '\f70c\f70c'; }

.fad.fa-mobile-retro::after, .fa-duotone.fa-mobile-retro::after {
  content: '\e527\e527'; }

.fad.fa-grip-lines-vertical::after, .fa-duotone.fa-grip-lines-vertical::after {
  content: '\f7a5\f7a5'; }

.fad.fa-bin-bottles-recycle::after, .fa-duotone.fa-bin-bottles-recycle::after {
  content: '\e5f6\e5f6'; }

.fad.fa-arrow-up-from-square::after, .fa-duotone.fa-arrow-up-from-square::after {
  content: '\e09c\e09c'; }

.fad.fa-file-dashed-line::after, .fa-duotone.fa-file-dashed-line::after {
  content: '\f877\f877'; }

.fad.fa-page-break::after, .fa-duotone.fa-page-break::after {
  content: '\f877\f877'; }

.fad.fa-bracket-curly-right::after, .fa-duotone.fa-bracket-curly-right::after {
  content: '\7d\7d'; }

.fad.fa-spider::after, .fa-duotone.fa-spider::after {
  content: '\f717\f717'; }

.fad.fa-clock-three::after, .fa-duotone.fa-clock-three::after {
  content: '\e356\e356'; }

.fad.fa-hands-bound::after, .fa-duotone.fa-hands-bound::after {
  content: '\e4f9\e4f9'; }

.fad.fa-scalpel-line-dashed::after, .fa-duotone.fa-scalpel-line-dashed::after {
  content: '\f61e\f61e'; }

.fad.fa-scalpel-path::after, .fa-duotone.fa-scalpel-path::after {
  content: '\f61e\f61e'; }

.fad.fa-file-invoice-dollar::after, .fa-duotone.fa-file-invoice-dollar::after {
  content: '\f571\f571'; }

.fad.fa-pipe-smoking::after, .fa-duotone.fa-pipe-smoking::after {
  content: '\e3c4\e3c4'; }

.fad.fa-face-astonished::after, .fa-duotone.fa-face-astonished::after {
  content: '\e36b\e36b'; }

.fad.fa-window::after, .fa-duotone.fa-window::after {
  content: '\f40e\f40e'; }

.fad.fa-plane-circle-exclamation::after, .fa-duotone.fa-plane-circle-exclamation::after {
  content: '\e556\e556'; }

.fad.fa-ear::after, .fa-duotone.fa-ear::after {
  content: '\f5f0\f5f0'; }

.fad.fa-file-lock::after, .fa-duotone.fa-file-lock::after {
  content: '\e3a6\e3a6'; }

.fad.fa-diagram-venn::after, .fa-duotone.fa-diagram-venn::after {
  content: '\e15a\e15a'; }

.fad.fa-x-ray::after, .fa-duotone.fa-x-ray::after {
  content: '\f497\f497'; }

.fad.fa-goal-net::after, .fa-duotone.fa-goal-net::after {
  content: '\e3ab\e3ab'; }

.fad.fa-coffin-cross::after, .fa-duotone.fa-coffin-cross::after {
  content: '\e051\e051'; }

.fad.fa-spell-check::after, .fa-duotone.fa-spell-check::after {
  content: '\f891\f891'; }

.fad.fa-location-xmark::after, .fa-duotone.fa-location-xmark::after {
  content: '\f60e\f60e'; }

.fad.fa-map-marker-times::after, .fa-duotone.fa-map-marker-times::after {
  content: '\f60e\f60e'; }

.fad.fa-map-marker-xmark::after, .fa-duotone.fa-map-marker-xmark::after {
  content: '\f60e\f60e'; }

.fad.fa-circle-quarter-stroke::after, .fa-duotone.fa-circle-quarter-stroke::after {
  content: '\e5d3\e5d3'; }

.fad.fa-lasso::after, .fa-duotone.fa-lasso::after {
  content: '\f8c8\f8c8'; }

.fad.fa-slash::after, .fa-duotone.fa-slash::after {
  content: '\f715\f715'; }

.fad.fa-person-to-portal::after, .fa-duotone.fa-person-to-portal::after {
  content: '\e022\e022'; }

.fad.fa-portal-enter::after, .fa-duotone.fa-portal-enter::after {
  content: '\e022\e022'; }

.fad.fa-calendar-star::after, .fa-duotone.fa-calendar-star::after {
  content: '\f736\f736'; }

.fad.fa-computer-mouse::after, .fa-duotone.fa-computer-mouse::after {
  content: '\f8cc\f8cc'; }

.fad.fa-mouse::after, .fa-duotone.fa-mouse::after {
  content: '\f8cc\f8cc'; }

.fad.fa-arrow-right-to-bracket::after, .fa-duotone.fa-arrow-right-to-bracket::after {
  content: '\f090\f090'; }

.fad.fa-sign-in::after, .fa-duotone.fa-sign-in::after {
  content: '\f090\f090'; }

.fad.fa-pegasus::after, .fa-duotone.fa-pegasus::after {
  content: '\f703\f703'; }

.fad.fa-files-medical::after, .fa-duotone.fa-files-medical::after {
  content: '\f7fd\f7fd'; }

.fad.fa-nfc-lock::after, .fa-duotone.fa-nfc-lock::after {
  content: '\e1f8\e1f8'; }

.fad.fa-person-ski-lift::after, .fa-duotone.fa-person-ski-lift::after {
  content: '\f7c8\f7c8'; }

.fad.fa-ski-lift::after, .fa-duotone.fa-ski-lift::after {
  content: '\f7c8\f7c8'; }

.fad.fa-square-6::after, .fa-duotone.fa-square-6::after {
  content: '\e25b\e25b'; }

.fad.fa-shop-slash::after, .fa-duotone.fa-shop-slash::after {
  content: '\e070\e070'; }

.fad.fa-store-alt-slash::after, .fa-duotone.fa-store-alt-slash::after {
  content: '\e070\e070'; }

.fad.fa-wind-turbine::after, .fa-duotone.fa-wind-turbine::after {
  content: '\f89b\f89b'; }

.fad.fa-sliders-simple::after, .fa-duotone.fa-sliders-simple::after {
  content: '\e253\e253'; }

.fad.fa-grid-round::after, .fa-duotone.fa-grid-round::after {
  content: '\e5da\e5da'; }

.fad.fa-badge-sheriff::after, .fa-duotone.fa-badge-sheriff::after {
  content: '\f8a2\f8a2'; }

.fad.fa-server::after, .fa-duotone.fa-server::after {
  content: '\f233\f233'; }

.fad.fa-virus-covid-slash::after, .fa-duotone.fa-virus-covid-slash::after {
  content: '\e4a9\e4a9'; }

.fad.fa-intersection::after, .fa-duotone.fa-intersection::after {
  content: '\f668\f668'; }

.fad.fa-shop-lock::after, .fa-duotone.fa-shop-lock::after {
  content: '\e4a5\e4a5'; }

.fad.fa-family::after, .fa-duotone.fa-family::after {
  content: '\e300\e300'; }

.fad.fa-hourglass-start::after, .fa-duotone.fa-hourglass-start::after {
  content: '\f251\f251'; }

.fad.fa-hourglass-1::after, .fa-duotone.fa-hourglass-1::after {
  content: '\f251\f251'; }

.fad.fa-user-hair-buns::after, .fa-duotone.fa-user-hair-buns::after {
  content: '\e3d3\e3d3'; }

.fad.fa-blender-phone::after, .fa-duotone.fa-blender-phone::after {
  content: '\f6b6\f6b6'; }

.fad.fa-hourglass-clock::after, .fa-duotone.fa-hourglass-clock::after {
  content: '\e41b\e41b'; }

.fad.fa-person-seat-reclined::after, .fa-duotone.fa-person-seat-reclined::after {
  content: '\e21f\e21f'; }

.fad.fa-paper-plane-top::after, .fa-duotone.fa-paper-plane-top::after {
  content: '\e20a\e20a'; }

.fad.fa-paper-plane-alt::after, .fa-duotone.fa-paper-plane-alt::after {
  content: '\e20a\e20a'; }

.fad.fa-send::after, .fa-duotone.fa-send::after {
  content: '\e20a\e20a'; }

.fad.fa-message-arrow-up::after, .fa-duotone.fa-message-arrow-up::after {
  content: '\e1dc\e1dc'; }

.fad.fa-comment-alt-arrow-up::after, .fa-duotone.fa-comment-alt-arrow-up::after {
  content: '\e1dc\e1dc'; }

.fad.fa-lightbulb-exclamation::after, .fa-duotone.fa-lightbulb-exclamation::after {
  content: '\f671\f671'; }

.fad.fa-layer-minus::after, .fa-duotone.fa-layer-minus::after {
  content: '\f5fe\f5fe'; }

.fad.fa-layer-group-minus::after, .fa-duotone.fa-layer-group-minus::after {
  content: '\f5fe\f5fe'; }

.fad.fa-chart-pie-simple-circle-currency::after, .fa-duotone.fa-chart-pie-simple-circle-currency::after {
  content: '\e604\e604'; }

.fad.fa-circle-e::after, .fa-duotone.fa-circle-e::after {
  content: '\e109\e109'; }

.fad.fa-building-wheat::after, .fa-duotone.fa-building-wheat::after {
  content: '\e4db\e4db'; }

.fad.fa-gauge-max::after, .fa-duotone.fa-gauge-max::after {
  content: '\f626\f626'; }

.fad.fa-tachometer-alt-fastest::after, .fa-duotone.fa-tachometer-alt-fastest::after {
  content: '\f626\f626'; }

.fad.fa-person-breastfeeding::after, .fa-duotone.fa-person-breastfeeding::after {
  content: '\e53a\e53a'; }

.fad.fa-apostrophe::after, .fa-duotone.fa-apostrophe::after {
  content: '\27\27'; }

.fad.fa-fire-hydrant::after, .fa-duotone.fa-fire-hydrant::after {
  content: '\e17f\e17f'; }

.fad.fa-right-to-bracket::after, .fa-duotone.fa-right-to-bracket::after {
  content: '\f2f6\f2f6'; }

.fad.fa-sign-in-alt::after, .fa-duotone.fa-sign-in-alt::after {
  content: '\f2f6\f2f6'; }

.fad.fa-video-plus::after, .fa-duotone.fa-video-plus::after {
  content: '\f4e1\f4e1'; }

.fad.fa-square-right::after, .fa-duotone.fa-square-right::after {
  content: '\f352\f352'; }

.fad.fa-arrow-alt-square-right::after, .fa-duotone.fa-arrow-alt-square-right::after {
  content: '\f352\f352'; }

.fad.fa-comment-smile::after, .fa-duotone.fa-comment-smile::after {
  content: '\f4b4\f4b4'; }

.fad.fa-venus::after, .fa-duotone.fa-venus::after {
  content: '\f221\f221'; }

.fad.fa-passport::after, .fa-duotone.fa-passport::after {
  content: '\f5ab\f5ab'; }

.fad.fa-inbox-in::after, .fa-duotone.fa-inbox-in::after {
  content: '\f310\f310'; }

.fad.fa-inbox-arrow-down::after, .fa-duotone.fa-inbox-arrow-down::after {
  content: '\f310\f310'; }

.fad.fa-heart-pulse::after, .fa-duotone.fa-heart-pulse::after {
  content: '\f21e\f21e'; }

.fad.fa-heartbeat::after, .fa-duotone.fa-heartbeat::after {
  content: '\f21e\f21e'; }

.fad.fa-circle-8::after, .fa-duotone.fa-circle-8::after {
  content: '\e0f5\e0f5'; }

.fad.fa-clouds-moon::after, .fa-duotone.fa-clouds-moon::after {
  content: '\f745\f745'; }

.fad.fa-clock-ten-thirty::after, .fa-duotone.fa-clock-ten-thirty::after {
  content: '\e355\e355'; }

.fad.fa-people-carry-box::after, .fa-duotone.fa-people-carry-box::after {
  content: '\f4ce\f4ce'; }

.fad.fa-people-carry::after, .fa-duotone.fa-people-carry::after {
  content: '\f4ce\f4ce'; }

.fad.fa-folder-user::after, .fa-duotone.fa-folder-user::after {
  content: '\e18e\e18e'; }

.fad.fa-trash-can-xmark::after, .fa-duotone.fa-trash-can-xmark::after {
  content: '\e2ae\e2ae'; }

.fad.fa-temperature-high::after, .fa-duotone.fa-temperature-high::after {
  content: '\f769\f769'; }

.fad.fa-microchip::after, .fa-duotone.fa-microchip::after {
  content: '\f2db\f2db'; }

.fad.fa-left-long-to-line::after, .fa-duotone.fa-left-long-to-line::after {
  content: '\e41e\e41e'; }

.fad.fa-crown::after, .fa-duotone.fa-crown::after {
  content: '\f521\f521'; }

.fad.fa-weight-hanging::after, .fa-duotone.fa-weight-hanging::after {
  content: '\f5cd\f5cd'; }

.fad.fa-xmarks-lines::after, .fa-duotone.fa-xmarks-lines::after {
  content: '\e59a\e59a'; }

.fad.fa-file-prescription::after, .fa-duotone.fa-file-prescription::after {
  content: '\f572\f572'; }

.fad.fa-calendar-range::after, .fa-duotone.fa-calendar-range::after {
  content: '\e0d6\e0d6'; }

.fad.fa-flower-daffodil::after, .fa-duotone.fa-flower-daffodil::after {
  content: '\f800\f800'; }

.fad.fa-hand-back-point-up::after, .fa-duotone.fa-hand-back-point-up::after {
  content: '\e1a2\e1a2'; }

.fad.fa-weight-scale::after, .fa-duotone.fa-weight-scale::after {
  content: '\f496\f496'; }

.fad.fa-weight::after, .fa-duotone.fa-weight::after {
  content: '\f496\f496'; }

.fad.fa-arrow-up-to-arc::after, .fa-duotone.fa-arrow-up-to-arc::after {
  content: '\e617\e617'; }

.fad.fa-star-exclamation::after, .fa-duotone.fa-star-exclamation::after {
  content: '\f2f3\f2f3'; }

.fad.fa-books::after, .fa-duotone.fa-books::after {
  content: '\f5db\f5db'; }

.fad.fa-user-group::after, .fa-duotone.fa-user-group::after {
  content: '\f500\f500'; }

.fad.fa-user-friends::after, .fa-duotone.fa-user-friends::after {
  content: '\f500\f500'; }

.fad.fa-arrow-up-a-z::after, .fa-duotone.fa-arrow-up-a-z::after {
  content: '\f15e\f15e'; }

.fad.fa-sort-alpha-up::after, .fa-duotone.fa-sort-alpha-up::after {
  content: '\f15e\f15e'; }

.fad.fa-layer-plus::after, .fa-duotone.fa-layer-plus::after {
  content: '\f5ff\f5ff'; }

.fad.fa-layer-group-plus::after, .fa-duotone.fa-layer-group-plus::after {
  content: '\f5ff\f5ff'; }

.fad.fa-play-pause::after, .fa-duotone.fa-play-pause::after {
  content: '\e22f\e22f'; }

.fad.fa-block-question::after, .fa-duotone.fa-block-question::after {
  content: '\e3dd\e3dd'; }

.fad.fa-snooze::after, .fa-duotone.fa-snooze::after {
  content: '\f880\f880'; }

.fad.fa-zzz::after, .fa-duotone.fa-zzz::after {
  content: '\f880\f880'; }

.fad.fa-scanner-image::after, .fa-duotone.fa-scanner-image::after {
  content: '\f8f3\f8f3'; }

.fad.fa-tv-retro::after, .fa-duotone.fa-tv-retro::after {
  content: '\f401\f401'; }

.fad.fa-square-t::after, .fa-duotone.fa-square-t::after {
  content: '\e280\e280'; }

.fad.fa-farm::after, .fa-duotone.fa-farm::after {
  content: '\f864\f864'; }

.fad.fa-barn-silo::after, .fa-duotone.fa-barn-silo::after {
  content: '\f864\f864'; }

.fad.fa-chess-knight::after, .fa-duotone.fa-chess-knight::after {
  content: '\f441\f441'; }

.fad.fa-bars-sort::after, .fa-duotone.fa-bars-sort::after {
  content: '\e0ae\e0ae'; }

.fad.fa-pallet-boxes::after, .fa-duotone.fa-pallet-boxes::after {
  content: '\f483\f483'; }

.fad.fa-palette-boxes::after, .fa-duotone.fa-palette-boxes::after {
  content: '\f483\f483'; }

.fad.fa-pallet-alt::after, .fa-duotone.fa-pallet-alt::after {
  content: '\f483\f483'; }

.fad.fa-face-laugh-squint::after, .fa-duotone.fa-face-laugh-squint::after {
  content: '\f59b\f59b'; }

.fad.fa-laugh-squint::after, .fa-duotone.fa-laugh-squint::after {
  content: '\f59b\f59b'; }

.fad.fa-code-simple::after, .fa-duotone.fa-code-simple::after {
  content: '\e13d\e13d'; }

.fad.fa-bolt-slash::after, .fa-duotone.fa-bolt-slash::after {
  content: '\e0b8\e0b8'; }

.fad.fa-panel-fire::after, .fa-duotone.fa-panel-fire::after {
  content: '\e42f\e42f'; }

.fad.fa-binary-circle-check::after, .fa-duotone.fa-binary-circle-check::after {
  content: '\e33c\e33c'; }

.fad.fa-comment-minus::after, .fa-duotone.fa-comment-minus::after {
  content: '\f4b1\f4b1'; }

.fad.fa-burrito::after, .fa-duotone.fa-burrito::after {
  content: '\f7ed\f7ed'; }

.fad.fa-violin::after, .fa-duotone.fa-violin::after {
  content: '\f8ed\f8ed'; }

.fad.fa-objects-column::after, .fa-duotone.fa-objects-column::after {
  content: '\e3c1\e3c1'; }

.fad.fa-square-chevron-down::after, .fa-duotone.fa-square-chevron-down::after {
  content: '\f329\f329'; }

.fad.fa-chevron-square-down::after, .fa-duotone.fa-chevron-square-down::after {
  content: '\f329\f329'; }

.fad.fa-comment-plus::after, .fa-duotone.fa-comment-plus::after {
  content: '\f4b2\f4b2'; }

.fad.fa-triangle-instrument::after, .fa-duotone.fa-triangle-instrument::after {
  content: '\f8e2\f8e2'; }

.fad.fa-triangle-music::after, .fa-duotone.fa-triangle-music::after {
  content: '\f8e2\f8e2'; }

.fad.fa-wheelchair::after, .fa-duotone.fa-wheelchair::after {
  content: '\f193\f193'; }

.fad.fa-user-pilot-tie::after, .fa-duotone.fa-user-pilot-tie::after {
  content: '\e2c1\e2c1'; }

.fad.fa-piano-keyboard::after, .fa-duotone.fa-piano-keyboard::after {
  content: '\f8d5\f8d5'; }

.fad.fa-bed-empty::after, .fa-duotone.fa-bed-empty::after {
  content: '\f8f9\f8f9'; }

.fad.fa-circle-arrow-up::after, .fa-duotone.fa-circle-arrow-up::after {
  content: '\f0aa\f0aa'; }

.fad.fa-arrow-circle-up::after, .fa-duotone.fa-arrow-circle-up::after {
  content: '\f0aa\f0aa'; }

.fad.fa-toggle-on::after, .fa-duotone.fa-toggle-on::after {
  content: '\f205\f205'; }

.fad.fa-rectangle-vertical::after, .fa-duotone.fa-rectangle-vertical::after {
  content: '\f2fb\f2fb'; }

.fad.fa-rectangle-portrait::after, .fa-duotone.fa-rectangle-portrait::after {
  content: '\f2fb\f2fb'; }

.fad.fa-person-walking::after, .fa-duotone.fa-person-walking::after {
  content: '\f554\f554'; }

.fad.fa-walking::after, .fa-duotone.fa-walking::after {
  content: '\f554\f554'; }

.fad.fa-l::after, .fa-duotone.fa-l::after {
  content: '\4c\4c'; }

.fad.fa-signal-stream::after, .fa-duotone.fa-signal-stream::after {
  content: '\f8dd\f8dd'; }

.fad.fa-down-to-bracket::after, .fa-duotone.fa-down-to-bracket::after {
  content: '\e4e7\e4e7'; }

.fad.fa-circle-z::after, .fa-duotone.fa-circle-z::after {
  content: '\e130\e130'; }

.fad.fa-stars::after, .fa-duotone.fa-stars::after {
  content: '\f762\f762'; }

.fad.fa-fire::after, .fa-duotone.fa-fire::after {
  content: '\f06d\f06d'; }

.fad.fa-bed-pulse::after, .fa-duotone.fa-bed-pulse::after {
  content: '\f487\f487'; }

.fad.fa-procedures::after, .fa-duotone.fa-procedures::after {
  content: '\f487\f487'; }

.fad.fa-house-day::after, .fa-duotone.fa-house-day::after {
  content: '\e00e\e00e'; }

.fad.fa-shuttle-space::after, .fa-duotone.fa-shuttle-space::after {
  content: '\f197\f197'; }

.fad.fa-space-shuttle::after, .fa-duotone.fa-space-shuttle::after {
  content: '\f197\f197'; }

.fad.fa-shirt-long-sleeve::after, .fa-duotone.fa-shirt-long-sleeve::after {
  content: '\e3c7\e3c7'; }

.fad.fa-chart-pie-simple::after, .fa-duotone.fa-chart-pie-simple::after {
  content: '\f64e\f64e'; }

.fad.fa-chart-pie-alt::after, .fa-duotone.fa-chart-pie-alt::after {
  content: '\f64e\f64e'; }

.fad.fa-face-laugh::after, .fa-duotone.fa-face-laugh::after {
  content: '\f599\f599'; }

.fad.fa-laugh::after, .fa-duotone.fa-laugh::after {
  content: '\f599\f599'; }

.fad.fa-folder-open::after, .fa-duotone.fa-folder-open::after {
  content: '\f07c\f07c'; }

.fad.fa-album-collection-circle-user::after, .fa-duotone.fa-album-collection-circle-user::after {
  content: '\e48f\e48f'; }

.fad.fa-candy::after, .fa-duotone.fa-candy::after {
  content: '\e3e7\e3e7'; }

.fad.fa-bowl-hot::after, .fa-duotone.fa-bowl-hot::after {
  content: '\f823\f823'; }

.fad.fa-soup::after, .fa-duotone.fa-soup::after {
  content: '\f823\f823'; }

.fad.fa-flatbread::after, .fa-duotone.fa-flatbread::after {
  content: '\e40b\e40b'; }

.fad.fa-heart-circle-plus::after, .fa-duotone.fa-heart-circle-plus::after {
  content: '\e500\e500'; }

.fad.fa-code-fork::after, .fa-duotone.fa-code-fork::after {
  content: '\e13b\e13b'; }

.fad.fa-city::after, .fa-duotone.fa-city::after {
  content: '\f64f\f64f'; }

.fad.fa-signal-bars-weak::after, .fa-duotone.fa-signal-bars-weak::after {
  content: '\f691\f691'; }

.fad.fa-signal-alt-1::after, .fa-duotone.fa-signal-alt-1::after {
  content: '\f691\f691'; }

.fad.fa-microphone-lines::after, .fa-duotone.fa-microphone-lines::after {
  content: '\f3c9\f3c9'; }

.fad.fa-microphone-alt::after, .fa-duotone.fa-microphone-alt::after {
  content: '\f3c9\f3c9'; }

.fad.fa-clock-twelve::after, .fa-duotone.fa-clock-twelve::after {
  content: '\e358\e358'; }

.fad.fa-pepper-hot::after, .fa-duotone.fa-pepper-hot::after {
  content: '\f816\f816'; }

.fad.fa-citrus-slice::after, .fa-duotone.fa-citrus-slice::after {
  content: '\e2f5\e2f5'; }

.fad.fa-sheep::after, .fa-duotone.fa-sheep::after {
  content: '\f711\f711'; }

.fad.fa-unlock::after, .fa-duotone.fa-unlock::after {
  content: '\f09c\f09c'; }

.fad.fa-colon-sign::after, .fa-duotone.fa-colon-sign::after {
  content: '\e140\e140'; }

.fad.fa-headset::after, .fa-duotone.fa-headset::after {
  content: '\f590\f590'; }

.fad.fa-badger-honey::after, .fa-duotone.fa-badger-honey::after {
  content: '\f6b4\f6b4'; }

.fad.fa-h4::after, .fa-duotone.fa-h4::after {
  content: '\f86a\f86a'; }

.fad.fa-store-slash::after, .fa-duotone.fa-store-slash::after {
  content: '\e071\e071'; }

.fad.fa-road-circle-xmark::after, .fa-duotone.fa-road-circle-xmark::after {
  content: '\e566\e566'; }

.fad.fa-signal-slash::after, .fa-duotone.fa-signal-slash::after {
  content: '\f695\f695'; }

.fad.fa-user-minus::after, .fa-duotone.fa-user-minus::after {
  content: '\f503\f503'; }

.fad.fa-mars-stroke-up::after, .fa-duotone.fa-mars-stroke-up::after {
  content: '\f22a\f22a'; }

.fad.fa-mars-stroke-v::after, .fa-duotone.fa-mars-stroke-v::after {
  content: '\f22a\f22a'; }

.fad.fa-champagne-glasses::after, .fa-duotone.fa-champagne-glasses::after {
  content: '\f79f\f79f'; }

.fad.fa-glass-cheers::after, .fa-duotone.fa-glass-cheers::after {
  content: '\f79f\f79f'; }

.fad.fa-taco::after, .fa-duotone.fa-taco::after {
  content: '\f826\f826'; }

.fad.fa-hexagon-plus::after, .fa-duotone.fa-hexagon-plus::after {
  content: '\f300\f300'; }

.fad.fa-plus-hexagon::after, .fa-duotone.fa-plus-hexagon::after {
  content: '\f300\f300'; }

.fad.fa-clipboard::after, .fa-duotone.fa-clipboard::after {
  content: '\f328\f328'; }

.fad.fa-house-circle-exclamation::after, .fa-duotone.fa-house-circle-exclamation::after {
  content: '\e50a\e50a'; }

.fad.fa-file-arrow-up::after, .fa-duotone.fa-file-arrow-up::after {
  content: '\f574\f574'; }

.fad.fa-file-upload::after, .fa-duotone.fa-file-upload::after {
  content: '\f574\f574'; }

.fad.fa-wifi::after, .fa-duotone.fa-wifi::after {
  content: '\f1eb\f1eb'; }

.fad.fa-wifi-3::after, .fa-duotone.fa-wifi-3::after {
  content: '\f1eb\f1eb'; }

.fad.fa-wifi-strong::after, .fa-duotone.fa-wifi-strong::after {
  content: '\f1eb\f1eb'; }

.fad.fa-messages::after, .fa-duotone.fa-messages::after {
  content: '\f4b6\f4b6'; }

.fad.fa-comments-alt::after, .fa-duotone.fa-comments-alt::after {
  content: '\f4b6\f4b6'; }

.fad.fa-bath::after, .fa-duotone.fa-bath::after {
  content: '\f2cd\f2cd'; }

.fad.fa-bathtub::after, .fa-duotone.fa-bathtub::after {
  content: '\f2cd\f2cd'; }

.fad.fa-umbrella-simple::after, .fa-duotone.fa-umbrella-simple::after {
  content: '\e2bc\e2bc'; }

.fad.fa-umbrella-alt::after, .fa-duotone.fa-umbrella-alt::after {
  content: '\e2bc\e2bc'; }

.fad.fa-rectangle-history-circle-plus::after, .fa-duotone.fa-rectangle-history-circle-plus::after {
  content: '\e4a3\e4a3'; }

.fad.fa-underline::after, .fa-duotone.fa-underline::after {
  content: '\f0cd\f0cd'; }

.fad.fa-prescription-bottle-pill::after, .fa-duotone.fa-prescription-bottle-pill::after {
  content: '\e5c0\e5c0'; }

.fad.fa-user-pen::after, .fa-duotone.fa-user-pen::after {
  content: '\f4ff\f4ff'; }

.fad.fa-user-edit::after, .fa-duotone.fa-user-edit::after {
  content: '\f4ff\f4ff'; }

.fad.fa-binary-slash::after, .fa-duotone.fa-binary-slash::after {
  content: '\e33e\e33e'; }

.fad.fa-square-o::after, .fa-duotone.fa-square-o::after {
  content: '\e278\e278'; }

.fad.fa-signature::after, .fa-duotone.fa-signature::after {
  content: '\f5b7\f5b7'; }

.fad.fa-stroopwafel::after, .fa-duotone.fa-stroopwafel::after {
  content: '\f551\f551'; }

.fad.fa-bold::after, .fa-duotone.fa-bold::after {
  content: '\f032\f032'; }

.fad.fa-anchor-lock::after, .fa-duotone.fa-anchor-lock::after {
  content: '\e4ad\e4ad'; }

.fad.fa-building-ngo::after, .fa-duotone.fa-building-ngo::after {
  content: '\e4d7\e4d7'; }

.fad.fa-transporter-3::after, .fa-duotone.fa-transporter-3::after {
  content: '\e045\e045'; }

.fad.fa-engine-warning::after, .fa-duotone.fa-engine-warning::after {
  content: '\f5f2\f5f2'; }

.fad.fa-engine-exclamation::after, .fa-duotone.fa-engine-exclamation::after {
  content: '\f5f2\f5f2'; }

.fad.fa-circle-down-right::after, .fa-duotone.fa-circle-down-right::after {
  content: '\e108\e108'; }

.fad.fa-square-k::after, .fa-duotone.fa-square-k::after {
  content: '\e274\e274'; }

.fad.fa-manat-sign::after, .fa-duotone.fa-manat-sign::after {
  content: '\e1d5\e1d5'; }

.fad.fa-money-check-pen::after, .fa-duotone.fa-money-check-pen::after {
  content: '\f872\f872'; }

.fad.fa-money-check-edit::after, .fa-duotone.fa-money-check-edit::after {
  content: '\f872\f872'; }

.fad.fa-not-equal::after, .fa-duotone.fa-not-equal::after {
  content: '\f53e\f53e'; }

.fad.fa-border-top-left::after, .fa-duotone.fa-border-top-left::after {
  content: '\f853\f853'; }

.fad.fa-border-style::after, .fa-duotone.fa-border-style::after {
  content: '\f853\f853'; }

.fad.fa-map-location-dot::after, .fa-duotone.fa-map-location-dot::after {
  content: '\f5a0\f5a0'; }

.fad.fa-map-marked-alt::after, .fa-duotone.fa-map-marked-alt::after {
  content: '\f5a0\f5a0'; }

.fad.fa-tilde::after, .fa-duotone.fa-tilde::after {
  content: '\7e\7e'; }

.fad.fa-jedi::after, .fa-duotone.fa-jedi::after {
  content: '\f669\f669'; }

.fad.fa-square-poll-vertical::after, .fa-duotone.fa-square-poll-vertical::after {
  content: '\f681\f681'; }

.fad.fa-poll::after, .fa-duotone.fa-poll::after {
  content: '\f681\f681'; }

.fad.fa-arrow-down-square-triangle::after, .fa-duotone.fa-arrow-down-square-triangle::after {
  content: '\f889\f889'; }

.fad.fa-sort-shapes-down-alt::after, .fa-duotone.fa-sort-shapes-down-alt::after {
  content: '\f889\f889'; }

.fad.fa-mug-hot::after, .fa-duotone.fa-mug-hot::after {
  content: '\f7b6\f7b6'; }

.fad.fa-dog-leashed::after, .fa-duotone.fa-dog-leashed::after {
  content: '\f6d4\f6d4'; }

.fad.fa-car-battery::after, .fa-duotone.fa-car-battery::after {
  content: '\f5df\f5df'; }

.fad.fa-battery-car::after, .fa-duotone.fa-battery-car::after {
  content: '\f5df\f5df'; }

.fad.fa-face-downcast-sweat::after, .fa-duotone.fa-face-downcast-sweat::after {
  content: '\e371\e371'; }

.fad.fa-mailbox-flag-up::after, .fa-duotone.fa-mailbox-flag-up::after {
  content: '\e5bb\e5bb'; }

.fad.fa-memo-circle-info::after, .fa-duotone.fa-memo-circle-info::after {
  content: '\e49a\e49a'; }

.fad.fa-gift::after, .fa-duotone.fa-gift::after {
  content: '\f06b\f06b'; }

.fad.fa-dice-two::after, .fa-duotone.fa-dice-two::after {
  content: '\f528\f528'; }

.fad.fa-volume::after, .fa-duotone.fa-volume::after {
  content: '\f6a8\f6a8'; }

.fad.fa-volume-medium::after, .fa-duotone.fa-volume-medium::after {
  content: '\f6a8\f6a8'; }

.fad.fa-transporter-5::after, .fa-duotone.fa-transporter-5::after {
  content: '\e2a6\e2a6'; }

.fad.fa-gauge-circle-bolt::after, .fa-duotone.fa-gauge-circle-bolt::after {
  content: '\e496\e496'; }

.fad.fa-coin-front::after, .fa-duotone.fa-coin-front::after {
  content: '\e3fc\e3fc'; }

.fad.fa-file-slash::after, .fa-duotone.fa-file-slash::after {
  content: '\e3a7\e3a7'; }

.fad.fa-message-arrow-up-right::after, .fa-duotone.fa-message-arrow-up-right::after {
  content: '\e1dd\e1dd'; }

.fad.fa-treasure-chest::after, .fa-duotone.fa-treasure-chest::after {
  content: '\f723\f723'; }

.fad.fa-chess-queen::after, .fa-duotone.fa-chess-queen::after {
  content: '\f445\f445'; }

.fad.fa-paintbrush-fine::after, .fa-duotone.fa-paintbrush-fine::after {
  content: '\f5a9\f5a9'; }

.fad.fa-paint-brush-alt::after, .fa-duotone.fa-paint-brush-alt::after {
  content: '\f5a9\f5a9'; }

.fad.fa-paint-brush-fine::after, .fa-duotone.fa-paint-brush-fine::after {
  content: '\f5a9\f5a9'; }

.fad.fa-paintbrush-alt::after, .fa-duotone.fa-paintbrush-alt::after {
  content: '\f5a9\f5a9'; }

.fad.fa-glasses::after, .fa-duotone.fa-glasses::after {
  content: '\f530\f530'; }

.fad.fa-hood-cloak::after, .fa-duotone.fa-hood-cloak::after {
  content: '\f6ef\f6ef'; }

.fad.fa-square-quote::after, .fa-duotone.fa-square-quote::after {
  content: '\e329\e329'; }

.fad.fa-up-left::after, .fa-duotone.fa-up-left::after {
  content: '\e2bd\e2bd'; }

.fad.fa-bring-front::after, .fa-duotone.fa-bring-front::after {
  content: '\f857\f857'; }

.fad.fa-chess-board::after, .fa-duotone.fa-chess-board::after {
  content: '\f43c\f43c'; }

.fad.fa-burger-cheese::after, .fa-duotone.fa-burger-cheese::after {
  content: '\f7f1\f7f1'; }

.fad.fa-cheeseburger::after, .fa-duotone.fa-cheeseburger::after {
  content: '\f7f1\f7f1'; }

.fad.fa-building-circle-check::after, .fa-duotone.fa-building-circle-check::after {
  content: '\e4d2\e4d2'; }

.fad.fa-repeat-1::after, .fa-duotone.fa-repeat-1::after {
  content: '\f365\f365'; }

.fad.fa-arrow-down-to-line::after, .fa-duotone.fa-arrow-down-to-line::after {
  content: '\f33d\f33d'; }

.fad.fa-arrow-to-bottom::after, .fa-duotone.fa-arrow-to-bottom::after {
  content: '\f33d\f33d'; }

.fad.fa-grid-5::after, .fa-duotone.fa-grid-5::after {
  content: '\e199\e199'; }

.fad.fa-swap-arrows::after, .fa-duotone.fa-swap-arrows::after {
  content: '\e60a\e60a'; }

.fad.fa-right-long-to-line::after, .fa-duotone.fa-right-long-to-line::after {
  content: '\e444\e444'; }

.fad.fa-person-chalkboard::after, .fa-duotone.fa-person-chalkboard::after {
  content: '\e53d\e53d'; }

.fad.fa-mars-stroke-right::after, .fa-duotone.fa-mars-stroke-right::after {
  content: '\f22b\f22b'; }

.fad.fa-mars-stroke-h::after, .fa-duotone.fa-mars-stroke-h::after {
  content: '\f22b\f22b'; }

.fad.fa-hand-back-fist::after, .fa-duotone.fa-hand-back-fist::after {
  content: '\f255\f255'; }

.fad.fa-hand-rock::after, .fa-duotone.fa-hand-rock::after {
  content: '\f255\f255'; }

.fad.fa-grid-round-5::after, .fa-duotone.fa-grid-round-5::after {
  content: '\e5de\e5de'; }

.fad.fa-tally::after, .fa-duotone.fa-tally::after {
  content: '\f69c\f69c'; }

.fad.fa-tally-5::after, .fa-duotone.fa-tally-5::after {
  content: '\f69c\f69c'; }

.fad.fa-square-caret-up::after, .fa-duotone.fa-square-caret-up::after {
  content: '\f151\f151'; }

.fad.fa-caret-square-up::after, .fa-duotone.fa-caret-square-up::after {
  content: '\f151\f151'; }

.fad.fa-cloud-showers-water::after, .fa-duotone.fa-cloud-showers-water::after {
  content: '\e4e4\e4e4'; }

.fad.fa-chart-bar::after, .fa-duotone.fa-chart-bar::after {
  content: '\f080\f080'; }

.fad.fa-bar-chart::after, .fa-duotone.fa-bar-chart::after {
  content: '\f080\f080'; }

.fad.fa-hands-bubbles::after, .fa-duotone.fa-hands-bubbles::after {
  content: '\e05e\e05e'; }

.fad.fa-hands-wash::after, .fa-duotone.fa-hands-wash::after {
  content: '\e05e\e05e'; }

.fad.fa-less-than-equal::after, .fa-duotone.fa-less-than-equal::after {
  content: '\f537\f537'; }

.fad.fa-train::after, .fa-duotone.fa-train::after {
  content: '\f238\f238'; }

.fad.fa-up-from-dotted-line::after, .fa-duotone.fa-up-from-dotted-line::after {
  content: '\e456\e456'; }

.fad.fa-eye-low-vision::after, .fa-duotone.fa-eye-low-vision::after {
  content: '\f2a8\f2a8'; }

.fad.fa-low-vision::after, .fa-duotone.fa-low-vision::after {
  content: '\f2a8\f2a8'; }

.fad.fa-traffic-light-go::after, .fa-duotone.fa-traffic-light-go::after {
  content: '\f638\f638'; }

.fad.fa-face-exhaling::after, .fa-duotone.fa-face-exhaling::after {
  content: '\e480\e480'; }

.fad.fa-sensor-fire::after, .fa-duotone.fa-sensor-fire::after {
  content: '\e02a\e02a'; }

.fad.fa-user-unlock::after, .fa-duotone.fa-user-unlock::after {
  content: '\e058\e058'; }

.fad.fa-hexagon-divide::after, .fa-duotone.fa-hexagon-divide::after {
  content: '\e1ad\e1ad'; }

.fad.fa-00::after, .fa-duotone.fa-00::after {
  content: '\e467\e467'; }

.fad.fa-crow::after, .fa-duotone.fa-crow::after {
  content: '\f520\f520'; }

.fad.fa-cassette-betamax::after, .fa-duotone.fa-cassette-betamax::after {
  content: '\f8a4\f8a4'; }

.fad.fa-betamax::after, .fa-duotone.fa-betamax::after {
  content: '\f8a4\f8a4'; }

.fad.fa-sailboat::after, .fa-duotone.fa-sailboat::after {
  content: '\e445\e445'; }

.fad.fa-window-restore::after, .fa-duotone.fa-window-restore::after {
  content: '\f2d2\f2d2'; }

.fad.fa-nfc-magnifying-glass::after, .fa-duotone.fa-nfc-magnifying-glass::after {
  content: '\e1f9\e1f9'; }

.fad.fa-file-binary::after, .fa-duotone.fa-file-binary::after {
  content: '\e175\e175'; }

.fad.fa-circle-v::after, .fa-duotone.fa-circle-v::after {
  content: '\e12a\e12a'; }

.fad.fa-square-plus::after, .fa-duotone.fa-square-plus::after {
  content: '\f0fe\f0fe'; }

.fad.fa-plus-square::after, .fa-duotone.fa-plus-square::after {
  content: '\f0fe\f0fe'; }

.fad.fa-bowl-scoops::after, .fa-duotone.fa-bowl-scoops::after {
  content: '\e3df\e3df'; }

.fad.fa-mistletoe::after, .fa-duotone.fa-mistletoe::after {
  content: '\f7b4\f7b4'; }

.fad.fa-custard::after, .fa-duotone.fa-custard::after {
  content: '\e403\e403'; }

.fad.fa-lacrosse-stick::after, .fa-duotone.fa-lacrosse-stick::after {
  content: '\e3b5\e3b5'; }

.fad.fa-hockey-mask::after, .fa-duotone.fa-hockey-mask::after {
  content: '\f6ee\f6ee'; }

.fad.fa-sunrise::after, .fa-duotone.fa-sunrise::after {
  content: '\f766\f766'; }

.fad.fa-subtitles::after, .fa-duotone.fa-subtitles::after {
  content: '\e60f\e60f'; }

.fad.fa-panel-ews::after, .fa-duotone.fa-panel-ews::after {
  content: '\e42e\e42e'; }

.fad.fa-torii-gate::after, .fa-duotone.fa-torii-gate::after {
  content: '\f6a1\f6a1'; }

.fad.fa-cloud-exclamation::after, .fa-duotone.fa-cloud-exclamation::after {
  content: '\e491\e491'; }

.fad.fa-message-lines::after, .fa-duotone.fa-message-lines::after {
  content: '\f4a6\f4a6'; }

.fad.fa-comment-alt-lines::after, .fa-duotone.fa-comment-alt-lines::after {
  content: '\f4a6\f4a6'; }

.fad.fa-frog::after, .fa-duotone.fa-frog::after {
  content: '\f52e\f52e'; }

.fad.fa-bucket::after, .fa-duotone.fa-bucket::after {
  content: '\e4cf\e4cf'; }

.fad.fa-floppy-disk-pen::after, .fa-duotone.fa-floppy-disk-pen::after {
  content: '\e182\e182'; }

.fad.fa-image::after, .fa-duotone.fa-image::after {
  content: '\f03e\f03e'; }

.fad.fa-window-frame::after, .fa-duotone.fa-window-frame::after {
  content: '\e04f\e04f'; }

.fad.fa-microphone::after, .fa-duotone.fa-microphone::after {
  content: '\f130\f130'; }

.fad.fa-cow::after, .fa-duotone.fa-cow::after {
  content: '\f6c8\f6c8'; }

.fad.fa-file-zip::after, .fa-duotone.fa-file-zip::after {
  content: '\e5ee\e5ee'; }

.fad.fa-square-ring::after, .fa-duotone.fa-square-ring::after {
  content: '\e44f\e44f'; }

.fad.fa-down-from-line::after, .fa-duotone.fa-down-from-line::after {
  content: '\f349\f349'; }

.fad.fa-arrow-alt-from-top::after, .fa-duotone.fa-arrow-alt-from-top::after {
  content: '\f349\f349'; }

.fad.fa-caret-up::after, .fa-duotone.fa-caret-up::after {
  content: '\f0d8\f0d8'; }

.fad.fa-shield-xmark::after, .fa-duotone.fa-shield-xmark::after {
  content: '\e24c\e24c'; }

.fad.fa-shield-times::after, .fa-duotone.fa-shield-times::after {
  content: '\e24c\e24c'; }

.fad.fa-screwdriver::after, .fa-duotone.fa-screwdriver::after {
  content: '\f54a\f54a'; }

.fad.fa-circle-sort-down::after, .fa-duotone.fa-circle-sort-down::after {
  content: '\e031\e031'; }

.fad.fa-sort-circle-down::after, .fa-duotone.fa-sort-circle-down::after {
  content: '\e031\e031'; }

.fad.fa-folder-closed::after, .fa-duotone.fa-folder-closed::after {
  content: '\e185\e185'; }

.fad.fa-house-tsunami::after, .fa-duotone.fa-house-tsunami::after {
  content: '\e515\e515'; }

.fad.fa-square-nfi::after, .fa-duotone.fa-square-nfi::after {
  content: '\e576\e576'; }

.fad.fa-forklift::after, .fa-duotone.fa-forklift::after {
  content: '\f47a\f47a'; }

.fad.fa-arrow-up-from-ground-water::after, .fa-duotone.fa-arrow-up-from-ground-water::after {
  content: '\e4b5\e4b5'; }

.fad.fa-bracket-square-right::after, .fa-duotone.fa-bracket-square-right::after {
  content: '\5d\5d'; }

.fad.fa-martini-glass::after, .fa-duotone.fa-martini-glass::after {
  content: '\f57b\f57b'; }

.fad.fa-glass-martini-alt::after, .fa-duotone.fa-glass-martini-alt::after {
  content: '\f57b\f57b'; }

.fad.fa-rotate-left::after, .fa-duotone.fa-rotate-left::after {
  content: '\f2ea\f2ea'; }

.fad.fa-rotate-back::after, .fa-duotone.fa-rotate-back::after {
  content: '\f2ea\f2ea'; }

.fad.fa-rotate-backward::after, .fa-duotone.fa-rotate-backward::after {
  content: '\f2ea\f2ea'; }

.fad.fa-undo-alt::after, .fa-duotone.fa-undo-alt::after {
  content: '\f2ea\f2ea'; }

.fad.fa-table-columns::after, .fa-duotone.fa-table-columns::after {
  content: '\f0db\f0db'; }

.fad.fa-columns::after, .fa-duotone.fa-columns::after {
  content: '\f0db\f0db'; }

.fad.fa-square-a::after, .fa-duotone.fa-square-a::after {
  content: '\e25f\e25f'; }

.fad.fa-tick::after, .fa-duotone.fa-tick::after {
  content: '\e32f\e32f'; }

.fad.fa-lemon::after, .fa-duotone.fa-lemon::after {
  content: '\f094\f094'; }

.fad.fa-head-side-mask::after, .fa-duotone.fa-head-side-mask::after {
  content: '\e063\e063'; }

.fad.fa-handshake::after, .fa-duotone.fa-handshake::after {
  content: '\f2b5\f2b5'; }

.fad.fa-gem::after, .fa-duotone.fa-gem::after {
  content: '\f3a5\f3a5'; }

.fad.fa-dolly::after, .fa-duotone.fa-dolly::after {
  content: '\f472\f472'; }

.fad.fa-dolly-box::after, .fa-duotone.fa-dolly-box::after {
  content: '\f472\f472'; }

.fad.fa-smoking::after, .fa-duotone.fa-smoking::after {
  content: '\f48d\f48d'; }

.fad.fa-minimize::after, .fa-duotone.fa-minimize::after {
  content: '\f78c\f78c'; }

.fad.fa-compress-arrows-alt::after, .fa-duotone.fa-compress-arrows-alt::after {
  content: '\f78c\f78c'; }

.fad.fa-refrigerator::after, .fa-duotone.fa-refrigerator::after {
  content: '\e026\e026'; }

.fad.fa-monument::after, .fa-duotone.fa-monument::after {
  content: '\f5a6\f5a6'; }

.fad.fa-octagon-xmark::after, .fa-duotone.fa-octagon-xmark::after {
  content: '\f2f0\f2f0'; }

.fad.fa-times-octagon::after, .fa-duotone.fa-times-octagon::after {
  content: '\f2f0\f2f0'; }

.fad.fa-xmark-octagon::after, .fa-duotone.fa-xmark-octagon::after {
  content: '\f2f0\f2f0'; }

.fad.fa-align-slash::after, .fa-duotone.fa-align-slash::after {
  content: '\f846\f846'; }

.fad.fa-snowplow::after, .fa-duotone.fa-snowplow::after {
  content: '\f7d2\f7d2'; }

.fad.fa-angles-right::after, .fa-duotone.fa-angles-right::after {
  content: '\f101\f101'; }

.fad.fa-angle-double-right::after, .fa-duotone.fa-angle-double-right::after {
  content: '\f101\f101'; }

.fad.fa-truck-ramp-couch::after, .fa-duotone.fa-truck-ramp-couch::after {
  content: '\f4dd\f4dd'; }

.fad.fa-truck-couch::after, .fa-duotone.fa-truck-couch::after {
  content: '\f4dd\f4dd'; }

.fad.fa-cannabis::after, .fa-duotone.fa-cannabis::after {
  content: '\f55f\f55f'; }

.fad.fa-circle-play::after, .fa-duotone.fa-circle-play::after {
  content: '\f144\f144'; }

.fad.fa-play-circle::after, .fa-duotone.fa-play-circle::after {
  content: '\f144\f144'; }

.fad.fa-arrow-up-right-and-arrow-down-left-from-center::after, .fa-duotone.fa-arrow-up-right-and-arrow-down-left-from-center::after {
  content: '\e0a0\e0a0'; }

.fad.fa-tablets::after, .fa-duotone.fa-tablets::after {
  content: '\f490\f490'; }

.fad.fa-360-degrees::after, .fa-duotone.fa-360-degrees::after {
  content: '\e2dc\e2dc'; }

.fad.fa-ethernet::after, .fa-duotone.fa-ethernet::after {
  content: '\f796\f796'; }

.fad.fa-euro-sign::after, .fa-duotone.fa-euro-sign::after {
  content: '\f153\f153'; }

.fad.fa-eur::after, .fa-duotone.fa-eur::after {
  content: '\f153\f153'; }

.fad.fa-euro::after, .fa-duotone.fa-euro::after {
  content: '\f153\f153'; }

.fad.fa-chair::after, .fa-duotone.fa-chair::after {
  content: '\f6c0\f6c0'; }

.fad.fa-circle-check::after, .fa-duotone.fa-circle-check::after {
  content: '\f058\f058'; }

.fad.fa-check-circle::after, .fa-duotone.fa-check-circle::after {
  content: '\f058\f058'; }

.fad.fa-square-dashed-circle-plus::after, .fa-duotone.fa-square-dashed-circle-plus::after {
  content: '\e5c2\e5c2'; }

.fad.fa-money-simple-from-bracket::after, .fa-duotone.fa-money-simple-from-bracket::after {
  content: '\e313\e313'; }

.fad.fa-bat::after, .fa-duotone.fa-bat::after {
  content: '\f6b5\f6b5'; }

.fad.fa-circle-stop::after, .fa-duotone.fa-circle-stop::after {
  content: '\f28d\f28d'; }

.fad.fa-stop-circle::after, .fa-duotone.fa-stop-circle::after {
  content: '\f28d\f28d'; }

.fad.fa-head-side-headphones::after, .fa-duotone.fa-head-side-headphones::after {
  content: '\f8c2\f8c2'; }

.fad.fa-phone-rotary::after, .fa-duotone.fa-phone-rotary::after {
  content: '\f8d3\f8d3'; }

.fad.fa-compass-drafting::after, .fa-duotone.fa-compass-drafting::after {
  content: '\f568\f568'; }

.fad.fa-drafting-compass::after, .fa-duotone.fa-drafting-compass::after {
  content: '\f568\f568'; }

.fad.fa-plate-wheat::after, .fa-duotone.fa-plate-wheat::after {
  content: '\e55a\e55a'; }

.fad.fa-calendar-circle-minus::after, .fa-duotone.fa-calendar-circle-minus::after {
  content: '\e46f\e46f'; }

.fad.fa-chopsticks::after, .fa-duotone.fa-chopsticks::after {
  content: '\e3f7\e3f7'; }

.fad.fa-car-wrench::after, .fa-duotone.fa-car-wrench::after {
  content: '\f5e3\f5e3'; }

.fad.fa-car-mechanic::after, .fa-duotone.fa-car-mechanic::after {
  content: '\f5e3\f5e3'; }

.fad.fa-icicles::after, .fa-duotone.fa-icicles::after {
  content: '\f7ad\f7ad'; }

.fad.fa-person-shelter::after, .fa-duotone.fa-person-shelter::after {
  content: '\e54f\e54f'; }

.fad.fa-neuter::after, .fa-duotone.fa-neuter::after {
  content: '\f22c\f22c'; }

.fad.fa-id-badge::after, .fa-duotone.fa-id-badge::after {
  content: '\f2c1\f2c1'; }

.fad.fa-kazoo::after, .fa-duotone.fa-kazoo::after {
  content: '\f8c7\f8c7'; }

.fad.fa-marker::after, .fa-duotone.fa-marker::after {
  content: '\f5a1\f5a1'; }

.fad.fa-bin-bottles::after, .fa-duotone.fa-bin-bottles::after {
  content: '\e5f5\e5f5'; }

.fad.fa-face-laugh-beam::after, .fa-duotone.fa-face-laugh-beam::after {
  content: '\f59a\f59a'; }

.fad.fa-laugh-beam::after, .fa-duotone.fa-laugh-beam::after {
  content: '\f59a\f59a'; }

.fad.fa-square-arrow-down-left::after, .fa-duotone.fa-square-arrow-down-left::after {
  content: '\e261\e261'; }

.fad.fa-battery-bolt::after, .fa-duotone.fa-battery-bolt::after {
  content: '\f376\f376'; }

.fad.fa-tree-large::after, .fa-duotone.fa-tree-large::after {
  content: '\f7dd\f7dd'; }

.fad.fa-helicopter-symbol::after, .fa-duotone.fa-helicopter-symbol::after {
  content: '\e502\e502'; }

.fad.fa-aperture::after, .fa-duotone.fa-aperture::after {
  content: '\e2df\e2df'; }

.fad.fa-universal-access::after, .fa-duotone.fa-universal-access::after {
  content: '\f29a\f29a'; }

.fad.fa-gear-complex::after, .fa-duotone.fa-gear-complex::after {
  content: '\e5e9\e5e9'; }

.fad.fa-file-magnifying-glass::after, .fa-duotone.fa-file-magnifying-glass::after {
  content: '\f865\f865'; }

.fad.fa-file-search::after, .fa-duotone.fa-file-search::after {
  content: '\f865\f865'; }

.fad.fa-up-right::after, .fa-duotone.fa-up-right::after {
  content: '\e2be\e2be'; }

.fad.fa-circle-chevron-up::after, .fa-duotone.fa-circle-chevron-up::after {
  content: '\f139\f139'; }

.fad.fa-chevron-circle-up::after, .fa-duotone.fa-chevron-circle-up::after {
  content: '\f139\f139'; }

.fad.fa-user-police::after, .fa-duotone.fa-user-police::after {
  content: '\e333\e333'; }

.fad.fa-lari-sign::after, .fa-duotone.fa-lari-sign::after {
  content: '\e1c8\e1c8'; }

.fad.fa-volcano::after, .fa-duotone.fa-volcano::after {
  content: '\f770\f770'; }

.fad.fa-teddy-bear::after, .fa-duotone.fa-teddy-bear::after {
  content: '\e3cf\e3cf'; }

.fad.fa-stocking::after, .fa-duotone.fa-stocking::after {
  content: '\f7d5\f7d5'; }

.fad.fa-person-walking-dashed-line-arrow-right::after, .fa-duotone.fa-person-walking-dashed-line-arrow-right::after {
  content: '\e553\e553'; }

.fad.fa-image-slash::after, .fa-duotone.fa-image-slash::after {
  content: '\e1b7\e1b7'; }

.fad.fa-mask-snorkel::after, .fa-duotone.fa-mask-snorkel::after {
  content: '\e3b7\e3b7'; }

.fad.fa-smoke::after, .fa-duotone.fa-smoke::after {
  content: '\f760\f760'; }

.fad.fa-sterling-sign::after, .fa-duotone.fa-sterling-sign::after {
  content: '\f154\f154'; }

.fad.fa-gbp::after, .fa-duotone.fa-gbp::after {
  content: '\f154\f154'; }

.fad.fa-pound-sign::after, .fa-duotone.fa-pound-sign::after {
  content: '\f154\f154'; }

.fad.fa-battery-exclamation::after, .fa-duotone.fa-battery-exclamation::after {
  content: '\e0b0\e0b0'; }

.fad.fa-viruses::after, .fa-duotone.fa-viruses::after {
  content: '\e076\e076'; }

.fad.fa-square-person-confined::after, .fa-duotone.fa-square-person-confined::after {
  content: '\e577\e577'; }

.fad.fa-user-tie::after, .fa-duotone.fa-user-tie::after {
  content: '\f508\f508'; }

.fad.fa-arrow-down-long::after, .fa-duotone.fa-arrow-down-long::after {
  content: '\f175\f175'; }

.fad.fa-long-arrow-down::after, .fa-duotone.fa-long-arrow-down::after {
  content: '\f175\f175'; }

.fad.fa-tent-arrow-down-to-line::after, .fa-duotone.fa-tent-arrow-down-to-line::after {
  content: '\e57e\e57e'; }

.fad.fa-certificate::after, .fa-duotone.fa-certificate::after {
  content: '\f0a3\f0a3'; }

.fad.fa-crystal-ball::after, .fa-duotone.fa-crystal-ball::after {
  content: '\e362\e362'; }

.fad.fa-reply-all::after, .fa-duotone.fa-reply-all::after {
  content: '\f122\f122'; }

.fad.fa-mail-reply-all::after, .fa-duotone.fa-mail-reply-all::after {
  content: '\f122\f122'; }

.fad.fa-suitcase::after, .fa-duotone.fa-suitcase::after {
  content: '\f0f2\f0f2'; }

.fad.fa-person-skating::after, .fa-duotone.fa-person-skating::after {
  content: '\f7c5\f7c5'; }

.fad.fa-skating::after, .fa-duotone.fa-skating::after {
  content: '\f7c5\f7c5'; }

.fad.fa-star-shooting::after, .fa-duotone.fa-star-shooting::after {
  content: '\e036\e036'; }

.fad.fa-binary-lock::after, .fa-duotone.fa-binary-lock::after {
  content: '\e33d\e33d'; }

.fad.fa-filter-circle-dollar::after, .fa-duotone.fa-filter-circle-dollar::after {
  content: '\f662\f662'; }

.fad.fa-funnel-dollar::after, .fa-duotone.fa-funnel-dollar::after {
  content: '\f662\f662'; }

.fad.fa-camera-retro::after, .fa-duotone.fa-camera-retro::after {
  content: '\f083\f083'; }

.fad.fa-circle-arrow-down::after, .fa-duotone.fa-circle-arrow-down::after {
  content: '\f0ab\f0ab'; }

.fad.fa-arrow-circle-down::after, .fa-duotone.fa-arrow-circle-down::after {
  content: '\f0ab\f0ab'; }

.fad.fa-comment-pen::after, .fa-duotone.fa-comment-pen::after {
  content: '\f4ae\f4ae'; }

.fad.fa-comment-edit::after, .fa-duotone.fa-comment-edit::after {
  content: '\f4ae\f4ae'; }

.fad.fa-file-import::after, .fa-duotone.fa-file-import::after {
  content: '\f56f\f56f'; }

.fad.fa-arrow-right-to-file::after, .fa-duotone.fa-arrow-right-to-file::after {
  content: '\f56f\f56f'; }

.fad.fa-banjo::after, .fa-duotone.fa-banjo::after {
  content: '\f8a3\f8a3'; }

.fad.fa-square-arrow-up-right::after, .fa-duotone.fa-square-arrow-up-right::after {
  content: '\f14c\f14c'; }

.fad.fa-external-link-square::after, .fa-duotone.fa-external-link-square::after {
  content: '\f14c\f14c'; }

.fad.fa-light-emergency-on::after, .fa-duotone.fa-light-emergency-on::after {
  content: '\e420\e420'; }

.fad.fa-kerning::after, .fa-duotone.fa-kerning::after {
  content: '\f86f\f86f'; }

.fad.fa-box-open::after, .fa-duotone.fa-box-open::after {
  content: '\f49e\f49e'; }

.fad.fa-square-f::after, .fa-duotone.fa-square-f::after {
  content: '\e270\e270'; }

.fad.fa-scroll::after, .fa-duotone.fa-scroll::after {
  content: '\f70e\f70e'; }

.fad.fa-spa::after, .fa-duotone.fa-spa::after {
  content: '\f5bb\f5bb'; }

.fad.fa-arrow-left-from-line::after, .fa-duotone.fa-arrow-left-from-line::after {
  content: '\f344\f344'; }

.fad.fa-arrow-from-right::after, .fa-duotone.fa-arrow-from-right::after {
  content: '\f344\f344'; }

.fad.fa-strawberry::after, .fa-duotone.fa-strawberry::after {
  content: '\e32b\e32b'; }

.fad.fa-location-pin-lock::after, .fa-duotone.fa-location-pin-lock::after {
  content: '\e51f\e51f'; }

.fad.fa-pause::after, .fa-duotone.fa-pause::after {
  content: '\f04c\f04c'; }

.fad.fa-clock-eight-thirty::after, .fa-duotone.fa-clock-eight-thirty::after {
  content: '\e346\e346'; }

.fad.fa-plane-engines::after, .fa-duotone.fa-plane-engines::after {
  content: '\f3de\f3de'; }

.fad.fa-plane-alt::after, .fa-duotone.fa-plane-alt::after {
  content: '\f3de\f3de'; }

.fad.fa-hill-avalanche::after, .fa-duotone.fa-hill-avalanche::after {
  content: '\e507\e507'; }

.fad.fa-temperature-empty::after, .fa-duotone.fa-temperature-empty::after {
  content: '\f2cb\f2cb'; }

.fad.fa-temperature-0::after, .fa-duotone.fa-temperature-0::after {
  content: '\f2cb\f2cb'; }

.fad.fa-thermometer-0::after, .fa-duotone.fa-thermometer-0::after {
  content: '\f2cb\f2cb'; }

.fad.fa-thermometer-empty::after, .fa-duotone.fa-thermometer-empty::after {
  content: '\f2cb\f2cb'; }

.fad.fa-bomb::after, .fa-duotone.fa-bomb::after {
  content: '\f1e2\f1e2'; }

.fad.fa-gauge-low::after, .fa-duotone.fa-gauge-low::after {
  content: '\f627\f627'; }

.fad.fa-tachometer-alt-slow::after, .fa-duotone.fa-tachometer-alt-slow::after {
  content: '\f627\f627'; }

.fad.fa-registered::after, .fa-duotone.fa-registered::after {
  content: '\f25d\f25d'; }

.fad.fa-trash-can-plus::after, .fa-duotone.fa-trash-can-plus::after {
  content: '\e2ac\e2ac'; }

.fad.fa-address-card::after, .fa-duotone.fa-address-card::after {
  content: '\f2bb\f2bb'; }

.fad.fa-contact-card::after, .fa-duotone.fa-contact-card::after {
  content: '\f2bb\f2bb'; }

.fad.fa-vcard::after, .fa-duotone.fa-vcard::after {
  content: '\f2bb\f2bb'; }

.fad.fa-scale-unbalanced-flip::after, .fa-duotone.fa-scale-unbalanced-flip::after {
  content: '\f516\f516'; }

.fad.fa-balance-scale-right::after, .fa-duotone.fa-balance-scale-right::after {
  content: '\f516\f516'; }

.fad.fa-globe-snow::after, .fa-duotone.fa-globe-snow::after {
  content: '\f7a3\f7a3'; }

.fad.fa-subscript::after, .fa-duotone.fa-subscript::after {
  content: '\f12c\f12c'; }

.fad.fa-diamond-turn-right::after, .fa-duotone.fa-diamond-turn-right::after {
  content: '\f5eb\f5eb'; }

.fad.fa-directions::after, .fa-duotone.fa-directions::after {
  content: '\f5eb\f5eb'; }

.fad.fa-integral::after, .fa-duotone.fa-integral::after {
  content: '\f667\f667'; }

.fad.fa-burst::after, .fa-duotone.fa-burst::after {
  content: '\e4dc\e4dc'; }

.fad.fa-house-laptop::after, .fa-duotone.fa-house-laptop::after {
  content: '\e066\e066'; }

.fad.fa-laptop-house::after, .fa-duotone.fa-laptop-house::after {
  content: '\e066\e066'; }

.fad.fa-face-tired::after, .fa-duotone.fa-face-tired::after {
  content: '\f5c8\f5c8'; }

.fad.fa-tired::after, .fa-duotone.fa-tired::after {
  content: '\f5c8\f5c8'; }

.fad.fa-money-bills::after, .fa-duotone.fa-money-bills::after {
  content: '\e1f3\e1f3'; }

.fad.fa-blinds-raised::after, .fa-duotone.fa-blinds-raised::after {
  content: '\f8fd\f8fd'; }

.fad.fa-smog::after, .fa-duotone.fa-smog::after {
  content: '\f75f\f75f'; }

.fad.fa-ufo-beam::after, .fa-duotone.fa-ufo-beam::after {
  content: '\e048\e048'; }

.fad.fa-circle-caret-up::after, .fa-duotone.fa-circle-caret-up::after {
  content: '\f331\f331'; }

.fad.fa-caret-circle-up::after, .fa-duotone.fa-caret-circle-up::after {
  content: '\f331\f331'; }

.fad.fa-user-vneck-hair-long::after, .fa-duotone.fa-user-vneck-hair-long::after {
  content: '\e463\e463'; }

.fad.fa-square-a-lock::after, .fa-duotone.fa-square-a-lock::after {
  content: '\e44d\e44d'; }

.fad.fa-crutch::after, .fa-duotone.fa-crutch::after {
  content: '\f7f7\f7f7'; }

.fad.fa-gas-pump-slash::after, .fa-duotone.fa-gas-pump-slash::after {
  content: '\f5f4\f5f4'; }

.fad.fa-cloud-arrow-up::after, .fa-duotone.fa-cloud-arrow-up::after {
  content: '\f0ee\f0ee'; }

.fad.fa-cloud-upload::after, .fa-duotone.fa-cloud-upload::after {
  content: '\f0ee\f0ee'; }

.fad.fa-cloud-upload-alt::after, .fa-duotone.fa-cloud-upload-alt::after {
  content: '\f0ee\f0ee'; }

.fad.fa-palette::after, .fa-duotone.fa-palette::after {
  content: '\f53f\f53f'; }

.fad.fa-transporter-4::after, .fa-duotone.fa-transporter-4::after {
  content: '\e2a5\e2a5'; }

.fad.fa-chart-mixed-up-circle-currency::after, .fa-duotone.fa-chart-mixed-up-circle-currency::after {
  content: '\e5d8\e5d8'; }

.fad.fa-objects-align-right::after, .fa-duotone.fa-objects-align-right::after {
  content: '\e3bf\e3bf'; }

.fad.fa-arrows-turn-right::after, .fa-duotone.fa-arrows-turn-right::after {
  content: '\e4c0\e4c0'; }

.fad.fa-vest::after, .fa-duotone.fa-vest::after {
  content: '\e085\e085'; }

.fad.fa-pig::after, .fa-duotone.fa-pig::after {
  content: '\f706\f706'; }

.fad.fa-inbox-full::after, .fa-duotone.fa-inbox-full::after {
  content: '\e1ba\e1ba'; }

.fad.fa-circle-envelope::after, .fa-duotone.fa-circle-envelope::after {
  content: '\e10c\e10c'; }

.fad.fa-envelope-circle::after, .fa-duotone.fa-envelope-circle::after {
  content: '\e10c\e10c'; }

.fad.fa-triangle-person-digging::after, .fa-duotone.fa-triangle-person-digging::after {
  content: '\f85d\f85d'; }

.fad.fa-construction::after, .fa-duotone.fa-construction::after {
  content: '\f85d\f85d'; }

.fad.fa-ferry::after, .fa-duotone.fa-ferry::after {
  content: '\e4ea\e4ea'; }

.fad.fa-bullseye-arrow::after, .fa-duotone.fa-bullseye-arrow::after {
  content: '\f648\f648'; }

.fad.fa-arrows-down-to-people::after, .fa-duotone.fa-arrows-down-to-people::after {
  content: '\e4b9\e4b9'; }

.fad.fa-seedling::after, .fa-duotone.fa-seedling::after {
  content: '\f4d8\f4d8'; }

.fad.fa-sprout::after, .fa-duotone.fa-sprout::after {
  content: '\f4d8\f4d8'; }

.fad.fa-clock-seven::after, .fa-duotone.fa-clock-seven::after {
  content: '\e350\e350'; }

.fad.fa-left-right::after, .fa-duotone.fa-left-right::after {
  content: '\f337\f337'; }

.fad.fa-arrows-alt-h::after, .fa-duotone.fa-arrows-alt-h::after {
  content: '\f337\f337'; }

.fad.fa-boxes-packing::after, .fa-duotone.fa-boxes-packing::after {
  content: '\e4c7\e4c7'; }

.fad.fa-circle-arrow-left::after, .fa-duotone.fa-circle-arrow-left::after {
  content: '\f0a8\f0a8'; }

.fad.fa-arrow-circle-left::after, .fa-duotone.fa-arrow-circle-left::after {
  content: '\f0a8\f0a8'; }

.fad.fa-flashlight::after, .fa-duotone.fa-flashlight::after {
  content: '\f8b8\f8b8'; }

.fad.fa-group-arrows-rotate::after, .fa-duotone.fa-group-arrows-rotate::after {
  content: '\e4f6\e4f6'; }

.fad.fa-bowl-food::after, .fa-duotone.fa-bowl-food::after {
  content: '\e4c6\e4c6'; }

.fad.fa-square-9::after, .fa-duotone.fa-square-9::after {
  content: '\e25e\e25e'; }

.fad.fa-candy-cane::after, .fa-duotone.fa-candy-cane::after {
  content: '\f786\f786'; }

.fad.fa-arrow-down-wide-short::after, .fa-duotone.fa-arrow-down-wide-short::after {
  content: '\f160\f160'; }

.fad.fa-sort-amount-asc::after, .fa-duotone.fa-sort-amount-asc::after {
  content: '\f160\f160'; }

.fad.fa-sort-amount-down::after, .fa-duotone.fa-sort-amount-down::after {
  content: '\f160\f160'; }

.fad.fa-square-dollar::after, .fa-duotone.fa-square-dollar::after {
  content: '\f2e9\f2e9'; }

.fad.fa-dollar-square::after, .fa-duotone.fa-dollar-square::after {
  content: '\f2e9\f2e9'; }

.fad.fa-usd-square::after, .fa-duotone.fa-usd-square::after {
  content: '\f2e9\f2e9'; }

.fad.fa-phone-arrow-right::after, .fa-duotone.fa-phone-arrow-right::after {
  content: '\e5be\e5be'; }

.fad.fa-hand-holding-seedling::after, .fa-duotone.fa-hand-holding-seedling::after {
  content: '\f4bf\f4bf'; }

.fad.fa-message-check::after, .fa-duotone.fa-message-check::after {
  content: '\f4a2\f4a2'; }

.fad.fa-comment-alt-check::after, .fa-duotone.fa-comment-alt-check::after {
  content: '\f4a2\f4a2'; }

.fad.fa-cloud-bolt::after, .fa-duotone.fa-cloud-bolt::after {
  content: '\f76c\f76c'; }

.fad.fa-thunderstorm::after, .fa-duotone.fa-thunderstorm::after {
  content: '\f76c\f76c'; }

.fad.fa-chart-line-up-down::after, .fa-duotone.fa-chart-line-up-down::after {
  content: '\e5d7\e5d7'; }

.fad.fa-text-slash::after, .fa-duotone.fa-text-slash::after {
  content: '\f87d\f87d'; }

.fad.fa-remove-format::after, .fa-duotone.fa-remove-format::after {
  content: '\f87d\f87d'; }

.fad.fa-watch::after, .fa-duotone.fa-watch::after {
  content: '\f2e1\f2e1'; }

.fad.fa-circle-down-left::after, .fa-duotone.fa-circle-down-left::after {
  content: '\e107\e107'; }

.fad.fa-text::after, .fa-duotone.fa-text::after {
  content: '\f893\f893'; }

.fad.fa-projector::after, .fa-duotone.fa-projector::after {
  content: '\f8d6\f8d6'; }

.fad.fa-face-smile-wink::after, .fa-duotone.fa-face-smile-wink::after {
  content: '\f4da\f4da'; }

.fad.fa-smile-wink::after, .fa-duotone.fa-smile-wink::after {
  content: '\f4da\f4da'; }

.fad.fa-tombstone-blank::after, .fa-duotone.fa-tombstone-blank::after {
  content: '\f721\f721'; }

.fad.fa-tombstone-alt::after, .fa-duotone.fa-tombstone-alt::after {
  content: '\f721\f721'; }

.fad.fa-chess-king-piece::after, .fa-duotone.fa-chess-king-piece::after {
  content: '\f440\f440'; }

.fad.fa-chess-king-alt::after, .fa-duotone.fa-chess-king-alt::after {
  content: '\f440\f440'; }

.fad.fa-circle-6::after, .fa-duotone.fa-circle-6::after {
  content: '\e0f3\e0f3'; }

.fad.fa-left::after, .fa-duotone.fa-left::after {
  content: '\f355\f355'; }

.fad.fa-arrow-alt-left::after, .fa-duotone.fa-arrow-alt-left::after {
  content: '\f355\f355'; }

.fad.fa-file-word::after, .fa-duotone.fa-file-word::after {
  content: '\f1c2\f1c2'; }

.fad.fa-file-powerpoint::after, .fa-duotone.fa-file-powerpoint::after {
  content: '\f1c4\f1c4'; }

.fad.fa-square-down::after, .fa-duotone.fa-square-down::after {
  content: '\f350\f350'; }

.fad.fa-arrow-alt-square-down::after, .fa-duotone.fa-arrow-alt-square-down::after {
  content: '\f350\f350'; }

.fad.fa-objects-align-center-vertical::after, .fa-duotone.fa-objects-align-center-vertical::after {
  content: '\e3bd\e3bd'; }

.fad.fa-arrows-left-right::after, .fa-duotone.fa-arrows-left-right::after {
  content: '\f07e\f07e'; }

.fad.fa-arrows-h::after, .fa-duotone.fa-arrows-h::after {
  content: '\f07e\f07e'; }

.fad.fa-house-lock::after, .fa-duotone.fa-house-lock::after {
  content: '\e510\e510'; }

.fad.fa-cloud-arrow-down::after, .fa-duotone.fa-cloud-arrow-down::after {
  content: '\f0ed\f0ed'; }

.fad.fa-cloud-download::after, .fa-duotone.fa-cloud-download::after {
  content: '\f0ed\f0ed'; }

.fad.fa-cloud-download-alt::after, .fa-duotone.fa-cloud-download-alt::after {
  content: '\f0ed\f0ed'; }

.fad.fa-wreath::after, .fa-duotone.fa-wreath::after {
  content: '\f7e2\f7e2'; }

.fad.fa-children::after, .fa-duotone.fa-children::after {
  content: '\e4e1\e4e1'; }

.fad.fa-meter-droplet::after, .fa-duotone.fa-meter-droplet::after {
  content: '\e1ea\e1ea'; }

.fad.fa-chalkboard::after, .fa-duotone.fa-chalkboard::after {
  content: '\f51b\f51b'; }

.fad.fa-blackboard::after, .fa-duotone.fa-blackboard::after {
  content: '\f51b\f51b'; }

.fad.fa-user-large-slash::after, .fa-duotone.fa-user-large-slash::after {
  content: '\f4fa\f4fa'; }

.fad.fa-user-alt-slash::after, .fa-duotone.fa-user-alt-slash::after {
  content: '\f4fa\f4fa'; }

.fad.fa-signal-strong::after, .fa-duotone.fa-signal-strong::after {
  content: '\f68f\f68f'; }

.fad.fa-signal-4::after, .fa-duotone.fa-signal-4::after {
  content: '\f68f\f68f'; }

.fad.fa-lollipop::after, .fa-duotone.fa-lollipop::after {
  content: '\e424\e424'; }

.fad.fa-lollypop::after, .fa-duotone.fa-lollypop::after {
  content: '\e424\e424'; }

.fad.fa-list-tree::after, .fa-duotone.fa-list-tree::after {
  content: '\e1d2\e1d2'; }

.fad.fa-envelope-open::after, .fa-duotone.fa-envelope-open::after {
  content: '\f2b6\f2b6'; }

.fad.fa-draw-circle::after, .fa-duotone.fa-draw-circle::after {
  content: '\f5ed\f5ed'; }

.fad.fa-cat-space::after, .fa-duotone.fa-cat-space::after {
  content: '\e001\e001'; }

.fad.fa-handshake-simple-slash::after, .fa-duotone.fa-handshake-simple-slash::after {
  content: '\e05f\e05f'; }

.fad.fa-handshake-alt-slash::after, .fa-duotone.fa-handshake-alt-slash::after {
  content: '\e05f\e05f'; }

.fad.fa-rabbit-running::after, .fa-duotone.fa-rabbit-running::after {
  content: '\f709\f709'; }

.fad.fa-rabbit-fast::after, .fa-duotone.fa-rabbit-fast::after {
  content: '\f709\f709'; }

.fad.fa-memo-pad::after, .fa-duotone.fa-memo-pad::after {
  content: '\e1da\e1da'; }

.fad.fa-mattress-pillow::after, .fa-duotone.fa-mattress-pillow::after {
  content: '\e525\e525'; }

.fad.fa-alarm-plus::after, .fa-duotone.fa-alarm-plus::after {
  content: '\f844\f844'; }

.fad.fa-alicorn::after, .fa-duotone.fa-alicorn::after {
  content: '\f6b0\f6b0'; }

.fad.fa-comment-question::after, .fa-duotone.fa-comment-question::after {
  content: '\e14b\e14b'; }

.fad.fa-gingerbread-man::after, .fa-duotone.fa-gingerbread-man::after {
  content: '\f79d\f79d'; }

.fad.fa-guarani-sign::after, .fa-duotone.fa-guarani-sign::after {
  content: '\e19a\e19a'; }

.fad.fa-burger-fries::after, .fa-duotone.fa-burger-fries::after {
  content: '\e0cd\e0cd'; }

.fad.fa-mug-tea::after, .fa-duotone.fa-mug-tea::after {
  content: '\f875\f875'; }

.fad.fa-border-top::after, .fa-duotone.fa-border-top::after {
  content: '\f855\f855'; }

.fad.fa-arrows-rotate::after, .fa-duotone.fa-arrows-rotate::after {
  content: '\f021\f021'; }

.fad.fa-refresh::after, .fa-duotone.fa-refresh::after {
  content: '\f021\f021'; }

.fad.fa-sync::after, .fa-duotone.fa-sync::after {
  content: '\f021\f021'; }

.fad.fa-circle-book-open::after, .fa-duotone.fa-circle-book-open::after {
  content: '\e0ff\e0ff'; }

.fad.fa-book-circle::after, .fa-duotone.fa-book-circle::after {
  content: '\e0ff\e0ff'; }

.fad.fa-arrows-to-dotted-line::after, .fa-duotone.fa-arrows-to-dotted-line::after {
  content: '\e0a6\e0a6'; }

.fad.fa-fire-extinguisher::after, .fa-duotone.fa-fire-extinguisher::after {
  content: '\f134\f134'; }

.fad.fa-garage-open::after, .fa-duotone.fa-garage-open::after {
  content: '\e00b\e00b'; }

.fad.fa-shelves-empty::after, .fa-duotone.fa-shelves-empty::after {
  content: '\e246\e246'; }

.fad.fa-cruzeiro-sign::after, .fa-duotone.fa-cruzeiro-sign::after {
  content: '\e152\e152'; }

.fad.fa-watch-apple::after, .fa-duotone.fa-watch-apple::after {
  content: '\e2cb\e2cb'; }

.fad.fa-watch-calculator::after, .fa-duotone.fa-watch-calculator::after {
  content: '\f8f0\f8f0'; }

.fad.fa-list-dropdown::after, .fa-duotone.fa-list-dropdown::after {
  content: '\e1cf\e1cf'; }

.fad.fa-cabinet-filing::after, .fa-duotone.fa-cabinet-filing::after {
  content: '\f64b\f64b'; }

.fad.fa-burger-soda::after, .fa-duotone.fa-burger-soda::after {
  content: '\f858\f858'; }

.fad.fa-square-arrow-up::after, .fa-duotone.fa-square-arrow-up::after {
  content: '\f33c\f33c'; }

.fad.fa-arrow-square-up::after, .fa-duotone.fa-arrow-square-up::after {
  content: '\f33c\f33c'; }

.fad.fa-greater-than-equal::after, .fa-duotone.fa-greater-than-equal::after {
  content: '\f532\f532'; }

.fad.fa-pallet-box::after, .fa-duotone.fa-pallet-box::after {
  content: '\e208\e208'; }

.fad.fa-face-confounded::after, .fa-duotone.fa-face-confounded::after {
  content: '\e36c\e36c'; }

.fad.fa-shield-halved::after, .fa-duotone.fa-shield-halved::after {
  content: '\f3ed\f3ed'; }

.fad.fa-shield-alt::after, .fa-duotone.fa-shield-alt::after {
  content: '\f3ed\f3ed'; }

.fad.fa-truck-plow::after, .fa-duotone.fa-truck-plow::after {
  content: '\f7de\f7de'; }

.fad.fa-book-atlas::after, .fa-duotone.fa-book-atlas::after {
  content: '\f558\f558'; }

.fad.fa-atlas::after, .fa-duotone.fa-atlas::after {
  content: '\f558\f558'; }

.fad.fa-virus::after, .fa-duotone.fa-virus::after {
  content: '\e074\e074'; }

.fad.fa-grid-round-2::after, .fa-duotone.fa-grid-round-2::after {
  content: '\e5db\e5db'; }

.fad.fa-comment-middle-top::after, .fa-duotone.fa-comment-middle-top::after {
  content: '\e14a\e14a'; }

.fad.fa-envelope-circle-check::after, .fa-duotone.fa-envelope-circle-check::after {
  content: '\e4e8\e4e8'; }

.fad.fa-layer-group::after, .fa-duotone.fa-layer-group::after {
  content: '\f5fd\f5fd'; }

.fad.fa-restroom-simple::after, .fa-duotone.fa-restroom-simple::after {
  content: '\e23a\e23a'; }

.fad.fa-arrows-to-dot::after, .fa-duotone.fa-arrows-to-dot::after {
  content: '\e4be\e4be'; }

.fad.fa-border-outer::after, .fa-duotone.fa-border-outer::after {
  content: '\f851\f851'; }

.fad.fa-hashtag-lock::after, .fa-duotone.fa-hashtag-lock::after {
  content: '\e415\e415'; }

.fad.fa-clock-two-thirty::after, .fa-duotone.fa-clock-two-thirty::after {
  content: '\e35b\e35b'; }

.fad.fa-archway::after, .fa-duotone.fa-archway::after {
  content: '\f557\f557'; }

.fad.fa-heart-circle-check::after, .fa-duotone.fa-heart-circle-check::after {
  content: '\e4fd\e4fd'; }

.fad.fa-house-chimney-crack::after, .fa-duotone.fa-house-chimney-crack::after {
  content: '\f6f1\f6f1'; }

.fad.fa-house-damage::after, .fa-duotone.fa-house-damage::after {
  content: '\f6f1\f6f1'; }

.fad.fa-file-zipper::after, .fa-duotone.fa-file-zipper::after {
  content: '\f1c6\f1c6'; }

.fad.fa-file-archive::after, .fa-duotone.fa-file-archive::after {
  content: '\f1c6\f1c6'; }

.fad.fa-heart-half::after, .fa-duotone.fa-heart-half::after {
  content: '\e1ab\e1ab'; }

.fad.fa-comment-check::after, .fa-duotone.fa-comment-check::after {
  content: '\f4ac\f4ac'; }

.fad.fa-square::after, .fa-duotone.fa-square::after {
  content: '\f0c8\f0c8'; }

.fad.fa-memo::after, .fa-duotone.fa-memo::after {
  content: '\e1d8\e1d8'; }

.fad.fa-martini-glass-empty::after, .fa-duotone.fa-martini-glass-empty::after {
  content: '\f000\f000'; }

.fad.fa-glass-martini::after, .fa-duotone.fa-glass-martini::after {
  content: '\f000\f000'; }

.fad.fa-couch::after, .fa-duotone.fa-couch::after {
  content: '\f4b8\f4b8'; }

.fad.fa-cedi-sign::after, .fa-duotone.fa-cedi-sign::after {
  content: '\e0df\e0df'; }

.fad.fa-italic::after, .fa-duotone.fa-italic::after {
  content: '\f033\f033'; }

.fad.fa-glass-citrus::after, .fa-duotone.fa-glass-citrus::after {
  content: '\f869\f869'; }

.fad.fa-calendar-lines-pen::after, .fa-duotone.fa-calendar-lines-pen::after {
  content: '\e472\e472'; }

.fad.fa-church::after, .fa-duotone.fa-church::after {
  content: '\f51d\f51d'; }

.fad.fa-person-snowmobiling::after, .fa-duotone.fa-person-snowmobiling::after {
  content: '\f7d1\f7d1'; }

.fad.fa-snowmobile::after, .fa-duotone.fa-snowmobile::after {
  content: '\f7d1\f7d1'; }

.fad.fa-face-hushed::after, .fa-duotone.fa-face-hushed::after {
  content: '\e37b\e37b'; }

.fad.fa-comments-dollar::after, .fa-duotone.fa-comments-dollar::after {
  content: '\f653\f653'; }

.fad.fa-pickaxe::after, .fa-duotone.fa-pickaxe::after {
  content: '\e5bf\e5bf'; }

.fad.fa-link-simple-slash::after, .fa-duotone.fa-link-simple-slash::after {
  content: '\e1ce\e1ce'; }

.fad.fa-democrat::after, .fa-duotone.fa-democrat::after {
  content: '\f747\f747'; }

.fad.fa-face-confused::after, .fa-duotone.fa-face-confused::after {
  content: '\e36d\e36d'; }

.fad.fa-pinball::after, .fa-duotone.fa-pinball::after {
  content: '\e229\e229'; }

.fad.fa-z::after, .fa-duotone.fa-z::after {
  content: '\5a\5a'; }

.fad.fa-person-skiing::after, .fa-duotone.fa-person-skiing::after {
  content: '\f7c9\f7c9'; }

.fad.fa-skiing::after, .fa-duotone.fa-skiing::after {
  content: '\f7c9\f7c9'; }

.fad.fa-deer::after, .fa-duotone.fa-deer::after {
  content: '\f78e\f78e'; }

.fad.fa-input-pipe::after, .fa-duotone.fa-input-pipe::after {
  content: '\e1be\e1be'; }

.fad.fa-road-lock::after, .fa-duotone.fa-road-lock::after {
  content: '\e567\e567'; }

.fad.fa-a::after, .fa-duotone.fa-a::after {
  content: '\41\41'; }

.fad.fa-bookmark-slash::after, .fa-duotone.fa-bookmark-slash::after {
  content: '\e0c2\e0c2'; }

.fad.fa-temperature-arrow-down::after, .fa-duotone.fa-temperature-arrow-down::after {
  content: '\e03f\e03f'; }

.fad.fa-temperature-down::after, .fa-duotone.fa-temperature-down::after {
  content: '\e03f\e03f'; }

.fad.fa-mace::after, .fa-duotone.fa-mace::after {
  content: '\f6f8\f6f8'; }

.fad.fa-feather-pointed::after, .fa-duotone.fa-feather-pointed::after {
  content: '\f56b\f56b'; }

.fad.fa-feather-alt::after, .fa-duotone.fa-feather-alt::after {
  content: '\f56b\f56b'; }

.fad.fa-sausage::after, .fa-duotone.fa-sausage::after {
  content: '\f820\f820'; }

.fad.fa-trash-can-clock::after, .fa-duotone.fa-trash-can-clock::after {
  content: '\e2aa\e2aa'; }

.fad.fa-p::after, .fa-duotone.fa-p::after {
  content: '\50\50'; }

.fad.fa-broom-wide::after, .fa-duotone.fa-broom-wide::after {
  content: '\e5d1\e5d1'; }

.fad.fa-snowflake::after, .fa-duotone.fa-snowflake::after {
  content: '\f2dc\f2dc'; }

.fad.fa-stomach::after, .fa-duotone.fa-stomach::after {
  content: '\f623\f623'; }

.fad.fa-newspaper::after, .fa-duotone.fa-newspaper::after {
  content: '\f1ea\f1ea'; }

.fad.fa-rectangle-ad::after, .fa-duotone.fa-rectangle-ad::after {
  content: '\f641\f641'; }

.fad.fa-ad::after, .fa-duotone.fa-ad::after {
  content: '\f641\f641'; }

.fad.fa-guitar-electric::after, .fa-duotone.fa-guitar-electric::after {
  content: '\f8be\f8be'; }

.fad.fa-arrow-turn-down-right::after, .fa-duotone.fa-arrow-turn-down-right::after {
  content: '\e3d6\e3d6'; }

.fad.fa-moon-cloud::after, .fa-duotone.fa-moon-cloud::after {
  content: '\f754\f754'; }

.fad.fa-bread-slice-butter::after, .fa-duotone.fa-bread-slice-butter::after {
  content: '\e3e1\e3e1'; }

.fad.fa-circle-arrow-right::after, .fa-duotone.fa-circle-arrow-right::after {
  content: '\f0a9\f0a9'; }

.fad.fa-arrow-circle-right::after, .fa-duotone.fa-arrow-circle-right::after {
  content: '\f0a9\f0a9'; }

.fad.fa-user-group-crown::after, .fa-duotone.fa-user-group-crown::after {
  content: '\f6a5\f6a5'; }

.fad.fa-users-crown::after, .fa-duotone.fa-users-crown::after {
  content: '\f6a5\f6a5'; }

.fad.fa-circle-i::after, .fa-duotone.fa-circle-i::after {
  content: '\e111\e111'; }

.fad.fa-toilet-paper-check::after, .fa-duotone.fa-toilet-paper-check::after {
  content: '\e5b2\e5b2'; }

.fad.fa-filter-circle-xmark::after, .fa-duotone.fa-filter-circle-xmark::after {
  content: '\e17b\e17b'; }

.fad.fa-locust::after, .fa-duotone.fa-locust::after {
  content: '\e520\e520'; }

.fad.fa-sort::after, .fa-duotone.fa-sort::after {
  content: '\f0dc\f0dc'; }

.fad.fa-unsorted::after, .fa-duotone.fa-unsorted::after {
  content: '\f0dc\f0dc'; }

.fad.fa-list-ol::after, .fa-duotone.fa-list-ol::after {
  content: '\f0cb\f0cb'; }

.fad.fa-list-1-2::after, .fa-duotone.fa-list-1-2::after {
  content: '\f0cb\f0cb'; }

.fad.fa-list-numeric::after, .fa-duotone.fa-list-numeric::after {
  content: '\f0cb\f0cb'; }

.fad.fa-chart-waterfall::after, .fa-duotone.fa-chart-waterfall::after {
  content: '\e0eb\e0eb'; }

.fad.fa-sparkle::after, .fa-duotone.fa-sparkle::after {
  content: '\e5d6\e5d6'; }

.fad.fa-face-party::after, .fa-duotone.fa-face-party::after {
  content: '\e383\e383'; }

.fad.fa-kidneys::after, .fa-duotone.fa-kidneys::after {
  content: '\f5fb\f5fb'; }

.fad.fa-wifi-exclamation::after, .fa-duotone.fa-wifi-exclamation::after {
  content: '\e2cf\e2cf'; }

.fad.fa-chart-network::after, .fa-duotone.fa-chart-network::after {
  content: '\f78a\f78a'; }

.fad.fa-person-dress-burst::after, .fa-duotone.fa-person-dress-burst::after {
  content: '\e544\e544'; }

.fad.fa-dice-d4::after, .fa-duotone.fa-dice-d4::after {
  content: '\f6d0\f6d0'; }

.fad.fa-money-check-dollar::after, .fa-duotone.fa-money-check-dollar::after {
  content: '\f53d\f53d'; }

.fad.fa-money-check-alt::after, .fa-duotone.fa-money-check-alt::after {
  content: '\f53d\f53d'; }

.fad.fa-vector-square::after, .fa-duotone.fa-vector-square::after {
  content: '\f5cb\f5cb'; }

.fad.fa-bread-slice::after, .fa-duotone.fa-bread-slice::after {
  content: '\f7ec\f7ec'; }

.fad.fa-language::after, .fa-duotone.fa-language::after {
  content: '\f1ab\f1ab'; }

.fad.fa-wheat-awn-slash::after, .fa-duotone.fa-wheat-awn-slash::after {
  content: '\e338\e338'; }

.fad.fa-face-kiss-wink-heart::after, .fa-duotone.fa-face-kiss-wink-heart::after {
  content: '\f598\f598'; }

.fad.fa-kiss-wink-heart::after, .fa-duotone.fa-kiss-wink-heart::after {
  content: '\f598\f598'; }

.fad.fa-dagger::after, .fa-duotone.fa-dagger::after {
  content: '\f6cb\f6cb'; }

.fad.fa-podium::after, .fa-duotone.fa-podium::after {
  content: '\f680\f680'; }

.fad.fa-memo-circle-check::after, .fa-duotone.fa-memo-circle-check::after {
  content: '\e1d9\e1d9'; }

.fad.fa-route-highway::after, .fa-duotone.fa-route-highway::after {
  content: '\f61a\f61a'; }

.fad.fa-down-to-line::after, .fa-duotone.fa-down-to-line::after {
  content: '\f34a\f34a'; }

.fad.fa-arrow-alt-to-bottom::after, .fa-duotone.fa-arrow-alt-to-bottom::after {
  content: '\f34a\f34a'; }

.fad.fa-filter::after, .fa-duotone.fa-filter::after {
  content: '\f0b0\f0b0'; }

.fad.fa-square-g::after, .fa-duotone.fa-square-g::after {
  content: '\e271\e271'; }

.fad.fa-circle-phone::after, .fa-duotone.fa-circle-phone::after {
  content: '\e11b\e11b'; }

.fad.fa-phone-circle::after, .fa-duotone.fa-phone-circle::after {
  content: '\e11b\e11b'; }

.fad.fa-clipboard-prescription::after, .fa-duotone.fa-clipboard-prescription::after {
  content: '\f5e8\f5e8'; }

.fad.fa-user-nurse-hair::after, .fa-duotone.fa-user-nurse-hair::after {
  content: '\e45d\e45d'; }

.fad.fa-question::after, .fa-duotone.fa-question::after {
  content: '\3f\3f'; }

.fad.fa-file-signature::after, .fa-duotone.fa-file-signature::after {
  content: '\f573\f573'; }

.fad.fa-toggle-large-on::after, .fa-duotone.fa-toggle-large-on::after {
  content: '\e5b1\e5b1'; }

.fad.fa-up-down-left-right::after, .fa-duotone.fa-up-down-left-right::after {
  content: '\f0b2\f0b2'; }

.fad.fa-arrows-alt::after, .fa-duotone.fa-arrows-alt::after {
  content: '\f0b2\f0b2'; }

.fad.fa-dryer-heat::after, .fa-duotone.fa-dryer-heat::after {
  content: '\f862\f862'; }

.fad.fa-dryer-alt::after, .fa-duotone.fa-dryer-alt::after {
  content: '\f862\f862'; }

.fad.fa-house-chimney-user::after, .fa-duotone.fa-house-chimney-user::after {
  content: '\e065\e065'; }

.fad.fa-hand-holding-heart::after, .fa-duotone.fa-hand-holding-heart::after {
  content: '\f4be\f4be'; }

.fad.fa-arrow-up-small-big::after, .fa-duotone.fa-arrow-up-small-big::after {
  content: '\f88f\f88f'; }

.fad.fa-sort-size-up-alt::after, .fa-duotone.fa-sort-size-up-alt::after {
  content: '\f88f\f88f'; }

.fad.fa-train-track::after, .fa-duotone.fa-train-track::after {
  content: '\e453\e453'; }

.fad.fa-puzzle-piece::after, .fa-duotone.fa-puzzle-piece::after {
  content: '\f12e\f12e'; }

.fad.fa-money-check::after, .fa-duotone.fa-money-check::after {
  content: '\f53c\f53c'; }

.fad.fa-star-half-stroke::after, .fa-duotone.fa-star-half-stroke::after {
  content: '\f5c0\f5c0'; }

.fad.fa-star-half-alt::after, .fa-duotone.fa-star-half-alt::after {
  content: '\f5c0\f5c0'; }

.fad.fa-file-exclamation::after, .fa-duotone.fa-file-exclamation::after {
  content: '\f31a\f31a'; }

.fad.fa-code::after, .fa-duotone.fa-code::after {
  content: '\f121\f121'; }

.fad.fa-whiskey-glass::after, .fa-duotone.fa-whiskey-glass::after {
  content: '\f7a0\f7a0'; }

.fad.fa-glass-whiskey::after, .fa-duotone.fa-glass-whiskey::after {
  content: '\f7a0\f7a0'; }

.fad.fa-moon-stars::after, .fa-duotone.fa-moon-stars::after {
  content: '\f755\f755'; }

.fad.fa-building-circle-exclamation::after, .fa-duotone.fa-building-circle-exclamation::after {
  content: '\e4d3\e4d3'; }

.fad.fa-clothes-hanger::after, .fa-duotone.fa-clothes-hanger::after {
  content: '\e136\e136'; }

.fad.fa-mobile-notch::after, .fa-duotone.fa-mobile-notch::after {
  content: '\e1ee\e1ee'; }

.fad.fa-mobile-iphone::after, .fa-duotone.fa-mobile-iphone::after {
  content: '\e1ee\e1ee'; }

.fad.fa-magnifying-glass-chart::after, .fa-duotone.fa-magnifying-glass-chart::after {
  content: '\e522\e522'; }

.fad.fa-arrow-up-right-from-square::after, .fa-duotone.fa-arrow-up-right-from-square::after {
  content: '\f08e\f08e'; }

.fad.fa-external-link::after, .fa-duotone.fa-external-link::after {
  content: '\f08e\f08e'; }

.fad.fa-cubes-stacked::after, .fa-duotone.fa-cubes-stacked::after {
  content: '\e4e6\e4e6'; }

.fad.fa-images-user::after, .fa-duotone.fa-images-user::after {
  content: '\e1b9\e1b9'; }

.fad.fa-won-sign::after, .fa-duotone.fa-won-sign::after {
  content: '\f159\f159'; }

.fad.fa-krw::after, .fa-duotone.fa-krw::after {
  content: '\f159\f159'; }

.fad.fa-won::after, .fa-duotone.fa-won::after {
  content: '\f159\f159'; }

.fad.fa-image-polaroid-user::after, .fa-duotone.fa-image-polaroid-user::after {
  content: '\e1b6\e1b6'; }

.fad.fa-virus-covid::after, .fa-duotone.fa-virus-covid::after {
  content: '\e4a8\e4a8'; }

.fad.fa-square-ellipsis::after, .fa-duotone.fa-square-ellipsis::after {
  content: '\e26e\e26e'; }

.fad.fa-pie::after, .fa-duotone.fa-pie::after {
  content: '\f705\f705'; }

.fad.fa-chess-knight-piece::after, .fa-duotone.fa-chess-knight-piece::after {
  content: '\f442\f442'; }

.fad.fa-chess-knight-alt::after, .fa-duotone.fa-chess-knight-alt::after {
  content: '\f442\f442'; }

.fad.fa-austral-sign::after, .fa-duotone.fa-austral-sign::after {
  content: '\e0a9\e0a9'; }

.fad.fa-cloud-plus::after, .fa-duotone.fa-cloud-plus::after {
  content: '\e35e\e35e'; }

.fad.fa-f::after, .fa-duotone.fa-f::after {
  content: '\46\46'; }

.fad.fa-leaf::after, .fa-duotone.fa-leaf::after {
  content: '\f06c\f06c'; }

.fad.fa-bed-bunk::after, .fa-duotone.fa-bed-bunk::after {
  content: '\f8f8\f8f8'; }

.fad.fa-road::after, .fa-duotone.fa-road::after {
  content: '\f018\f018'; }

.fad.fa-taxi::after, .fa-duotone.fa-taxi::after {
  content: '\f1ba\f1ba'; }

.fad.fa-cab::after, .fa-duotone.fa-cab::after {
  content: '\f1ba\f1ba'; }

.fad.fa-person-circle-plus::after, .fa-duotone.fa-person-circle-plus::after {
  content: '\e541\e541'; }

.fad.fa-chart-pie::after, .fa-duotone.fa-chart-pie::after {
  content: '\f200\f200'; }

.fad.fa-pie-chart::after, .fa-duotone.fa-pie-chart::after {
  content: '\f200\f200'; }

.fad.fa-bolt-lightning::after, .fa-duotone.fa-bolt-lightning::after {
  content: '\e0b7\e0b7'; }

.fad.fa-clock-eight::after, .fa-duotone.fa-clock-eight::after {
  content: '\e345\e345'; }

.fad.fa-sack-xmark::after, .fa-duotone.fa-sack-xmark::after {
  content: '\e56a\e56a'; }

.fad.fa-file-excel::after, .fa-duotone.fa-file-excel::after {
  content: '\f1c3\f1c3'; }

.fad.fa-file-contract::after, .fa-duotone.fa-file-contract::after {
  content: '\f56c\f56c'; }

.fad.fa-fish-fins::after, .fa-duotone.fa-fish-fins::after {
  content: '\e4f2\e4f2'; }

.fad.fa-circle-q::after, .fa-duotone.fa-circle-q::after {
  content: '\e11e\e11e'; }

.fad.fa-building-flag::after, .fa-duotone.fa-building-flag::after {
  content: '\e4d5\e4d5'; }

.fad.fa-face-grin-beam::after, .fa-duotone.fa-face-grin-beam::after {
  content: '\f582\f582'; }

.fad.fa-grin-beam::after, .fa-duotone.fa-grin-beam::after {
  content: '\f582\f582'; }

.fad.fa-object-ungroup::after, .fa-duotone.fa-object-ungroup::after {
  content: '\f248\f248'; }

.fad.fa-face-disguise::after, .fa-duotone.fa-face-disguise::after {
  content: '\e370\e370'; }

.fad.fa-circle-arrow-down-right::after, .fa-duotone.fa-circle-arrow-down-right::after {
  content: '\e0fa\e0fa'; }

.fad.fa-alien-8bit::after, .fa-duotone.fa-alien-8bit::after {
  content: '\f8f6\f8f6'; }

.fad.fa-alien-monster::after, .fa-duotone.fa-alien-monster::after {
  content: '\f8f6\f8f6'; }

.fad.fa-hand-point-ribbon::after, .fa-duotone.fa-hand-point-ribbon::after {
  content: '\e1a6\e1a6'; }

.fad.fa-poop::after, .fa-duotone.fa-poop::after {
  content: '\f619\f619'; }

.fad.fa-object-exclude::after, .fa-duotone.fa-object-exclude::after {
  content: '\e49c\e49c'; }

.fad.fa-telescope::after, .fa-duotone.fa-telescope::after {
  content: '\e03e\e03e'; }

.fad.fa-location-pin::after, .fa-duotone.fa-location-pin::after {
  content: '\f041\f041'; }

.fad.fa-map-marker::after, .fa-duotone.fa-map-marker::after {
  content: '\f041\f041'; }

.fad.fa-square-list::after, .fa-duotone.fa-square-list::after {
  content: '\e489\e489'; }

.fad.fa-kaaba::after, .fa-duotone.fa-kaaba::after {
  content: '\f66b\f66b'; }

.fad.fa-toilet-paper::after, .fa-duotone.fa-toilet-paper::after {
  content: '\f71e\f71e'; }

.fad.fa-helmet-safety::after, .fa-duotone.fa-helmet-safety::after {
  content: '\f807\f807'; }

.fad.fa-hard-hat::after, .fa-duotone.fa-hard-hat::after {
  content: '\f807\f807'; }

.fad.fa-hat-hard::after, .fa-duotone.fa-hat-hard::after {
  content: '\f807\f807'; }

.fad.fa-comment-code::after, .fa-duotone.fa-comment-code::after {
  content: '\e147\e147'; }

.fad.fa-sim-cards::after, .fa-duotone.fa-sim-cards::after {
  content: '\e251\e251'; }

.fad.fa-starship::after, .fa-duotone.fa-starship::after {
  content: '\e039\e039'; }

.fad.fa-eject::after, .fa-duotone.fa-eject::after {
  content: '\f052\f052'; }

.fad.fa-circle-right::after, .fa-duotone.fa-circle-right::after {
  content: '\f35a\f35a'; }

.fad.fa-arrow-alt-circle-right::after, .fa-duotone.fa-arrow-alt-circle-right::after {
  content: '\f35a\f35a'; }

.fad.fa-plane-circle-check::after, .fa-duotone.fa-plane-circle-check::after {
  content: '\e555\e555'; }

.fad.fa-seal::after, .fa-duotone.fa-seal::after {
  content: '\e241\e241'; }

.fad.fa-user-cowboy::after, .fa-duotone.fa-user-cowboy::after {
  content: '\f8ea\f8ea'; }

.fad.fa-hexagon-vertical-nft::after, .fa-duotone.fa-hexagon-vertical-nft::after {
  content: '\e505\e505'; }

.fad.fa-face-rolling-eyes::after, .fa-duotone.fa-face-rolling-eyes::after {
  content: '\f5a5\f5a5'; }

.fad.fa-meh-rolling-eyes::after, .fa-duotone.fa-meh-rolling-eyes::after {
  content: '\f5a5\f5a5'; }

.fad.fa-bread-loaf::after, .fa-duotone.fa-bread-loaf::after {
  content: '\f7eb\f7eb'; }

.fad.fa-rings-wedding::after, .fa-duotone.fa-rings-wedding::after {
  content: '\f81b\f81b'; }

.fad.fa-object-group::after, .fa-duotone.fa-object-group::after {
  content: '\f247\f247'; }

.fad.fa-french-fries::after, .fa-duotone.fa-french-fries::after {
  content: '\f803\f803'; }

.fad.fa-chart-line::after, .fa-duotone.fa-chart-line::after {
  content: '\f201\f201'; }

.fad.fa-line-chart::after, .fa-duotone.fa-line-chart::after {
  content: '\f201\f201'; }

.fad.fa-calendar-arrow-down::after, .fa-duotone.fa-calendar-arrow-down::after {
  content: '\e0d0\e0d0'; }

.fad.fa-calendar-download::after, .fa-duotone.fa-calendar-download::after {
  content: '\e0d0\e0d0'; }

.fad.fa-send-back::after, .fa-duotone.fa-send-back::after {
  content: '\f87e\f87e'; }

.fad.fa-mask-ventilator::after, .fa-duotone.fa-mask-ventilator::after {
  content: '\e524\e524'; }

.fad.fa-signature-lock::after, .fa-duotone.fa-signature-lock::after {
  content: '\e3ca\e3ca'; }

.fad.fa-arrow-right::after, .fa-duotone.fa-arrow-right::after {
  content: '\f061\f061'; }

.fad.fa-signs-post::after, .fa-duotone.fa-signs-post::after {
  content: '\f277\f277'; }

.fad.fa-map-signs::after, .fa-duotone.fa-map-signs::after {
  content: '\f277\f277'; }

.fad.fa-octagon-plus::after, .fa-duotone.fa-octagon-plus::after {
  content: '\f301\f301'; }

.fad.fa-plus-octagon::after, .fa-duotone.fa-plus-octagon::after {
  content: '\f301\f301'; }

.fad.fa-cash-register::after, .fa-duotone.fa-cash-register::after {
  content: '\f788\f788'; }

.fad.fa-person-circle-question::after, .fa-duotone.fa-person-circle-question::after {
  content: '\e542\e542'; }

.fad.fa-melon-slice::after, .fa-duotone.fa-melon-slice::after {
  content: '\e311\e311'; }

.fad.fa-space-station-moon::after, .fa-duotone.fa-space-station-moon::after {
  content: '\e033\e033'; }

.fad.fa-message-smile::after, .fa-duotone.fa-message-smile::after {
  content: '\f4aa\f4aa'; }

.fad.fa-comment-alt-smile::after, .fa-duotone.fa-comment-alt-smile::after {
  content: '\f4aa\f4aa'; }

.fad.fa-cup-straw::after, .fa-duotone.fa-cup-straw::after {
  content: '\e363\e363'; }

.fad.fa-left-from-line::after, .fa-duotone.fa-left-from-line::after {
  content: '\f348\f348'; }

.fad.fa-arrow-alt-from-right::after, .fa-duotone.fa-arrow-alt-from-right::after {
  content: '\f348\f348'; }

.fad.fa-h::after, .fa-duotone.fa-h::after {
  content: '\48\48'; }

.fad.fa-basket-shopping-simple::after, .fa-duotone.fa-basket-shopping-simple::after {
  content: '\e0af\e0af'; }

.fad.fa-shopping-basket-alt::after, .fa-duotone.fa-shopping-basket-alt::after {
  content: '\e0af\e0af'; }

.fad.fa-hands-holding-heart::after, .fa-duotone.fa-hands-holding-heart::after {
  content: '\f4c3\f4c3'; }

.fad.fa-hands-heart::after, .fa-duotone.fa-hands-heart::after {
  content: '\f4c3\f4c3'; }

.fad.fa-clock-nine::after, .fa-duotone.fa-clock-nine::after {
  content: '\e34c\e34c'; }

.fad.fa-tarp::after, .fa-duotone.fa-tarp::after {
  content: '\e57b\e57b'; }

.fad.fa-face-sleepy::after, .fa-duotone.fa-face-sleepy::after {
  content: '\e38e\e38e'; }

.fad.fa-hand-horns::after, .fa-duotone.fa-hand-horns::after {
  content: '\e1a9\e1a9'; }

.fad.fa-screwdriver-wrench::after, .fa-duotone.fa-screwdriver-wrench::after {
  content: '\f7d9\f7d9'; }

.fad.fa-tools::after, .fa-duotone.fa-tools::after {
  content: '\f7d9\f7d9'; }

.fad.fa-arrows-to-eye::after, .fa-duotone.fa-arrows-to-eye::after {
  content: '\e4bf\e4bf'; }

.fad.fa-circle-three-quarters::after, .fa-duotone.fa-circle-three-quarters::after {
  content: '\e125\e125'; }

.fad.fa-trophy-star::after, .fa-duotone.fa-trophy-star::after {
  content: '\f2eb\f2eb'; }

.fad.fa-trophy-alt::after, .fa-duotone.fa-trophy-alt::after {
  content: '\f2eb\f2eb'; }

.fad.fa-plug-circle-bolt::after, .fa-duotone.fa-plug-circle-bolt::after {
  content: '\e55b\e55b'; }

.fad.fa-face-thermometer::after, .fa-duotone.fa-face-thermometer::after {
  content: '\e39a\e39a'; }

.fad.fa-grid-round-4::after, .fa-duotone.fa-grid-round-4::after {
  content: '\e5dd\e5dd'; }

.fad.fa-shirt-running::after, .fa-duotone.fa-shirt-running::after {
  content: '\e3c8\e3c8'; }

.fad.fa-book-circle-arrow-up::after, .fa-duotone.fa-book-circle-arrow-up::after {
  content: '\e0bd\e0bd'; }

.fad.fa-face-nauseated::after, .fa-duotone.fa-face-nauseated::after {
  content: '\e381\e381'; }

.fad.fa-heart::after, .fa-duotone.fa-heart::after {
  content: '\f004\f004'; }

.fad.fa-file-chart-pie::after, .fa-duotone.fa-file-chart-pie::after {
  content: '\f65a\f65a'; }

.fad.fa-mars-and-venus::after, .fa-duotone.fa-mars-and-venus::after {
  content: '\f224\f224'; }

.fad.fa-house-user::after, .fa-duotone.fa-house-user::after {
  content: '\e1b0\e1b0'; }

.fad.fa-home-user::after, .fa-duotone.fa-home-user::after {
  content: '\e1b0\e1b0'; }

.fad.fa-circle-arrow-down-left::after, .fa-duotone.fa-circle-arrow-down-left::after {
  content: '\e0f9\e0f9'; }

.fad.fa-dumpster-fire::after, .fa-duotone.fa-dumpster-fire::after {
  content: '\f794\f794'; }

.fad.fa-hexagon-minus::after, .fa-duotone.fa-hexagon-minus::after {
  content: '\f307\f307'; }

.fad.fa-minus-hexagon::after, .fa-duotone.fa-minus-hexagon::after {
  content: '\f307\f307'; }

.fad.fa-left-to-line::after, .fa-duotone.fa-left-to-line::after {
  content: '\f34b\f34b'; }

.fad.fa-arrow-alt-to-left::after, .fa-duotone.fa-arrow-alt-to-left::after {
  content: '\f34b\f34b'; }

.fad.fa-house-crack::after, .fa-duotone.fa-house-crack::after {
  content: '\e3b1\e3b1'; }

.fad.fa-paw-simple::after, .fa-duotone.fa-paw-simple::after {
  content: '\f701\f701'; }

.fad.fa-paw-alt::after, .fa-duotone.fa-paw-alt::after {
  content: '\f701\f701'; }

.fad.fa-arrow-left-long-to-line::after, .fa-duotone.fa-arrow-left-long-to-line::after {
  content: '\e3d4\e3d4'; }

.fad.fa-brackets-round::after, .fa-duotone.fa-brackets-round::after {
  content: '\e0c5\e0c5'; }

.fad.fa-parentheses::after, .fa-duotone.fa-parentheses::after {
  content: '\e0c5\e0c5'; }

.fad.fa-martini-glass-citrus::after, .fa-duotone.fa-martini-glass-citrus::after {
  content: '\f561\f561'; }

.fad.fa-cocktail::after, .fa-duotone.fa-cocktail::after {
  content: '\f561\f561'; }

.fad.fa-user-shakespeare::after, .fa-duotone.fa-user-shakespeare::after {
  content: '\e2c2\e2c2'; }

.fad.fa-arrow-right-to-arc::after, .fa-duotone.fa-arrow-right-to-arc::after {
  content: '\e4b2\e4b2'; }

.fad.fa-face-surprise::after, .fa-duotone.fa-face-surprise::after {
  content: '\f5c2\f5c2'; }

.fad.fa-surprise::after, .fa-duotone.fa-surprise::after {
  content: '\f5c2\f5c2'; }

.fad.fa-bottle-water::after, .fa-duotone.fa-bottle-water::after {
  content: '\e4c5\e4c5'; }

.fad.fa-circle-pause::after, .fa-duotone.fa-circle-pause::after {
  content: '\f28b\f28b'; }

.fad.fa-pause-circle::after, .fa-duotone.fa-pause-circle::after {
  content: '\f28b\f28b'; }

.fad.fa-gauge-circle-plus::after, .fa-duotone.fa-gauge-circle-plus::after {
  content: '\e498\e498'; }

.fad.fa-folders::after, .fa-duotone.fa-folders::after {
  content: '\f660\f660'; }

.fad.fa-angel::after, .fa-duotone.fa-angel::after {
  content: '\f779\f779'; }

.fad.fa-value-absolute::after, .fa-duotone.fa-value-absolute::after {
  content: '\f6a6\f6a6'; }

.fad.fa-rabbit::after, .fa-duotone.fa-rabbit::after {
  content: '\f708\f708'; }

.fad.fa-toilet-paper-slash::after, .fa-duotone.fa-toilet-paper-slash::after {
  content: '\e072\e072'; }

.fad.fa-circle-euro::after, .fa-duotone.fa-circle-euro::after {
  content: '\e5ce\e5ce'; }

.fad.fa-apple-whole::after, .fa-duotone.fa-apple-whole::after {
  content: '\f5d1\f5d1'; }

.fad.fa-apple-alt::after, .fa-duotone.fa-apple-alt::after {
  content: '\f5d1\f5d1'; }

.fad.fa-kitchen-set::after, .fa-duotone.fa-kitchen-set::after {
  content: '\e51a\e51a'; }

.fad.fa-diamond-half::after, .fa-duotone.fa-diamond-half::after {
  content: '\e5b7\e5b7'; }

.fad.fa-lock-keyhole::after, .fa-duotone.fa-lock-keyhole::after {
  content: '\f30d\f30d'; }

.fad.fa-lock-alt::after, .fa-duotone.fa-lock-alt::after {
  content: '\f30d\f30d'; }

.fad.fa-r::after, .fa-duotone.fa-r::after {
  content: '\52\52'; }

.fad.fa-temperature-quarter::after, .fa-duotone.fa-temperature-quarter::after {
  content: '\f2ca\f2ca'; }

.fad.fa-temperature-1::after, .fa-duotone.fa-temperature-1::after {
  content: '\f2ca\f2ca'; }

.fad.fa-thermometer-1::after, .fa-duotone.fa-thermometer-1::after {
  content: '\f2ca\f2ca'; }

.fad.fa-thermometer-quarter::after, .fa-duotone.fa-thermometer-quarter::after {
  content: '\f2ca\f2ca'; }

.fad.fa-square-info::after, .fa-duotone.fa-square-info::after {
  content: '\f30f\f30f'; }

.fad.fa-info-square::after, .fa-duotone.fa-info-square::after {
  content: '\f30f\f30f'; }

.fad.fa-wifi-slash::after, .fa-duotone.fa-wifi-slash::after {
  content: '\f6ac\f6ac'; }

.fad.fa-toilet-paper-xmark::after, .fa-duotone.fa-toilet-paper-xmark::after {
  content: '\e5b3\e5b3'; }

.fad.fa-hands-holding-dollar::after, .fa-duotone.fa-hands-holding-dollar::after {
  content: '\f4c5\f4c5'; }

.fad.fa-hands-usd::after, .fa-duotone.fa-hands-usd::after {
  content: '\f4c5\f4c5'; }

.fad.fa-cube::after, .fa-duotone.fa-cube::after {
  content: '\f1b2\f1b2'; }

.fad.fa-arrow-down-triangle-square::after, .fa-duotone.fa-arrow-down-triangle-square::after {
  content: '\f888\f888'; }

.fad.fa-sort-shapes-down::after, .fa-duotone.fa-sort-shapes-down::after {
  content: '\f888\f888'; }

.fad.fa-bitcoin-sign::after, .fa-duotone.fa-bitcoin-sign::after {
  content: '\e0b4\e0b4'; }

.fad.fa-shutters::after, .fa-duotone.fa-shutters::after {
  content: '\e449\e449'; }

.fad.fa-shield-dog::after, .fa-duotone.fa-shield-dog::after {
  content: '\e573\e573'; }

.fad.fa-solar-panel::after, .fa-duotone.fa-solar-panel::after {
  content: '\f5ba\f5ba'; }

.fad.fa-lock-open::after, .fa-duotone.fa-lock-open::after {
  content: '\f3c1\f3c1'; }

.fad.fa-table-tree::after, .fa-duotone.fa-table-tree::after {
  content: '\e293\e293'; }

.fad.fa-house-chimney-heart::after, .fa-duotone.fa-house-chimney-heart::after {
  content: '\e1b2\e1b2'; }

.fad.fa-tally-3::after, .fa-duotone.fa-tally-3::after {
  content: '\e296\e296'; }

.fad.fa-elevator::after, .fa-duotone.fa-elevator::after {
  content: '\e16d\e16d'; }

.fad.fa-money-bill-transfer::after, .fa-duotone.fa-money-bill-transfer::after {
  content: '\e528\e528'; }

.fad.fa-money-bill-trend-up::after, .fa-duotone.fa-money-bill-trend-up::after {
  content: '\e529\e529'; }

.fad.fa-house-flood-water-circle-arrow-right::after, .fa-duotone.fa-house-flood-water-circle-arrow-right::after {
  content: '\e50f\e50f'; }

.fad.fa-square-poll-horizontal::after, .fa-duotone.fa-square-poll-horizontal::after {
  content: '\f682\f682'; }

.fad.fa-poll-h::after, .fa-duotone.fa-poll-h::after {
  content: '\f682\f682'; }

.fad.fa-circle::after, .fa-duotone.fa-circle::after {
  content: '\f111\f111'; }

.fad.fa-cart-circle-exclamation::after, .fa-duotone.fa-cart-circle-exclamation::after {
  content: '\e3f2\e3f2'; }

.fad.fa-sword::after, .fa-duotone.fa-sword::after {
  content: '\f71c\f71c'; }

.fad.fa-backward-fast::after, .fa-duotone.fa-backward-fast::after {
  content: '\f049\f049'; }

.fad.fa-fast-backward::after, .fa-duotone.fa-fast-backward::after {
  content: '\f049\f049'; }

.fad.fa-recycle::after, .fa-duotone.fa-recycle::after {
  content: '\f1b8\f1b8'; }

.fad.fa-user-astronaut::after, .fa-duotone.fa-user-astronaut::after {
  content: '\f4fb\f4fb'; }

.fad.fa-interrobang::after, .fa-duotone.fa-interrobang::after {
  content: '\e5ba\e5ba'; }

.fad.fa-plane-slash::after, .fa-duotone.fa-plane-slash::after {
  content: '\e069\e069'; }

.fad.fa-circle-dashed::after, .fa-duotone.fa-circle-dashed::after {
  content: '\e105\e105'; }

.fad.fa-trademark::after, .fa-duotone.fa-trademark::after {
  content: '\f25c\f25c'; }

.fad.fa-basketball::after, .fa-duotone.fa-basketball::after {
  content: '\f434\f434'; }

.fad.fa-basketball-ball::after, .fa-duotone.fa-basketball-ball::after {
  content: '\f434\f434'; }

.fad.fa-fork-knife::after, .fa-duotone.fa-fork-knife::after {
  content: '\f2e6\f2e6'; }

.fad.fa-utensils-alt::after, .fa-duotone.fa-utensils-alt::after {
  content: '\f2e6\f2e6'; }

.fad.fa-satellite-dish::after, .fa-duotone.fa-satellite-dish::after {
  content: '\f7c0\f7c0'; }

.fad.fa-badge-check::after, .fa-duotone.fa-badge-check::after {
  content: '\f336\f336'; }

.fad.fa-circle-up::after, .fa-duotone.fa-circle-up::after {
  content: '\f35b\f35b'; }

.fad.fa-arrow-alt-circle-up::after, .fa-duotone.fa-arrow-alt-circle-up::after {
  content: '\f35b\f35b'; }

.fad.fa-slider::after, .fa-duotone.fa-slider::after {
  content: '\e252\e252'; }

.fad.fa-mobile-screen-button::after, .fa-duotone.fa-mobile-screen-button::after {
  content: '\f3cd\f3cd'; }

.fad.fa-mobile-alt::after, .fa-duotone.fa-mobile-alt::after {
  content: '\f3cd\f3cd'; }

.fad.fa-clock-one-thirty::after, .fa-duotone.fa-clock-one-thirty::after {
  content: '\e34f\e34f'; }

.fad.fa-inbox-out::after, .fa-duotone.fa-inbox-out::after {
  content: '\f311\f311'; }

.fad.fa-inbox-arrow-up::after, .fa-duotone.fa-inbox-arrow-up::after {
  content: '\f311\f311'; }

.fad.fa-cloud-slash::after, .fa-duotone.fa-cloud-slash::after {
  content: '\e137\e137'; }

.fad.fa-volume-high::after, .fa-duotone.fa-volume-high::after {
  content: '\f028\f028'; }

.fad.fa-volume-up::after, .fa-duotone.fa-volume-up::after {
  content: '\f028\f028'; }

.fad.fa-users-rays::after, .fa-duotone.fa-users-rays::after {
  content: '\e593\e593'; }

.fad.fa-wallet::after, .fa-duotone.fa-wallet::after {
  content: '\f555\f555'; }

.fad.fa-octagon-check::after, .fa-duotone.fa-octagon-check::after {
  content: '\e426\e426'; }

.fad.fa-flatbread-stuffed::after, .fa-duotone.fa-flatbread-stuffed::after {
  content: '\e40c\e40c'; }

.fad.fa-clipboard-check::after, .fa-duotone.fa-clipboard-check::after {
  content: '\f46c\f46c'; }

.fad.fa-cart-circle-plus::after, .fa-duotone.fa-cart-circle-plus::after {
  content: '\e3f3\e3f3'; }

.fad.fa-truck-clock::after, .fa-duotone.fa-truck-clock::after {
  content: '\f48c\f48c'; }

.fad.fa-shipping-timed::after, .fa-duotone.fa-shipping-timed::after {
  content: '\f48c\f48c'; }

.fad.fa-pool-8-ball::after, .fa-duotone.fa-pool-8-ball::after {
  content: '\e3c5\e3c5'; }

.fad.fa-file-audio::after, .fa-duotone.fa-file-audio::after {
  content: '\f1c7\f1c7'; }

.fad.fa-turn-down-left::after, .fa-duotone.fa-turn-down-left::after {
  content: '\e331\e331'; }

.fad.fa-lock-hashtag::after, .fa-duotone.fa-lock-hashtag::after {
  content: '\e423\e423'; }

.fad.fa-chart-radar::after, .fa-duotone.fa-chart-radar::after {
  content: '\e0e7\e0e7'; }

.fad.fa-staff::after, .fa-duotone.fa-staff::after {
  content: '\f71b\f71b'; }

.fad.fa-burger::after, .fa-duotone.fa-burger::after {
  content: '\f805\f805'; }

.fad.fa-hamburger::after, .fa-duotone.fa-hamburger::after {
  content: '\f805\f805'; }

.fad.fa-utility-pole::after, .fa-duotone.fa-utility-pole::after {
  content: '\e2c3\e2c3'; }

.fad.fa-transporter-6::after, .fa-duotone.fa-transporter-6::after {
  content: '\e2a7\e2a7'; }

.fad.fa-wrench::after, .fa-duotone.fa-wrench::after {
  content: '\f0ad\f0ad'; }

.fad.fa-bugs::after, .fa-duotone.fa-bugs::after {
  content: '\e4d0\e4d0'; }

.fad.fa-vector-polygon::after, .fa-duotone.fa-vector-polygon::after {
  content: '\e2c7\e2c7'; }

.fad.fa-diagram-nested::after, .fa-duotone.fa-diagram-nested::after {
  content: '\e157\e157'; }

.fad.fa-rupee-sign::after, .fa-duotone.fa-rupee-sign::after {
  content: '\f156\f156'; }

.fad.fa-rupee::after, .fa-duotone.fa-rupee::after {
  content: '\f156\f156'; }

.fad.fa-file-image::after, .fa-duotone.fa-file-image::after {
  content: '\f1c5\f1c5'; }

.fad.fa-circle-question::after, .fa-duotone.fa-circle-question::after {
  content: '\f059\f059'; }

.fad.fa-question-circle::after, .fa-duotone.fa-question-circle::after {
  content: '\f059\f059'; }

.fad.fa-image-user::after, .fa-duotone.fa-image-user::after {
  content: '\e1b8\e1b8'; }

.fad.fa-buoy::after, .fa-duotone.fa-buoy::after {
  content: '\e5b5\e5b5'; }

.fad.fa-plane-departure::after, .fa-duotone.fa-plane-departure::after {
  content: '\f5b0\f5b0'; }

.fad.fa-handshake-slash::after, .fa-duotone.fa-handshake-slash::after {
  content: '\e060\e060'; }

.fad.fa-book-bookmark::after, .fa-duotone.fa-book-bookmark::after {
  content: '\e0bb\e0bb'; }

.fad.fa-border-center-h::after, .fa-duotone.fa-border-center-h::after {
  content: '\f89c\f89c'; }

.fad.fa-can-food::after, .fa-duotone.fa-can-food::after {
  content: '\e3e6\e3e6'; }

.fad.fa-typewriter::after, .fa-duotone.fa-typewriter::after {
  content: '\f8e7\f8e7'; }

.fad.fa-arrow-right-from-arc::after, .fa-duotone.fa-arrow-right-from-arc::after {
  content: '\e4b1\e4b1'; }

.fad.fa-circle-k::after, .fa-duotone.fa-circle-k::after {
  content: '\e113\e113'; }

.fad.fa-face-hand-over-mouth::after, .fa-duotone.fa-face-hand-over-mouth::after {
  content: '\e378\e378'; }

.fad.fa-popcorn::after, .fa-duotone.fa-popcorn::after {
  content: '\f819\f819'; }

.fad.fa-house-water::after, .fa-duotone.fa-house-water::after {
  content: '\f74f\f74f'; }

.fad.fa-house-flood::after, .fa-duotone.fa-house-flood::after {
  content: '\f74f\f74f'; }

.fad.fa-object-subtract::after, .fa-duotone.fa-object-subtract::after {
  content: '\e49e\e49e'; }

.fad.fa-code-branch::after, .fa-duotone.fa-code-branch::after {
  content: '\f126\f126'; }

.fad.fa-warehouse-full::after, .fa-duotone.fa-warehouse-full::after {
  content: '\f495\f495'; }

.fad.fa-warehouse-alt::after, .fa-duotone.fa-warehouse-alt::after {
  content: '\f495\f495'; }

.fad.fa-hat-cowboy::after, .fa-duotone.fa-hat-cowboy::after {
  content: '\f8c0\f8c0'; }

.fad.fa-bridge::after, .fa-duotone.fa-bridge::after {
  content: '\e4c8\e4c8'; }

.fad.fa-phone-flip::after, .fa-duotone.fa-phone-flip::after {
  content: '\f879\f879'; }

.fad.fa-phone-alt::after, .fa-duotone.fa-phone-alt::after {
  content: '\f879\f879'; }

.fad.fa-arrow-down-from-dotted-line::after, .fa-duotone.fa-arrow-down-from-dotted-line::after {
  content: '\e090\e090'; }

.fad.fa-file-doc::after, .fa-duotone.fa-file-doc::after {
  content: '\e5ed\e5ed'; }

.fad.fa-square-quarters::after, .fa-duotone.fa-square-quarters::after {
  content: '\e44e\e44e'; }

.fad.fa-truck-front::after, .fa-duotone.fa-truck-front::after {
  content: '\e2b7\e2b7'; }

.fad.fa-cat::after, .fa-duotone.fa-cat::after {
  content: '\f6be\f6be'; }

.fad.fa-trash-xmark::after, .fa-duotone.fa-trash-xmark::after {
  content: '\e2b4\e2b4'; }

.fad.fa-circle-caret-left::after, .fa-duotone.fa-circle-caret-left::after {
  content: '\f32e\f32e'; }

.fad.fa-caret-circle-left::after, .fa-duotone.fa-caret-circle-left::after {
  content: '\f32e\f32e'; }

.fad.fa-files::after, .fa-duotone.fa-files::after {
  content: '\e178\e178'; }

.fad.fa-anchor-circle-exclamation::after, .fa-duotone.fa-anchor-circle-exclamation::after {
  content: '\e4ab\e4ab'; }

.fad.fa-face-clouds::after, .fa-duotone.fa-face-clouds::after {
  content: '\e47d\e47d'; }

.fad.fa-user-crown::after, .fa-duotone.fa-user-crown::after {
  content: '\f6a4\f6a4'; }

.fad.fa-truck-field::after, .fa-duotone.fa-truck-field::after {
  content: '\e58d\e58d'; }

.fad.fa-route::after, .fa-duotone.fa-route::after {
  content: '\f4d7\f4d7'; }

.fad.fa-cart-circle-check::after, .fa-duotone.fa-cart-circle-check::after {
  content: '\e3f1\e3f1'; }

.fad.fa-clipboard-question::after, .fa-duotone.fa-clipboard-question::after {
  content: '\e4e3\e4e3'; }

.fad.fa-panorama::after, .fa-duotone.fa-panorama::after {
  content: '\e209\e209'; }

.fad.fa-comment-medical::after, .fa-duotone.fa-comment-medical::after {
  content: '\f7f5\f7f5'; }

.fad.fa-teeth-open::after, .fa-duotone.fa-teeth-open::after {
  content: '\f62f\f62f'; }

.fad.fa-user-tie-hair-long::after, .fa-duotone.fa-user-tie-hair-long::after {
  content: '\e460\e460'; }

.fad.fa-file-circle-minus::after, .fa-duotone.fa-file-circle-minus::after {
  content: '\e4ed\e4ed'; }

.fad.fa-head-side-medical::after, .fa-duotone.fa-head-side-medical::after {
  content: '\f809\f809'; }

.fad.fa-tags::after, .fa-duotone.fa-tags::after {
  content: '\f02c\f02c'; }

.fad.fa-wine-glass::after, .fa-duotone.fa-wine-glass::after {
  content: '\f4e3\f4e3'; }

.fad.fa-forward-fast::after, .fa-duotone.fa-forward-fast::after {
  content: '\f050\f050'; }

.fad.fa-fast-forward::after, .fa-duotone.fa-fast-forward::after {
  content: '\f050\f050'; }

.fad.fa-face-meh-blank::after, .fa-duotone.fa-face-meh-blank::after {
  content: '\f5a4\f5a4'; }

.fad.fa-meh-blank::after, .fa-duotone.fa-meh-blank::after {
  content: '\f5a4\f5a4'; }

.fad.fa-user-robot::after, .fa-duotone.fa-user-robot::after {
  content: '\e04b\e04b'; }

.fad.fa-square-parking::after, .fa-duotone.fa-square-parking::after {
  content: '\f540\f540'; }

.fad.fa-parking::after, .fa-duotone.fa-parking::after {
  content: '\f540\f540'; }

.fad.fa-card-diamond::after, .fa-duotone.fa-card-diamond::after {
  content: '\e3ea\e3ea'; }

.fad.fa-face-zipper::after, .fa-duotone.fa-face-zipper::after {
  content: '\e3a5\e3a5'; }

.fad.fa-face-raised-eyebrow::after, .fa-duotone.fa-face-raised-eyebrow::after {
  content: '\e388\e388'; }

.fad.fa-house-signal::after, .fa-duotone.fa-house-signal::after {
  content: '\e012\e012'; }

.fad.fa-square-chevron-up::after, .fa-duotone.fa-square-chevron-up::after {
  content: '\f32c\f32c'; }

.fad.fa-chevron-square-up::after, .fa-duotone.fa-chevron-square-up::after {
  content: '\f32c\f32c'; }

.fad.fa-bars-progress::after, .fa-duotone.fa-bars-progress::after {
  content: '\f828\f828'; }

.fad.fa-tasks-alt::after, .fa-duotone.fa-tasks-alt::after {
  content: '\f828\f828'; }

.fad.fa-faucet-drip::after, .fa-duotone.fa-faucet-drip::after {
  content: '\e006\e006'; }

.fad.fa-arrows-to-line::after, .fa-duotone.fa-arrows-to-line::after {
  content: '\e0a7\e0a7'; }

.fad.fa-dolphin::after, .fa-duotone.fa-dolphin::after {
  content: '\e168\e168'; }

.fad.fa-arrow-up-right::after, .fa-duotone.fa-arrow-up-right::after {
  content: '\e09f\e09f'; }

.fad.fa-circle-r::after, .fa-duotone.fa-circle-r::after {
  content: '\e120\e120'; }

.fad.fa-cart-flatbed::after, .fa-duotone.fa-cart-flatbed::after {
  content: '\f474\f474'; }

.fad.fa-dolly-flatbed::after, .fa-duotone.fa-dolly-flatbed::after {
  content: '\f474\f474'; }

.fad.fa-ban-smoking::after, .fa-duotone.fa-ban-smoking::after {
  content: '\f54d\f54d'; }

.fad.fa-smoking-ban::after, .fa-duotone.fa-smoking-ban::after {
  content: '\f54d\f54d'; }

.fad.fa-circle-sort-up::after, .fa-duotone.fa-circle-sort-up::after {
  content: '\e032\e032'; }

.fad.fa-sort-circle-up::after, .fa-duotone.fa-sort-circle-up::after {
  content: '\e032\e032'; }

.fad.fa-terminal::after, .fa-duotone.fa-terminal::after {
  content: '\f120\f120'; }

.fad.fa-mobile-button::after, .fa-duotone.fa-mobile-button::after {
  content: '\f10b\f10b'; }

.fad.fa-house-medical-flag::after, .fa-duotone.fa-house-medical-flag::after {
  content: '\e514\e514'; }

.fad.fa-basket-shopping::after, .fa-duotone.fa-basket-shopping::after {
  content: '\f291\f291'; }

.fad.fa-shopping-basket::after, .fa-duotone.fa-shopping-basket::after {
  content: '\f291\f291'; }

.fad.fa-tape::after, .fa-duotone.fa-tape::after {
  content: '\f4db\f4db'; }

.fad.fa-chestnut::after, .fa-duotone.fa-chestnut::after {
  content: '\e3f6\e3f6'; }

.fad.fa-bus-simple::after, .fa-duotone.fa-bus-simple::after {
  content: '\f55e\f55e'; }

.fad.fa-bus-alt::after, .fa-duotone.fa-bus-alt::after {
  content: '\f55e\f55e'; }

.fad.fa-eye::after, .fa-duotone.fa-eye::after {
  content: '\f06e\f06e'; }

.fad.fa-face-sad-cry::after, .fa-duotone.fa-face-sad-cry::after {
  content: '\f5b3\f5b3'; }

.fad.fa-sad-cry::after, .fa-duotone.fa-sad-cry::after {
  content: '\f5b3\f5b3'; }

.fad.fa-heat::after, .fa-duotone.fa-heat::after {
  content: '\e00c\e00c'; }

.fad.fa-ticket-airline::after, .fa-duotone.fa-ticket-airline::after {
  content: '\e29a\e29a'; }

.fad.fa-boot-heeled::after, .fa-duotone.fa-boot-heeled::after {
  content: '\e33f\e33f'; }

.fad.fa-arrows-minimize::after, .fa-duotone.fa-arrows-minimize::after {
  content: '\e0a5\e0a5'; }

.fad.fa-compress-arrows::after, .fa-duotone.fa-compress-arrows::after {
  content: '\e0a5\e0a5'; }

.fad.fa-audio-description::after, .fa-duotone.fa-audio-description::after {
  content: '\f29e\f29e'; }

.fad.fa-person-military-to-person::after, .fa-duotone.fa-person-military-to-person::after {
  content: '\e54c\e54c'; }

.fad.fa-file-shield::after, .fa-duotone.fa-file-shield::after {
  content: '\e4f0\e4f0'; }

.fad.fa-hexagon::after, .fa-duotone.fa-hexagon::after {
  content: '\f312\f312'; }

.fad.fa-manhole::after, .fa-duotone.fa-manhole::after {
  content: '\e1d6\e1d6'; }

.fad.fa-user-slash::after, .fa-duotone.fa-user-slash::after {
  content: '\f506\f506'; }

.fad.fa-pen::after, .fa-duotone.fa-pen::after {
  content: '\f304\f304'; }

.fad.fa-tower-observation::after, .fa-duotone.fa-tower-observation::after {
  content: '\e586\e586'; }

.fad.fa-floppy-disks::after, .fa-duotone.fa-floppy-disks::after {
  content: '\e183\e183'; }

.fad.fa-toilet-paper-blank-under::after, .fa-duotone.fa-toilet-paper-blank-under::after {
  content: '\e29f\e29f'; }

.fad.fa-toilet-paper-reverse-alt::after, .fa-duotone.fa-toilet-paper-reverse-alt::after {
  content: '\e29f\e29f'; }

.fad.fa-file-code::after, .fa-duotone.fa-file-code::after {
  content: '\f1c9\f1c9'; }

.fad.fa-signal::after, .fa-duotone.fa-signal::after {
  content: '\f012\f012'; }

.fad.fa-signal-5::after, .fa-duotone.fa-signal-5::after {
  content: '\f012\f012'; }

.fad.fa-signal-perfect::after, .fa-duotone.fa-signal-perfect::after {
  content: '\f012\f012'; }

.fad.fa-pump::after, .fa-duotone.fa-pump::after {
  content: '\e442\e442'; }

.fad.fa-bus::after, .fa-duotone.fa-bus::after {
  content: '\f207\f207'; }

.fad.fa-heart-circle-xmark::after, .fa-duotone.fa-heart-circle-xmark::after {
  content: '\e501\e501'; }

.fad.fa-arrow-up-left-from-circle::after, .fa-duotone.fa-arrow-up-left-from-circle::after {
  content: '\e09e\e09e'; }

.fad.fa-house-chimney::after, .fa-duotone.fa-house-chimney::after {
  content: '\e3af\e3af'; }

.fad.fa-home-lg::after, .fa-duotone.fa-home-lg::after {
  content: '\e3af\e3af'; }

.fad.fa-window-maximize::after, .fa-duotone.fa-window-maximize::after {
  content: '\f2d0\f2d0'; }

.fad.fa-dryer::after, .fa-duotone.fa-dryer::after {
  content: '\f861\f861'; }

.fad.fa-face-frown::after, .fa-duotone.fa-face-frown::after {
  content: '\f119\f119'; }

.fad.fa-frown::after, .fa-duotone.fa-frown::after {
  content: '\f119\f119'; }

.fad.fa-chess-bishop-piece::after, .fa-duotone.fa-chess-bishop-piece::after {
  content: '\f43b\f43b'; }

.fad.fa-chess-bishop-alt::after, .fa-duotone.fa-chess-bishop-alt::after {
  content: '\f43b\f43b'; }

.fad.fa-shirt-tank-top::after, .fa-duotone.fa-shirt-tank-top::after {
  content: '\e3c9\e3c9'; }

.fad.fa-diploma::after, .fa-duotone.fa-diploma::after {
  content: '\f5ea\f5ea'; }

.fad.fa-scroll-ribbon::after, .fa-duotone.fa-scroll-ribbon::after {
  content: '\f5ea\f5ea'; }

.fad.fa-screencast::after, .fa-duotone.fa-screencast::after {
  content: '\e23e\e23e'; }

.fad.fa-walker::after, .fa-duotone.fa-walker::after {
  content: '\f831\f831'; }

.fad.fa-prescription::after, .fa-duotone.fa-prescription::after {
  content: '\f5b1\f5b1'; }

.fad.fa-shop::after, .fa-duotone.fa-shop::after {
  content: '\f54f\f54f'; }

.fad.fa-store-alt::after, .fa-duotone.fa-store-alt::after {
  content: '\f54f\f54f'; }

.fad.fa-floppy-disk::after, .fa-duotone.fa-floppy-disk::after {
  content: '\f0c7\f0c7'; }

.fad.fa-save::after, .fa-duotone.fa-save::after {
  content: '\f0c7\f0c7'; }

.fad.fa-vihara::after, .fa-duotone.fa-vihara::after {
  content: '\f6a7\f6a7'; }

.fad.fa-face-kiss-closed-eyes::after, .fa-duotone.fa-face-kiss-closed-eyes::after {
  content: '\e37d\e37d'; }

.fad.fa-scale-unbalanced::after, .fa-duotone.fa-scale-unbalanced::after {
  content: '\f515\f515'; }

.fad.fa-balance-scale-left::after, .fa-duotone.fa-balance-scale-left::after {
  content: '\f515\f515'; }

.fad.fa-file-user::after, .fa-duotone.fa-file-user::after {
  content: '\f65c\f65c'; }

.fad.fa-user-police-tie::after, .fa-duotone.fa-user-police-tie::after {
  content: '\e334\e334'; }

.fad.fa-face-tongue-money::after, .fa-duotone.fa-face-tongue-money::after {
  content: '\e39d\e39d'; }

.fad.fa-tennis-ball::after, .fa-duotone.fa-tennis-ball::after {
  content: '\f45e\f45e'; }

.fad.fa-square-l::after, .fa-duotone.fa-square-l::after {
  content: '\e275\e275'; }

.fad.fa-sort-up::after, .fa-duotone.fa-sort-up::after {
  content: '\f0de\f0de'; }

.fad.fa-sort-asc::after, .fa-duotone.fa-sort-asc::after {
  content: '\f0de\f0de'; }

.fad.fa-calendar-arrow-up::after, .fa-duotone.fa-calendar-arrow-up::after {
  content: '\e0d1\e0d1'; }

.fad.fa-calendar-upload::after, .fa-duotone.fa-calendar-upload::after {
  content: '\e0d1\e0d1'; }

.fad.fa-comment-dots::after, .fa-duotone.fa-comment-dots::after {
  content: '\f4ad\f4ad'; }

.fad.fa-commenting::after, .fa-duotone.fa-commenting::after {
  content: '\f4ad\f4ad'; }

.fad.fa-plant-wilt::after, .fa-duotone.fa-plant-wilt::after {
  content: '\e5aa\e5aa'; }

.fad.fa-scarf::after, .fa-duotone.fa-scarf::after {
  content: '\f7c1\f7c1'; }

.fad.fa-album-circle-plus::after, .fa-duotone.fa-album-circle-plus::after {
  content: '\e48c\e48c'; }

.fad.fa-user-nurse-hair-long::after, .fa-duotone.fa-user-nurse-hair-long::after {
  content: '\e45e\e45e'; }

.fad.fa-diamond::after, .fa-duotone.fa-diamond::after {
  content: '\f219\f219'; }

.fad.fa-square-left::after, .fa-duotone.fa-square-left::after {
  content: '\f351\f351'; }

.fad.fa-arrow-alt-square-left::after, .fa-duotone.fa-arrow-alt-square-left::after {
  content: '\f351\f351'; }

.fad.fa-face-grin-squint::after, .fa-duotone.fa-face-grin-squint::after {
  content: '\f585\f585'; }

.fad.fa-grin-squint::after, .fa-duotone.fa-grin-squint::after {
  content: '\f585\f585'; }

.fad.fa-circle-ellipsis-vertical::after, .fa-duotone.fa-circle-ellipsis-vertical::after {
  content: '\e10b\e10b'; }

.fad.fa-hand-holding-dollar::after, .fa-duotone.fa-hand-holding-dollar::after {
  content: '\f4c0\f4c0'; }

.fad.fa-hand-holding-usd::after, .fa-duotone.fa-hand-holding-usd::after {
  content: '\f4c0\f4c0'; }

.fad.fa-grid-dividers::after, .fa-duotone.fa-grid-dividers::after {
  content: '\e3ad\e3ad'; }

.fad.fa-bacterium::after, .fa-duotone.fa-bacterium::after {
  content: '\e05a\e05a'; }

.fad.fa-hand-pointer::after, .fa-duotone.fa-hand-pointer::after {
  content: '\f25a\f25a'; }

.fad.fa-drum-steelpan::after, .fa-duotone.fa-drum-steelpan::after {
  content: '\f56a\f56a'; }

.fad.fa-hand-scissors::after, .fa-duotone.fa-hand-scissors::after {
  content: '\f257\f257'; }

.fad.fa-hands-praying::after, .fa-duotone.fa-hands-praying::after {
  content: '\f684\f684'; }

.fad.fa-praying-hands::after, .fa-duotone.fa-praying-hands::after {
  content: '\f684\f684'; }

.fad.fa-face-pensive::after, .fa-duotone.fa-face-pensive::after {
  content: '\e384\e384'; }

.fad.fa-user-music::after, .fa-duotone.fa-user-music::after {
  content: '\f8eb\f8eb'; }

.fad.fa-arrow-rotate-right::after, .fa-duotone.fa-arrow-rotate-right::after {
  content: '\f01e\f01e'; }

.fad.fa-arrow-right-rotate::after, .fa-duotone.fa-arrow-right-rotate::after {
  content: '\f01e\f01e'; }

.fad.fa-arrow-rotate-forward::after, .fa-duotone.fa-arrow-rotate-forward::after {
  content: '\f01e\f01e'; }

.fad.fa-redo::after, .fa-duotone.fa-redo::after {
  content: '\f01e\f01e'; }

.fad.fa-messages-dollar::after, .fa-duotone.fa-messages-dollar::after {
  content: '\f652\f652'; }

.fad.fa-comments-alt-dollar::after, .fa-duotone.fa-comments-alt-dollar::after {
  content: '\f652\f652'; }

.fad.fa-sensor-on::after, .fa-duotone.fa-sensor-on::after {
  content: '\e02b\e02b'; }

.fad.fa-balloon::after, .fa-duotone.fa-balloon::after {
  content: '\e2e3\e2e3'; }

.fad.fa-biohazard::after, .fa-duotone.fa-biohazard::after {
  content: '\f780\f780'; }

.fad.fa-chess-queen-piece::after, .fa-duotone.fa-chess-queen-piece::after {
  content: '\f446\f446'; }

.fad.fa-chess-queen-alt::after, .fa-duotone.fa-chess-queen-alt::after {
  content: '\f446\f446'; }

.fad.fa-location-crosshairs::after, .fa-duotone.fa-location-crosshairs::after {
  content: '\f601\f601'; }

.fad.fa-location::after, .fa-duotone.fa-location::after {
  content: '\f601\f601'; }

.fad.fa-mars-double::after, .fa-duotone.fa-mars-double::after {
  content: '\f227\f227'; }

.fad.fa-house-person-leave::after, .fa-duotone.fa-house-person-leave::after {
  content: '\e00f\e00f'; }

.fad.fa-house-leave::after, .fa-duotone.fa-house-leave::after {
  content: '\e00f\e00f'; }

.fad.fa-house-person-depart::after, .fa-duotone.fa-house-person-depart::after {
  content: '\e00f\e00f'; }

.fad.fa-ruler-triangle::after, .fa-duotone.fa-ruler-triangle::after {
  content: '\f61c\f61c'; }

.fad.fa-card-club::after, .fa-duotone.fa-card-club::after {
  content: '\e3e9\e3e9'; }

.fad.fa-child-dress::after, .fa-duotone.fa-child-dress::after {
  content: '\e59c\e59c'; }

.fad.fa-users-between-lines::after, .fa-duotone.fa-users-between-lines::after {
  content: '\e591\e591'; }

.fad.fa-lungs-virus::after, .fa-duotone.fa-lungs-virus::after {
  content: '\e067\e067'; }

.fad.fa-spinner-third::after, .fa-duotone.fa-spinner-third::after {
  content: '\f3f4\f3f4'; }

.fad.fa-face-grin-tears::after, .fa-duotone.fa-face-grin-tears::after {
  content: '\f588\f588'; }

.fad.fa-grin-tears::after, .fa-duotone.fa-grin-tears::after {
  content: '\f588\f588'; }

.fad.fa-phone::after, .fa-duotone.fa-phone::after {
  content: '\f095\f095'; }

.fad.fa-computer-mouse-scrollwheel::after, .fa-duotone.fa-computer-mouse-scrollwheel::after {
  content: '\f8cd\f8cd'; }

.fad.fa-mouse-alt::after, .fa-duotone.fa-mouse-alt::after {
  content: '\f8cd\f8cd'; }

.fad.fa-calendar-xmark::after, .fa-duotone.fa-calendar-xmark::after {
  content: '\f273\f273'; }

.fad.fa-calendar-times::after, .fa-duotone.fa-calendar-times::after {
  content: '\f273\f273'; }

.fad.fa-child-reaching::after, .fa-duotone.fa-child-reaching::after {
  content: '\e59d\e59d'; }

.fad.fa-table-layout::after, .fa-duotone.fa-table-layout::after {
  content: '\e290\e290'; }

.fad.fa-narwhal::after, .fa-duotone.fa-narwhal::after {
  content: '\f6fe\f6fe'; }

.fad.fa-ramp-loading::after, .fa-duotone.fa-ramp-loading::after {
  content: '\f4d4\f4d4'; }

.fad.fa-calendar-circle-plus::after, .fa-duotone.fa-calendar-circle-plus::after {
  content: '\e470\e470'; }

.fad.fa-toothbrush::after, .fa-duotone.fa-toothbrush::after {
  content: '\f635\f635'; }

.fad.fa-border-inner::after, .fa-duotone.fa-border-inner::after {
  content: '\f84e\f84e'; }

.fad.fa-paw-claws::after, .fa-duotone.fa-paw-claws::after {
  content: '\f702\f702'; }

.fad.fa-kiwi-fruit::after, .fa-duotone.fa-kiwi-fruit::after {
  content: '\e30c\e30c'; }

.fad.fa-traffic-light-slow::after, .fa-duotone.fa-traffic-light-slow::after {
  content: '\f639\f639'; }

.fad.fa-rectangle-code::after, .fa-duotone.fa-rectangle-code::after {
  content: '\e322\e322'; }

.fad.fa-head-side-virus::after, .fa-duotone.fa-head-side-virus::after {
  content: '\e064\e064'; }

.fad.fa-keyboard-brightness::after, .fa-duotone.fa-keyboard-brightness::after {
  content: '\e1c0\e1c0'; }

.fad.fa-books-medical::after, .fa-duotone.fa-books-medical::after {
  content: '\f7e8\f7e8'; }

.fad.fa-lightbulb-slash::after, .fa-duotone.fa-lightbulb-slash::after {
  content: '\f673\f673'; }

.fad.fa-house-blank::after, .fa-duotone.fa-house-blank::after {
  content: '\e487\e487'; }

.fad.fa-home-blank::after, .fa-duotone.fa-home-blank::after {
  content: '\e487\e487'; }

.fad.fa-square-5::after, .fa-duotone.fa-square-5::after {
  content: '\e25a\e25a'; }

.fad.fa-square-heart::after, .fa-duotone.fa-square-heart::after {
  content: '\f4c8\f4c8'; }

.fad.fa-heart-square::after, .fa-duotone.fa-heart-square::after {
  content: '\f4c8\f4c8'; }

.fad.fa-puzzle::after, .fa-duotone.fa-puzzle::after {
  content: '\e443\e443'; }

.fad.fa-user-gear::after, .fa-duotone.fa-user-gear::after {
  content: '\f4fe\f4fe'; }

.fad.fa-user-cog::after, .fa-duotone.fa-user-cog::after {
  content: '\f4fe\f4fe'; }

.fad.fa-pipe-circle-check::after, .fa-duotone.fa-pipe-circle-check::after {
  content: '\e436\e436'; }

.fad.fa-arrow-up-1-9::after, .fa-duotone.fa-arrow-up-1-9::after {
  content: '\f163\f163'; }

.fad.fa-sort-numeric-up::after, .fa-duotone.fa-sort-numeric-up::after {
  content: '\f163\f163'; }

.fad.fa-octagon-exclamation::after, .fa-duotone.fa-octagon-exclamation::after {
  content: '\e204\e204'; }

.fad.fa-dial-low::after, .fa-duotone.fa-dial-low::after {
  content: '\e15d\e15d'; }

.fad.fa-door-closed::after, .fa-duotone.fa-door-closed::after {
  content: '\f52a\f52a'; }

.fad.fa-laptop-mobile::after, .fa-duotone.fa-laptop-mobile::after {
  content: '\f87a\f87a'; }

.fad.fa-phone-laptop::after, .fa-duotone.fa-phone-laptop::after {
  content: '\f87a\f87a'; }

.fad.fa-conveyor-belt-boxes::after, .fa-duotone.fa-conveyor-belt-boxes::after {
  content: '\f46f\f46f'; }

.fad.fa-conveyor-belt-alt::after, .fa-duotone.fa-conveyor-belt-alt::after {
  content: '\f46f\f46f'; }

.fad.fa-shield-virus::after, .fa-duotone.fa-shield-virus::after {
  content: '\e06c\e06c'; }

.fad.fa-starfighter-twin-ion-engine-advanced::after, .fa-duotone.fa-starfighter-twin-ion-engine-advanced::after {
  content: '\e28e\e28e'; }

.fad.fa-starfighter-alt-advanced::after, .fa-duotone.fa-starfighter-alt-advanced::after {
  content: '\e28e\e28e'; }

.fad.fa-dice-six::after, .fa-duotone.fa-dice-six::after {
  content: '\f526\f526'; }

.fad.fa-starfighter-twin-ion-engine::after, .fa-duotone.fa-starfighter-twin-ion-engine::after {
  content: '\e038\e038'; }

.fad.fa-starfighter-alt::after, .fa-duotone.fa-starfighter-alt::after {
  content: '\e038\e038'; }

.fad.fa-rocket-launch::after, .fa-duotone.fa-rocket-launch::after {
  content: '\e027\e027'; }

.fad.fa-mosquito-net::after, .fa-duotone.fa-mosquito-net::after {
  content: '\e52c\e52c'; }

.fad.fa-vent-damper::after, .fa-duotone.fa-vent-damper::after {
  content: '\e465\e465'; }

.fad.fa-bridge-water::after, .fa-duotone.fa-bridge-water::after {
  content: '\e4ce\e4ce'; }

.fad.fa-ban-bug::after, .fa-duotone.fa-ban-bug::after {
  content: '\f7f9\f7f9'; }

.fad.fa-debug::after, .fa-duotone.fa-debug::after {
  content: '\f7f9\f7f9'; }

.fad.fa-person-booth::after, .fa-duotone.fa-person-booth::after {
  content: '\f756\f756'; }

.fad.fa-text-width::after, .fa-duotone.fa-text-width::after {
  content: '\f035\f035'; }

.fad.fa-garage-car::after, .fa-duotone.fa-garage-car::after {
  content: '\e00a\e00a'; }

.fad.fa-square-kanban::after, .fa-duotone.fa-square-kanban::after {
  content: '\e488\e488'; }

.fad.fa-hat-wizard::after, .fa-duotone.fa-hat-wizard::after {
  content: '\f6e8\f6e8'; }

.fad.fa-pen-fancy::after, .fa-duotone.fa-pen-fancy::after {
  content: '\f5ac\f5ac'; }

.fad.fa-coffee-pot::after, .fa-duotone.fa-coffee-pot::after {
  content: '\e002\e002'; }

.fad.fa-mouse-field::after, .fa-duotone.fa-mouse-field::after {
  content: '\e5a8\e5a8'; }

.fad.fa-person-digging::after, .fa-duotone.fa-person-digging::after {
  content: '\f85e\f85e'; }

.fad.fa-digging::after, .fa-duotone.fa-digging::after {
  content: '\f85e\f85e'; }

.fad.fa-shower-down::after, .fa-duotone.fa-shower-down::after {
  content: '\e24d\e24d'; }

.fad.fa-shower-alt::after, .fa-duotone.fa-shower-alt::after {
  content: '\e24d\e24d'; }

.fad.fa-box-circle-check::after, .fa-duotone.fa-box-circle-check::after {
  content: '\e0c4\e0c4'; }

.fad.fa-brightness::after, .fa-duotone.fa-brightness::after {
  content: '\e0c9\e0c9'; }

.fad.fa-car-side-bolt::after, .fa-duotone.fa-car-side-bolt::after {
  content: '\e344\e344'; }

.fad.fa-ornament::after, .fa-duotone.fa-ornament::after {
  content: '\f7b8\f7b8'; }

.fad.fa-phone-arrow-down-left::after, .fa-duotone.fa-phone-arrow-down-left::after {
  content: '\e223\e223'; }

.fad.fa-phone-arrow-down::after, .fa-duotone.fa-phone-arrow-down::after {
  content: '\e223\e223'; }

.fad.fa-phone-incoming::after, .fa-duotone.fa-phone-incoming::after {
  content: '\e223\e223'; }

.fad.fa-cloud-word::after, .fa-duotone.fa-cloud-word::after {
  content: '\e138\e138'; }

.fad.fa-hand-fingers-crossed::after, .fa-duotone.fa-hand-fingers-crossed::after {
  content: '\e1a3\e1a3'; }

.fad.fa-trash::after, .fa-duotone.fa-trash::after {
  content: '\f1f8\f1f8'; }

.fad.fa-gauge-simple::after, .fa-duotone.fa-gauge-simple::after {
  content: '\f629\f629'; }

.fad.fa-gauge-simple-med::after, .fa-duotone.fa-gauge-simple-med::after {
  content: '\f629\f629'; }

.fad.fa-tachometer-average::after, .fa-duotone.fa-tachometer-average::after {
  content: '\f629\f629'; }

.fad.fa-arrow-down-small-big::after, .fa-duotone.fa-arrow-down-small-big::after {
  content: '\f88d\f88d'; }

.fad.fa-sort-size-down-alt::after, .fa-duotone.fa-sort-size-down-alt::after {
  content: '\f88d\f88d'; }

.fad.fa-book-medical::after, .fa-duotone.fa-book-medical::after {
  content: '\f7e6\f7e6'; }

.fad.fa-face-melting::after, .fa-duotone.fa-face-melting::after {
  content: '\e483\e483'; }

.fad.fa-poo::after, .fa-duotone.fa-poo::after {
  content: '\f2fe\f2fe'; }

.fad.fa-pen-clip-slash::after, .fa-duotone.fa-pen-clip-slash::after {
  content: '\e20f\e20f'; }

.fad.fa-pen-alt-slash::after, .fa-duotone.fa-pen-alt-slash::after {
  content: '\e20f\e20f'; }

.fad.fa-quote-right::after, .fa-duotone.fa-quote-right::after {
  content: '\f10e\f10e'; }

.fad.fa-quote-right-alt::after, .fa-duotone.fa-quote-right-alt::after {
  content: '\f10e\f10e'; }

.fad.fa-scroll-old::after, .fa-duotone.fa-scroll-old::after {
  content: '\f70f\f70f'; }

.fad.fa-guitars::after, .fa-duotone.fa-guitars::after {
  content: '\f8bf\f8bf'; }

.fad.fa-phone-xmark::after, .fa-duotone.fa-phone-xmark::after {
  content: '\e227\e227'; }

.fad.fa-hose::after, .fa-duotone.fa-hose::after {
  content: '\e419\e419'; }

.fad.fa-clock-six::after, .fa-duotone.fa-clock-six::after {
  content: '\e352\e352'; }

.fad.fa-shirt::after, .fa-duotone.fa-shirt::after {
  content: '\f553\f553'; }

.fad.fa-t-shirt::after, .fa-duotone.fa-t-shirt::after {
  content: '\f553\f553'; }

.fad.fa-tshirt::after, .fa-duotone.fa-tshirt::after {
  content: '\f553\f553'; }

.fad.fa-billboard::after, .fa-duotone.fa-billboard::after {
  content: '\e5cd\e5cd'; }

.fad.fa-square-r::after, .fa-duotone.fa-square-r::after {
  content: '\e27c\e27c'; }

.fad.fa-cubes::after, .fa-duotone.fa-cubes::after {
  content: '\f1b3\f1b3'; }

.fad.fa-envelope-open-dollar::after, .fa-duotone.fa-envelope-open-dollar::after {
  content: '\f657\f657'; }

.fad.fa-divide::after, .fa-duotone.fa-divide::after {
  content: '\f529\f529'; }

.fad.fa-sun-cloud::after, .fa-duotone.fa-sun-cloud::after {
  content: '\f763\f763'; }

.fad.fa-lamp-floor::after, .fa-duotone.fa-lamp-floor::after {
  content: '\e015\e015'; }

.fad.fa-square-7::after, .fa-duotone.fa-square-7::after {
  content: '\e25c\e25c'; }

.fad.fa-tenge-sign::after, .fa-duotone.fa-tenge-sign::after {
  content: '\f7d7\f7d7'; }

.fad.fa-tenge::after, .fa-duotone.fa-tenge::after {
  content: '\f7d7\f7d7'; }

.fad.fa-headphones::after, .fa-duotone.fa-headphones::after {
  content: '\f025\f025'; }

.fad.fa-hands-holding::after, .fa-duotone.fa-hands-holding::after {
  content: '\f4c2\f4c2'; }

.fad.fa-campfire::after, .fa-duotone.fa-campfire::after {
  content: '\f6ba\f6ba'; }

.fad.fa-circle-ampersand::after, .fa-duotone.fa-circle-ampersand::after {
  content: '\e0f8\e0f8'; }

.fad.fa-snowflakes::after, .fa-duotone.fa-snowflakes::after {
  content: '\f7cf\f7cf'; }

.fad.fa-hands-clapping::after, .fa-duotone.fa-hands-clapping::after {
  content: '\e1a8\e1a8'; }

.fad.fa-republican::after, .fa-duotone.fa-republican::after {
  content: '\f75e\f75e'; }

.fad.fa-leaf-maple::after, .fa-duotone.fa-leaf-maple::after {
  content: '\f6f6\f6f6'; }

.fad.fa-arrow-left::after, .fa-duotone.fa-arrow-left::after {
  content: '\f060\f060'; }

.fad.fa-person-circle-xmark::after, .fa-duotone.fa-person-circle-xmark::after {
  content: '\e543\e543'; }

.fad.fa-ruler::after, .fa-duotone.fa-ruler::after {
  content: '\f545\f545'; }

.fad.fa-cup-straw-swoosh::after, .fa-duotone.fa-cup-straw-swoosh::after {
  content: '\e364\e364'; }

.fad.fa-temperature-sun::after, .fa-duotone.fa-temperature-sun::after {
  content: '\f76a\f76a'; }

.fad.fa-temperature-hot::after, .fa-duotone.fa-temperature-hot::after {
  content: '\f76a\f76a'; }

.fad.fa-align-left::after, .fa-duotone.fa-align-left::after {
  content: '\f036\f036'; }

.fad.fa-dice-d6::after, .fa-duotone.fa-dice-d6::after {
  content: '\f6d1\f6d1'; }

.fad.fa-restroom::after, .fa-duotone.fa-restroom::after {
  content: '\f7bd\f7bd'; }

.fad.fa-high-definition::after, .fa-duotone.fa-high-definition::after {
  content: '\e1ae\e1ae'; }

.fad.fa-rectangle-hd::after, .fa-duotone.fa-rectangle-hd::after {
  content: '\e1ae\e1ae'; }

.fad.fa-j::after, .fa-duotone.fa-j::after {
  content: '\4a\4a'; }

.fad.fa-galaxy::after, .fa-duotone.fa-galaxy::after {
  content: '\e008\e008'; }

.fad.fa-users-viewfinder::after, .fa-duotone.fa-users-viewfinder::after {
  content: '\e595\e595'; }

.fad.fa-file-video::after, .fa-duotone.fa-file-video::after {
  content: '\f1c8\f1c8'; }

.fad.fa-cherries::after, .fa-duotone.fa-cherries::after {
  content: '\e0ec\e0ec'; }

.fad.fa-up-right-from-square::after, .fa-duotone.fa-up-right-from-square::after {
  content: '\f35d\f35d'; }

.fad.fa-external-link-alt::after, .fa-duotone.fa-external-link-alt::after {
  content: '\f35d\f35d'; }

.fad.fa-circle-sort::after, .fa-duotone.fa-circle-sort::after {
  content: '\e030\e030'; }

.fad.fa-sort-circle::after, .fa-duotone.fa-sort-circle::after {
  content: '\e030\e030'; }

.fad.fa-table-cells::after, .fa-duotone.fa-table-cells::after {
  content: '\f00a\f00a'; }

.fad.fa-th::after, .fa-duotone.fa-th::after {
  content: '\f00a\f00a'; }

.fad.fa-file-pdf::after, .fa-duotone.fa-file-pdf::after {
  content: '\f1c1\f1c1'; }

.fad.fa-siren::after, .fa-duotone.fa-siren::after {
  content: '\e02d\e02d'; }

.fad.fa-arrow-up-to-dotted-line::after, .fa-duotone.fa-arrow-up-to-dotted-line::after {
  content: '\e0a1\e0a1'; }

.fad.fa-image-landscape::after, .fa-duotone.fa-image-landscape::after {
  content: '\e1b5\e1b5'; }

.fad.fa-landscape::after, .fa-duotone.fa-landscape::after {
  content: '\e1b5\e1b5'; }

.fad.fa-tank-water::after, .fa-duotone.fa-tank-water::after {
  content: '\e452\e452'; }

.fad.fa-curling-stone::after, .fa-duotone.fa-curling-stone::after {
  content: '\f44a\f44a'; }

.fad.fa-curling::after, .fa-duotone.fa-curling::after {
  content: '\f44a\f44a'; }

.fad.fa-gamepad-modern::after, .fa-duotone.fa-gamepad-modern::after {
  content: '\e5a2\e5a2'; }

.fad.fa-gamepad-alt::after, .fa-duotone.fa-gamepad-alt::after {
  content: '\e5a2\e5a2'; }

.fad.fa-messages-question::after, .fa-duotone.fa-messages-question::after {
  content: '\e1e7\e1e7'; }

.fad.fa-book-bible::after, .fa-duotone.fa-book-bible::after {
  content: '\f647\f647'; }

.fad.fa-bible::after, .fa-duotone.fa-bible::after {
  content: '\f647\f647'; }

.fad.fa-o::after, .fa-duotone.fa-o::after {
  content: '\4f\4f'; }

.fad.fa-suitcase-medical::after, .fa-duotone.fa-suitcase-medical::after {
  content: '\f0fa\f0fa'; }

.fad.fa-medkit::after, .fa-duotone.fa-medkit::after {
  content: '\f0fa\f0fa'; }

.fad.fa-briefcase-arrow-right::after, .fa-duotone.fa-briefcase-arrow-right::after {
  content: '\e2f2\e2f2'; }

.fad.fa-expand-wide::after, .fa-duotone.fa-expand-wide::after {
  content: '\f320\f320'; }

.fad.fa-clock-eleven-thirty::after, .fa-duotone.fa-clock-eleven-thirty::after {
  content: '\e348\e348'; }

.fad.fa-rv::after, .fa-duotone.fa-rv::after {
  content: '\f7be\f7be'; }

.fad.fa-user-secret::after, .fa-duotone.fa-user-secret::after {
  content: '\f21b\f21b'; }

.fad.fa-otter::after, .fa-duotone.fa-otter::after {
  content: '\f700\f700'; }

.fad.fa-dreidel::after, .fa-duotone.fa-dreidel::after {
  content: '\f792\f792'; }

.fad.fa-person-dress::after, .fa-duotone.fa-person-dress::after {
  content: '\f182\f182'; }

.fad.fa-female::after, .fa-duotone.fa-female::after {
  content: '\f182\f182'; }

.fad.fa-comment-dollar::after, .fa-duotone.fa-comment-dollar::after {
  content: '\f651\f651'; }

.fad.fa-business-time::after, .fa-duotone.fa-business-time::after {
  content: '\f64a\f64a'; }

.fad.fa-briefcase-clock::after, .fa-duotone.fa-briefcase-clock::after {
  content: '\f64a\f64a'; }

.fad.fa-flower-tulip::after, .fa-duotone.fa-flower-tulip::after {
  content: '\f801\f801'; }

.fad.fa-people-pants-simple::after, .fa-duotone.fa-people-pants-simple::after {
  content: '\e21a\e21a'; }

.fad.fa-cloud-drizzle::after, .fa-duotone.fa-cloud-drizzle::after {
  content: '\f738\f738'; }

.fad.fa-table-cells-large::after, .fa-duotone.fa-table-cells-large::after {
  content: '\f009\f009'; }

.fad.fa-th-large::after, .fa-duotone.fa-th-large::after {
  content: '\f009\f009'; }

.fad.fa-book-tanakh::after, .fa-duotone.fa-book-tanakh::after {
  content: '\f827\f827'; }

.fad.fa-tanakh::after, .fa-duotone.fa-tanakh::after {
  content: '\f827\f827'; }

.fad.fa-solar-system::after, .fa-duotone.fa-solar-system::after {
  content: '\e02f\e02f'; }

.fad.fa-seal-question::after, .fa-duotone.fa-seal-question::after {
  content: '\e243\e243'; }

.fad.fa-phone-volume::after, .fa-duotone.fa-phone-volume::after {
  content: '\f2a0\f2a0'; }

.fad.fa-volume-control-phone::after, .fa-duotone.fa-volume-control-phone::after {
  content: '\f2a0\f2a0'; }

.fad.fa-disc-drive::after, .fa-duotone.fa-disc-drive::after {
  content: '\f8b5\f8b5'; }

.fad.fa-hat-cowboy-side::after, .fa-duotone.fa-hat-cowboy-side::after {
  content: '\f8c1\f8c1'; }

.fad.fa-table-rows::after, .fa-duotone.fa-table-rows::after {
  content: '\e292\e292'; }

.fad.fa-rows::after, .fa-duotone.fa-rows::after {
  content: '\e292\e292'; }

.fad.fa-location-exclamation::after, .fa-duotone.fa-location-exclamation::after {
  content: '\f608\f608'; }

.fad.fa-map-marker-exclamation::after, .fa-duotone.fa-map-marker-exclamation::after {
  content: '\f608\f608'; }

.fad.fa-face-fearful::after, .fa-duotone.fa-face-fearful::after {
  content: '\e375\e375'; }

.fad.fa-clipboard-user::after, .fa-duotone.fa-clipboard-user::after {
  content: '\f7f3\f7f3'; }

.fad.fa-bus-school::after, .fa-duotone.fa-bus-school::after {
  content: '\f5dd\f5dd'; }

.fad.fa-film-slash::after, .fa-duotone.fa-film-slash::after {
  content: '\e179\e179'; }

.fad.fa-square-arrow-down-right::after, .fa-duotone.fa-square-arrow-down-right::after {
  content: '\e262\e262'; }

.fad.fa-book-sparkles::after, .fa-duotone.fa-book-sparkles::after {
  content: '\f6b8\f6b8'; }

.fad.fa-book-spells::after, .fa-duotone.fa-book-spells::after {
  content: '\f6b8\f6b8'; }

.fad.fa-washing-machine::after, .fa-duotone.fa-washing-machine::after {
  content: '\f898\f898'; }

.fad.fa-washer::after, .fa-duotone.fa-washer::after {
  content: '\f898\f898'; }

.fad.fa-child::after, .fa-duotone.fa-child::after {
  content: '\f1ae\f1ae'; }

.fad.fa-lira-sign::after, .fa-duotone.fa-lira-sign::after {
  content: '\f195\f195'; }

.fad.fa-user-visor::after, .fa-duotone.fa-user-visor::after {
  content: '\e04c\e04c'; }

.fad.fa-file-plus-minus::after, .fa-duotone.fa-file-plus-minus::after {
  content: '\e177\e177'; }

.fad.fa-chess-clock-flip::after, .fa-duotone.fa-chess-clock-flip::after {
  content: '\f43e\f43e'; }

.fad.fa-chess-clock-alt::after, .fa-duotone.fa-chess-clock-alt::after {
  content: '\f43e\f43e'; }

.fad.fa-satellite::after, .fa-duotone.fa-satellite::after {
  content: '\f7bf\f7bf'; }

.fad.fa-plane-lock::after, .fa-duotone.fa-plane-lock::after {
  content: '\e558\e558'; }

.fad.fa-steering-wheel::after, .fa-duotone.fa-steering-wheel::after {
  content: '\f622\f622'; }

.fad.fa-tag::after, .fa-duotone.fa-tag::after {
  content: '\f02b\f02b'; }

.fad.fa-stretcher::after, .fa-duotone.fa-stretcher::after {
  content: '\f825\f825'; }

.fad.fa-book-section::after, .fa-duotone.fa-book-section::after {
  content: '\e0c1\e0c1'; }

.fad.fa-book-law::after, .fa-duotone.fa-book-law::after {
  content: '\e0c1\e0c1'; }

.fad.fa-inboxes::after, .fa-duotone.fa-inboxes::after {
  content: '\e1bb\e1bb'; }

.fad.fa-coffee-bean::after, .fa-duotone.fa-coffee-bean::after {
  content: '\e13e\e13e'; }

.fad.fa-circle-yen::after, .fa-duotone.fa-circle-yen::after {
  content: '\e5d0\e5d0'; }

.fad.fa-brackets-curly::after, .fa-duotone.fa-brackets-curly::after {
  content: '\f7ea\f7ea'; }

.fad.fa-ellipsis-stroke-vertical::after, .fa-duotone.fa-ellipsis-stroke-vertical::after {
  content: '\f39c\f39c'; }

.fad.fa-ellipsis-v-alt::after, .fa-duotone.fa-ellipsis-v-alt::after {
  content: '\f39c\f39c'; }

.fad.fa-comment::after, .fa-duotone.fa-comment::after {
  content: '\f075\f075'; }

.fad.fa-square-1::after, .fa-duotone.fa-square-1::after {
  content: '\e256\e256'; }

.fad.fa-cake-candles::after, .fa-duotone.fa-cake-candles::after {
  content: '\f1fd\f1fd'; }

.fad.fa-birthday-cake::after, .fa-duotone.fa-birthday-cake::after {
  content: '\f1fd\f1fd'; }

.fad.fa-cake::after, .fa-duotone.fa-cake::after {
  content: '\f1fd\f1fd'; }

.fad.fa-head-side::after, .fa-duotone.fa-head-side::after {
  content: '\f6e9\f6e9'; }

.fad.fa-envelope::after, .fa-duotone.fa-envelope::after {
  content: '\f0e0\f0e0'; }

.fad.fa-dolly-empty::after, .fa-duotone.fa-dolly-empty::after {
  content: '\f473\f473'; }

.fad.fa-face-tissue::after, .fa-duotone.fa-face-tissue::after {
  content: '\e39c\e39c'; }

.fad.fa-angles-up::after, .fa-duotone.fa-angles-up::after {
  content: '\f102\f102'; }

.fad.fa-angle-double-up::after, .fa-duotone.fa-angle-double-up::after {
  content: '\f102\f102'; }

.fad.fa-bin-recycle::after, .fa-duotone.fa-bin-recycle::after {
  content: '\e5f7\e5f7'; }

.fad.fa-paperclip::after, .fa-duotone.fa-paperclip::after {
  content: '\f0c6\f0c6'; }

.fad.fa-chart-line-down::after, .fa-duotone.fa-chart-line-down::after {
  content: '\f64d\f64d'; }

.fad.fa-arrow-right-to-city::after, .fa-duotone.fa-arrow-right-to-city::after {
  content: '\e4b3\e4b3'; }

.fad.fa-lock-a::after, .fa-duotone.fa-lock-a::after {
  content: '\e422\e422'; }

.fad.fa-ribbon::after, .fa-duotone.fa-ribbon::after {
  content: '\f4d6\f4d6'; }

.fad.fa-lungs::after, .fa-duotone.fa-lungs::after {
  content: '\f604\f604'; }

.fad.fa-person-pinball::after, .fa-duotone.fa-person-pinball::after {
  content: '\e21d\e21d'; }

.fad.fa-arrow-up-9-1::after, .fa-duotone.fa-arrow-up-9-1::after {
  content: '\f887\f887'; }

.fad.fa-sort-numeric-up-alt::after, .fa-duotone.fa-sort-numeric-up-alt::after {
  content: '\f887\f887'; }

.fad.fa-apple-core::after, .fa-duotone.fa-apple-core::after {
  content: '\e08f\e08f'; }

.fad.fa-circle-y::after, .fa-duotone.fa-circle-y::after {
  content: '\e12f\e12f'; }

.fad.fa-h6::after, .fa-duotone.fa-h6::after {
  content: '\e413\e413'; }

.fad.fa-litecoin-sign::after, .fa-duotone.fa-litecoin-sign::after {
  content: '\e1d3\e1d3'; }

.fad.fa-circle-small::after, .fa-duotone.fa-circle-small::after {
  content: '\e122\e122'; }

.fad.fa-border-none::after, .fa-duotone.fa-border-none::after {
  content: '\f850\f850'; }

.fad.fa-arrow-turn-down-left::after, .fa-duotone.fa-arrow-turn-down-left::after {
  content: '\e2e1\e2e1'; }

.fad.fa-circle-nodes::after, .fa-duotone.fa-circle-nodes::after {
  content: '\e4e2\e4e2'; }

.fad.fa-parachute-box::after, .fa-duotone.fa-parachute-box::after {
  content: '\f4cd\f4cd'; }

.fad.fa-message-medical::after, .fa-duotone.fa-message-medical::after {
  content: '\f7f4\f7f4'; }

.fad.fa-comment-alt-medical::after, .fa-duotone.fa-comment-alt-medical::after {
  content: '\f7f4\f7f4'; }

.fad.fa-rugby-ball::after, .fa-duotone.fa-rugby-ball::after {
  content: '\e3c6\e3c6'; }

.fad.fa-comment-music::after, .fa-duotone.fa-comment-music::after {
  content: '\f8b0\f8b0'; }

.fad.fa-indent::after, .fa-duotone.fa-indent::after {
  content: '\f03c\f03c'; }

.fad.fa-tree-deciduous::after, .fa-duotone.fa-tree-deciduous::after {
  content: '\f400\f400'; }

.fad.fa-tree-alt::after, .fa-duotone.fa-tree-alt::after {
  content: '\f400\f400'; }

.fad.fa-puzzle-piece-simple::after, .fa-duotone.fa-puzzle-piece-simple::after {
  content: '\e231\e231'; }

.fad.fa-puzzle-piece-alt::after, .fa-duotone.fa-puzzle-piece-alt::after {
  content: '\e231\e231'; }

.fad.fa-truck-field-un::after, .fa-duotone.fa-truck-field-un::after {
  content: '\e58e\e58e'; }

.fad.fa-nfc-trash::after, .fa-duotone.fa-nfc-trash::after {
  content: '\e1fd\e1fd'; }

.fad.fa-hourglass::after, .fa-duotone.fa-hourglass::after {
  content: '\f254\f254'; }

.fad.fa-hourglass-empty::after, .fa-duotone.fa-hourglass-empty::after {
  content: '\f254\f254'; }

.fad.fa-mountain::after, .fa-duotone.fa-mountain::after {
  content: '\f6fc\f6fc'; }

.fad.fa-file-xmark::after, .fa-duotone.fa-file-xmark::after {
  content: '\f317\f317'; }

.fad.fa-file-times::after, .fa-duotone.fa-file-times::after {
  content: '\f317\f317'; }

.fad.fa-house-heart::after, .fa-duotone.fa-house-heart::after {
  content: '\f4c9\f4c9'; }

.fad.fa-home-heart::after, .fa-duotone.fa-home-heart::after {
  content: '\f4c9\f4c9'; }

.fad.fa-house-chimney-blank::after, .fa-duotone.fa-house-chimney-blank::after {
  content: '\e3b0\e3b0'; }

.fad.fa-meter-bolt::after, .fa-duotone.fa-meter-bolt::after {
  content: '\e1e9\e1e9'; }

.fad.fa-user-doctor::after, .fa-duotone.fa-user-doctor::after {
  content: '\f0f0\f0f0'; }

.fad.fa-user-md::after, .fa-duotone.fa-user-md::after {
  content: '\f0f0\f0f0'; }

.fad.fa-slash-back::after, .fa-duotone.fa-slash-back::after {
  content: '\5c\5c'; }

.fad.fa-circle-info::after, .fa-duotone.fa-circle-info::after {
  content: '\f05a\f05a'; }

.fad.fa-info-circle::after, .fa-duotone.fa-info-circle::after {
  content: '\f05a\f05a'; }

.fad.fa-fishing-rod::after, .fa-duotone.fa-fishing-rod::after {
  content: '\e3a8\e3a8'; }

.fad.fa-hammer-crash::after, .fa-duotone.fa-hammer-crash::after {
  content: '\e414\e414'; }

.fad.fa-message-heart::after, .fa-duotone.fa-message-heart::after {
  content: '\e5c9\e5c9'; }

.fad.fa-cloud-meatball::after, .fa-duotone.fa-cloud-meatball::after {
  content: '\f73b\f73b'; }

.fad.fa-camera-polaroid::after, .fa-duotone.fa-camera-polaroid::after {
  content: '\f8aa\f8aa'; }

.fad.fa-camera::after, .fa-duotone.fa-camera::after {
  content: '\f030\f030'; }

.fad.fa-camera-alt::after, .fa-duotone.fa-camera-alt::after {
  content: '\f030\f030'; }

.fad.fa-square-virus::after, .fa-duotone.fa-square-virus::after {
  content: '\e578\e578'; }

.fad.fa-cart-arrow-up::after, .fa-duotone.fa-cart-arrow-up::after {
  content: '\e3ee\e3ee'; }

.fad.fa-meteor::after, .fa-duotone.fa-meteor::after {
  content: '\f753\f753'; }

.fad.fa-car-on::after, .fa-duotone.fa-car-on::after {
  content: '\e4dd\e4dd'; }

.fad.fa-sleigh::after, .fa-duotone.fa-sleigh::after {
  content: '\f7cc\f7cc'; }

.fad.fa-arrow-down-1-9::after, .fa-duotone.fa-arrow-down-1-9::after {
  content: '\f162\f162'; }

.fad.fa-sort-numeric-asc::after, .fa-duotone.fa-sort-numeric-asc::after {
  content: '\f162\f162'; }

.fad.fa-sort-numeric-down::after, .fa-duotone.fa-sort-numeric-down::after {
  content: '\f162\f162'; }

.fad.fa-buoy-mooring::after, .fa-duotone.fa-buoy-mooring::after {
  content: '\e5b6\e5b6'; }

.fad.fa-square-4::after, .fa-duotone.fa-square-4::after {
  content: '\e259\e259'; }

.fad.fa-hand-holding-droplet::after, .fa-duotone.fa-hand-holding-droplet::after {
  content: '\f4c1\f4c1'; }

.fad.fa-hand-holding-water::after, .fa-duotone.fa-hand-holding-water::after {
  content: '\f4c1\f4c1'; }

.fad.fa-tricycle-adult::after, .fa-duotone.fa-tricycle-adult::after {
  content: '\e5c4\e5c4'; }

.fad.fa-waveform::after, .fa-duotone.fa-waveform::after {
  content: '\f8f1\f8f1'; }

.fad.fa-water::after, .fa-duotone.fa-water::after {
  content: '\f773\f773'; }

.fad.fa-star-sharp-half-stroke::after, .fa-duotone.fa-star-sharp-half-stroke::after {
  content: '\e28d\e28d'; }

.fad.fa-star-sharp-half-alt::after, .fa-duotone.fa-star-sharp-half-alt::after {
  content: '\e28d\e28d'; }

.fad.fa-nfc-signal::after, .fa-duotone.fa-nfc-signal::after {
  content: '\e1fb\e1fb'; }

.fad.fa-plane-prop::after, .fa-duotone.fa-plane-prop::after {
  content: '\e22b\e22b'; }

.fad.fa-calendar-check::after, .fa-duotone.fa-calendar-check::after {
  content: '\f274\f274'; }

.fad.fa-clock-desk::after, .fa-duotone.fa-clock-desk::after {
  content: '\e134\e134'; }

.fad.fa-calendar-clock::after, .fa-duotone.fa-calendar-clock::after {
  content: '\e0d2\e0d2'; }

.fad.fa-calendar-time::after, .fa-duotone.fa-calendar-time::after {
  content: '\e0d2\e0d2'; }

.fad.fa-braille::after, .fa-duotone.fa-braille::after {
  content: '\f2a1\f2a1'; }

.fad.fa-prescription-bottle-medical::after, .fa-duotone.fa-prescription-bottle-medical::after {
  content: '\f486\f486'; }

.fad.fa-prescription-bottle-alt::after, .fa-duotone.fa-prescription-bottle-alt::after {
  content: '\f486\f486'; }

.fad.fa-plate-utensils::after, .fa-duotone.fa-plate-utensils::after {
  content: '\e43b\e43b'; }

.fad.fa-family-pants::after, .fa-duotone.fa-family-pants::after {
  content: '\e302\e302'; }

.fad.fa-hose-reel::after, .fa-duotone.fa-hose-reel::after {
  content: '\e41a\e41a'; }

.fad.fa-house-window::after, .fa-duotone.fa-house-window::after {
  content: '\e3b3\e3b3'; }

.fad.fa-landmark::after, .fa-duotone.fa-landmark::after {
  content: '\f66f\f66f'; }

.fad.fa-truck::after, .fa-duotone.fa-truck::after {
  content: '\f0d1\f0d1'; }

.fad.fa-crosshairs::after, .fa-duotone.fa-crosshairs::after {
  content: '\f05b\f05b'; }

.fad.fa-cloud-rainbow::after, .fa-duotone.fa-cloud-rainbow::after {
  content: '\f73e\f73e'; }

.fad.fa-person-cane::after, .fa-duotone.fa-person-cane::after {
  content: '\e53c\e53c'; }

.fad.fa-alien::after, .fa-duotone.fa-alien::after {
  content: '\f8f5\f8f5'; }

.fad.fa-tent::after, .fa-duotone.fa-tent::after {
  content: '\e57d\e57d'; }

.fad.fa-laptop-binary::after, .fa-duotone.fa-laptop-binary::after {
  content: '\e5e7\e5e7'; }

.fad.fa-vest-patches::after, .fa-duotone.fa-vest-patches::after {
  content: '\e086\e086'; }

.fad.fa-people-dress-simple::after, .fa-duotone.fa-people-dress-simple::after {
  content: '\e218\e218'; }

.fad.fa-check-double::after, .fa-duotone.fa-check-double::after {
  content: '\f560\f560'; }

.fad.fa-arrow-down-a-z::after, .fa-duotone.fa-arrow-down-a-z::after {
  content: '\f15d\f15d'; }

.fad.fa-sort-alpha-asc::after, .fa-duotone.fa-sort-alpha-asc::after {
  content: '\f15d\f15d'; }

.fad.fa-sort-alpha-down::after, .fa-duotone.fa-sort-alpha-down::after {
  content: '\f15d\f15d'; }

.fad.fa-bowling-ball-pin::after, .fa-duotone.fa-bowling-ball-pin::after {
  content: '\e0c3\e0c3'; }

.fad.fa-bell-school-slash::after, .fa-duotone.fa-bell-school-slash::after {
  content: '\f5d6\f5d6'; }

.fad.fa-plus-large::after, .fa-duotone.fa-plus-large::after {
  content: '\e59e\e59e'; }

.fad.fa-money-bill-wheat::after, .fa-duotone.fa-money-bill-wheat::after {
  content: '\e52a\e52a'; }

.fad.fa-camera-viewfinder::after, .fa-duotone.fa-camera-viewfinder::after {
  content: '\e0da\e0da'; }

.fad.fa-screenshot::after, .fa-duotone.fa-screenshot::after {
  content: '\e0da\e0da'; }

.fad.fa-message-music::after, .fa-duotone.fa-message-music::after {
  content: '\f8af\f8af'; }

.fad.fa-comment-alt-music::after, .fa-duotone.fa-comment-alt-music::after {
  content: '\f8af\f8af'; }

.fad.fa-car-building::after, .fa-duotone.fa-car-building::after {
  content: '\f859\f859'; }

.fad.fa-border-bottom-right::after, .fa-duotone.fa-border-bottom-right::after {
  content: '\f854\f854'; }

.fad.fa-border-style-alt::after, .fa-duotone.fa-border-style-alt::after {
  content: '\f854\f854'; }

.fad.fa-octagon::after, .fa-duotone.fa-octagon::after {
  content: '\f306\f306'; }

.fad.fa-comment-arrow-up-right::after, .fa-duotone.fa-comment-arrow-up-right::after {
  content: '\e145\e145'; }

.fad.fa-octagon-divide::after, .fa-duotone.fa-octagon-divide::after {
  content: '\e203\e203'; }

.fad.fa-cookie::after, .fa-duotone.fa-cookie::after {
  content: '\f563\f563'; }

.fad.fa-arrow-rotate-left::after, .fa-duotone.fa-arrow-rotate-left::after {
  content: '\f0e2\f0e2'; }

.fad.fa-arrow-left-rotate::after, .fa-duotone.fa-arrow-left-rotate::after {
  content: '\f0e2\f0e2'; }

.fad.fa-arrow-rotate-back::after, .fa-duotone.fa-arrow-rotate-back::after {
  content: '\f0e2\f0e2'; }

.fad.fa-arrow-rotate-backward::after, .fa-duotone.fa-arrow-rotate-backward::after {
  content: '\f0e2\f0e2'; }

.fad.fa-undo::after, .fa-duotone.fa-undo::after {
  content: '\f0e2\f0e2'; }

.fad.fa-tv-music::after, .fa-duotone.fa-tv-music::after {
  content: '\f8e6\f8e6'; }

.fad.fa-hard-drive::after, .fa-duotone.fa-hard-drive::after {
  content: '\f0a0\f0a0'; }

.fad.fa-hdd::after, .fa-duotone.fa-hdd::after {
  content: '\f0a0\f0a0'; }

.fad.fa-reel::after, .fa-duotone.fa-reel::after {
  content: '\e238\e238'; }

.fad.fa-face-grin-squint-tears::after, .fa-duotone.fa-face-grin-squint-tears::after {
  content: '\f586\f586'; }

.fad.fa-grin-squint-tears::after, .fa-duotone.fa-grin-squint-tears::after {
  content: '\f586\f586'; }

.fad.fa-dumbbell::after, .fa-duotone.fa-dumbbell::after {
  content: '\f44b\f44b'; }

.fad.fa-rectangle-list::after, .fa-duotone.fa-rectangle-list::after {
  content: '\f022\f022'; }

.fad.fa-list-alt::after, .fa-duotone.fa-list-alt::after {
  content: '\f022\f022'; }

.fad.fa-tarp-droplet::after, .fa-duotone.fa-tarp-droplet::after {
  content: '\e57c\e57c'; }

.fad.fa-alarm-exclamation::after, .fa-duotone.fa-alarm-exclamation::after {
  content: '\f843\f843'; }

.fad.fa-house-medical-circle-check::after, .fa-duotone.fa-house-medical-circle-check::after {
  content: '\e511\e511'; }

.fad.fa-traffic-cone::after, .fa-duotone.fa-traffic-cone::after {
  content: '\f636\f636'; }

.fad.fa-grate::after, .fa-duotone.fa-grate::after {
  content: '\e193\e193'; }

.fad.fa-arrow-down-right::after, .fa-duotone.fa-arrow-down-right::after {
  content: '\e093\e093'; }

.fad.fa-person-skiing-nordic::after, .fa-duotone.fa-person-skiing-nordic::after {
  content: '\f7ca\f7ca'; }

.fad.fa-skiing-nordic::after, .fa-duotone.fa-skiing-nordic::after {
  content: '\f7ca\f7ca'; }

.fad.fa-calendar-plus::after, .fa-duotone.fa-calendar-plus::after {
  content: '\f271\f271'; }

.fad.fa-person-from-portal::after, .fa-duotone.fa-person-from-portal::after {
  content: '\e023\e023'; }

.fad.fa-portal-exit::after, .fa-duotone.fa-portal-exit::after {
  content: '\e023\e023'; }

.fad.fa-plane-arrival::after, .fa-duotone.fa-plane-arrival::after {
  content: '\f5af\f5af'; }

.fad.fa-cowbell-circle-plus::after, .fa-duotone.fa-cowbell-circle-plus::after {
  content: '\f8b4\f8b4'; }

.fad.fa-cowbell-more::after, .fa-duotone.fa-cowbell-more::after {
  content: '\f8b4\f8b4'; }

.fad.fa-circle-left::after, .fa-duotone.fa-circle-left::after {
  content: '\f359\f359'; }

.fad.fa-arrow-alt-circle-left::after, .fa-duotone.fa-arrow-alt-circle-left::after {
  content: '\f359\f359'; }

.fad.fa-distribute-spacing-vertical::after, .fa-duotone.fa-distribute-spacing-vertical::after {
  content: '\e366\e366'; }

.fad.fa-signal-bars-fair::after, .fa-duotone.fa-signal-bars-fair::after {
  content: '\f692\f692'; }

.fad.fa-signal-alt-2::after, .fa-duotone.fa-signal-alt-2::after {
  content: '\f692\f692'; }

.fad.fa-sportsball::after, .fa-duotone.fa-sportsball::after {
  content: '\e44b\e44b'; }

.fad.fa-game-console-handheld-crank::after, .fa-duotone.fa-game-console-handheld-crank::after {
  content: '\e5b9\e5b9'; }

.fad.fa-train-subway::after, .fa-duotone.fa-train-subway::after {
  content: '\f239\f239'; }

.fad.fa-subway::after, .fa-duotone.fa-subway::after {
  content: '\f239\f239'; }

.fad.fa-chart-gantt::after, .fa-duotone.fa-chart-gantt::after {
  content: '\e0e4\e0e4'; }

.fad.fa-face-smile-upside-down::after, .fa-duotone.fa-face-smile-upside-down::after {
  content: '\e395\e395'; }

.fad.fa-ball-pile::after, .fa-duotone.fa-ball-pile::after {
  content: '\f77e\f77e'; }

.fad.fa-badge-dollar::after, .fa-duotone.fa-badge-dollar::after {
  content: '\f645\f645'; }

.fad.fa-money-bills-simple::after, .fa-duotone.fa-money-bills-simple::after {
  content: '\e1f4\e1f4'; }

.fad.fa-money-bills-alt::after, .fa-duotone.fa-money-bills-alt::after {
  content: '\e1f4\e1f4'; }

.fad.fa-list-timeline::after, .fa-duotone.fa-list-timeline::after {
  content: '\e1d1\e1d1'; }

.fad.fa-indian-rupee-sign::after, .fa-duotone.fa-indian-rupee-sign::after {
  content: '\e1bc\e1bc'; }

.fad.fa-indian-rupee::after, .fa-duotone.fa-indian-rupee::after {
  content: '\e1bc\e1bc'; }

.fad.fa-inr::after, .fa-duotone.fa-inr::after {
  content: '\e1bc\e1bc'; }

.fad.fa-crop-simple::after, .fa-duotone.fa-crop-simple::after {
  content: '\f565\f565'; }

.fad.fa-crop-alt::after, .fa-duotone.fa-crop-alt::after {
  content: '\f565\f565'; }

.fad.fa-money-bill-1::after, .fa-duotone.fa-money-bill-1::after {
  content: '\f3d1\f3d1'; }

.fad.fa-money-bill-alt::after, .fa-duotone.fa-money-bill-alt::after {
  content: '\f3d1\f3d1'; }

.fad.fa-left-long::after, .fa-duotone.fa-left-long::after {
  content: '\f30a\f30a'; }

.fad.fa-long-arrow-alt-left::after, .fa-duotone.fa-long-arrow-alt-left::after {
  content: '\f30a\f30a'; }

.fad.fa-keyboard-down::after, .fa-duotone.fa-keyboard-down::after {
  content: '\e1c2\e1c2'; }

.fad.fa-circle-up-right::after, .fa-duotone.fa-circle-up-right::after {
  content: '\e129\e129'; }

.fad.fa-cloud-bolt-moon::after, .fa-duotone.fa-cloud-bolt-moon::after {
  content: '\f76d\f76d'; }

.fad.fa-thunderstorm-moon::after, .fa-duotone.fa-thunderstorm-moon::after {
  content: '\f76d\f76d'; }

.fad.fa-dna::after, .fa-duotone.fa-dna::after {
  content: '\f471\f471'; }

.fad.fa-virus-slash::after, .fa-duotone.fa-virus-slash::after {
  content: '\e075\e075'; }

.fad.fa-bracket-round-right::after, .fa-duotone.fa-bracket-round-right::after {
  content: '\29\29'; }

.fad.fa-circle-sterling::after, .fa-duotone.fa-circle-sterling::after {
  content: '\e5cf\e5cf'; }

.fad.fa-circle-5::after, .fa-duotone.fa-circle-5::after {
  content: '\e0f2\e0f2'; }

.fad.fa-minus::after, .fa-duotone.fa-minus::after {
  content: '\f068\f068'; }

.fad.fa-subtract::after, .fa-duotone.fa-subtract::after {
  content: '\f068\f068'; }

.fad.fa-fire-flame::after, .fa-duotone.fa-fire-flame::after {
  content: '\f6df\f6df'; }

.fad.fa-flame::after, .fa-duotone.fa-flame::after {
  content: '\f6df\f6df'; }

.fad.fa-right-to-line::after, .fa-duotone.fa-right-to-line::after {
  content: '\f34c\f34c'; }

.fad.fa-arrow-alt-to-right::after, .fa-duotone.fa-arrow-alt-to-right::after {
  content: '\f34c\f34c'; }

.fad.fa-gif::after, .fa-duotone.fa-gif::after {
  content: '\e190\e190'; }

.fad.fa-chess::after, .fa-duotone.fa-chess::after {
  content: '\f439\f439'; }

.fad.fa-trash-slash::after, .fa-duotone.fa-trash-slash::after {
  content: '\e2b3\e2b3'; }

.fad.fa-arrow-left-long::after, .fa-duotone.fa-arrow-left-long::after {
  content: '\f177\f177'; }

.fad.fa-long-arrow-left::after, .fa-duotone.fa-long-arrow-left::after {
  content: '\f177\f177'; }

.fad.fa-plug-circle-check::after, .fa-duotone.fa-plug-circle-check::after {
  content: '\e55c\e55c'; }

.fad.fa-font-case::after, .fa-duotone.fa-font-case::after {
  content: '\f866\f866'; }

.fad.fa-street-view::after, .fa-duotone.fa-street-view::after {
  content: '\f21d\f21d'; }

.fad.fa-arrow-down-left::after, .fa-duotone.fa-arrow-down-left::after {
  content: '\e091\e091'; }

.fad.fa-franc-sign::after, .fa-duotone.fa-franc-sign::after {
  content: '\e18f\e18f'; }

.fad.fa-flask-round-poison::after, .fa-duotone.fa-flask-round-poison::after {
  content: '\f6e0\f6e0'; }

.fad.fa-flask-poison::after, .fa-duotone.fa-flask-poison::after {
  content: '\f6e0\f6e0'; }

.fad.fa-volume-off::after, .fa-duotone.fa-volume-off::after {
  content: '\f026\f026'; }

.fad.fa-book-circle-arrow-right::after, .fa-duotone.fa-book-circle-arrow-right::after {
  content: '\e0bc\e0bc'; }

.fad.fa-chart-user::after, .fa-duotone.fa-chart-user::after {
  content: '\f6a3\f6a3'; }

.fad.fa-user-chart::after, .fa-duotone.fa-user-chart::after {
  content: '\f6a3\f6a3'; }

.fad.fa-hands-asl-interpreting::after, .fa-duotone.fa-hands-asl-interpreting::after {
  content: '\f2a3\f2a3'; }

.fad.fa-american-sign-language-interpreting::after, .fa-duotone.fa-american-sign-language-interpreting::after {
  content: '\f2a3\f2a3'; }

.fad.fa-asl-interpreting::after, .fa-duotone.fa-asl-interpreting::after {
  content: '\f2a3\f2a3'; }

.fad.fa-hands-american-sign-language-interpreting::after, .fa-duotone.fa-hands-american-sign-language-interpreting::after {
  content: '\f2a3\f2a3'; }

.fad.fa-presentation-screen::after, .fa-duotone.fa-presentation-screen::after {
  content: '\f685\f685'; }

.fad.fa-presentation::after, .fa-duotone.fa-presentation::after {
  content: '\f685\f685'; }

.fad.fa-circle-bolt::after, .fa-duotone.fa-circle-bolt::after {
  content: '\e0fe\e0fe'; }

.fad.fa-face-smile-halo::after, .fa-duotone.fa-face-smile-halo::after {
  content: '\e38f\e38f'; }

.fad.fa-cart-circle-arrow-down::after, .fa-duotone.fa-cart-circle-arrow-down::after {
  content: '\e3ef\e3ef'; }

.fad.fa-house-person-return::after, .fa-duotone.fa-house-person-return::after {
  content: '\e011\e011'; }

.fad.fa-house-person-arrive::after, .fa-duotone.fa-house-person-arrive::after {
  content: '\e011\e011'; }

.fad.fa-house-return::after, .fa-duotone.fa-house-return::after {
  content: '\e011\e011'; }

.fad.fa-message-xmark::after, .fa-duotone.fa-message-xmark::after {
  content: '\f4ab\f4ab'; }

.fad.fa-comment-alt-times::after, .fa-duotone.fa-comment-alt-times::after {
  content: '\f4ab\f4ab'; }

.fad.fa-message-times::after, .fa-duotone.fa-message-times::after {
  content: '\f4ab\f4ab'; }

.fad.fa-file-certificate::after, .fa-duotone.fa-file-certificate::after {
  content: '\f5f3\f5f3'; }

.fad.fa-file-award::after, .fa-duotone.fa-file-award::after {
  content: '\f5f3\f5f3'; }

.fad.fa-user-doctor-hair-long::after, .fa-duotone.fa-user-doctor-hair-long::after {
  content: '\e459\e459'; }

.fad.fa-camera-security::after, .fa-duotone.fa-camera-security::after {
  content: '\f8fe\f8fe'; }

.fad.fa-camera-home::after, .fa-duotone.fa-camera-home::after {
  content: '\f8fe\f8fe'; }

.fad.fa-gear::after, .fa-duotone.fa-gear::after {
  content: '\f013\f013'; }

.fad.fa-cog::after, .fa-duotone.fa-cog::after {
  content: '\f013\f013'; }

.fad.fa-droplet-slash::after, .fa-duotone.fa-droplet-slash::after {
  content: '\f5c7\f5c7'; }

.fad.fa-tint-slash::after, .fa-duotone.fa-tint-slash::after {
  content: '\f5c7\f5c7'; }

.fad.fa-book-heart::after, .fa-duotone.fa-book-heart::after {
  content: '\f499\f499'; }

.fad.fa-mosque::after, .fa-duotone.fa-mosque::after {
  content: '\f678\f678'; }

.fad.fa-duck::after, .fa-duotone.fa-duck::after {
  content: '\f6d8\f6d8'; }

.fad.fa-mosquito::after, .fa-duotone.fa-mosquito::after {
  content: '\e52b\e52b'; }

.fad.fa-star-of-david::after, .fa-duotone.fa-star-of-david::after {
  content: '\f69a\f69a'; }

.fad.fa-flag-swallowtail::after, .fa-duotone.fa-flag-swallowtail::after {
  content: '\f74c\f74c'; }

.fad.fa-flag-alt::after, .fa-duotone.fa-flag-alt::after {
  content: '\f74c\f74c'; }

.fad.fa-person-military-rifle::after, .fa-duotone.fa-person-military-rifle::after {
  content: '\e54b\e54b'; }

.fad.fa-car-garage::after, .fa-duotone.fa-car-garage::after {
  content: '\f5e2\f5e2'; }

.fad.fa-cart-shopping::after, .fa-duotone.fa-cart-shopping::after {
  content: '\f07a\f07a'; }

.fad.fa-shopping-cart::after, .fa-duotone.fa-shopping-cart::after {
  content: '\f07a\f07a'; }

.fad.fa-book-font::after, .fa-duotone.fa-book-font::after {
  content: '\e0bf\e0bf'; }

.fad.fa-shield-plus::after, .fa-duotone.fa-shield-plus::after {
  content: '\e24a\e24a'; }

.fad.fa-vials::after, .fa-duotone.fa-vials::after {
  content: '\f493\f493'; }

.fad.fa-eye-dropper-full::after, .fa-duotone.fa-eye-dropper-full::after {
  content: '\e172\e172'; }

.fad.fa-distribute-spacing-horizontal::after, .fa-duotone.fa-distribute-spacing-horizontal::after {
  content: '\e365\e365'; }

.fad.fa-tablet-rugged::after, .fa-duotone.fa-tablet-rugged::after {
  content: '\f48f\f48f'; }

.fad.fa-temperature-snow::after, .fa-duotone.fa-temperature-snow::after {
  content: '\f768\f768'; }

.fad.fa-temperature-frigid::after, .fa-duotone.fa-temperature-frigid::after {
  content: '\f768\f768'; }

.fad.fa-moped::after, .fa-duotone.fa-moped::after {
  content: '\e3b9\e3b9'; }

.fad.fa-face-smile-plus::after, .fa-duotone.fa-face-smile-plus::after {
  content: '\f5b9\f5b9'; }

.fad.fa-smile-plus::after, .fa-duotone.fa-smile-plus::after {
  content: '\f5b9\f5b9'; }

.fad.fa-radio-tuner::after, .fa-duotone.fa-radio-tuner::after {
  content: '\f8d8\f8d8'; }

.fad.fa-radio-alt::after, .fa-duotone.fa-radio-alt::after {
  content: '\f8d8\f8d8'; }

.fad.fa-face-swear::after, .fa-duotone.fa-face-swear::after {
  content: '\e399\e399'; }

.fad.fa-water-arrow-down::after, .fa-duotone.fa-water-arrow-down::after {
  content: '\f774\f774'; }

.fad.fa-water-lower::after, .fa-duotone.fa-water-lower::after {
  content: '\f774\f774'; }

.fad.fa-scanner-touchscreen::after, .fa-duotone.fa-scanner-touchscreen::after {
  content: '\f48a\f48a'; }

.fad.fa-circle-7::after, .fa-duotone.fa-circle-7::after {
  content: '\e0f4\e0f4'; }

.fad.fa-plug-circle-plus::after, .fa-duotone.fa-plug-circle-plus::after {
  content: '\e55f\e55f'; }

.fad.fa-person-ski-jumping::after, .fa-duotone.fa-person-ski-jumping::after {
  content: '\f7c7\f7c7'; }

.fad.fa-ski-jump::after, .fa-duotone.fa-ski-jump::after {
  content: '\f7c7\f7c7'; }

.fad.fa-place-of-worship::after, .fa-duotone.fa-place-of-worship::after {
  content: '\f67f\f67f'; }

.fad.fa-water-arrow-up::after, .fa-duotone.fa-water-arrow-up::after {
  content: '\f775\f775'; }

.fad.fa-water-rise::after, .fa-duotone.fa-water-rise::after {
  content: '\f775\f775'; }

.fad.fa-waveform-lines::after, .fa-duotone.fa-waveform-lines::after {
  content: '\f8f2\f8f2'; }

.fad.fa-waveform-path::after, .fa-duotone.fa-waveform-path::after {
  content: '\f8f2\f8f2'; }

.fad.fa-split::after, .fa-duotone.fa-split::after {
  content: '\e254\e254'; }

.fad.fa-film-canister::after, .fa-duotone.fa-film-canister::after {
  content: '\f8b7\f8b7'; }

.fad.fa-film-cannister::after, .fa-duotone.fa-film-cannister::after {
  content: '\f8b7\f8b7'; }

.fad.fa-folder-xmark::after, .fa-duotone.fa-folder-xmark::after {
  content: '\f65f\f65f'; }

.fad.fa-folder-times::after, .fa-duotone.fa-folder-times::after {
  content: '\f65f\f65f'; }

.fad.fa-toilet-paper-blank::after, .fa-duotone.fa-toilet-paper-blank::after {
  content: '\f71f\f71f'; }

.fad.fa-toilet-paper-alt::after, .fa-duotone.fa-toilet-paper-alt::after {
  content: '\f71f\f71f'; }

.fad.fa-tablet-screen::after, .fa-duotone.fa-tablet-screen::after {
  content: '\f3fc\f3fc'; }

.fad.fa-tablet-android-alt::after, .fa-duotone.fa-tablet-android-alt::after {
  content: '\f3fc\f3fc'; }

.fad.fa-hexagon-vertical-nft-slanted::after, .fa-duotone.fa-hexagon-vertical-nft-slanted::after {
  content: '\e506\e506'; }

.fad.fa-folder-music::after, .fa-duotone.fa-folder-music::after {
  content: '\e18d\e18d'; }

.fad.fa-display-medical::after, .fa-duotone.fa-display-medical::after {
  content: '\e166\e166'; }

.fad.fa-desktop-medical::after, .fa-duotone.fa-desktop-medical::after {
  content: '\e166\e166'; }

.fad.fa-share-all::after, .fa-duotone.fa-share-all::after {
  content: '\f367\f367'; }

.fad.fa-peapod::after, .fa-duotone.fa-peapod::after {
  content: '\e31c\e31c'; }

.fad.fa-chess-clock::after, .fa-duotone.fa-chess-clock::after {
  content: '\f43d\f43d'; }

.fad.fa-axe::after, .fa-duotone.fa-axe::after {
  content: '\f6b2\f6b2'; }

.fad.fa-square-d::after, .fa-duotone.fa-square-d::after {
  content: '\e268\e268'; }

.fad.fa-grip-vertical::after, .fa-duotone.fa-grip-vertical::after {
  content: '\f58e\f58e'; }

.fad.fa-mobile-signal-out::after, .fa-duotone.fa-mobile-signal-out::after {
  content: '\e1f0\e1f0'; }

.fad.fa-arrow-turn-up::after, .fa-duotone.fa-arrow-turn-up::after {
  content: '\f148\f148'; }

.fad.fa-level-up::after, .fa-duotone.fa-level-up::after {
  content: '\f148\f148'; }

.fad.fa-u::after, .fa-duotone.fa-u::after {
  content: '\55\55'; }

.fad.fa-arrow-up-from-dotted-line::after, .fa-duotone.fa-arrow-up-from-dotted-line::after {
  content: '\e09b\e09b'; }

.fad.fa-square-root-variable::after, .fa-duotone.fa-square-root-variable::after {
  content: '\f698\f698'; }

.fad.fa-square-root-alt::after, .fa-duotone.fa-square-root-alt::after {
  content: '\f698\f698'; }

.fad.fa-light-switch-on::after, .fa-duotone.fa-light-switch-on::after {
  content: '\e019\e019'; }

.fad.fa-arrow-down-arrow-up::after, .fa-duotone.fa-arrow-down-arrow-up::after {
  content: '\f883\f883'; }

.fad.fa-sort-alt::after, .fa-duotone.fa-sort-alt::after {
  content: '\f883\f883'; }

.fad.fa-raindrops::after, .fa-duotone.fa-raindrops::after {
  content: '\f75c\f75c'; }

.fad.fa-dash::after, .fa-duotone.fa-dash::after {
  content: '\e404\e404'; }

.fad.fa-minus-large::after, .fa-duotone.fa-minus-large::after {
  content: '\e404\e404'; }

.fad.fa-clock::after, .fa-duotone.fa-clock::after {
  content: '\f017\f017'; }

.fad.fa-clock-four::after, .fa-duotone.fa-clock-four::after {
  content: '\f017\f017'; }

.fad.fa-input-numeric::after, .fa-duotone.fa-input-numeric::after {
  content: '\e1bd\e1bd'; }

.fad.fa-truck-tow::after, .fa-duotone.fa-truck-tow::after {
  content: '\e2b8\e2b8'; }

.fad.fa-backward-step::after, .fa-duotone.fa-backward-step::after {
  content: '\f048\f048'; }

.fad.fa-step-backward::after, .fa-duotone.fa-step-backward::after {
  content: '\f048\f048'; }

.fad.fa-pallet::after, .fa-duotone.fa-pallet::after {
  content: '\f482\f482'; }

.fad.fa-car-bolt::after, .fa-duotone.fa-car-bolt::after {
  content: '\e341\e341'; }

.fad.fa-arrows-maximize::after, .fa-duotone.fa-arrows-maximize::after {
  content: '\f31d\f31d'; }

.fad.fa-expand-arrows::after, .fa-duotone.fa-expand-arrows::after {
  content: '\f31d\f31d'; }

.fad.fa-faucet::after, .fa-duotone.fa-faucet::after {
  content: '\e005\e005'; }

.fad.fa-cloud-sleet::after, .fa-duotone.fa-cloud-sleet::after {
  content: '\f741\f741'; }

.fad.fa-lamp-street::after, .fa-duotone.fa-lamp-street::after {
  content: '\e1c5\e1c5'; }

.fad.fa-list-radio::after, .fa-duotone.fa-list-radio::after {
  content: '\e1d0\e1d0'; }

.fad.fa-pen-nib-slash::after, .fa-duotone.fa-pen-nib-slash::after {
  content: '\e4a1\e4a1'; }

.fad.fa-baseball-bat-ball::after, .fa-duotone.fa-baseball-bat-ball::after {
  content: '\f432\f432'; }

.fad.fa-square-up-left::after, .fa-duotone.fa-square-up-left::after {
  content: '\e282\e282'; }

.fad.fa-overline::after, .fa-duotone.fa-overline::after {
  content: '\f876\f876'; }

.fad.fa-s::after, .fa-duotone.fa-s::after {
  content: '\53\53'; }

.fad.fa-timeline::after, .fa-duotone.fa-timeline::after {
  content: '\e29c\e29c'; }

.fad.fa-keyboard::after, .fa-duotone.fa-keyboard::after {
  content: '\f11c\f11c'; }

.fad.fa-arrows-from-dotted-line::after, .fa-duotone.fa-arrows-from-dotted-line::after {
  content: '\e0a3\e0a3'; }

.fad.fa-usb-drive::after, .fa-duotone.fa-usb-drive::after {
  content: '\f8e9\f8e9'; }

.fad.fa-ballot::after, .fa-duotone.fa-ballot::after {
  content: '\f732\f732'; }

.fad.fa-caret-down::after, .fa-duotone.fa-caret-down::after {
  content: '\f0d7\f0d7'; }

.fad.fa-location-dot-slash::after, .fa-duotone.fa-location-dot-slash::after {
  content: '\f605\f605'; }

.fad.fa-map-marker-alt-slash::after, .fa-duotone.fa-map-marker-alt-slash::after {
  content: '\f605\f605'; }

.fad.fa-cards::after, .fa-duotone.fa-cards::after {
  content: '\e3ed\e3ed'; }

.fad.fa-house-chimney-medical::after, .fa-duotone.fa-house-chimney-medical::after {
  content: '\f7f2\f7f2'; }

.fad.fa-clinic-medical::after, .fa-duotone.fa-clinic-medical::after {
  content: '\f7f2\f7f2'; }

.fad.fa-boxing-glove::after, .fa-duotone.fa-boxing-glove::after {
  content: '\f438\f438'; }

.fad.fa-glove-boxing::after, .fa-duotone.fa-glove-boxing::after {
  content: '\f438\f438'; }

.fad.fa-temperature-three-quarters::after, .fa-duotone.fa-temperature-three-quarters::after {
  content: '\f2c8\f2c8'; }

.fad.fa-temperature-3::after, .fa-duotone.fa-temperature-3::after {
  content: '\f2c8\f2c8'; }

.fad.fa-thermometer-3::after, .fa-duotone.fa-thermometer-3::after {
  content: '\f2c8\f2c8'; }

.fad.fa-thermometer-three-quarters::after, .fa-duotone.fa-thermometer-three-quarters::after {
  content: '\f2c8\f2c8'; }

.fad.fa-bell-school::after, .fa-duotone.fa-bell-school::after {
  content: '\f5d5\f5d5'; }

.fad.fa-mobile-screen::after, .fa-duotone.fa-mobile-screen::after {
  content: '\f3cf\f3cf'; }

.fad.fa-mobile-android-alt::after, .fa-duotone.fa-mobile-android-alt::after {
  content: '\f3cf\f3cf'; }

.fad.fa-plane-up::after, .fa-duotone.fa-plane-up::after {
  content: '\e22d\e22d'; }

.fad.fa-folder-heart::after, .fa-duotone.fa-folder-heart::after {
  content: '\e189\e189'; }

.fad.fa-circle-location-arrow::after, .fa-duotone.fa-circle-location-arrow::after {
  content: '\f602\f602'; }

.fad.fa-location-circle::after, .fa-duotone.fa-location-circle::after {
  content: '\f602\f602'; }

.fad.fa-face-head-bandage::after, .fa-duotone.fa-face-head-bandage::after {
  content: '\e37a\e37a'; }

.fad.fa-sushi-roll::after, .fa-duotone.fa-sushi-roll::after {
  content: '\e48b\e48b'; }

.fad.fa-maki-roll::after, .fa-duotone.fa-maki-roll::after {
  content: '\e48b\e48b'; }

.fad.fa-makizushi::after, .fa-duotone.fa-makizushi::after {
  content: '\e48b\e48b'; }

.fad.fa-car-bump::after, .fa-duotone.fa-car-bump::after {
  content: '\f5e0\f5e0'; }

.fad.fa-piggy-bank::after, .fa-duotone.fa-piggy-bank::after {
  content: '\f4d3\f4d3'; }

.fad.fa-racquet::after, .fa-duotone.fa-racquet::after {
  content: '\f45a\f45a'; }

.fad.fa-car-mirrors::after, .fa-duotone.fa-car-mirrors::after {
  content: '\e343\e343'; }

.fad.fa-industry-windows::after, .fa-duotone.fa-industry-windows::after {
  content: '\f3b3\f3b3'; }

.fad.fa-industry-alt::after, .fa-duotone.fa-industry-alt::after {
  content: '\f3b3\f3b3'; }

.fad.fa-bolt-auto::after, .fa-duotone.fa-bolt-auto::after {
  content: '\e0b6\e0b6'; }

.fad.fa-battery-half::after, .fa-duotone.fa-battery-half::after {
  content: '\f242\f242'; }

.fad.fa-battery-3::after, .fa-duotone.fa-battery-3::after {
  content: '\f242\f242'; }

.fad.fa-flux-capacitor::after, .fa-duotone.fa-flux-capacitor::after {
  content: '\f8ba\f8ba'; }

.fad.fa-mountain-city::after, .fa-duotone.fa-mountain-city::after {
  content: '\e52e\e52e'; }

.fad.fa-coins::after, .fa-duotone.fa-coins::after {
  content: '\f51e\f51e'; }

.fad.fa-honey-pot::after, .fa-duotone.fa-honey-pot::after {
  content: '\e418\e418'; }

.fad.fa-olive::after, .fa-duotone.fa-olive::after {
  content: '\e316\e316'; }

.fad.fa-khanda::after, .fa-duotone.fa-khanda::after {
  content: '\f66d\f66d'; }

.fad.fa-filter-list::after, .fa-duotone.fa-filter-list::after {
  content: '\e17c\e17c'; }

.fad.fa-outlet::after, .fa-duotone.fa-outlet::after {
  content: '\e01c\e01c'; }

.fad.fa-sliders::after, .fa-duotone.fa-sliders::after {
  content: '\f1de\f1de'; }

.fad.fa-sliders-h::after, .fa-duotone.fa-sliders-h::after {
  content: '\f1de\f1de'; }

.fad.fa-cauldron::after, .fa-duotone.fa-cauldron::after {
  content: '\f6bf\f6bf'; }

.fad.fa-people::after, .fa-duotone.fa-people::after {
  content: '\e216\e216'; }

.fad.fa-folder-tree::after, .fa-duotone.fa-folder-tree::after {
  content: '\f802\f802'; }

.fad.fa-network-wired::after, .fa-duotone.fa-network-wired::after {
  content: '\f6ff\f6ff'; }

.fad.fa-croissant::after, .fa-duotone.fa-croissant::after {
  content: '\f7f6\f7f6'; }

.fad.fa-map-pin::after, .fa-duotone.fa-map-pin::after {
  content: '\f276\f276'; }

.fad.fa-hamsa::after, .fa-duotone.fa-hamsa::after {
  content: '\f665\f665'; }

.fad.fa-cent-sign::after, .fa-duotone.fa-cent-sign::after {
  content: '\e3f5\e3f5'; }

.fad.fa-swords-laser::after, .fa-duotone.fa-swords-laser::after {
  content: '\e03d\e03d'; }

.fad.fa-flask::after, .fa-duotone.fa-flask::after {
  content: '\f0c3\f0c3'; }

.fad.fa-person-pregnant::after, .fa-duotone.fa-person-pregnant::after {
  content: '\e31e\e31e'; }

.fad.fa-square-u::after, .fa-duotone.fa-square-u::after {
  content: '\e281\e281'; }

.fad.fa-wand-sparkles::after, .fa-duotone.fa-wand-sparkles::after {
  content: '\f72b\f72b'; }

.fad.fa-router::after, .fa-duotone.fa-router::after {
  content: '\f8da\f8da'; }

.fad.fa-ellipsis-vertical::after, .fa-duotone.fa-ellipsis-vertical::after {
  content: '\f142\f142'; }

.fad.fa-ellipsis-v::after, .fa-duotone.fa-ellipsis-v::after {
  content: '\f142\f142'; }

.fad.fa-sword-laser-alt::after, .fa-duotone.fa-sword-laser-alt::after {
  content: '\e03c\e03c'; }

.fad.fa-ticket::after, .fa-duotone.fa-ticket::after {
  content: '\f145\f145'; }

.fad.fa-power-off::after, .fa-duotone.fa-power-off::after {
  content: '\f011\f011'; }

.fad.fa-coin::after, .fa-duotone.fa-coin::after {
  content: '\f85c\f85c'; }

.fad.fa-laptop-slash::after, .fa-duotone.fa-laptop-slash::after {
  content: '\e1c7\e1c7'; }

.fad.fa-right-long::after, .fa-duotone.fa-right-long::after {
  content: '\f30b\f30b'; }

.fad.fa-long-arrow-alt-right::after, .fa-duotone.fa-long-arrow-alt-right::after {
  content: '\f30b\f30b'; }

.fad.fa-circle-b::after, .fa-duotone.fa-circle-b::after {
  content: '\e0fd\e0fd'; }

.fad.fa-person-dress-simple::after, .fa-duotone.fa-person-dress-simple::after {
  content: '\e21c\e21c'; }

.fad.fa-pipe-collar::after, .fa-duotone.fa-pipe-collar::after {
  content: '\e437\e437'; }

.fad.fa-lights-holiday::after, .fa-duotone.fa-lights-holiday::after {
  content: '\f7b2\f7b2'; }

.fad.fa-citrus::after, .fa-duotone.fa-citrus::after {
  content: '\e2f4\e2f4'; }

.fad.fa-flag-usa::after, .fa-duotone.fa-flag-usa::after {
  content: '\f74d\f74d'; }

.fad.fa-laptop-file::after, .fa-duotone.fa-laptop-file::after {
  content: '\e51d\e51d'; }

.fad.fa-tty::after, .fa-duotone.fa-tty::after {
  content: '\f1e4\f1e4'; }

.fad.fa-teletype::after, .fa-duotone.fa-teletype::after {
  content: '\f1e4\f1e4'; }

.fad.fa-chart-tree-map::after, .fa-duotone.fa-chart-tree-map::after {
  content: '\e0ea\e0ea'; }

.fad.fa-diagram-next::after, .fa-duotone.fa-diagram-next::after {
  content: '\e476\e476'; }

.fad.fa-person-rifle::after, .fa-duotone.fa-person-rifle::after {
  content: '\e54e\e54e'; }

.fad.fa-clock-five-thirty::after, .fa-duotone.fa-clock-five-thirty::after {
  content: '\e34a\e34a'; }

.fad.fa-pipe-valve::after, .fa-duotone.fa-pipe-valve::after {
  content: '\e439\e439'; }

.fad.fa-arrow-up-from-arc::after, .fa-duotone.fa-arrow-up-from-arc::after {
  content: '\e4b4\e4b4'; }

.fad.fa-face-spiral-eyes::after, .fa-duotone.fa-face-spiral-eyes::after {
  content: '\e485\e485'; }

.fad.fa-compress-wide::after, .fa-duotone.fa-compress-wide::after {
  content: '\f326\f326'; }

.fad.fa-circle-phone-hangup::after, .fa-duotone.fa-circle-phone-hangup::after {
  content: '\e11d\e11d'; }

.fad.fa-phone-circle-down::after, .fa-duotone.fa-phone-circle-down::after {
  content: '\e11d\e11d'; }

.fad.fa-gear-complex-code::after, .fa-duotone.fa-gear-complex-code::after {
  content: '\e5eb\e5eb'; }

.fad.fa-house-medical-circle-exclamation::after, .fa-duotone.fa-house-medical-circle-exclamation::after {
  content: '\e512\e512'; }

.fad.fa-badminton::after, .fa-duotone.fa-badminton::after {
  content: '\e33a\e33a'; }

.fad.fa-closed-captioning::after, .fa-duotone.fa-closed-captioning::after {
  content: '\f20a\f20a'; }

.fad.fa-person-hiking::after, .fa-duotone.fa-person-hiking::after {
  content: '\f6ec\f6ec'; }

.fad.fa-hiking::after, .fa-duotone.fa-hiking::after {
  content: '\f6ec\f6ec'; }

.fad.fa-right-from-line::after, .fa-duotone.fa-right-from-line::after {
  content: '\f347\f347'; }

.fad.fa-arrow-alt-from-left::after, .fa-duotone.fa-arrow-alt-from-left::after {
  content: '\f347\f347'; }

.fad.fa-venus-double::after, .fa-duotone.fa-venus-double::after {
  content: '\f226\f226'; }

.fad.fa-images::after, .fa-duotone.fa-images::after {
  content: '\f302\f302'; }

.fad.fa-calculator::after, .fa-duotone.fa-calculator::after {
  content: '\f1ec\f1ec'; }

.fad.fa-shuttlecock::after, .fa-duotone.fa-shuttlecock::after {
  content: '\f45b\f45b'; }

.fad.fa-user-hair::after, .fa-duotone.fa-user-hair::after {
  content: '\e45a\e45a'; }

.fad.fa-eye-evil::after, .fa-duotone.fa-eye-evil::after {
  content: '\f6db\f6db'; }

.fad.fa-people-pulling::after, .fa-duotone.fa-people-pulling::after {
  content: '\e535\e535'; }

.fad.fa-n::after, .fa-duotone.fa-n::after {
  content: '\4e\4e'; }

.fad.fa-swap::after, .fa-duotone.fa-swap::after {
  content: '\e609\e609'; }

.fad.fa-garage::after, .fa-duotone.fa-garage::after {
  content: '\e009\e009'; }

.fad.fa-cable-car::after, .fa-duotone.fa-cable-car::after {
  content: '\f7da\f7da'; }

.fad.fa-tram::after, .fa-duotone.fa-tram::after {
  content: '\f7da\f7da'; }

.fad.fa-shovel-snow::after, .fa-duotone.fa-shovel-snow::after {
  content: '\f7c3\f7c3'; }

.fad.fa-cloud-rain::after, .fa-duotone.fa-cloud-rain::after {
  content: '\f73d\f73d'; }

.fad.fa-face-lying::after, .fa-duotone.fa-face-lying::after {
  content: '\e37e\e37e'; }

.fad.fa-sprinkler::after, .fa-duotone.fa-sprinkler::after {
  content: '\e035\e035'; }

.fad.fa-building-circle-xmark::after, .fa-duotone.fa-building-circle-xmark::after {
  content: '\e4d4\e4d4'; }

.fad.fa-person-sledding::after, .fa-duotone.fa-person-sledding::after {
  content: '\f7cb\f7cb'; }

.fad.fa-sledding::after, .fa-duotone.fa-sledding::after {
  content: '\f7cb\f7cb'; }

.fad.fa-game-console-handheld::after, .fa-duotone.fa-game-console-handheld::after {
  content: '\f8bb\f8bb'; }

.fad.fa-ship::after, .fa-duotone.fa-ship::after {
  content: '\f21a\f21a'; }

.fad.fa-clock-six-thirty::after, .fa-duotone.fa-clock-six-thirty::after {
  content: '\e353\e353'; }

.fad.fa-battery-slash::after, .fa-duotone.fa-battery-slash::after {
  content: '\f377\f377'; }

.fad.fa-tugrik-sign::after, .fa-duotone.fa-tugrik-sign::after {
  content: '\e2ba\e2ba'; }

.fad.fa-arrows-down-to-line::after, .fa-duotone.fa-arrows-down-to-line::after {
  content: '\e4b8\e4b8'; }

.fad.fa-download::after, .fa-duotone.fa-download::after {
  content: '\f019\f019'; }

.fad.fa-angles-up-down::after, .fa-duotone.fa-angles-up-down::after {
  content: '\e60d\e60d'; }

.fad.fa-shelves::after, .fa-duotone.fa-shelves::after {
  content: '\f480\f480'; }

.fad.fa-inventory::after, .fa-duotone.fa-inventory::after {
  content: '\f480\f480'; }

.fad.fa-cloud-snow::after, .fa-duotone.fa-cloud-snow::after {
  content: '\f742\f742'; }

.fad.fa-face-grin::after, .fa-duotone.fa-face-grin::after {
  content: '\f580\f580'; }

.fad.fa-grin::after, .fa-duotone.fa-grin::after {
  content: '\f580\f580'; }

.fad.fa-delete-left::after, .fa-duotone.fa-delete-left::after {
  content: '\f55a\f55a'; }

.fad.fa-backspace::after, .fa-duotone.fa-backspace::after {
  content: '\f55a\f55a'; }

.fad.fa-oven::after, .fa-duotone.fa-oven::after {
  content: '\e01d\e01d'; }

.fad.fa-cloud-binary::after, .fa-duotone.fa-cloud-binary::after {
  content: '\e601\e601'; }

.fad.fa-eye-dropper::after, .fa-duotone.fa-eye-dropper::after {
  content: '\f1fb\f1fb'; }

.fad.fa-eye-dropper-empty::after, .fa-duotone.fa-eye-dropper-empty::after {
  content: '\f1fb\f1fb'; }

.fad.fa-eyedropper::after, .fa-duotone.fa-eyedropper::after {
  content: '\f1fb\f1fb'; }

.fad.fa-comment-captions::after, .fa-duotone.fa-comment-captions::after {
  content: '\e146\e146'; }

.fad.fa-comments-question::after, .fa-duotone.fa-comments-question::after {
  content: '\e14e\e14e'; }

.fad.fa-scribble::after, .fa-duotone.fa-scribble::after {
  content: '\e23f\e23f'; }

.fad.fa-rotate-exclamation::after, .fa-duotone.fa-rotate-exclamation::after {
  content: '\e23c\e23c'; }

.fad.fa-file-circle-check::after, .fa-duotone.fa-file-circle-check::after {
  content: '\e5a0\e5a0'; }

.fad.fa-glass::after, .fa-duotone.fa-glass::after {
  content: '\f804\f804'; }

.fad.fa-loader::after, .fa-duotone.fa-loader::after {
  content: '\e1d4\e1d4'; }

.fad.fa-forward::after, .fa-duotone.fa-forward::after {
  content: '\f04e\f04e'; }

.fad.fa-user-pilot::after, .fa-duotone.fa-user-pilot::after {
  content: '\e2c0\e2c0'; }

.fad.fa-mobile::after, .fa-duotone.fa-mobile::after {
  content: '\f3ce\f3ce'; }

.fad.fa-mobile-android::after, .fa-duotone.fa-mobile-android::after {
  content: '\f3ce\f3ce'; }

.fad.fa-mobile-phone::after, .fa-duotone.fa-mobile-phone::after {
  content: '\f3ce\f3ce'; }

.fad.fa-code-pull-request-closed::after, .fa-duotone.fa-code-pull-request-closed::after {
  content: '\e3f9\e3f9'; }

.fad.fa-face-meh::after, .fa-duotone.fa-face-meh::after {
  content: '\f11a\f11a'; }

.fad.fa-meh::after, .fa-duotone.fa-meh::after {
  content: '\f11a\f11a'; }

.fad.fa-align-center::after, .fa-duotone.fa-align-center::after {
  content: '\f037\f037'; }

.fad.fa-book-skull::after, .fa-duotone.fa-book-skull::after {
  content: '\f6b7\f6b7'; }

.fad.fa-book-dead::after, .fa-duotone.fa-book-dead::after {
  content: '\f6b7\f6b7'; }

.fad.fa-id-card::after, .fa-duotone.fa-id-card::after {
  content: '\f2c2\f2c2'; }

.fad.fa-drivers-license::after, .fa-duotone.fa-drivers-license::after {
  content: '\f2c2\f2c2'; }

.fad.fa-face-dotted::after, .fa-duotone.fa-face-dotted::after {
  content: '\e47f\e47f'; }

.fad.fa-face-worried::after, .fa-duotone.fa-face-worried::after {
  content: '\e3a3\e3a3'; }

.fad.fa-outdent::after, .fa-duotone.fa-outdent::after {
  content: '\f03b\f03b'; }

.fad.fa-dedent::after, .fa-duotone.fa-dedent::after {
  content: '\f03b\f03b'; }

.fad.fa-heart-circle-exclamation::after, .fa-duotone.fa-heart-circle-exclamation::after {
  content: '\e4fe\e4fe'; }

.fad.fa-house::after, .fa-duotone.fa-house::after {
  content: '\f015\f015'; }

.fad.fa-home::after, .fa-duotone.fa-home::after {
  content: '\f015\f015'; }

.fad.fa-home-alt::after, .fa-duotone.fa-home-alt::after {
  content: '\f015\f015'; }

.fad.fa-home-lg-alt::after, .fa-duotone.fa-home-lg-alt::after {
  content: '\f015\f015'; }

.fad.fa-vector-circle::after, .fa-duotone.fa-vector-circle::after {
  content: '\e2c6\e2c6'; }

.fad.fa-car-circle-bolt::after, .fa-duotone.fa-car-circle-bolt::after {
  content: '\e342\e342'; }

.fad.fa-calendar-week::after, .fa-duotone.fa-calendar-week::after {
  content: '\f784\f784'; }

.fad.fa-flying-disc::after, .fa-duotone.fa-flying-disc::after {
  content: '\e3a9\e3a9'; }

.fad.fa-laptop-medical::after, .fa-duotone.fa-laptop-medical::after {
  content: '\f812\f812'; }

.fad.fa-square-down-right::after, .fa-duotone.fa-square-down-right::after {
  content: '\e26c\e26c'; }

.fad.fa-b::after, .fa-duotone.fa-b::after {
  content: '\42\42'; }

.fad.fa-seat-airline::after, .fa-duotone.fa-seat-airline::after {
  content: '\e244\e244'; }

.fad.fa-moon-over-sun::after, .fa-duotone.fa-moon-over-sun::after {
  content: '\f74a\f74a'; }

.fad.fa-eclipse-alt::after, .fa-duotone.fa-eclipse-alt::after {
  content: '\f74a\f74a'; }

.fad.fa-pipe::after, .fa-duotone.fa-pipe::after {
  content: '\7c\7c'; }

.fad.fa-file-medical::after, .fa-duotone.fa-file-medical::after {
  content: '\f477\f477'; }

.fad.fa-potato::after, .fa-duotone.fa-potato::after {
  content: '\e440\e440'; }

.fad.fa-dice-one::after, .fa-duotone.fa-dice-one::after {
  content: '\f525\f525'; }

.fad.fa-circle-a::after, .fa-duotone.fa-circle-a::after {
  content: '\e0f7\e0f7'; }

.fad.fa-helmet-battle::after, .fa-duotone.fa-helmet-battle::after {
  content: '\f6eb\f6eb'; }

.fad.fa-butter::after, .fa-duotone.fa-butter::after {
  content: '\e3e4\e3e4'; }

.fad.fa-blanket-fire::after, .fa-duotone.fa-blanket-fire::after {
  content: '\e3da\e3da'; }

.fad.fa-kiwi-bird::after, .fa-duotone.fa-kiwi-bird::after {
  content: '\f535\f535'; }

.fad.fa-castle::after, .fa-duotone.fa-castle::after {
  content: '\e0de\e0de'; }

.fad.fa-golf-club::after, .fa-duotone.fa-golf-club::after {
  content: '\f451\f451'; }

.fad.fa-arrow-right-arrow-left::after, .fa-duotone.fa-arrow-right-arrow-left::after {
  content: '\f0ec\f0ec'; }

.fad.fa-exchange::after, .fa-duotone.fa-exchange::after {
  content: '\f0ec\f0ec'; }

.fad.fa-rotate-right::after, .fa-duotone.fa-rotate-right::after {
  content: '\f2f9\f2f9'; }

.fad.fa-redo-alt::after, .fa-duotone.fa-redo-alt::after {
  content: '\f2f9\f2f9'; }

.fad.fa-rotate-forward::after, .fa-duotone.fa-rotate-forward::after {
  content: '\f2f9\f2f9'; }

.fad.fa-utensils::after, .fa-duotone.fa-utensils::after {
  content: '\f2e7\f2e7'; }

.fad.fa-cutlery::after, .fa-duotone.fa-cutlery::after {
  content: '\f2e7\f2e7'; }

.fad.fa-arrow-up-wide-short::after, .fa-duotone.fa-arrow-up-wide-short::after {
  content: '\f161\f161'; }

.fad.fa-sort-amount-up::after, .fa-duotone.fa-sort-amount-up::after {
  content: '\f161\f161'; }

.fad.fa-chart-pie-simple-circle-dollar::after, .fa-duotone.fa-chart-pie-simple-circle-dollar::after {
  content: '\e605\e605'; }

.fad.fa-balloons::after, .fa-duotone.fa-balloons::after {
  content: '\e2e4\e2e4'; }

.fad.fa-mill-sign::after, .fa-duotone.fa-mill-sign::after {
  content: '\e1ed\e1ed'; }

.fad.fa-bowl-rice::after, .fa-duotone.fa-bowl-rice::after {
  content: '\e2eb\e2eb'; }

.fad.fa-timeline-arrow::after, .fa-duotone.fa-timeline-arrow::after {
  content: '\e29d\e29d'; }

.fad.fa-skull::after, .fa-duotone.fa-skull::after {
  content: '\f54c\f54c'; }

.fad.fa-game-board-simple::after, .fa-duotone.fa-game-board-simple::after {
  content: '\f868\f868'; }

.fad.fa-game-board-alt::after, .fa-duotone.fa-game-board-alt::after {
  content: '\f868\f868'; }

.fad.fa-circle-video::after, .fa-duotone.fa-circle-video::after {
  content: '\e12b\e12b'; }

.fad.fa-video-circle::after, .fa-duotone.fa-video-circle::after {
  content: '\e12b\e12b'; }

.fad.fa-chart-scatter-bubble::after, .fa-duotone.fa-chart-scatter-bubble::after {
  content: '\e0e9\e0e9'; }

.fad.fa-house-turret::after, .fa-duotone.fa-house-turret::after {
  content: '\e1b4\e1b4'; }

.fad.fa-banana::after, .fa-duotone.fa-banana::after {
  content: '\e2e5\e2e5'; }

.fad.fa-hand-holding-skull::after, .fa-duotone.fa-hand-holding-skull::after {
  content: '\e1a4\e1a4'; }

.fad.fa-people-dress::after, .fa-duotone.fa-people-dress::after {
  content: '\e217\e217'; }

.fad.fa-loveseat::after, .fa-duotone.fa-loveseat::after {
  content: '\f4cc\f4cc'; }

.fad.fa-couch-small::after, .fa-duotone.fa-couch-small::after {
  content: '\f4cc\f4cc'; }

.fad.fa-tower-broadcast::after, .fa-duotone.fa-tower-broadcast::after {
  content: '\f519\f519'; }

.fad.fa-broadcast-tower::after, .fa-duotone.fa-broadcast-tower::after {
  content: '\f519\f519'; }

.fad.fa-truck-pickup::after, .fa-duotone.fa-truck-pickup::after {
  content: '\f63c\f63c'; }

.fad.fa-block-quote::after, .fa-duotone.fa-block-quote::after {
  content: '\e0b5\e0b5'; }

.fad.fa-up-long::after, .fa-duotone.fa-up-long::after {
  content: '\f30c\f30c'; }

.fad.fa-long-arrow-alt-up::after, .fa-duotone.fa-long-arrow-alt-up::after {
  content: '\f30c\f30c'; }

.fad.fa-stop::after, .fa-duotone.fa-stop::after {
  content: '\f04d\f04d'; }

.fad.fa-code-merge::after, .fa-duotone.fa-code-merge::after {
  content: '\f387\f387'; }

.fad.fa-money-check-dollar-pen::after, .fa-duotone.fa-money-check-dollar-pen::after {
  content: '\f873\f873'; }

.fad.fa-money-check-edit-alt::after, .fa-duotone.fa-money-check-edit-alt::after {
  content: '\f873\f873'; }

.fad.fa-up-from-line::after, .fa-duotone.fa-up-from-line::after {
  content: '\f346\f346'; }

.fad.fa-arrow-alt-from-bottom::after, .fa-duotone.fa-arrow-alt-from-bottom::after {
  content: '\f346\f346'; }

.fad.fa-upload::after, .fa-duotone.fa-upload::after {
  content: '\f093\f093'; }

.fad.fa-hurricane::after, .fa-duotone.fa-hurricane::after {
  content: '\f751\f751'; }

.fad.fa-grid-round-2-plus::after, .fa-duotone.fa-grid-round-2-plus::after {
  content: '\e5dc\e5dc'; }

.fad.fa-people-pants::after, .fa-duotone.fa-people-pants::after {
  content: '\e219\e219'; }

.fad.fa-mound::after, .fa-duotone.fa-mound::after {
  content: '\e52d\e52d'; }

.fad.fa-windsock::after, .fa-duotone.fa-windsock::after {
  content: '\f777\f777'; }

.fad.fa-circle-half::after, .fa-duotone.fa-circle-half::after {
  content: '\e110\e110'; }

.fad.fa-brake-warning::after, .fa-duotone.fa-brake-warning::after {
  content: '\e0c7\e0c7'; }

.fad.fa-toilet-portable::after, .fa-duotone.fa-toilet-portable::after {
  content: '\e583\e583'; }

.fad.fa-compact-disc::after, .fa-duotone.fa-compact-disc::after {
  content: '\f51f\f51f'; }

.fad.fa-file-arrow-down::after, .fa-duotone.fa-file-arrow-down::after {
  content: '\f56d\f56d'; }

.fad.fa-file-download::after, .fa-duotone.fa-file-download::after {
  content: '\f56d\f56d'; }

.fad.fa-saxophone-fire::after, .fa-duotone.fa-saxophone-fire::after {
  content: '\f8db\f8db'; }

.fad.fa-sax-hot::after, .fa-duotone.fa-sax-hot::after {
  content: '\f8db\f8db'; }

.fad.fa-camera-web-slash::after, .fa-duotone.fa-camera-web-slash::after {
  content: '\f833\f833'; }

.fad.fa-webcam-slash::after, .fa-duotone.fa-webcam-slash::after {
  content: '\f833\f833'; }

.fad.fa-folder-medical::after, .fa-duotone.fa-folder-medical::after {
  content: '\e18c\e18c'; }

.fad.fa-folder-gear::after, .fa-duotone.fa-folder-gear::after {
  content: '\e187\e187'; }

.fad.fa-folder-cog::after, .fa-duotone.fa-folder-cog::after {
  content: '\e187\e187'; }

.fad.fa-hand-wave::after, .fa-duotone.fa-hand-wave::after {
  content: '\e1a7\e1a7'; }

.fad.fa-arrow-up-arrow-down::after, .fa-duotone.fa-arrow-up-arrow-down::after {
  content: '\e099\e099'; }

.fad.fa-sort-up-down::after, .fa-duotone.fa-sort-up-down::after {
  content: '\e099\e099'; }

.fad.fa-caravan::after, .fa-duotone.fa-caravan::after {
  content: '\f8ff\f8ff'; }

.fad.fa-shield-cat::after, .fa-duotone.fa-shield-cat::after {
  content: '\e572\e572'; }

.fad.fa-message-slash::after, .fa-duotone.fa-message-slash::after {
  content: '\f4a9\f4a9'; }

.fad.fa-comment-alt-slash::after, .fa-duotone.fa-comment-alt-slash::after {
  content: '\f4a9\f4a9'; }

.fad.fa-bolt::after, .fa-duotone.fa-bolt::after {
  content: '\f0e7\f0e7'; }

.fad.fa-zap::after, .fa-duotone.fa-zap::after {
  content: '\f0e7\f0e7'; }

.fad.fa-trash-can-check::after, .fa-duotone.fa-trash-can-check::after {
  content: '\e2a9\e2a9'; }

.fad.fa-glass-water::after, .fa-duotone.fa-glass-water::after {
  content: '\e4f4\e4f4'; }

.fad.fa-oil-well::after, .fa-duotone.fa-oil-well::after {
  content: '\e532\e532'; }

.fad.fa-person-simple::after, .fa-duotone.fa-person-simple::after {
  content: '\e220\e220'; }

.fad.fa-vault::after, .fa-duotone.fa-vault::after {
  content: '\e2c5\e2c5'; }

.fad.fa-mars::after, .fa-duotone.fa-mars::after {
  content: '\f222\f222'; }

.fad.fa-toilet::after, .fa-duotone.fa-toilet::after {
  content: '\f7d8\f7d8'; }

.fad.fa-plane-circle-xmark::after, .fa-duotone.fa-plane-circle-xmark::after {
  content: '\e557\e557'; }

.fad.fa-yen-sign::after, .fa-duotone.fa-yen-sign::after {
  content: '\f157\f157'; }

.fad.fa-cny::after, .fa-duotone.fa-cny::after {
  content: '\f157\f157'; }

.fad.fa-jpy::after, .fa-duotone.fa-jpy::after {
  content: '\f157\f157'; }

.fad.fa-rmb::after, .fa-duotone.fa-rmb::after {
  content: '\f157\f157'; }

.fad.fa-yen::after, .fa-duotone.fa-yen::after {
  content: '\f157\f157'; }

.fad.fa-gear-code::after, .fa-duotone.fa-gear-code::after {
  content: '\e5e8\e5e8'; }

.fad.fa-notes::after, .fa-duotone.fa-notes::after {
  content: '\e202\e202'; }

.fad.fa-ruble-sign::after, .fa-duotone.fa-ruble-sign::after {
  content: '\f158\f158'; }

.fad.fa-rouble::after, .fa-duotone.fa-rouble::after {
  content: '\f158\f158'; }

.fad.fa-rub::after, .fa-duotone.fa-rub::after {
  content: '\f158\f158'; }

.fad.fa-ruble::after, .fa-duotone.fa-ruble::after {
  content: '\f158\f158'; }

.fad.fa-trash-undo::after, .fa-duotone.fa-trash-undo::after {
  content: '\f895\f895'; }

.fad.fa-trash-arrow-turn-left::after, .fa-duotone.fa-trash-arrow-turn-left::after {
  content: '\f895\f895'; }

.fad.fa-champagne-glass::after, .fa-duotone.fa-champagne-glass::after {
  content: '\f79e\f79e'; }

.fad.fa-glass-champagne::after, .fa-duotone.fa-glass-champagne::after {
  content: '\f79e\f79e'; }

.fad.fa-objects-align-center-horizontal::after, .fa-duotone.fa-objects-align-center-horizontal::after {
  content: '\e3bc\e3bc'; }

.fad.fa-sun::after, .fa-duotone.fa-sun::after {
  content: '\f185\f185'; }

.fad.fa-trash-can-slash::after, .fa-duotone.fa-trash-can-slash::after {
  content: '\e2ad\e2ad'; }

.fad.fa-trash-alt-slash::after, .fa-duotone.fa-trash-alt-slash::after {
  content: '\e2ad\e2ad'; }

.fad.fa-screen-users::after, .fa-duotone.fa-screen-users::after {
  content: '\f63d\f63d'; }

.fad.fa-users-class::after, .fa-duotone.fa-users-class::after {
  content: '\f63d\f63d'; }

.fad.fa-guitar::after, .fa-duotone.fa-guitar::after {
  content: '\f7a6\f7a6'; }

.fad.fa-square-arrow-left::after, .fa-duotone.fa-square-arrow-left::after {
  content: '\f33a\f33a'; }

.fad.fa-arrow-square-left::after, .fa-duotone.fa-arrow-square-left::after {
  content: '\f33a\f33a'; }

.fad.fa-square-8::after, .fa-duotone.fa-square-8::after {
  content: '\e25d\e25d'; }

.fad.fa-face-smile-hearts::after, .fa-duotone.fa-face-smile-hearts::after {
  content: '\e390\e390'; }

.fad.fa-brackets-square::after, .fa-duotone.fa-brackets-square::after {
  content: '\f7e9\f7e9'; }

.fad.fa-brackets::after, .fa-duotone.fa-brackets::after {
  content: '\f7e9\f7e9'; }

.fad.fa-laptop-arrow-down::after, .fa-duotone.fa-laptop-arrow-down::after {
  content: '\e1c6\e1c6'; }

.fad.fa-hockey-stick-puck::after, .fa-duotone.fa-hockey-stick-puck::after {
  content: '\e3ae\e3ae'; }

.fad.fa-house-tree::after, .fa-duotone.fa-house-tree::after {
  content: '\e1b3\e1b3'; }

.fad.fa-signal-fair::after, .fa-duotone.fa-signal-fair::after {
  content: '\f68d\f68d'; }

.fad.fa-signal-2::after, .fa-duotone.fa-signal-2::after {
  content: '\f68d\f68d'; }

.fad.fa-face-laugh-wink::after, .fa-duotone.fa-face-laugh-wink::after {
  content: '\f59c\f59c'; }

.fad.fa-laugh-wink::after, .fa-duotone.fa-laugh-wink::after {
  content: '\f59c\f59c'; }

.fad.fa-circle-dollar::after, .fa-duotone.fa-circle-dollar::after {
  content: '\f2e8\f2e8'; }

.fad.fa-dollar-circle::after, .fa-duotone.fa-dollar-circle::after {
  content: '\f2e8\f2e8'; }

.fad.fa-usd-circle::after, .fa-duotone.fa-usd-circle::after {
  content: '\f2e8\f2e8'; }

.fad.fa-horse-head::after, .fa-duotone.fa-horse-head::after {
  content: '\f7ab\f7ab'; }

.fad.fa-arrows-repeat::after, .fa-duotone.fa-arrows-repeat::after {
  content: '\f364\f364'; }

.fad.fa-repeat-alt::after, .fa-duotone.fa-repeat-alt::after {
  content: '\f364\f364'; }

.fad.fa-bore-hole::after, .fa-duotone.fa-bore-hole::after {
  content: '\e4c3\e4c3'; }

.fad.fa-industry::after, .fa-duotone.fa-industry::after {
  content: '\f275\f275'; }

.fad.fa-image-polaroid::after, .fa-duotone.fa-image-polaroid::after {
  content: '\f8c4\f8c4'; }

.fad.fa-wave-triangle::after, .fa-duotone.fa-wave-triangle::after {
  content: '\f89a\f89a'; }

.fad.fa-person-running-fast::after, .fa-duotone.fa-person-running-fast::after {
  content: '\e5ff\e5ff'; }

.fad.fa-circle-down::after, .fa-duotone.fa-circle-down::after {
  content: '\f358\f358'; }

.fad.fa-arrow-alt-circle-down::after, .fa-duotone.fa-arrow-alt-circle-down::after {
  content: '\f358\f358'; }

.fad.fa-grill::after, .fa-duotone.fa-grill::after {
  content: '\e5a3\e5a3'; }

.fad.fa-arrows-turn-to-dots::after, .fa-duotone.fa-arrows-turn-to-dots::after {
  content: '\e4c1\e4c1'; }

.fad.fa-chart-mixed::after, .fa-duotone.fa-chart-mixed::after {
  content: '\f643\f643'; }

.fad.fa-analytics::after, .fa-duotone.fa-analytics::after {
  content: '\f643\f643'; }

.fad.fa-florin-sign::after, .fa-duotone.fa-florin-sign::after {
  content: '\e184\e184'; }

.fad.fa-arrow-down-short-wide::after, .fa-duotone.fa-arrow-down-short-wide::after {
  content: '\f884\f884'; }

.fad.fa-sort-amount-desc::after, .fa-duotone.fa-sort-amount-desc::after {
  content: '\f884\f884'; }

.fad.fa-sort-amount-down-alt::after, .fa-duotone.fa-sort-amount-down-alt::after {
  content: '\f884\f884'; }

.fad.fa-less-than::after, .fa-duotone.fa-less-than::after {
  content: '\3c\3c'; }

.fad.fa-display-code::after, .fa-duotone.fa-display-code::after {
  content: '\e165\e165'; }

.fad.fa-desktop-code::after, .fa-duotone.fa-desktop-code::after {
  content: '\e165\e165'; }

.fad.fa-face-drooling::after, .fa-duotone.fa-face-drooling::after {
  content: '\e372\e372'; }

.fad.fa-oil-temperature::after, .fa-duotone.fa-oil-temperature::after {
  content: '\f614\f614'; }

.fad.fa-oil-temp::after, .fa-duotone.fa-oil-temp::after {
  content: '\f614\f614'; }

.fad.fa-square-question::after, .fa-duotone.fa-square-question::after {
  content: '\f2fd\f2fd'; }

.fad.fa-question-square::after, .fa-duotone.fa-question-square::after {
  content: '\f2fd\f2fd'; }

.fad.fa-air-conditioner::after, .fa-duotone.fa-air-conditioner::after {
  content: '\f8f4\f8f4'; }

.fad.fa-angle-down::after, .fa-duotone.fa-angle-down::after {
  content: '\f107\f107'; }

.fad.fa-mountains::after, .fa-duotone.fa-mountains::after {
  content: '\f6fd\f6fd'; }

.fad.fa-omega::after, .fa-duotone.fa-omega::after {
  content: '\f67a\f67a'; }

.fad.fa-car-tunnel::after, .fa-duotone.fa-car-tunnel::after {
  content: '\e4de\e4de'; }

.fad.fa-person-dolly-empty::after, .fa-duotone.fa-person-dolly-empty::after {
  content: '\f4d1\f4d1'; }

.fad.fa-pan-food::after, .fa-duotone.fa-pan-food::after {
  content: '\e42b\e42b'; }

.fad.fa-head-side-cough::after, .fa-duotone.fa-head-side-cough::after {
  content: '\e061\e061'; }

.fad.fa-grip-lines::after, .fa-duotone.fa-grip-lines::after {
  content: '\f7a4\f7a4'; }

.fad.fa-thumbs-down::after, .fa-duotone.fa-thumbs-down::after {
  content: '\f165\f165'; }

.fad.fa-user-lock::after, .fa-duotone.fa-user-lock::after {
  content: '\f502\f502'; }

.fad.fa-arrow-right-long::after, .fa-duotone.fa-arrow-right-long::after {
  content: '\f178\f178'; }

.fad.fa-long-arrow-right::after, .fa-duotone.fa-long-arrow-right::after {
  content: '\f178\f178'; }

.fad.fa-tickets-airline::after, .fa-duotone.fa-tickets-airline::after {
  content: '\e29b\e29b'; }

.fad.fa-anchor-circle-xmark::after, .fa-duotone.fa-anchor-circle-xmark::after {
  content: '\e4ac\e4ac'; }

.fad.fa-ellipsis::after, .fa-duotone.fa-ellipsis::after {
  content: '\f141\f141'; }

.fad.fa-ellipsis-h::after, .fa-duotone.fa-ellipsis-h::after {
  content: '\f141\f141'; }

.fad.fa-nfc-slash::after, .fa-duotone.fa-nfc-slash::after {
  content: '\e1fc\e1fc'; }

.fad.fa-chess-pawn::after, .fa-duotone.fa-chess-pawn::after {
  content: '\f443\f443'; }

.fad.fa-kit-medical::after, .fa-duotone.fa-kit-medical::after {
  content: '\f479\f479'; }

.fad.fa-first-aid::after, .fa-duotone.fa-first-aid::after {
  content: '\f479\f479'; }

.fad.fa-grid-2-plus::after, .fa-duotone.fa-grid-2-plus::after {
  content: '\e197\e197'; }

.fad.fa-bells::after, .fa-duotone.fa-bells::after {
  content: '\f77f\f77f'; }

.fad.fa-person-through-window::after, .fa-duotone.fa-person-through-window::after {
  content: '\e5a9\e5a9'; }

.fad.fa-toolbox::after, .fa-duotone.fa-toolbox::after {
  content: '\f552\f552'; }

.fad.fa-envelope-dot::after, .fa-duotone.fa-envelope-dot::after {
  content: '\e16f\e16f'; }

.fad.fa-envelope-badge::after, .fa-duotone.fa-envelope-badge::after {
  content: '\e16f\e16f'; }

.fad.fa-hands-holding-circle::after, .fa-duotone.fa-hands-holding-circle::after {
  content: '\e4fb\e4fb'; }

.fad.fa-bug::after, .fa-duotone.fa-bug::after {
  content: '\f188\f188'; }

.fad.fa-bowl-chopsticks::after, .fa-duotone.fa-bowl-chopsticks::after {
  content: '\e2e9\e2e9'; }

.fad.fa-credit-card::after, .fa-duotone.fa-credit-card::after {
  content: '\f09d\f09d'; }

.fad.fa-credit-card-alt::after, .fa-duotone.fa-credit-card-alt::after {
  content: '\f09d\f09d'; }

.fad.fa-circle-s::after, .fa-duotone.fa-circle-s::after {
  content: '\e121\e121'; }

.fad.fa-box-ballot::after, .fa-duotone.fa-box-ballot::after {
  content: '\f735\f735'; }

.fad.fa-car::after, .fa-duotone.fa-car::after {
  content: '\f1b9\f1b9'; }

.fad.fa-automobile::after, .fa-duotone.fa-automobile::after {
  content: '\f1b9\f1b9'; }

.fad.fa-hand-holding-hand::after, .fa-duotone.fa-hand-holding-hand::after {
  content: '\e4f7\e4f7'; }

.fad.fa-user-tie-hair::after, .fa-duotone.fa-user-tie-hair::after {
  content: '\e45f\e45f'; }

.fad.fa-podium-star::after, .fa-duotone.fa-podium-star::after {
  content: '\f758\f758'; }

.fad.fa-user-hair-mullet::after, .fa-duotone.fa-user-hair-mullet::after {
  content: '\e45c\e45c'; }

.fad.fa-business-front::after, .fa-duotone.fa-business-front::after {
  content: '\e45c\e45c'; }

.fad.fa-party-back::after, .fa-duotone.fa-party-back::after {
  content: '\e45c\e45c'; }

.fad.fa-trian-balbot::after, .fa-duotone.fa-trian-balbot::after {
  content: '\e45c\e45c'; }

.fad.fa-microphone-stand::after, .fa-duotone.fa-microphone-stand::after {
  content: '\f8cb\f8cb'; }

.fad.fa-book-open-reader::after, .fa-duotone.fa-book-open-reader::after {
  content: '\f5da\f5da'; }

.fad.fa-book-reader::after, .fa-duotone.fa-book-reader::after {
  content: '\f5da\f5da'; }

.fad.fa-family-dress::after, .fa-duotone.fa-family-dress::after {
  content: '\e301\e301'; }

.fad.fa-circle-x::after, .fa-duotone.fa-circle-x::after {
  content: '\e12e\e12e'; }

.fad.fa-cabin::after, .fa-duotone.fa-cabin::after {
  content: '\e46d\e46d'; }

.fad.fa-mountain-sun::after, .fa-duotone.fa-mountain-sun::after {
  content: '\e52f\e52f'; }

.fad.fa-chart-simple-horizontal::after, .fa-duotone.fa-chart-simple-horizontal::after {
  content: '\e474\e474'; }

.fad.fa-arrows-left-right-to-line::after, .fa-duotone.fa-arrows-left-right-to-line::after {
  content: '\e4ba\e4ba'; }

.fad.fa-hand-back-point-left::after, .fa-duotone.fa-hand-back-point-left::after {
  content: '\e19f\e19f'; }

.fad.fa-message-dots::after, .fa-duotone.fa-message-dots::after {
  content: '\f4a3\f4a3'; }

.fad.fa-comment-alt-dots::after, .fa-duotone.fa-comment-alt-dots::after {
  content: '\f4a3\f4a3'; }

.fad.fa-messaging::after, .fa-duotone.fa-messaging::after {
  content: '\f4a3\f4a3'; }

.fad.fa-file-heart::after, .fa-duotone.fa-file-heart::after {
  content: '\e176\e176'; }

.fad.fa-beer-mug::after, .fa-duotone.fa-beer-mug::after {
  content: '\e0b3\e0b3'; }

.fad.fa-beer-foam::after, .fa-duotone.fa-beer-foam::after {
  content: '\e0b3\e0b3'; }

.fad.fa-dice-d20::after, .fa-duotone.fa-dice-d20::after {
  content: '\f6cf\f6cf'; }

.fad.fa-drone::after, .fa-duotone.fa-drone::after {
  content: '\f85f\f85f'; }

.fad.fa-truck-droplet::after, .fa-duotone.fa-truck-droplet::after {
  content: '\e58c\e58c'; }

.fad.fa-file-circle-xmark::after, .fa-duotone.fa-file-circle-xmark::after {
  content: '\e5a1\e5a1'; }

.fad.fa-temperature-arrow-up::after, .fa-duotone.fa-temperature-arrow-up::after {
  content: '\e040\e040'; }

.fad.fa-temperature-up::after, .fa-duotone.fa-temperature-up::after {
  content: '\e040\e040'; }

.fad.fa-medal::after, .fa-duotone.fa-medal::after {
  content: '\f5a2\f5a2'; }

.fad.fa-person-fairy::after, .fa-duotone.fa-person-fairy::after {
  content: '\e608\e608'; }

.fad.fa-bed::after, .fa-duotone.fa-bed::after {
  content: '\f236\f236'; }

.fad.fa-book-copy::after, .fa-duotone.fa-book-copy::after {
  content: '\e0be\e0be'; }

.fad.fa-square-h::after, .fa-duotone.fa-square-h::after {
  content: '\f0fd\f0fd'; }

.fad.fa-h-square::after, .fa-duotone.fa-h-square::after {
  content: '\f0fd\f0fd'; }

.fad.fa-square-c::after, .fa-duotone.fa-square-c::after {
  content: '\e266\e266'; }

.fad.fa-clock-two::after, .fa-duotone.fa-clock-two::after {
  content: '\e35a\e35a'; }

.fad.fa-square-ellipsis-vertical::after, .fa-duotone.fa-square-ellipsis-vertical::after {
  content: '\e26f\e26f'; }

.fad.fa-calendar-users::after, .fa-duotone.fa-calendar-users::after {
  content: '\e5e2\e5e2'; }

.fad.fa-podcast::after, .fa-duotone.fa-podcast::after {
  content: '\f2ce\f2ce'; }

.fad.fa-bee::after, .fa-duotone.fa-bee::after {
  content: '\e0b2\e0b2'; }

.fad.fa-temperature-full::after, .fa-duotone.fa-temperature-full::after {
  content: '\f2c7\f2c7'; }

.fad.fa-temperature-4::after, .fa-duotone.fa-temperature-4::after {
  content: '\f2c7\f2c7'; }

.fad.fa-thermometer-4::after, .fa-duotone.fa-thermometer-4::after {
  content: '\f2c7\f2c7'; }

.fad.fa-thermometer-full::after, .fa-duotone.fa-thermometer-full::after {
  content: '\f2c7\f2c7'; }

.fad.fa-bell::after, .fa-duotone.fa-bell::after {
  content: '\f0f3\f0f3'; }

.fad.fa-candy-bar::after, .fa-duotone.fa-candy-bar::after {
  content: '\e3e8\e3e8'; }

.fad.fa-chocolate-bar::after, .fa-duotone.fa-chocolate-bar::after {
  content: '\e3e8\e3e8'; }

.fad.fa-xmark-large::after, .fa-duotone.fa-xmark-large::after {
  content: '\e59b\e59b'; }

.fad.fa-pinata::after, .fa-duotone.fa-pinata::after {
  content: '\e3c3\e3c3'; }

.fad.fa-arrows-from-line::after, .fa-duotone.fa-arrows-from-line::after {
  content: '\e0a4\e0a4'; }

.fad.fa-superscript::after, .fa-duotone.fa-superscript::after {
  content: '\f12b\f12b'; }

.fad.fa-bowl-spoon::after, .fa-duotone.fa-bowl-spoon::after {
  content: '\e3e0\e3e0'; }

.fad.fa-hexagon-check::after, .fa-duotone.fa-hexagon-check::after {
  content: '\e416\e416'; }

.fad.fa-plug-circle-xmark::after, .fa-duotone.fa-plug-circle-xmark::after {
  content: '\e560\e560'; }

.fad.fa-star-of-life::after, .fa-duotone.fa-star-of-life::after {
  content: '\f621\f621'; }

.fad.fa-phone-slash::after, .fa-duotone.fa-phone-slash::after {
  content: '\f3dd\f3dd'; }

.fad.fa-traffic-light-stop::after, .fa-duotone.fa-traffic-light-stop::after {
  content: '\f63a\f63a'; }

.fad.fa-paint-roller::after, .fa-duotone.fa-paint-roller::after {
  content: '\f5aa\f5aa'; }

.fad.fa-accent-grave::after, .fa-duotone.fa-accent-grave::after {
  content: '\60\60'; }

.fad.fa-handshake-angle::after, .fa-duotone.fa-handshake-angle::after {
  content: '\f4c4\f4c4'; }

.fad.fa-hands-helping::after, .fa-duotone.fa-hands-helping::after {
  content: '\f4c4\f4c4'; }

.fad.fa-circle-0::after, .fa-duotone.fa-circle-0::after {
  content: '\e0ed\e0ed'; }

.fad.fa-dial-med-low::after, .fa-duotone.fa-dial-med-low::after {
  content: '\e160\e160'; }

.fad.fa-location-dot::after, .fa-duotone.fa-location-dot::after {
  content: '\f3c5\f3c5'; }

.fad.fa-map-marker-alt::after, .fa-duotone.fa-map-marker-alt::after {
  content: '\f3c5\f3c5'; }

.fad.fa-crab::after, .fa-duotone.fa-crab::after {
  content: '\e3ff\e3ff'; }

.fad.fa-box-open-full::after, .fa-duotone.fa-box-open-full::after {
  content: '\f49c\f49c'; }

.fad.fa-box-full::after, .fa-duotone.fa-box-full::after {
  content: '\f49c\f49c'; }

.fad.fa-file::after, .fa-duotone.fa-file::after {
  content: '\f15b\f15b'; }

.fad.fa-greater-than::after, .fa-duotone.fa-greater-than::after {
  content: '\3e\3e'; }

.fad.fa-quotes::after, .fa-duotone.fa-quotes::after {
  content: '\e234\e234'; }

.fad.fa-pretzel::after, .fa-duotone.fa-pretzel::after {
  content: '\e441\e441'; }

.fad.fa-person-swimming::after, .fa-duotone.fa-person-swimming::after {
  content: '\f5c4\f5c4'; }

.fad.fa-swimmer::after, .fa-duotone.fa-swimmer::after {
  content: '\f5c4\f5c4'; }

.fad.fa-arrow-down::after, .fa-duotone.fa-arrow-down::after {
  content: '\f063\f063'; }

.fad.fa-user-robot-xmarks::after, .fa-duotone.fa-user-robot-xmarks::after {
  content: '\e4a7\e4a7'; }

.fad.fa-message-quote::after, .fa-duotone.fa-message-quote::after {
  content: '\e1e4\e1e4'; }

.fad.fa-comment-alt-quote::after, .fa-duotone.fa-comment-alt-quote::after {
  content: '\e1e4\e1e4'; }

.fad.fa-candy-corn::after, .fa-duotone.fa-candy-corn::after {
  content: '\f6bd\f6bd'; }

.fad.fa-folder-magnifying-glass::after, .fa-duotone.fa-folder-magnifying-glass::after {
  content: '\e18b\e18b'; }

.fad.fa-folder-search::after, .fa-duotone.fa-folder-search::after {
  content: '\e18b\e18b'; }

.fad.fa-notebook::after, .fa-duotone.fa-notebook::after {
  content: '\e201\e201'; }

.fad.fa-droplet::after, .fa-duotone.fa-droplet::after {
  content: '\f043\f043'; }

.fad.fa-tint::after, .fa-duotone.fa-tint::after {
  content: '\f043\f043'; }

.fad.fa-bullseye-pointer::after, .fa-duotone.fa-bullseye-pointer::after {
  content: '\f649\f649'; }

.fad.fa-eraser::after, .fa-duotone.fa-eraser::after {
  content: '\f12d\f12d'; }

.fad.fa-hexagon-image::after, .fa-duotone.fa-hexagon-image::after {
  content: '\e504\e504'; }

.fad.fa-earth-americas::after, .fa-duotone.fa-earth-americas::after {
  content: '\f57d\f57d'; }

.fad.fa-earth::after, .fa-duotone.fa-earth::after {
  content: '\f57d\f57d'; }

.fad.fa-earth-america::after, .fa-duotone.fa-earth-america::after {
  content: '\f57d\f57d'; }

.fad.fa-globe-americas::after, .fa-duotone.fa-globe-americas::after {
  content: '\f57d\f57d'; }

.fad.fa-crate-apple::after, .fa-duotone.fa-crate-apple::after {
  content: '\f6b1\f6b1'; }

.fad.fa-apple-crate::after, .fa-duotone.fa-apple-crate::after {
  content: '\f6b1\f6b1'; }

.fad.fa-person-burst::after, .fa-duotone.fa-person-burst::after {
  content: '\e53b\e53b'; }

.fad.fa-game-board::after, .fa-duotone.fa-game-board::after {
  content: '\f867\f867'; }

.fad.fa-hat-chef::after, .fa-duotone.fa-hat-chef::after {
  content: '\f86b\f86b'; }

.fad.fa-hand-back-point-right::after, .fa-duotone.fa-hand-back-point-right::after {
  content: '\e1a1\e1a1'; }

.fad.fa-dove::after, .fa-duotone.fa-dove::after {
  content: '\f4ba\f4ba'; }

.fad.fa-snowflake-droplets::after, .fa-duotone.fa-snowflake-droplets::after {
  content: '\e5c1\e5c1'; }

.fad.fa-battery-empty::after, .fa-duotone.fa-battery-empty::after {
  content: '\f244\f244'; }

.fad.fa-battery-0::after, .fa-duotone.fa-battery-0::after {
  content: '\f244\f244'; }

.fad.fa-grid-4::after, .fa-duotone.fa-grid-4::after {
  content: '\e198\e198'; }

.fad.fa-socks::after, .fa-duotone.fa-socks::after {
  content: '\f696\f696'; }

.fad.fa-face-sunglasses::after, .fa-duotone.fa-face-sunglasses::after {
  content: '\e398\e398'; }

.fad.fa-inbox::after, .fa-duotone.fa-inbox::after {
  content: '\f01c\f01c'; }

.fad.fa-square-0::after, .fa-duotone.fa-square-0::after {
  content: '\e255\e255'; }

.fad.fa-section::after, .fa-duotone.fa-section::after {
  content: '\e447\e447'; }

.fad.fa-square-this-way-up::after, .fa-duotone.fa-square-this-way-up::after {
  content: '\f49f\f49f'; }

.fad.fa-box-up::after, .fa-duotone.fa-box-up::after {
  content: '\f49f\f49f'; }

.fad.fa-gauge-high::after, .fa-duotone.fa-gauge-high::after {
  content: '\f625\f625'; }

.fad.fa-tachometer-alt::after, .fa-duotone.fa-tachometer-alt::after {
  content: '\f625\f625'; }

.fad.fa-tachometer-alt-fast::after, .fa-duotone.fa-tachometer-alt-fast::after {
  content: '\f625\f625'; }

.fad.fa-square-ampersand::after, .fa-duotone.fa-square-ampersand::after {
  content: '\e260\e260'; }

.fad.fa-envelope-open-text::after, .fa-duotone.fa-envelope-open-text::after {
  content: '\f658\f658'; }

.fad.fa-lamp-desk::after, .fa-duotone.fa-lamp-desk::after {
  content: '\e014\e014'; }

.fad.fa-hospital::after, .fa-duotone.fa-hospital::after {
  content: '\f0f8\f0f8'; }

.fad.fa-hospital-alt::after, .fa-duotone.fa-hospital-alt::after {
  content: '\f0f8\f0f8'; }

.fad.fa-hospital-wide::after, .fa-duotone.fa-hospital-wide::after {
  content: '\f0f8\f0f8'; }

.fad.fa-poll-people::after, .fa-duotone.fa-poll-people::after {
  content: '\f759\f759'; }

.fad.fa-whiskey-glass-ice::after, .fa-duotone.fa-whiskey-glass-ice::after {
  content: '\f7a1\f7a1'; }

.fad.fa-glass-whiskey-rocks::after, .fa-duotone.fa-glass-whiskey-rocks::after {
  content: '\f7a1\f7a1'; }

.fad.fa-wine-bottle::after, .fa-duotone.fa-wine-bottle::after {
  content: '\f72f\f72f'; }

.fad.fa-chess-rook::after, .fa-duotone.fa-chess-rook::after {
  content: '\f447\f447'; }

.fad.fa-user-bounty-hunter::after, .fa-duotone.fa-user-bounty-hunter::after {
  content: '\e2bf\e2bf'; }

.fad.fa-bars-staggered::after, .fa-duotone.fa-bars-staggered::after {
  content: '\f550\f550'; }

.fad.fa-reorder::after, .fa-duotone.fa-reorder::after {
  content: '\f550\f550'; }

.fad.fa-stream::after, .fa-duotone.fa-stream::after {
  content: '\f550\f550'; }

.fad.fa-diagram-sankey::after, .fa-duotone.fa-diagram-sankey::after {
  content: '\e158\e158'; }

.fad.fa-cloud-hail-mixed::after, .fa-duotone.fa-cloud-hail-mixed::after {
  content: '\f73a\f73a'; }

.fad.fa-circle-up-left::after, .fa-duotone.fa-circle-up-left::after {
  content: '\e128\e128'; }

.fad.fa-dharmachakra::after, .fa-duotone.fa-dharmachakra::after {
  content: '\f655\f655'; }

.fad.fa-objects-align-left::after, .fa-duotone.fa-objects-align-left::after {
  content: '\e3be\e3be'; }

.fad.fa-oil-can-drip::after, .fa-duotone.fa-oil-can-drip::after {
  content: '\e205\e205'; }

.fad.fa-face-smiling-hands::after, .fa-duotone.fa-face-smiling-hands::after {
  content: '\e396\e396'; }

.fad.fa-broccoli::after, .fa-duotone.fa-broccoli::after {
  content: '\e3e2\e3e2'; }

.fad.fa-route-interstate::after, .fa-duotone.fa-route-interstate::after {
  content: '\f61b\f61b'; }

.fad.fa-ear-muffs::after, .fa-duotone.fa-ear-muffs::after {
  content: '\f795\f795'; }

.fad.fa-hotdog::after, .fa-duotone.fa-hotdog::after {
  content: '\f80f\f80f'; }

.fad.fa-transporter-empty::after, .fa-duotone.fa-transporter-empty::after {
  content: '\e046\e046'; }

.fad.fa-person-walking-with-cane::after, .fa-duotone.fa-person-walking-with-cane::after {
  content: '\f29d\f29d'; }

.fad.fa-blind::after, .fa-duotone.fa-blind::after {
  content: '\f29d\f29d'; }

.fad.fa-angle-90::after, .fa-duotone.fa-angle-90::after {
  content: '\e08d\e08d'; }

.fad.fa-rectangle-terminal::after, .fa-duotone.fa-rectangle-terminal::after {
  content: '\e236\e236'; }

.fad.fa-kite::after, .fa-duotone.fa-kite::after {
  content: '\f6f4\f6f4'; }

.fad.fa-drum::after, .fa-duotone.fa-drum::after {
  content: '\f569\f569'; }

.fad.fa-scrubber::after, .fa-duotone.fa-scrubber::after {
  content: '\f2f8\f2f8'; }

.fad.fa-ice-cream::after, .fa-duotone.fa-ice-cream::after {
  content: '\f810\f810'; }

.fad.fa-heart-circle-bolt::after, .fa-duotone.fa-heart-circle-bolt::after {
  content: '\e4fc\e4fc'; }

.fad.fa-fish-bones::after, .fa-duotone.fa-fish-bones::after {
  content: '\e304\e304'; }

.fad.fa-deer-rudolph::after, .fa-duotone.fa-deer-rudolph::after {
  content: '\f78f\f78f'; }

.fad.fa-fax::after, .fa-duotone.fa-fax::after {
  content: '\f1ac\f1ac'; }

.fad.fa-paragraph::after, .fa-duotone.fa-paragraph::after {
  content: '\f1dd\f1dd'; }

.fad.fa-head-side-heart::after, .fa-duotone.fa-head-side-heart::after {
  content: '\e1aa\e1aa'; }

.fad.fa-square-e::after, .fa-duotone.fa-square-e::after {
  content: '\e26d\e26d'; }

.fad.fa-meter-fire::after, .fa-duotone.fa-meter-fire::after {
  content: '\e1eb\e1eb'; }

.fad.fa-cloud-hail::after, .fa-duotone.fa-cloud-hail::after {
  content: '\f739\f739'; }

.fad.fa-check-to-slot::after, .fa-duotone.fa-check-to-slot::after {
  content: '\f772\f772'; }

.fad.fa-vote-yea::after, .fa-duotone.fa-vote-yea::after {
  content: '\f772\f772'; }

.fad.fa-money-from-bracket::after, .fa-duotone.fa-money-from-bracket::after {
  content: '\e312\e312'; }

.fad.fa-star-half::after, .fa-duotone.fa-star-half::after {
  content: '\f089\f089'; }

.fad.fa-car-bus::after, .fa-duotone.fa-car-bus::after {
  content: '\f85a\f85a'; }

.fad.fa-speaker::after, .fa-duotone.fa-speaker::after {
  content: '\f8df\f8df'; }

.fad.fa-timer::after, .fa-duotone.fa-timer::after {
  content: '\e29e\e29e'; }

.fad.fa-boxes-stacked::after, .fa-duotone.fa-boxes-stacked::after {
  content: '\f468\f468'; }

.fad.fa-boxes::after, .fa-duotone.fa-boxes::after {
  content: '\f468\f468'; }

.fad.fa-boxes-alt::after, .fa-duotone.fa-boxes-alt::after {
  content: '\f468\f468'; }

.fad.fa-grill-hot::after, .fa-duotone.fa-grill-hot::after {
  content: '\e5a5\e5a5'; }

.fad.fa-ballot-check::after, .fa-duotone.fa-ballot-check::after {
  content: '\f733\f733'; }

.fad.fa-link::after, .fa-duotone.fa-link::after {
  content: '\f0c1\f0c1'; }

.fad.fa-chain::after, .fa-duotone.fa-chain::after {
  content: '\f0c1\f0c1'; }

.fad.fa-ear-listen::after, .fa-duotone.fa-ear-listen::after {
  content: '\f2a2\f2a2'; }

.fad.fa-assistive-listening-systems::after, .fa-duotone.fa-assistive-listening-systems::after {
  content: '\f2a2\f2a2'; }

.fad.fa-file-minus::after, .fa-duotone.fa-file-minus::after {
  content: '\f318\f318'; }

.fad.fa-tree-city::after, .fa-duotone.fa-tree-city::after {
  content: '\e587\e587'; }

.fad.fa-play::after, .fa-duotone.fa-play::after {
  content: '\f04b\f04b'; }

.fad.fa-font::after, .fa-duotone.fa-font::after {
  content: '\f031\f031'; }

.fad.fa-cup-togo::after, .fa-duotone.fa-cup-togo::after {
  content: '\f6c5\f6c5'; }

.fad.fa-coffee-togo::after, .fa-duotone.fa-coffee-togo::after {
  content: '\f6c5\f6c5'; }

.fad.fa-square-down-left::after, .fa-duotone.fa-square-down-left::after {
  content: '\e26b\e26b'; }

.fad.fa-burger-lettuce::after, .fa-duotone.fa-burger-lettuce::after {
  content: '\e3e3\e3e3'; }

.fad.fa-rupiah-sign::after, .fa-duotone.fa-rupiah-sign::after {
  content: '\e23d\e23d'; }

.fad.fa-magnifying-glass::after, .fa-duotone.fa-magnifying-glass::after {
  content: '\f002\f002'; }

.fad.fa-search::after, .fa-duotone.fa-search::after {
  content: '\f002\f002'; }

.fad.fa-table-tennis-paddle-ball::after, .fa-duotone.fa-table-tennis-paddle-ball::after {
  content: '\f45d\f45d'; }

.fad.fa-ping-pong-paddle-ball::after, .fa-duotone.fa-ping-pong-paddle-ball::after {
  content: '\f45d\f45d'; }

.fad.fa-table-tennis::after, .fa-duotone.fa-table-tennis::after {
  content: '\f45d\f45d'; }

.fad.fa-person-dots-from-line::after, .fa-duotone.fa-person-dots-from-line::after {
  content: '\f470\f470'; }

.fad.fa-diagnoses::after, .fa-duotone.fa-diagnoses::after {
  content: '\f470\f470'; }

.fad.fa-chevrons-down::after, .fa-duotone.fa-chevrons-down::after {
  content: '\f322\f322'; }

.fad.fa-chevron-double-down::after, .fa-duotone.fa-chevron-double-down::after {
  content: '\f322\f322'; }

.fad.fa-trash-can-arrow-up::after, .fa-duotone.fa-trash-can-arrow-up::after {
  content: '\f82a\f82a'; }

.fad.fa-trash-restore-alt::after, .fa-duotone.fa-trash-restore-alt::after {
  content: '\f82a\f82a'; }

.fad.fa-signal-good::after, .fa-duotone.fa-signal-good::after {
  content: '\f68e\f68e'; }

.fad.fa-signal-3::after, .fa-duotone.fa-signal-3::after {
  content: '\f68e\f68e'; }

.fad.fa-location-question::after, .fa-duotone.fa-location-question::after {
  content: '\f60b\f60b'; }

.fad.fa-map-marker-question::after, .fa-duotone.fa-map-marker-question::after {
  content: '\f60b\f60b'; }

.fad.fa-floppy-disk-circle-xmark::after, .fa-duotone.fa-floppy-disk-circle-xmark::after {
  content: '\e181\e181'; }

.fad.fa-floppy-disk-times::after, .fa-duotone.fa-floppy-disk-times::after {
  content: '\e181\e181'; }

.fad.fa-save-circle-xmark::after, .fa-duotone.fa-save-circle-xmark::after {
  content: '\e181\e181'; }

.fad.fa-save-times::after, .fa-duotone.fa-save-times::after {
  content: '\e181\e181'; }

.fad.fa-naira-sign::after, .fa-duotone.fa-naira-sign::after {
  content: '\e1f6\e1f6'; }

.fad.fa-peach::after, .fa-duotone.fa-peach::after {
  content: '\e20b\e20b'; }

.fad.fa-taxi-bus::after, .fa-duotone.fa-taxi-bus::after {
  content: '\e298\e298'; }

.fad.fa-bracket-curly::after, .fa-duotone.fa-bracket-curly::after {
  content: '\7b\7b'; }

.fad.fa-bracket-curly-left::after, .fa-duotone.fa-bracket-curly-left::after {
  content: '\7b\7b'; }

.fad.fa-lobster::after, .fa-duotone.fa-lobster::after {
  content: '\e421\e421'; }

.fad.fa-cart-flatbed-empty::after, .fa-duotone.fa-cart-flatbed-empty::after {
  content: '\f476\f476'; }

.fad.fa-dolly-flatbed-empty::after, .fa-duotone.fa-dolly-flatbed-empty::after {
  content: '\f476\f476'; }

.fad.fa-colon::after, .fa-duotone.fa-colon::after {
  content: '\3a\3a'; }

.fad.fa-cart-arrow-down::after, .fa-duotone.fa-cart-arrow-down::after {
  content: '\f218\f218'; }

.fad.fa-wand::after, .fa-duotone.fa-wand::after {
  content: '\f72a\f72a'; }

.fad.fa-walkie-talkie::after, .fa-duotone.fa-walkie-talkie::after {
  content: '\f8ef\f8ef'; }

.fad.fa-file-pen::after, .fa-duotone.fa-file-pen::after {
  content: '\f31c\f31c'; }

.fad.fa-file-edit::after, .fa-duotone.fa-file-edit::after {
  content: '\f31c\f31c'; }

.fad.fa-receipt::after, .fa-duotone.fa-receipt::after {
  content: '\f543\f543'; }

.fad.fa-table-picnic::after, .fa-duotone.fa-table-picnic::after {
  content: '\e32d\e32d'; }

.fad.fa-square-pen::after, .fa-duotone.fa-square-pen::after {
  content: '\f14b\f14b'; }

.fad.fa-pen-square::after, .fa-duotone.fa-pen-square::after {
  content: '\f14b\f14b'; }

.fad.fa-pencil-square::after, .fa-duotone.fa-pencil-square::after {
  content: '\f14b\f14b'; }

.fad.fa-circle-microphone-lines::after, .fa-duotone.fa-circle-microphone-lines::after {
  content: '\e117\e117'; }

.fad.fa-microphone-circle-alt::after, .fa-duotone.fa-microphone-circle-alt::after {
  content: '\e117\e117'; }

.fad.fa-display-slash::after, .fa-duotone.fa-display-slash::after {
  content: '\e2fa\e2fa'; }

.fad.fa-desktop-slash::after, .fa-duotone.fa-desktop-slash::after {
  content: '\e2fa\e2fa'; }

.fad.fa-suitcase-rolling::after, .fa-duotone.fa-suitcase-rolling::after {
  content: '\f5c1\f5c1'; }

.fad.fa-person-circle-exclamation::after, .fa-duotone.fa-person-circle-exclamation::after {
  content: '\e53f\e53f'; }

.fad.fa-transporter-2::after, .fa-duotone.fa-transporter-2::after {
  content: '\e044\e044'; }

.fad.fa-hands-holding-diamond::after, .fa-duotone.fa-hands-holding-diamond::after {
  content: '\f47c\f47c'; }

.fad.fa-hand-receiving::after, .fa-duotone.fa-hand-receiving::after {
  content: '\f47c\f47c'; }

.fad.fa-money-bill-simple-wave::after, .fa-duotone.fa-money-bill-simple-wave::after {
  content: '\e1f2\e1f2'; }

.fad.fa-chevron-down::after, .fa-duotone.fa-chevron-down::after {
  content: '\f078\f078'; }

.fad.fa-battery-full::after, .fa-duotone.fa-battery-full::after {
  content: '\f240\f240'; }

.fad.fa-battery::after, .fa-duotone.fa-battery::after {
  content: '\f240\f240'; }

.fad.fa-battery-5::after, .fa-duotone.fa-battery-5::after {
  content: '\f240\f240'; }

.fad.fa-bell-plus::after, .fa-duotone.fa-bell-plus::after {
  content: '\f849\f849'; }

.fad.fa-book-arrow-right::after, .fa-duotone.fa-book-arrow-right::after {
  content: '\e0b9\e0b9'; }

.fad.fa-hospitals::after, .fa-duotone.fa-hospitals::after {
  content: '\f80e\f80e'; }

.fad.fa-club::after, .fa-duotone.fa-club::after {
  content: '\f327\f327'; }

.fad.fa-skull-crossbones::after, .fa-duotone.fa-skull-crossbones::after {
  content: '\f714\f714'; }

.fad.fa-droplet-degree::after, .fa-duotone.fa-droplet-degree::after {
  content: '\f748\f748'; }

.fad.fa-dewpoint::after, .fa-duotone.fa-dewpoint::after {
  content: '\f748\f748'; }

.fad.fa-code-compare::after, .fa-duotone.fa-code-compare::after {
  content: '\e13a\e13a'; }

.fad.fa-list-ul::after, .fa-duotone.fa-list-ul::after {
  content: '\f0ca\f0ca'; }

.fad.fa-list-dots::after, .fa-duotone.fa-list-dots::after {
  content: '\f0ca\f0ca'; }

.fad.fa-hand-holding-magic::after, .fa-duotone.fa-hand-holding-magic::after {
  content: '\f6e5\f6e5'; }

.fad.fa-watermelon-slice::after, .fa-duotone.fa-watermelon-slice::after {
  content: '\e337\e337'; }

.fad.fa-circle-ellipsis::after, .fa-duotone.fa-circle-ellipsis::after {
  content: '\e10a\e10a'; }

.fad.fa-school-lock::after, .fa-duotone.fa-school-lock::after {
  content: '\e56f\e56f'; }

.fad.fa-tower-cell::after, .fa-duotone.fa-tower-cell::after {
  content: '\e585\e585'; }

.fad.fa-sd-cards::after, .fa-duotone.fa-sd-cards::after {
  content: '\e240\e240'; }

.fad.fa-jug-bottle::after, .fa-duotone.fa-jug-bottle::after {
  content: '\e5fb\e5fb'; }

.fad.fa-down-long::after, .fa-duotone.fa-down-long::after {
  content: '\f309\f309'; }

.fad.fa-long-arrow-alt-down::after, .fa-duotone.fa-long-arrow-alt-down::after {
  content: '\f309\f309'; }

.fad.fa-envelopes::after, .fa-duotone.fa-envelopes::after {
  content: '\e170\e170'; }

.fad.fa-phone-office::after, .fa-duotone.fa-phone-office::after {
  content: '\f67d\f67d'; }

.fad.fa-ranking-star::after, .fa-duotone.fa-ranking-star::after {
  content: '\e561\e561'; }

.fad.fa-chess-king::after, .fa-duotone.fa-chess-king::after {
  content: '\f43f\f43f'; }

.fad.fa-nfc-pen::after, .fa-duotone.fa-nfc-pen::after {
  content: '\e1fa\e1fa'; }

.fad.fa-person-harassing::after, .fa-duotone.fa-person-harassing::after {
  content: '\e549\e549'; }

.fad.fa-hat-winter::after, .fa-duotone.fa-hat-winter::after {
  content: '\f7a8\f7a8'; }

.fad.fa-brazilian-real-sign::after, .fa-duotone.fa-brazilian-real-sign::after {
  content: '\e46c\e46c'; }

.fad.fa-landmark-dome::after, .fa-duotone.fa-landmark-dome::after {
  content: '\f752\f752'; }

.fad.fa-landmark-alt::after, .fa-duotone.fa-landmark-alt::after {
  content: '\f752\f752'; }

.fad.fa-bone-break::after, .fa-duotone.fa-bone-break::after {
  content: '\f5d8\f5d8'; }

.fad.fa-arrow-up::after, .fa-duotone.fa-arrow-up::after {
  content: '\f062\f062'; }

.fad.fa-down-from-dotted-line::after, .fa-duotone.fa-down-from-dotted-line::after {
  content: '\e407\e407'; }

.fad.fa-tv::after, .fa-duotone.fa-tv::after {
  content: '\f26c\f26c'; }

.fad.fa-television::after, .fa-duotone.fa-television::after {
  content: '\f26c\f26c'; }

.fad.fa-tv-alt::after, .fa-duotone.fa-tv-alt::after {
  content: '\f26c\f26c'; }

.fad.fa-border-left::after, .fa-duotone.fa-border-left::after {
  content: '\f84f\f84f'; }

.fad.fa-circle-divide::after, .fa-duotone.fa-circle-divide::after {
  content: '\e106\e106'; }

.fad.fa-shrimp::after, .fa-duotone.fa-shrimp::after {
  content: '\e448\e448'; }

.fad.fa-list-check::after, .fa-duotone.fa-list-check::after {
  content: '\f0ae\f0ae'; }

.fad.fa-tasks::after, .fa-duotone.fa-tasks::after {
  content: '\f0ae\f0ae'; }

.fad.fa-diagram-subtask::after, .fa-duotone.fa-diagram-subtask::after {
  content: '\e479\e479'; }

.fad.fa-jug-detergent::after, .fa-duotone.fa-jug-detergent::after {
  content: '\e519\e519'; }

.fad.fa-circle-user::after, .fa-duotone.fa-circle-user::after {
  content: '\f2bd\f2bd'; }

.fad.fa-user-circle::after, .fa-duotone.fa-user-circle::after {
  content: '\f2bd\f2bd'; }

.fad.fa-square-y::after, .fa-duotone.fa-square-y::after {
  content: '\e287\e287'; }

.fad.fa-user-doctor-hair::after, .fa-duotone.fa-user-doctor-hair::after {
  content: '\e458\e458'; }

.fad.fa-planet-ringed::after, .fa-duotone.fa-planet-ringed::after {
  content: '\e020\e020'; }

.fad.fa-mushroom::after, .fa-duotone.fa-mushroom::after {
  content: '\e425\e425'; }

.fad.fa-user-shield::after, .fa-duotone.fa-user-shield::after {
  content: '\f505\f505'; }

.fad.fa-megaphone::after, .fa-duotone.fa-megaphone::after {
  content: '\f675\f675'; }

.fad.fa-wreath-laurel::after, .fa-duotone.fa-wreath-laurel::after {
  content: '\e5d2\e5d2'; }

.fad.fa-circle-exclamation-check::after, .fa-duotone.fa-circle-exclamation-check::after {
  content: '\e10d\e10d'; }

.fad.fa-wind::after, .fa-duotone.fa-wind::after {
  content: '\f72e\f72e'; }

.fad.fa-box-dollar::after, .fa-duotone.fa-box-dollar::after {
  content: '\f4a0\f4a0'; }

.fad.fa-box-usd::after, .fa-duotone.fa-box-usd::after {
  content: '\f4a0\f4a0'; }

.fad.fa-car-burst::after, .fa-duotone.fa-car-burst::after {
  content: '\f5e1\f5e1'; }

.fad.fa-car-crash::after, .fa-duotone.fa-car-crash::after {
  content: '\f5e1\f5e1'; }

.fad.fa-y::after, .fa-duotone.fa-y::after {
  content: '\59\59'; }

.fad.fa-user-headset::after, .fa-duotone.fa-user-headset::after {
  content: '\f82d\f82d'; }

.fad.fa-arrows-retweet::after, .fa-duotone.fa-arrows-retweet::after {
  content: '\f361\f361'; }

.fad.fa-retweet-alt::after, .fa-duotone.fa-retweet-alt::after {
  content: '\f361\f361'; }

.fad.fa-person-snowboarding::after, .fa-duotone.fa-person-snowboarding::after {
  content: '\f7ce\f7ce'; }

.fad.fa-snowboarding::after, .fa-duotone.fa-snowboarding::after {
  content: '\f7ce\f7ce'; }

.fad.fa-square-chevron-right::after, .fa-duotone.fa-square-chevron-right::after {
  content: '\f32b\f32b'; }

.fad.fa-chevron-square-right::after, .fa-duotone.fa-chevron-square-right::after {
  content: '\f32b\f32b'; }

.fad.fa-lacrosse-stick-ball::after, .fa-duotone.fa-lacrosse-stick-ball::after {
  content: '\e3b6\e3b6'; }

.fad.fa-truck-fast::after, .fa-duotone.fa-truck-fast::after {
  content: '\f48b\f48b'; }

.fad.fa-shipping-fast::after, .fa-duotone.fa-shipping-fast::after {
  content: '\f48b\f48b'; }

.fad.fa-user-magnifying-glass::after, .fa-duotone.fa-user-magnifying-glass::after {
  content: '\e5c5\e5c5'; }

.fad.fa-star-sharp::after, .fa-duotone.fa-star-sharp::after {
  content: '\e28b\e28b'; }

.fad.fa-comment-heart::after, .fa-duotone.fa-comment-heart::after {
  content: '\e5c8\e5c8'; }

.fad.fa-circle-1::after, .fa-duotone.fa-circle-1::after {
  content: '\e0ee\e0ee'; }

.fad.fa-circle-star::after, .fa-duotone.fa-circle-star::after {
  content: '\e123\e123'; }

.fad.fa-star-circle::after, .fa-duotone.fa-star-circle::after {
  content: '\e123\e123'; }

.fad.fa-fish::after, .fa-duotone.fa-fish::after {
  content: '\f578\f578'; }

.fad.fa-cloud-fog::after, .fa-duotone.fa-cloud-fog::after {
  content: '\f74e\f74e'; }

.fad.fa-fog::after, .fa-duotone.fa-fog::after {
  content: '\f74e\f74e'; }

.fad.fa-waffle::after, .fa-duotone.fa-waffle::after {
  content: '\e466\e466'; }

.fad.fa-music-note::after, .fa-duotone.fa-music-note::after {
  content: '\f8cf\f8cf'; }

.fad.fa-music-alt::after, .fa-duotone.fa-music-alt::after {
  content: '\f8cf\f8cf'; }

.fad.fa-hexagon-exclamation::after, .fa-duotone.fa-hexagon-exclamation::after {
  content: '\e417\e417'; }

.fad.fa-cart-shopping-fast::after, .fa-duotone.fa-cart-shopping-fast::after {
  content: '\e0dc\e0dc'; }

.fad.fa-object-union::after, .fa-duotone.fa-object-union::after {
  content: '\e49f\e49f'; }

.fad.fa-user-graduate::after, .fa-duotone.fa-user-graduate::after {
  content: '\f501\f501'; }

.fad.fa-starfighter::after, .fa-duotone.fa-starfighter::after {
  content: '\e037\e037'; }

.fad.fa-circle-half-stroke::after, .fa-duotone.fa-circle-half-stroke::after {
  content: '\f042\f042'; }

.fad.fa-adjust::after, .fa-duotone.fa-adjust::after {
  content: '\f042\f042'; }

.fad.fa-arrow-right-long-to-line::after, .fa-duotone.fa-arrow-right-long-to-line::after {
  content: '\e3d5\e3d5'; }

.fad.fa-square-arrow-down::after, .fa-duotone.fa-square-arrow-down::after {
  content: '\f339\f339'; }

.fad.fa-arrow-square-down::after, .fa-duotone.fa-arrow-square-down::after {
  content: '\f339\f339'; }

.fad.fa-diamond-half-stroke::after, .fa-duotone.fa-diamond-half-stroke::after {
  content: '\e5b8\e5b8'; }

.fad.fa-clapperboard::after, .fa-duotone.fa-clapperboard::after {
  content: '\e131\e131'; }

.fad.fa-square-chevron-left::after, .fa-duotone.fa-square-chevron-left::after {
  content: '\f32a\f32a'; }

.fad.fa-chevron-square-left::after, .fa-duotone.fa-chevron-square-left::after {
  content: '\f32a\f32a'; }

.fad.fa-phone-intercom::after, .fa-duotone.fa-phone-intercom::after {
  content: '\e434\e434'; }

.fad.fa-link-horizontal::after, .fa-duotone.fa-link-horizontal::after {
  content: '\e1cb\e1cb'; }

.fad.fa-chain-horizontal::after, .fa-duotone.fa-chain-horizontal::after {
  content: '\e1cb\e1cb'; }

.fad.fa-mango::after, .fa-duotone.fa-mango::after {
  content: '\e30f\e30f'; }

.fad.fa-music-note-slash::after, .fa-duotone.fa-music-note-slash::after {
  content: '\f8d0\f8d0'; }

.fad.fa-music-alt-slash::after, .fa-duotone.fa-music-alt-slash::after {
  content: '\f8d0\f8d0'; }

.fad.fa-circle-radiation::after, .fa-duotone.fa-circle-radiation::after {
  content: '\f7ba\f7ba'; }

.fad.fa-radiation-alt::after, .fa-duotone.fa-radiation-alt::after {
  content: '\f7ba\f7ba'; }

.fad.fa-face-tongue-sweat::after, .fa-duotone.fa-face-tongue-sweat::after {
  content: '\e39e\e39e'; }

.fad.fa-globe-stand::after, .fa-duotone.fa-globe-stand::after {
  content: '\f5f6\f5f6'; }

.fad.fa-baseball::after, .fa-duotone.fa-baseball::after {
  content: '\f433\f433'; }

.fad.fa-baseball-ball::after, .fa-duotone.fa-baseball-ball::after {
  content: '\f433\f433'; }

.fad.fa-circle-p::after, .fa-duotone.fa-circle-p::after {
  content: '\e11a\e11a'; }

.fad.fa-award-simple::after, .fa-duotone.fa-award-simple::after {
  content: '\e0ab\e0ab'; }

.fad.fa-jet-fighter-up::after, .fa-duotone.fa-jet-fighter-up::after {
  content: '\e518\e518'; }

.fad.fa-diagram-project::after, .fa-duotone.fa-diagram-project::after {
  content: '\f542\f542'; }

.fad.fa-project-diagram::after, .fa-duotone.fa-project-diagram::after {
  content: '\f542\f542'; }

.fad.fa-pedestal::after, .fa-duotone.fa-pedestal::after {
  content: '\e20d\e20d'; }

.fad.fa-chart-pyramid::after, .fa-duotone.fa-chart-pyramid::after {
  content: '\e0e6\e0e6'; }

.fad.fa-sidebar::after, .fa-duotone.fa-sidebar::after {
  content: '\e24e\e24e'; }

.fad.fa-snowman-head::after, .fa-duotone.fa-snowman-head::after {
  content: '\f79b\f79b'; }

.fad.fa-frosty-head::after, .fa-duotone.fa-frosty-head::after {
  content: '\f79b\f79b'; }

.fad.fa-copy::after, .fa-duotone.fa-copy::after {
  content: '\f0c5\f0c5'; }

.fad.fa-burger-glass::after, .fa-duotone.fa-burger-glass::after {
  content: '\e0ce\e0ce'; }

.fad.fa-volume-xmark::after, .fa-duotone.fa-volume-xmark::after {
  content: '\f6a9\f6a9'; }

.fad.fa-volume-mute::after, .fa-duotone.fa-volume-mute::after {
  content: '\f6a9\f6a9'; }

.fad.fa-volume-times::after, .fa-duotone.fa-volume-times::after {
  content: '\f6a9\f6a9'; }

.fad.fa-hand-sparkles::after, .fa-duotone.fa-hand-sparkles::after {
  content: '\e05d\e05d'; }

.fad.fa-bars-filter::after, .fa-duotone.fa-bars-filter::after {
  content: '\e0ad\e0ad'; }

.fad.fa-paintbrush-pencil::after, .fa-duotone.fa-paintbrush-pencil::after {
  content: '\e206\e206'; }

.fad.fa-party-bell::after, .fa-duotone.fa-party-bell::after {
  content: '\e31a\e31a'; }

.fad.fa-user-vneck-hair::after, .fa-duotone.fa-user-vneck-hair::after {
  content: '\e462\e462'; }

.fad.fa-jack-o-lantern::after, .fa-duotone.fa-jack-o-lantern::after {
  content: '\f30e\f30e'; }

.fad.fa-grip::after, .fa-duotone.fa-grip::after {
  content: '\f58d\f58d'; }

.fad.fa-grip-horizontal::after, .fa-duotone.fa-grip-horizontal::after {
  content: '\f58d\f58d'; }

.fad.fa-share-from-square::after, .fa-duotone.fa-share-from-square::after {
  content: '\f14d\f14d'; }

.fad.fa-share-square::after, .fa-duotone.fa-share-square::after {
  content: '\f14d\f14d'; }

.fad.fa-keynote::after, .fa-duotone.fa-keynote::after {
  content: '\f66c\f66c'; }

.fad.fa-child-combatant::after, .fa-duotone.fa-child-combatant::after {
  content: '\e4e0\e4e0'; }

.fad.fa-child-rifle::after, .fa-duotone.fa-child-rifle::after {
  content: '\e4e0\e4e0'; }

.fad.fa-gun::after, .fa-duotone.fa-gun::after {
  content: '\e19b\e19b'; }

.fad.fa-square-phone::after, .fa-duotone.fa-square-phone::after {
  content: '\f098\f098'; }

.fad.fa-phone-square::after, .fa-duotone.fa-phone-square::after {
  content: '\f098\f098'; }

.fad.fa-hat-beach::after, .fa-duotone.fa-hat-beach::after {
  content: '\e606\e606'; }

.fad.fa-plus::after, .fa-duotone.fa-plus::after {
  content: '\2b\2b'; }

.fad.fa-add::after, .fa-duotone.fa-add::after {
  content: '\2b\2b'; }

.fad.fa-expand::after, .fa-duotone.fa-expand::after {
  content: '\f065\f065'; }

.fad.fa-computer::after, .fa-duotone.fa-computer::after {
  content: '\e4e5\e4e5'; }

.fad.fa-fort::after, .fa-duotone.fa-fort::after {
  content: '\e486\e486'; }

.fad.fa-cloud-check::after, .fa-duotone.fa-cloud-check::after {
  content: '\e35c\e35c'; }

.fad.fa-xmark::after, .fa-duotone.fa-xmark::after {
  content: '\f00d\f00d'; }

.fad.fa-close::after, .fa-duotone.fa-close::after {
  content: '\f00d\f00d'; }

.fad.fa-multiply::after, .fa-duotone.fa-multiply::after {
  content: '\f00d\f00d'; }

.fad.fa-remove::after, .fa-duotone.fa-remove::after {
  content: '\f00d\f00d'; }

.fad.fa-times::after, .fa-duotone.fa-times::after {
  content: '\f00d\f00d'; }

.fad.fa-face-smirking::after, .fa-duotone.fa-face-smirking::after {
  content: '\e397\e397'; }

.fad.fa-arrows-up-down-left-right::after, .fa-duotone.fa-arrows-up-down-left-right::after {
  content: '\f047\f047'; }

.fad.fa-arrows::after, .fa-duotone.fa-arrows::after {
  content: '\f047\f047'; }

.fad.fa-chalkboard-user::after, .fa-duotone.fa-chalkboard-user::after {
  content: '\f51c\f51c'; }

.fad.fa-chalkboard-teacher::after, .fa-duotone.fa-chalkboard-teacher::after {
  content: '\f51c\f51c'; }

.fad.fa-rhombus::after, .fa-duotone.fa-rhombus::after {
  content: '\e23b\e23b'; }

.fad.fa-claw-marks::after, .fa-duotone.fa-claw-marks::after {
  content: '\f6c2\f6c2'; }

.fad.fa-peso-sign::after, .fa-duotone.fa-peso-sign::after {
  content: '\e222\e222'; }

.fad.fa-face-smile-tongue::after, .fa-duotone.fa-face-smile-tongue::after {
  content: '\e394\e394'; }

.fad.fa-cart-circle-xmark::after, .fa-duotone.fa-cart-circle-xmark::after {
  content: '\e3f4\e3f4'; }

.fad.fa-building-shield::after, .fa-duotone.fa-building-shield::after {
  content: '\e4d8\e4d8'; }

.fad.fa-circle-phone-flip::after, .fa-duotone.fa-circle-phone-flip::after {
  content: '\e11c\e11c'; }

.fad.fa-phone-circle-alt::after, .fa-duotone.fa-phone-circle-alt::after {
  content: '\e11c\e11c'; }

.fad.fa-baby::after, .fa-duotone.fa-baby::after {
  content: '\f77c\f77c'; }

.fad.fa-users-line::after, .fa-duotone.fa-users-line::after {
  content: '\e592\e592'; }

.fad.fa-quote-left::after, .fa-duotone.fa-quote-left::after {
  content: '\f10d\f10d'; }

.fad.fa-quote-left-alt::after, .fa-duotone.fa-quote-left-alt::after {
  content: '\f10d\f10d'; }

.fad.fa-tractor::after, .fa-duotone.fa-tractor::after {
  content: '\f722\f722'; }

.fad.fa-key-skeleton::after, .fa-duotone.fa-key-skeleton::after {
  content: '\f6f3\f6f3'; }

.fad.fa-trash-arrow-up::after, .fa-duotone.fa-trash-arrow-up::after {
  content: '\f829\f829'; }

.fad.fa-trash-restore::after, .fa-duotone.fa-trash-restore::after {
  content: '\f829\f829'; }

.fad.fa-arrow-down-up-lock::after, .fa-duotone.fa-arrow-down-up-lock::after {
  content: '\e4b0\e4b0'; }

.fad.fa-arrow-down-to-bracket::after, .fa-duotone.fa-arrow-down-to-bracket::after {
  content: '\e094\e094'; }

.fad.fa-lines-leaning::after, .fa-duotone.fa-lines-leaning::after {
  content: '\e51e\e51e'; }

.fad.fa-square-q::after, .fa-duotone.fa-square-q::after {
  content: '\e27b\e27b'; }

.fad.fa-ruler-combined::after, .fa-duotone.fa-ruler-combined::after {
  content: '\f546\f546'; }

.fad.fa-symbols::after, .fa-duotone.fa-symbols::after {
  content: '\f86e\f86e'; }

.fad.fa-icons-alt::after, .fa-duotone.fa-icons-alt::after {
  content: '\f86e\f86e'; }

.fad.fa-copyright::after, .fa-duotone.fa-copyright::after {
  content: '\f1f9\f1f9'; }

.fad.fa-flask-gear::after, .fa-duotone.fa-flask-gear::after {
  content: '\e5f1\e5f1'; }

.fad.fa-highlighter-line::after, .fa-duotone.fa-highlighter-line::after {
  content: '\e1af\e1af'; }

.fad.fa-bracket-square::after, .fa-duotone.fa-bracket-square::after {
  content: '\5b\5b'; }

.fad.fa-bracket::after, .fa-duotone.fa-bracket::after {
  content: '\5b\5b'; }

.fad.fa-bracket-left::after, .fa-duotone.fa-bracket-left::after {
  content: '\5b\5b'; }

.fad.fa-island-tropical::after, .fa-duotone.fa-island-tropical::after {
  content: '\f811\f811'; }

.fad.fa-island-tree-palm::after, .fa-duotone.fa-island-tree-palm::after {
  content: '\f811\f811'; }

.fad.fa-arrow-right-from-line::after, .fa-duotone.fa-arrow-right-from-line::after {
  content: '\f343\f343'; }

.fad.fa-arrow-from-left::after, .fa-duotone.fa-arrow-from-left::after {
  content: '\f343\f343'; }

.fad.fa-h2::after, .fa-duotone.fa-h2::after {
  content: '\f314\f314'; }

.fad.fa-equals::after, .fa-duotone.fa-equals::after {
  content: '\3d\3d'; }

.fad.fa-cake-slice::after, .fa-duotone.fa-cake-slice::after {
  content: '\e3e5\e3e5'; }

.fad.fa-shortcake::after, .fa-duotone.fa-shortcake::after {
  content: '\e3e5\e3e5'; }

.fad.fa-peanut::after, .fa-duotone.fa-peanut::after {
  content: '\e430\e430'; }

.fad.fa-wrench-simple::after, .fa-duotone.fa-wrench-simple::after {
  content: '\e2d1\e2d1'; }

.fad.fa-blender::after, .fa-duotone.fa-blender::after {
  content: '\f517\f517'; }

.fad.fa-teeth::after, .fa-duotone.fa-teeth::after {
  content: '\f62e\f62e'; }

.fad.fa-tally-2::after, .fa-duotone.fa-tally-2::after {
  content: '\e295\e295'; }

.fad.fa-shekel-sign::after, .fa-duotone.fa-shekel-sign::after {
  content: '\f20b\f20b'; }

.fad.fa-ils::after, .fa-duotone.fa-ils::after {
  content: '\f20b\f20b'; }

.fad.fa-shekel::after, .fa-duotone.fa-shekel::after {
  content: '\f20b\f20b'; }

.fad.fa-sheqel::after, .fa-duotone.fa-sheqel::after {
  content: '\f20b\f20b'; }

.fad.fa-sheqel-sign::after, .fa-duotone.fa-sheqel-sign::after {
  content: '\f20b\f20b'; }

.fad.fa-cars::after, .fa-duotone.fa-cars::after {
  content: '\f85b\f85b'; }

.fad.fa-axe-battle::after, .fa-duotone.fa-axe-battle::after {
  content: '\f6b3\f6b3'; }

.fad.fa-user-hair-long::after, .fa-duotone.fa-user-hair-long::after {
  content: '\e45b\e45b'; }

.fad.fa-map::after, .fa-duotone.fa-map::after {
  content: '\f279\f279'; }

.fad.fa-arrow-left-from-arc::after, .fa-duotone.fa-arrow-left-from-arc::after {
  content: '\e615\e615'; }

.fad.fa-file-circle-info::after, .fa-duotone.fa-file-circle-info::after {
  content: '\e493\e493'; }

.fad.fa-face-disappointed::after, .fa-duotone.fa-face-disappointed::after {
  content: '\e36f\e36f'; }

.fad.fa-lasso-sparkles::after, .fa-duotone.fa-lasso-sparkles::after {
  content: '\e1c9\e1c9'; }

.fad.fa-clock-eleven::after, .fa-duotone.fa-clock-eleven::after {
  content: '\e347\e347'; }

.fad.fa-rocket::after, .fa-duotone.fa-rocket::after {
  content: '\f135\f135'; }

.fad.fa-siren-on::after, .fa-duotone.fa-siren-on::after {
  content: '\e02e\e02e'; }

.fad.fa-clock-ten::after, .fa-duotone.fa-clock-ten::after {
  content: '\e354\e354'; }

.fad.fa-candle-holder::after, .fa-duotone.fa-candle-holder::after {
  content: '\f6bc\f6bc'; }

.fad.fa-video-arrow-down-left::after, .fa-duotone.fa-video-arrow-down-left::after {
  content: '\e2c8\e2c8'; }

.fad.fa-photo-film::after, .fa-duotone.fa-photo-film::after {
  content: '\f87c\f87c'; }

.fad.fa-photo-video::after, .fa-duotone.fa-photo-video::after {
  content: '\f87c\f87c'; }

.fad.fa-floppy-disk-circle-arrow-right::after, .fa-duotone.fa-floppy-disk-circle-arrow-right::after {
  content: '\e180\e180'; }

.fad.fa-save-circle-arrow-right::after, .fa-duotone.fa-save-circle-arrow-right::after {
  content: '\e180\e180'; }

.fad.fa-folder-minus::after, .fa-duotone.fa-folder-minus::after {
  content: '\f65d\f65d'; }

.fad.fa-planet-moon::after, .fa-duotone.fa-planet-moon::after {
  content: '\e01f\e01f'; }

.fad.fa-face-eyes-xmarks::after, .fa-duotone.fa-face-eyes-xmarks::after {
  content: '\e374\e374'; }

.fad.fa-chart-scatter::after, .fa-duotone.fa-chart-scatter::after {
  content: '\f7ee\f7ee'; }

.fad.fa-display-arrow-down::after, .fa-duotone.fa-display-arrow-down::after {
  content: '\e164\e164'; }

.fad.fa-store::after, .fa-duotone.fa-store::after {
  content: '\f54e\f54e'; }

.fad.fa-arrow-trend-up::after, .fa-duotone.fa-arrow-trend-up::after {
  content: '\e098\e098'; }

.fad.fa-plug-circle-minus::after, .fa-duotone.fa-plug-circle-minus::after {
  content: '\e55e\e55e'; }

.fad.fa-olive-branch::after, .fa-duotone.fa-olive-branch::after {
  content: '\e317\e317'; }

.fad.fa-angle::after, .fa-duotone.fa-angle::after {
  content: '\e08c\e08c'; }

.fad.fa-vacuum-robot::after, .fa-duotone.fa-vacuum-robot::after {
  content: '\e04e\e04e'; }

.fad.fa-sign-hanging::after, .fa-duotone.fa-sign-hanging::after {
  content: '\f4d9\f4d9'; }

.fad.fa-sign::after, .fa-duotone.fa-sign::after {
  content: '\f4d9\f4d9'; }

.fad.fa-square-divide::after, .fa-duotone.fa-square-divide::after {
  content: '\e26a\e26a'; }

.fad.fa-signal-stream-slash::after, .fa-duotone.fa-signal-stream-slash::after {
  content: '\e250\e250'; }

.fad.fa-bezier-curve::after, .fa-duotone.fa-bezier-curve::after {
  content: '\f55b\f55b'; }

.fad.fa-eye-dropper-half::after, .fa-duotone.fa-eye-dropper-half::after {
  content: '\e173\e173'; }

.fad.fa-store-lock::after, .fa-duotone.fa-store-lock::after {
  content: '\e4a6\e4a6'; }

.fad.fa-bell-slash::after, .fa-duotone.fa-bell-slash::after {
  content: '\f1f6\f1f6'; }

.fad.fa-cloud-bolt-sun::after, .fa-duotone.fa-cloud-bolt-sun::after {
  content: '\f76e\f76e'; }

.fad.fa-thunderstorm-sun::after, .fa-duotone.fa-thunderstorm-sun::after {
  content: '\f76e\f76e'; }

.fad.fa-camera-slash::after, .fa-duotone.fa-camera-slash::after {
  content: '\e0d9\e0d9'; }

.fad.fa-comment-quote::after, .fa-duotone.fa-comment-quote::after {
  content: '\e14c\e14c'; }

.fad.fa-tablet::after, .fa-duotone.fa-tablet::after {
  content: '\f3fb\f3fb'; }

.fad.fa-tablet-android::after, .fa-duotone.fa-tablet-android::after {
  content: '\f3fb\f3fb'; }

.fad.fa-school-flag::after, .fa-duotone.fa-school-flag::after {
  content: '\e56e\e56e'; }

.fad.fa-message-code::after, .fa-duotone.fa-message-code::after {
  content: '\e1df\e1df'; }

.fad.fa-glass-half::after, .fa-duotone.fa-glass-half::after {
  content: '\e192\e192'; }

.fad.fa-glass-half-empty::after, .fa-duotone.fa-glass-half-empty::after {
  content: '\e192\e192'; }

.fad.fa-glass-half-full::after, .fa-duotone.fa-glass-half-full::after {
  content: '\e192\e192'; }

.fad.fa-fill::after, .fa-duotone.fa-fill::after {
  content: '\f575\f575'; }

.fad.fa-message-minus::after, .fa-duotone.fa-message-minus::after {
  content: '\f4a7\f4a7'; }

.fad.fa-comment-alt-minus::after, .fa-duotone.fa-comment-alt-minus::after {
  content: '\f4a7\f4a7'; }

.fad.fa-angle-up::after, .fa-duotone.fa-angle-up::after {
  content: '\f106\f106'; }

.fad.fa-dinosaur::after, .fa-duotone.fa-dinosaur::after {
  content: '\e5fe\e5fe'; }

.fad.fa-drumstick-bite::after, .fa-duotone.fa-drumstick-bite::after {
  content: '\f6d7\f6d7'; }

.fad.fa-link-horizontal-slash::after, .fa-duotone.fa-link-horizontal-slash::after {
  content: '\e1cc\e1cc'; }

.fad.fa-chain-horizontal-slash::after, .fa-duotone.fa-chain-horizontal-slash::after {
  content: '\e1cc\e1cc'; }

.fad.fa-holly-berry::after, .fa-duotone.fa-holly-berry::after {
  content: '\f7aa\f7aa'; }

.fad.fa-nose::after, .fa-duotone.fa-nose::after {
  content: '\e5bd\e5bd'; }

.fad.fa-arrow-left-to-arc::after, .fa-duotone.fa-arrow-left-to-arc::after {
  content: '\e616\e616'; }

.fad.fa-chevron-left::after, .fa-duotone.fa-chevron-left::after {
  content: '\f053\f053'; }

.fad.fa-bacteria::after, .fa-duotone.fa-bacteria::after {
  content: '\e059\e059'; }

.fad.fa-clouds::after, .fa-duotone.fa-clouds::after {
  content: '\f744\f744'; }

.fad.fa-money-bill-simple::after, .fa-duotone.fa-money-bill-simple::after {
  content: '\e1f1\e1f1'; }

.fad.fa-hand-lizard::after, .fa-duotone.fa-hand-lizard::after {
  content: '\f258\f258'; }

.fad.fa-table-pivot::after, .fa-duotone.fa-table-pivot::after {
  content: '\e291\e291'; }

.fad.fa-filter-slash::after, .fa-duotone.fa-filter-slash::after {
  content: '\e17d\e17d'; }

.fad.fa-trash-can-undo::after, .fa-duotone.fa-trash-can-undo::after {
  content: '\f896\f896'; }

.fad.fa-trash-can-arrow-turn-left::after, .fa-duotone.fa-trash-can-arrow-turn-left::after {
  content: '\f896\f896'; }

.fad.fa-trash-undo-alt::after, .fa-duotone.fa-trash-undo-alt::after {
  content: '\f896\f896'; }

.fad.fa-notdef::after, .fa-duotone.fa-notdef::after {
  content: '\e1fe\e1fe'; }

.fad.fa-disease::after, .fa-duotone.fa-disease::after {
  content: '\f7fa\f7fa'; }

.fad.fa-person-to-door::after, .fa-duotone.fa-person-to-door::after {
  content: '\e433\e433'; }

.fad.fa-turntable::after, .fa-duotone.fa-turntable::after {
  content: '\f8e4\f8e4'; }

.fad.fa-briefcase-medical::after, .fa-duotone.fa-briefcase-medical::after {
  content: '\f469\f469'; }

.fad.fa-genderless::after, .fa-duotone.fa-genderless::after {
  content: '\f22d\f22d'; }

.fad.fa-chevron-right::after, .fa-duotone.fa-chevron-right::after {
  content: '\f054\f054'; }

.fad.fa-signal-weak::after, .fa-duotone.fa-signal-weak::after {
  content: '\f68c\f68c'; }

.fad.fa-signal-1::after, .fa-duotone.fa-signal-1::after {
  content: '\f68c\f68c'; }

.fad.fa-clock-five::after, .fa-duotone.fa-clock-five::after {
  content: '\e349\e349'; }

.fad.fa-retweet::after, .fa-duotone.fa-retweet::after {
  content: '\f079\f079'; }

.fad.fa-car-rear::after, .fa-duotone.fa-car-rear::after {
  content: '\f5de\f5de'; }

.fad.fa-car-alt::after, .fa-duotone.fa-car-alt::after {
  content: '\f5de\f5de'; }

.fad.fa-pump-soap::after, .fa-duotone.fa-pump-soap::after {
  content: '\e06b\e06b'; }

.fad.fa-computer-classic::after, .fa-duotone.fa-computer-classic::after {
  content: '\f8b1\f8b1'; }

.fad.fa-frame::after, .fa-duotone.fa-frame::after {
  content: '\e495\e495'; }

.fad.fa-video-slash::after, .fa-duotone.fa-video-slash::after {
  content: '\f4e2\f4e2'; }

.fad.fa-battery-quarter::after, .fa-duotone.fa-battery-quarter::after {
  content: '\f243\f243'; }

.fad.fa-battery-2::after, .fa-duotone.fa-battery-2::after {
  content: '\f243\f243'; }

.fad.fa-ellipsis-stroke::after, .fa-duotone.fa-ellipsis-stroke::after {
  content: '\f39b\f39b'; }

.fad.fa-ellipsis-h-alt::after, .fa-duotone.fa-ellipsis-h-alt::after {
  content: '\f39b\f39b'; }

.fad.fa-radio::after, .fa-duotone.fa-radio::after {
  content: '\f8d7\f8d7'; }

.fad.fa-baby-carriage::after, .fa-duotone.fa-baby-carriage::after {
  content: '\f77d\f77d'; }

.fad.fa-carriage-baby::after, .fa-duotone.fa-carriage-baby::after {
  content: '\f77d\f77d'; }

.fad.fa-face-expressionless::after, .fa-duotone.fa-face-expressionless::after {
  content: '\e373\e373'; }

.fad.fa-down-to-dotted-line::after, .fa-duotone.fa-down-to-dotted-line::after {
  content: '\e408\e408'; }

.fad.fa-cloud-music::after, .fa-duotone.fa-cloud-music::after {
  content: '\f8ae\f8ae'; }

.fad.fa-traffic-light::after, .fa-duotone.fa-traffic-light::after {
  content: '\f637\f637'; }

.fad.fa-cloud-minus::after, .fa-duotone.fa-cloud-minus::after {
  content: '\e35d\e35d'; }

.fad.fa-thermometer::after, .fa-duotone.fa-thermometer::after {
  content: '\f491\f491'; }

.fad.fa-shield-minus::after, .fa-duotone.fa-shield-minus::after {
  content: '\e249\e249'; }

.fad.fa-vr-cardboard::after, .fa-duotone.fa-vr-cardboard::after {
  content: '\f729\f729'; }

.fad.fa-car-tilt::after, .fa-duotone.fa-car-tilt::after {
  content: '\f5e5\f5e5'; }

.fad.fa-gauge-circle-minus::after, .fa-duotone.fa-gauge-circle-minus::after {
  content: '\e497\e497'; }

.fad.fa-brightness-low::after, .fa-duotone.fa-brightness-low::after {
  content: '\e0ca\e0ca'; }

.fad.fa-hand-middle-finger::after, .fa-duotone.fa-hand-middle-finger::after {
  content: '\f806\f806'; }

.fad.fa-percent::after, .fa-duotone.fa-percent::after {
  content: '\25\25'; }

.fad.fa-percentage::after, .fa-duotone.fa-percentage::after {
  content: '\25\25'; }

.fad.fa-truck-moving::after, .fa-duotone.fa-truck-moving::after {
  content: '\f4df\f4df'; }

.fad.fa-glass-water-droplet::after, .fa-duotone.fa-glass-water-droplet::after {
  content: '\e4f5\e4f5'; }

.fad.fa-conveyor-belt::after, .fa-duotone.fa-conveyor-belt::after {
  content: '\f46e\f46e'; }

.fad.fa-location-check::after, .fa-duotone.fa-location-check::after {
  content: '\f606\f606'; }

.fad.fa-map-marker-check::after, .fa-duotone.fa-map-marker-check::after {
  content: '\f606\f606'; }

.fad.fa-coin-vertical::after, .fa-duotone.fa-coin-vertical::after {
  content: '\e3fd\e3fd'; }

.fad.fa-display::after, .fa-duotone.fa-display::after {
  content: '\e163\e163'; }

.fad.fa-person-sign::after, .fa-duotone.fa-person-sign::after {
  content: '\f757\f757'; }

.fad.fa-face-smile::after, .fa-duotone.fa-face-smile::after {
  content: '\f118\f118'; }

.fad.fa-smile::after, .fa-duotone.fa-smile::after {
  content: '\f118\f118'; }

.fad.fa-phone-hangup::after, .fa-duotone.fa-phone-hangup::after {
  content: '\e225\e225'; }

.fad.fa-signature-slash::after, .fa-duotone.fa-signature-slash::after {
  content: '\e3cb\e3cb'; }

.fad.fa-thumbtack::after, .fa-duotone.fa-thumbtack::after {
  content: '\f08d\f08d'; }

.fad.fa-thumb-tack::after, .fa-duotone.fa-thumb-tack::after {
  content: '\f08d\f08d'; }

.fad.fa-wheat-slash::after, .fa-duotone.fa-wheat-slash::after {
  content: '\e339\e339'; }

.fad.fa-trophy::after, .fa-duotone.fa-trophy::after {
  content: '\f091\f091'; }

.fad.fa-clouds-sun::after, .fa-duotone.fa-clouds-sun::after {
  content: '\f746\f746'; }

.fad.fa-person-praying::after, .fa-duotone.fa-person-praying::after {
  content: '\f683\f683'; }

.fad.fa-pray::after, .fa-duotone.fa-pray::after {
  content: '\f683\f683'; }

.fad.fa-hammer::after, .fa-duotone.fa-hammer::after {
  content: '\f6e3\f6e3'; }

.fad.fa-face-vomit::after, .fa-duotone.fa-face-vomit::after {
  content: '\e3a0\e3a0'; }

.fad.fa-speakers::after, .fa-duotone.fa-speakers::after {
  content: '\f8e0\f8e0'; }

.fad.fa-tty-answer::after, .fa-duotone.fa-tty-answer::after {
  content: '\e2b9\e2b9'; }

.fad.fa-teletype-answer::after, .fa-duotone.fa-teletype-answer::after {
  content: '\e2b9\e2b9'; }

.fad.fa-mug-tea-saucer::after, .fa-duotone.fa-mug-tea-saucer::after {
  content: '\e1f5\e1f5'; }

.fad.fa-diagram-lean-canvas::after, .fa-duotone.fa-diagram-lean-canvas::after {
  content: '\e156\e156'; }

.fad.fa-alt::after, .fa-duotone.fa-alt::after {
  content: '\e08a\e08a'; }

.fad.fa-dial::after, .fa-duotone.fa-dial::after {
  content: '\e15b\e15b'; }

.fad.fa-dial-med-high::after, .fa-duotone.fa-dial-med-high::after {
  content: '\e15b\e15b'; }

.fad.fa-hand-peace::after, .fa-duotone.fa-hand-peace::after {
  content: '\f25b\f25b'; }

.fad.fa-circle-trash::after, .fa-duotone.fa-circle-trash::after {
  content: '\e126\e126'; }

.fad.fa-trash-circle::after, .fa-duotone.fa-trash-circle::after {
  content: '\e126\e126'; }

.fad.fa-rotate::after, .fa-duotone.fa-rotate::after {
  content: '\f2f1\f2f1'; }

.fad.fa-sync-alt::after, .fa-duotone.fa-sync-alt::after {
  content: '\f2f1\f2f1'; }

.fad.fa-circle-quarters::after, .fa-duotone.fa-circle-quarters::after {
  content: '\e3f8\e3f8'; }

.fad.fa-spinner::after, .fa-duotone.fa-spinner::after {
  content: '\f110\f110'; }

.fad.fa-tower-control::after, .fa-duotone.fa-tower-control::after {
  content: '\e2a2\e2a2'; }

.fad.fa-arrow-up-triangle-square::after, .fa-duotone.fa-arrow-up-triangle-square::after {
  content: '\f88a\f88a'; }

.fad.fa-sort-shapes-up::after, .fa-duotone.fa-sort-shapes-up::after {
  content: '\f88a\f88a'; }

.fad.fa-whale::after, .fa-duotone.fa-whale::after {
  content: '\f72c\f72c'; }

.fad.fa-robot::after, .fa-duotone.fa-robot::after {
  content: '\f544\f544'; }

.fad.fa-peace::after, .fa-duotone.fa-peace::after {
  content: '\f67c\f67c'; }

.fad.fa-party-horn::after, .fa-duotone.fa-party-horn::after {
  content: '\e31b\e31b'; }

.fad.fa-gears::after, .fa-duotone.fa-gears::after {
  content: '\f085\f085'; }

.fad.fa-cogs::after, .fa-duotone.fa-cogs::after {
  content: '\f085\f085'; }

.fad.fa-sun-bright::after, .fa-duotone.fa-sun-bright::after {
  content: '\e28f\e28f'; }

.fad.fa-sun-alt::after, .fa-duotone.fa-sun-alt::after {
  content: '\e28f\e28f'; }

.fad.fa-warehouse::after, .fa-duotone.fa-warehouse::after {
  content: '\f494\f494'; }

.fad.fa-conveyor-belt-arm::after, .fa-duotone.fa-conveyor-belt-arm::after {
  content: '\e5f8\e5f8'; }

.fad.fa-lock-keyhole-open::after, .fa-duotone.fa-lock-keyhole-open::after {
  content: '\f3c2\f3c2'; }

.fad.fa-lock-open-alt::after, .fa-duotone.fa-lock-open-alt::after {
  content: '\f3c2\f3c2'; }

.fad.fa-square-fragile::after, .fa-duotone.fa-square-fragile::after {
  content: '\f49b\f49b'; }

.fad.fa-box-fragile::after, .fa-duotone.fa-box-fragile::after {
  content: '\f49b\f49b'; }

.fad.fa-square-wine-glass-crack::after, .fa-duotone.fa-square-wine-glass-crack::after {
  content: '\f49b\f49b'; }

.fad.fa-arrow-up-right-dots::after, .fa-duotone.fa-arrow-up-right-dots::after {
  content: '\e4b7\e4b7'; }

.fad.fa-square-n::after, .fa-duotone.fa-square-n::after {
  content: '\e277\e277'; }

.fad.fa-splotch::after, .fa-duotone.fa-splotch::after {
  content: '\f5bc\f5bc'; }

.fad.fa-face-grin-hearts::after, .fa-duotone.fa-face-grin-hearts::after {
  content: '\f584\f584'; }

.fad.fa-grin-hearts::after, .fa-duotone.fa-grin-hearts::after {
  content: '\f584\f584'; }

.fad.fa-meter::after, .fa-duotone.fa-meter::after {
  content: '\e1e8\e1e8'; }

.fad.fa-mandolin::after, .fa-duotone.fa-mandolin::after {
  content: '\f6f9\f6f9'; }

.fad.fa-dice-four::after, .fa-duotone.fa-dice-four::after {
  content: '\f524\f524'; }

.fad.fa-sim-card::after, .fa-duotone.fa-sim-card::after {
  content: '\f7c4\f7c4'; }

.fad.fa-transgender::after, .fa-duotone.fa-transgender::after {
  content: '\f225\f225'; }

.fad.fa-transgender-alt::after, .fa-duotone.fa-transgender-alt::after {
  content: '\f225\f225'; }

.fad.fa-mercury::after, .fa-duotone.fa-mercury::after {
  content: '\f223\f223'; }

.fad.fa-up-from-bracket::after, .fa-duotone.fa-up-from-bracket::after {
  content: '\e590\e590'; }

.fad.fa-knife-kitchen::after, .fa-duotone.fa-knife-kitchen::after {
  content: '\f6f5\f6f5'; }

.fad.fa-border-right::after, .fa-duotone.fa-border-right::after {
  content: '\f852\f852'; }

.fad.fa-arrow-turn-down::after, .fa-duotone.fa-arrow-turn-down::after {
  content: '\f149\f149'; }

.fad.fa-level-down::after, .fa-duotone.fa-level-down::after {
  content: '\f149\f149'; }

.fad.fa-spade::after, .fa-duotone.fa-spade::after {
  content: '\f2f4\f2f4'; }

.fad.fa-card-spade::after, .fa-duotone.fa-card-spade::after {
  content: '\e3ec\e3ec'; }

.fad.fa-line-columns::after, .fa-duotone.fa-line-columns::after {
  content: '\f870\f870'; }

.fad.fa-arrow-right-to-line::after, .fa-duotone.fa-arrow-right-to-line::after {
  content: '\f340\f340'; }

.fad.fa-arrow-to-right::after, .fa-duotone.fa-arrow-to-right::after {
  content: '\f340\f340'; }

.fad.fa-person-falling-burst::after, .fa-duotone.fa-person-falling-burst::after {
  content: '\e547\e547'; }

.fad.fa-flag-pennant::after, .fa-duotone.fa-flag-pennant::after {
  content: '\f456\f456'; }

.fad.fa-pennant::after, .fa-duotone.fa-pennant::after {
  content: '\f456\f456'; }

.fad.fa-conveyor-belt-empty::after, .fa-duotone.fa-conveyor-belt-empty::after {
  content: '\e150\e150'; }

.fad.fa-user-group-simple::after, .fa-duotone.fa-user-group-simple::after {
  content: '\e603\e603'; }

.fad.fa-award::after, .fa-duotone.fa-award::after {
  content: '\f559\f559'; }

.fad.fa-ticket-simple::after, .fa-duotone.fa-ticket-simple::after {
  content: '\f3ff\f3ff'; }

.fad.fa-ticket-alt::after, .fa-duotone.fa-ticket-alt::after {
  content: '\f3ff\f3ff'; }

.fad.fa-building::after, .fa-duotone.fa-building::after {
  content: '\f1ad\f1ad'; }

.fad.fa-angles-left::after, .fa-duotone.fa-angles-left::after {
  content: '\f100\f100'; }

.fad.fa-angle-double-left::after, .fa-duotone.fa-angle-double-left::after {
  content: '\f100\f100'; }

.fad.fa-camcorder::after, .fa-duotone.fa-camcorder::after {
  content: '\f8a8\f8a8'; }

.fad.fa-video-handheld::after, .fa-duotone.fa-video-handheld::after {
  content: '\f8a8\f8a8'; }

.fad.fa-pancakes::after, .fa-duotone.fa-pancakes::after {
  content: '\e42d\e42d'; }

.fad.fa-album-circle-user::after, .fa-duotone.fa-album-circle-user::after {
  content: '\e48d\e48d'; }

.fad.fa-subtitles-slash::after, .fa-duotone.fa-subtitles-slash::after {
  content: '\e610\e610'; }

.fad.fa-qrcode::after, .fa-duotone.fa-qrcode::after {
  content: '\f029\f029'; }

.fad.fa-dice-d10::after, .fa-duotone.fa-dice-d10::after {
  content: '\f6cd\f6cd'; }

.fad.fa-fireplace::after, .fa-duotone.fa-fireplace::after {
  content: '\f79a\f79a'; }

.fad.fa-browser::after, .fa-duotone.fa-browser::after {
  content: '\f37e\f37e'; }

.fad.fa-pen-paintbrush::after, .fa-duotone.fa-pen-paintbrush::after {
  content: '\f618\f618'; }

.fad.fa-pencil-paintbrush::after, .fa-duotone.fa-pencil-paintbrush::after {
  content: '\f618\f618'; }

.fad.fa-fish-cooked::after, .fa-duotone.fa-fish-cooked::after {
  content: '\f7fe\f7fe'; }

.fad.fa-chair-office::after, .fa-duotone.fa-chair-office::after {
  content: '\f6c1\f6c1'; }

.fad.fa-nesting-dolls::after, .fa-duotone.fa-nesting-dolls::after {
  content: '\e3ba\e3ba'; }

.fad.fa-clock-rotate-left::after, .fa-duotone.fa-clock-rotate-left::after {
  content: '\f1da\f1da'; }

.fad.fa-history::after, .fa-duotone.fa-history::after {
  content: '\f1da\f1da'; }

.fad.fa-trumpet::after, .fa-duotone.fa-trumpet::after {
  content: '\f8e3\f8e3'; }

.fad.fa-face-grin-beam-sweat::after, .fa-duotone.fa-face-grin-beam-sweat::after {
  content: '\f583\f583'; }

.fad.fa-grin-beam-sweat::after, .fa-duotone.fa-grin-beam-sweat::after {
  content: '\f583\f583'; }

.fad.fa-fire-smoke::after, .fa-duotone.fa-fire-smoke::after {
  content: '\f74b\f74b'; }

.fad.fa-phone-missed::after, .fa-duotone.fa-phone-missed::after {
  content: '\e226\e226'; }

.fad.fa-file-export::after, .fa-duotone.fa-file-export::after {
  content: '\f56e\f56e'; }

.fad.fa-arrow-right-from-file::after, .fa-duotone.fa-arrow-right-from-file::after {
  content: '\f56e\f56e'; }

.fad.fa-shield::after, .fa-duotone.fa-shield::after {
  content: '\f132\f132'; }

.fad.fa-shield-blank::after, .fa-duotone.fa-shield-blank::after {
  content: '\f132\f132'; }

.fad.fa-arrow-up-short-wide::after, .fa-duotone.fa-arrow-up-short-wide::after {
  content: '\f885\f885'; }

.fad.fa-sort-amount-up-alt::after, .fa-duotone.fa-sort-amount-up-alt::after {
  content: '\f885\f885'; }

.fad.fa-arrows-repeat-1::after, .fa-duotone.fa-arrows-repeat-1::after {
  content: '\f366\f366'; }

.fad.fa-repeat-1-alt::after, .fa-duotone.fa-repeat-1-alt::after {
  content: '\f366\f366'; }

.fad.fa-gun-slash::after, .fa-duotone.fa-gun-slash::after {
  content: '\e19c\e19c'; }

.fad.fa-avocado::after, .fa-duotone.fa-avocado::after {
  content: '\e0aa\e0aa'; }

.fad.fa-binary::after, .fa-duotone.fa-binary::after {
  content: '\e33b\e33b'; }

.fad.fa-glasses-round::after, .fa-duotone.fa-glasses-round::after {
  content: '\f5f5\f5f5'; }

.fad.fa-glasses-alt::after, .fa-duotone.fa-glasses-alt::after {
  content: '\f5f5\f5f5'; }

.fad.fa-phone-plus::after, .fa-duotone.fa-phone-plus::after {
  content: '\f4d2\f4d2'; }

.fad.fa-ditto::after, .fa-duotone.fa-ditto::after {
  content: '\22\22'; }

.fad.fa-person-seat::after, .fa-duotone.fa-person-seat::after {
  content: '\e21e\e21e'; }

.fad.fa-house-medical::after, .fa-duotone.fa-house-medical::after {
  content: '\e3b2\e3b2'; }

.fad.fa-golf-ball-tee::after, .fa-duotone.fa-golf-ball-tee::after {
  content: '\f450\f450'; }

.fad.fa-golf-ball::after, .fa-duotone.fa-golf-ball::after {
  content: '\f450\f450'; }

.fad.fa-circle-chevron-left::after, .fa-duotone.fa-circle-chevron-left::after {
  content: '\f137\f137'; }

.fad.fa-chevron-circle-left::after, .fa-duotone.fa-chevron-circle-left::after {
  content: '\f137\f137'; }

.fad.fa-house-chimney-window::after, .fa-duotone.fa-house-chimney-window::after {
  content: '\e00d\e00d'; }

.fad.fa-scythe::after, .fa-duotone.fa-scythe::after {
  content: '\f710\f710'; }

.fad.fa-pen-nib::after, .fa-duotone.fa-pen-nib::after {
  content: '\f5ad\f5ad'; }

.fad.fa-ban-parking::after, .fa-duotone.fa-ban-parking::after {
  content: '\f616\f616'; }

.fad.fa-parking-circle-slash::after, .fa-duotone.fa-parking-circle-slash::after {
  content: '\f616\f616'; }

.fad.fa-tent-arrow-turn-left::after, .fa-duotone.fa-tent-arrow-turn-left::after {
  content: '\e580\e580'; }

.fad.fa-face-diagonal-mouth::after, .fa-duotone.fa-face-diagonal-mouth::after {
  content: '\e47e\e47e'; }

.fad.fa-diagram-cells::after, .fa-duotone.fa-diagram-cells::after {
  content: '\e475\e475'; }

.fad.fa-cricket-bat-ball::after, .fa-duotone.fa-cricket-bat-ball::after {
  content: '\f449\f449'; }

.fad.fa-cricket::after, .fa-duotone.fa-cricket::after {
  content: '\f449\f449'; }

.fad.fa-tents::after, .fa-duotone.fa-tents::after {
  content: '\e582\e582'; }

.fad.fa-wand-magic::after, .fa-duotone.fa-wand-magic::after {
  content: '\f0d0\f0d0'; }

.fad.fa-magic::after, .fa-duotone.fa-magic::after {
  content: '\f0d0\f0d0'; }

.fad.fa-dog::after, .fa-duotone.fa-dog::after {
  content: '\f6d3\f6d3'; }

.fad.fa-pen-line::after, .fa-duotone.fa-pen-line::after {
  content: '\e212\e212'; }

.fad.fa-atom-simple::after, .fa-duotone.fa-atom-simple::after {
  content: '\f5d3\f5d3'; }

.fad.fa-atom-alt::after, .fa-duotone.fa-atom-alt::after {
  content: '\f5d3\f5d3'; }

.fad.fa-ampersand::after, .fa-duotone.fa-ampersand::after {
  content: '\26\26'; }

.fad.fa-carrot::after, .fa-duotone.fa-carrot::after {
  content: '\f787\f787'; }

.fad.fa-arrow-up-from-line::after, .fa-duotone.fa-arrow-up-from-line::after {
  content: '\f342\f342'; }

.fad.fa-arrow-from-bottom::after, .fa-duotone.fa-arrow-from-bottom::after {
  content: '\f342\f342'; }

.fad.fa-moon::after, .fa-duotone.fa-moon::after {
  content: '\f186\f186'; }

.fad.fa-pen-slash::after, .fa-duotone.fa-pen-slash::after {
  content: '\e213\e213'; }

.fad.fa-wine-glass-empty::after, .fa-duotone.fa-wine-glass-empty::after {
  content: '\f5ce\f5ce'; }

.fad.fa-wine-glass-alt::after, .fa-duotone.fa-wine-glass-alt::after {
  content: '\f5ce\f5ce'; }

.fad.fa-square-star::after, .fa-duotone.fa-square-star::after {
  content: '\e27f\e27f'; }

.fad.fa-cheese::after, .fa-duotone.fa-cheese::after {
  content: '\f7ef\f7ef'; }

.fad.fa-send-backward::after, .fa-duotone.fa-send-backward::after {
  content: '\f87f\f87f'; }

.fad.fa-yin-yang::after, .fa-duotone.fa-yin-yang::after {
  content: '\f6ad\f6ad'; }

.fad.fa-music::after, .fa-duotone.fa-music::after {
  content: '\f001\f001'; }

.fad.fa-compass-slash::after, .fa-duotone.fa-compass-slash::after {
  content: '\f5e9\f5e9'; }

.fad.fa-clock-one::after, .fa-duotone.fa-clock-one::after {
  content: '\e34e\e34e'; }

.fad.fa-file-music::after, .fa-duotone.fa-file-music::after {
  content: '\f8b6\f8b6'; }

.fad.fa-code-commit::after, .fa-duotone.fa-code-commit::after {
  content: '\f386\f386'; }

.fad.fa-temperature-low::after, .fa-duotone.fa-temperature-low::after {
  content: '\f76b\f76b'; }

.fad.fa-person-biking::after, .fa-duotone.fa-person-biking::after {
  content: '\f84a\f84a'; }

.fad.fa-biking::after, .fa-duotone.fa-biking::after {
  content: '\f84a\f84a'; }

.fad.fa-display-chart-up-circle-currency::after, .fa-duotone.fa-display-chart-up-circle-currency::after {
  content: '\e5e5\e5e5'; }

.fad.fa-skeleton::after, .fa-duotone.fa-skeleton::after {
  content: '\f620\f620'; }

.fad.fa-circle-g::after, .fa-duotone.fa-circle-g::after {
  content: '\e10f\e10f'; }

.fad.fa-circle-arrow-up-left::after, .fa-duotone.fa-circle-arrow-up-left::after {
  content: '\e0fb\e0fb'; }

.fad.fa-coin-blank::after, .fa-duotone.fa-coin-blank::after {
  content: '\e3fb\e3fb'; }

.fad.fa-broom::after, .fa-duotone.fa-broom::after {
  content: '\f51a\f51a'; }

.fad.fa-vacuum::after, .fa-duotone.fa-vacuum::after {
  content: '\e04d\e04d'; }

.fad.fa-shield-heart::after, .fa-duotone.fa-shield-heart::after {
  content: '\e574\e574'; }

.fad.fa-card-heart::after, .fa-duotone.fa-card-heart::after {
  content: '\e3eb\e3eb'; }

.fad.fa-lightbulb-cfl-on::after, .fa-duotone.fa-lightbulb-cfl-on::after {
  content: '\e5a7\e5a7'; }

.fad.fa-melon::after, .fa-duotone.fa-melon::after {
  content: '\e310\e310'; }

.fad.fa-gopuram::after, .fa-duotone.fa-gopuram::after {
  content: '\f664\f664'; }

.fad.fa-earth-oceania::after, .fa-duotone.fa-earth-oceania::after {
  content: '\e47b\e47b'; }

.fad.fa-globe-oceania::after, .fa-duotone.fa-globe-oceania::after {
  content: '\e47b\e47b'; }

.fad.fa-container-storage::after, .fa-duotone.fa-container-storage::after {
  content: '\f4b7\f4b7'; }

.fad.fa-face-pouting::after, .fa-duotone.fa-face-pouting::after {
  content: '\e387\e387'; }

.fad.fa-square-xmark::after, .fa-duotone.fa-square-xmark::after {
  content: '\f2d3\f2d3'; }

.fad.fa-times-square::after, .fa-duotone.fa-times-square::after {
  content: '\f2d3\f2d3'; }

.fad.fa-xmark-square::after, .fa-duotone.fa-xmark-square::after {
  content: '\f2d3\f2d3'; }

.fad.fa-face-explode::after, .fa-duotone.fa-face-explode::after {
  content: '\e2fe\e2fe'; }

.fad.fa-exploding-head::after, .fa-duotone.fa-exploding-head::after {
  content: '\e2fe\e2fe'; }

.fad.fa-hashtag::after, .fa-duotone.fa-hashtag::after {
  content: '\23\23'; }

.fad.fa-up-right-and-down-left-from-center::after, .fa-duotone.fa-up-right-and-down-left-from-center::after {
  content: '\f424\f424'; }

.fad.fa-expand-alt::after, .fa-duotone.fa-expand-alt::after {
  content: '\f424\f424'; }

.fad.fa-oil-can::after, .fa-duotone.fa-oil-can::after {
  content: '\f613\f613'; }

.fad.fa-t::after, .fa-duotone.fa-t::after {
  content: '\54\54'; }

.fad.fa-transformer-bolt::after, .fa-duotone.fa-transformer-bolt::after {
  content: '\e2a4\e2a4'; }

.fad.fa-hippo::after, .fa-duotone.fa-hippo::after {
  content: '\f6ed\f6ed'; }

.fad.fa-chart-column::after, .fa-duotone.fa-chart-column::after {
  content: '\e0e3\e0e3'; }

.fad.fa-cassette-vhs::after, .fa-duotone.fa-cassette-vhs::after {
  content: '\f8ec\f8ec'; }

.fad.fa-vhs::after, .fa-duotone.fa-vhs::after {
  content: '\f8ec\f8ec'; }

.fad.fa-infinity::after, .fa-duotone.fa-infinity::after {
  content: '\f534\f534'; }

.fad.fa-vial-circle-check::after, .fa-duotone.fa-vial-circle-check::after {
  content: '\e596\e596'; }

.fad.fa-chimney::after, .fa-duotone.fa-chimney::after {
  content: '\f78b\f78b'; }

.fad.fa-object-intersect::after, .fa-duotone.fa-object-intersect::after {
  content: '\e49d\e49d'; }

.fad.fa-person-arrow-down-to-line::after, .fa-duotone.fa-person-arrow-down-to-line::after {
  content: '\e538\e538'; }

.fad.fa-voicemail::after, .fa-duotone.fa-voicemail::after {
  content: '\f897\f897'; }

.fad.fa-block-brick::after, .fa-duotone.fa-block-brick::after {
  content: '\e3db\e3db'; }

.fad.fa-wall-brick::after, .fa-duotone.fa-wall-brick::after {
  content: '\e3db\e3db'; }

.fad.fa-fan::after, .fa-duotone.fa-fan::after {
  content: '\f863\f863'; }

.fad.fa-bags-shopping::after, .fa-duotone.fa-bags-shopping::after {
  content: '\f847\f847'; }

.fad.fa-paragraph-left::after, .fa-duotone.fa-paragraph-left::after {
  content: '\f878\f878'; }

.fad.fa-paragraph-rtl::after, .fa-duotone.fa-paragraph-rtl::after {
  content: '\f878\f878'; }

.fad.fa-person-walking-luggage::after, .fa-duotone.fa-person-walking-luggage::after {
  content: '\e554\e554'; }

.fad.fa-caravan-simple::after, .fa-duotone.fa-caravan-simple::after {
  content: '\e000\e000'; }

.fad.fa-caravan-alt::after, .fa-duotone.fa-caravan-alt::after {
  content: '\e000\e000'; }

.fad.fa-turtle::after, .fa-duotone.fa-turtle::after {
  content: '\f726\f726'; }

.fad.fa-pencil-mechanical::after, .fa-duotone.fa-pencil-mechanical::after {
  content: '\e5ca\e5ca'; }

.fad.fa-up-down::after, .fa-duotone.fa-up-down::after {
  content: '\f338\f338'; }

.fad.fa-arrows-alt-v::after, .fa-duotone.fa-arrows-alt-v::after {
  content: '\f338\f338'; }

.fad.fa-cloud-moon-rain::after, .fa-duotone.fa-cloud-moon-rain::after {
  content: '\f73c\f73c'; }

.fad.fa-booth-curtain::after, .fa-duotone.fa-booth-curtain::after {
  content: '\f734\f734'; }

.fad.fa-calendar::after, .fa-duotone.fa-calendar::after {
  content: '\f133\f133'; }

.fad.fa-box-heart::after, .fa-duotone.fa-box-heart::after {
  content: '\f49d\f49d'; }

.fad.fa-trailer::after, .fa-duotone.fa-trailer::after {
  content: '\e041\e041'; }

.fad.fa-user-doctor-message::after, .fa-duotone.fa-user-doctor-message::after {
  content: '\f82e\f82e'; }

.fad.fa-user-md-chat::after, .fa-duotone.fa-user-md-chat::after {
  content: '\f82e\f82e'; }

.fad.fa-bahai::after, .fa-duotone.fa-bahai::after {
  content: '\f666\f666'; }

.fad.fa-haykal::after, .fa-duotone.fa-haykal::after {
  content: '\f666\f666'; }

.fad.fa-lighthouse::after, .fa-duotone.fa-lighthouse::after {
  content: '\e612\e612'; }

.fad.fa-amp-guitar::after, .fa-duotone.fa-amp-guitar::after {
  content: '\f8a1\f8a1'; }

.fad.fa-sd-card::after, .fa-duotone.fa-sd-card::after {
  content: '\f7c2\f7c2'; }

.fad.fa-volume-slash::after, .fa-duotone.fa-volume-slash::after {
  content: '\f2e2\f2e2'; }

.fad.fa-border-bottom::after, .fa-duotone.fa-border-bottom::after {
  content: '\f84d\f84d'; }

.fad.fa-wifi-weak::after, .fa-duotone.fa-wifi-weak::after {
  content: '\f6aa\f6aa'; }

.fad.fa-wifi-1::after, .fa-duotone.fa-wifi-1::after {
  content: '\f6aa\f6aa'; }

.fad.fa-dragon::after, .fa-duotone.fa-dragon::after {
  content: '\f6d5\f6d5'; }

.fad.fa-shoe-prints::after, .fa-duotone.fa-shoe-prints::after {
  content: '\f54b\f54b'; }

.fad.fa-circle-plus::after, .fa-duotone.fa-circle-plus::after {
  content: '\f055\f055'; }

.fad.fa-plus-circle::after, .fa-duotone.fa-plus-circle::after {
  content: '\f055\f055'; }

.fad.fa-face-grin-tongue-wink::after, .fa-duotone.fa-face-grin-tongue-wink::after {
  content: '\f58b\f58b'; }

.fad.fa-grin-tongue-wink::after, .fa-duotone.fa-grin-tongue-wink::after {
  content: '\f58b\f58b'; }

.fad.fa-hand-holding::after, .fa-duotone.fa-hand-holding::after {
  content: '\f4bd\f4bd'; }

.fad.fa-plug-circle-exclamation::after, .fa-duotone.fa-plug-circle-exclamation::after {
  content: '\e55d\e55d'; }

.fad.fa-link-slash::after, .fa-duotone.fa-link-slash::after {
  content: '\f127\f127'; }

.fad.fa-chain-broken::after, .fa-duotone.fa-chain-broken::after {
  content: '\f127\f127'; }

.fad.fa-chain-slash::after, .fa-duotone.fa-chain-slash::after {
  content: '\f127\f127'; }

.fad.fa-unlink::after, .fa-duotone.fa-unlink::after {
  content: '\f127\f127'; }

.fad.fa-clone::after, .fa-duotone.fa-clone::after {
  content: '\f24d\f24d'; }

.fad.fa-person-walking-arrow-loop-left::after, .fa-duotone.fa-person-walking-arrow-loop-left::after {
  content: '\e551\e551'; }

.fad.fa-arrow-up-z-a::after, .fa-duotone.fa-arrow-up-z-a::after {
  content: '\f882\f882'; }

.fad.fa-sort-alpha-up-alt::after, .fa-duotone.fa-sort-alpha-up-alt::after {
  content: '\f882\f882'; }

.fad.fa-fire-flame-curved::after, .fa-duotone.fa-fire-flame-curved::after {
  content: '\f7e4\f7e4'; }

.fad.fa-fire-alt::after, .fa-duotone.fa-fire-alt::after {
  content: '\f7e4\f7e4'; }

.fad.fa-tornado::after, .fa-duotone.fa-tornado::after {
  content: '\f76f\f76f'; }

.fad.fa-file-circle-plus::after, .fa-duotone.fa-file-circle-plus::after {
  content: '\e494\e494'; }

.fad.fa-delete-right::after, .fa-duotone.fa-delete-right::after {
  content: '\e154\e154'; }

.fad.fa-book-quran::after, .fa-duotone.fa-book-quran::after {
  content: '\f687\f687'; }

.fad.fa-quran::after, .fa-duotone.fa-quran::after {
  content: '\f687\f687'; }

.fad.fa-circle-quarter::after, .fa-duotone.fa-circle-quarter::after {
  content: '\e11f\e11f'; }

.fad.fa-anchor::after, .fa-duotone.fa-anchor::after {
  content: '\f13d\f13d'; }

.fad.fa-border-all::after, .fa-duotone.fa-border-all::after {
  content: '\f84c\f84c'; }

.fad.fa-function::after, .fa-duotone.fa-function::after {
  content: '\f661\f661'; }

.fad.fa-face-angry::after, .fa-duotone.fa-face-angry::after {
  content: '\f556\f556'; }

.fad.fa-angry::after, .fa-duotone.fa-angry::after {
  content: '\f556\f556'; }

.fad.fa-people-simple::after, .fa-duotone.fa-people-simple::after {
  content: '\e21b\e21b'; }

.fad.fa-cookie-bite::after, .fa-duotone.fa-cookie-bite::after {
  content: '\f564\f564'; }

.fad.fa-arrow-trend-down::after, .fa-duotone.fa-arrow-trend-down::after {
  content: '\e097\e097'; }

.fad.fa-rss::after, .fa-duotone.fa-rss::after {
  content: '\f09e\f09e'; }

.fad.fa-feed::after, .fa-duotone.fa-feed::after {
  content: '\f09e\f09e'; }

.fad.fa-face-monocle::after, .fa-duotone.fa-face-monocle::after {
  content: '\e380\e380'; }

.fad.fa-draw-polygon::after, .fa-duotone.fa-draw-polygon::after {
  content: '\f5ee\f5ee'; }

.fad.fa-scale-balanced::after, .fa-duotone.fa-scale-balanced::after {
  content: '\f24e\f24e'; }

.fad.fa-balance-scale::after, .fa-duotone.fa-balance-scale::after {
  content: '\f24e\f24e'; }

.fad.fa-calendar-lines::after, .fa-duotone.fa-calendar-lines::after {
  content: '\e0d5\e0d5'; }

.fad.fa-calendar-note::after, .fa-duotone.fa-calendar-note::after {
  content: '\e0d5\e0d5'; }

.fad.fa-arrow-down-big-small::after, .fa-duotone.fa-arrow-down-big-small::after {
  content: '\f88c\f88c'; }

.fad.fa-sort-size-down::after, .fa-duotone.fa-sort-size-down::after {
  content: '\f88c\f88c'; }

.fad.fa-gauge-simple-high::after, .fa-duotone.fa-gauge-simple-high::after {
  content: '\f62a\f62a'; }

.fad.fa-tachometer::after, .fa-duotone.fa-tachometer::after {
  content: '\f62a\f62a'; }

.fad.fa-tachometer-fast::after, .fa-duotone.fa-tachometer-fast::after {
  content: '\f62a\f62a'; }

.fad.fa-do-not-enter::after, .fa-duotone.fa-do-not-enter::after {
  content: '\f5ec\f5ec'; }

.fad.fa-shower::after, .fa-duotone.fa-shower::after {
  content: '\f2cc\f2cc'; }

.fad.fa-dice-d8::after, .fa-duotone.fa-dice-d8::after {
  content: '\f6d2\f6d2'; }

.fad.fa-desktop::after, .fa-duotone.fa-desktop::after {
  content: '\f390\f390'; }

.fad.fa-desktop-alt::after, .fa-duotone.fa-desktop-alt::after {
  content: '\f390\f390'; }

.fad.fa-m::after, .fa-duotone.fa-m::after {
  content: '\4d\4d'; }

.fad.fa-grip-dots-vertical::after, .fa-duotone.fa-grip-dots-vertical::after {
  content: '\e411\e411'; }

.fad.fa-face-viewfinder::after, .fa-duotone.fa-face-viewfinder::after {
  content: '\e2ff\e2ff'; }

.fad.fa-soft-serve::after, .fa-duotone.fa-soft-serve::after {
  content: '\e400\e400'; }

.fad.fa-creemee::after, .fa-duotone.fa-creemee::after {
  content: '\e400\e400'; }

.fad.fa-h5::after, .fa-duotone.fa-h5::after {
  content: '\e412\e412'; }

.fad.fa-hand-back-point-down::after, .fa-duotone.fa-hand-back-point-down::after {
  content: '\e19e\e19e'; }

.fad.fa-table-list::after, .fa-duotone.fa-table-list::after {
  content: '\f00b\f00b'; }

.fad.fa-th-list::after, .fa-duotone.fa-th-list::after {
  content: '\f00b\f00b'; }

.fad.fa-comment-sms::after, .fa-duotone.fa-comment-sms::after {
  content: '\f7cd\f7cd'; }

.fad.fa-sms::after, .fa-duotone.fa-sms::after {
  content: '\f7cd\f7cd'; }

.fad.fa-rectangle::after, .fa-duotone.fa-rectangle::after {
  content: '\f2fa\f2fa'; }

.fad.fa-rectangle-landscape::after, .fa-duotone.fa-rectangle-landscape::after {
  content: '\f2fa\f2fa'; }

.fad.fa-clipboard-list-check::after, .fa-duotone.fa-clipboard-list-check::after {
  content: '\f737\f737'; }

.fad.fa-turkey::after, .fa-duotone.fa-turkey::after {
  content: '\f725\f725'; }

.fad.fa-book::after, .fa-duotone.fa-book::after {
  content: '\f02d\f02d'; }

.fad.fa-user-plus::after, .fa-duotone.fa-user-plus::after {
  content: '\f234\f234'; }

.fad.fa-ice-skate::after, .fa-duotone.fa-ice-skate::after {
  content: '\f7ac\f7ac'; }

.fad.fa-check::after, .fa-duotone.fa-check::after {
  content: '\f00c\f00c'; }

.fad.fa-battery-three-quarters::after, .fa-duotone.fa-battery-three-quarters::after {
  content: '\f241\f241'; }

.fad.fa-battery-4::after, .fa-duotone.fa-battery-4::after {
  content: '\f241\f241'; }

.fad.fa-tomato::after, .fa-duotone.fa-tomato::after {
  content: '\e330\e330'; }

.fad.fa-sword-laser::after, .fa-duotone.fa-sword-laser::after {
  content: '\e03b\e03b'; }

.fad.fa-house-circle-check::after, .fa-duotone.fa-house-circle-check::after {
  content: '\e509\e509'; }

.fad.fa-buildings::after, .fa-duotone.fa-buildings::after {
  content: '\e0cc\e0cc'; }

.fad.fa-angle-left::after, .fa-duotone.fa-angle-left::after {
  content: '\f104\f104'; }

.fad.fa-cart-flatbed-boxes::after, .fa-duotone.fa-cart-flatbed-boxes::after {
  content: '\f475\f475'; }

.fad.fa-dolly-flatbed-alt::after, .fa-duotone.fa-dolly-flatbed-alt::after {
  content: '\f475\f475'; }

.fad.fa-diagram-successor::after, .fa-duotone.fa-diagram-successor::after {
  content: '\e47a\e47a'; }

.fad.fa-truck-arrow-right::after, .fa-duotone.fa-truck-arrow-right::after {
  content: '\e58b\e58b'; }

.fad.fa-square-w::after, .fa-duotone.fa-square-w::after {
  content: '\e285\e285'; }

.fad.fa-arrows-split-up-and-left::after, .fa-duotone.fa-arrows-split-up-and-left::after {
  content: '\e4bc\e4bc'; }

.fad.fa-lamp::after, .fa-duotone.fa-lamp::after {
  content: '\f4ca\f4ca'; }

.fad.fa-airplay::after, .fa-duotone.fa-airplay::after {
  content: '\e089\e089'; }

.fad.fa-hand-fist::after, .fa-duotone.fa-hand-fist::after {
  content: '\f6de\f6de'; }

.fad.fa-fist-raised::after, .fa-duotone.fa-fist-raised::after {
  content: '\f6de\f6de'; }

.fad.fa-shield-quartered::after, .fa-duotone.fa-shield-quartered::after {
  content: '\e575\e575'; }

.fad.fa-slash-forward::after, .fa-duotone.fa-slash-forward::after {
  content: '\2f\2f'; }

.fad.fa-location-pen::after, .fa-duotone.fa-location-pen::after {
  content: '\f607\f607'; }

.fad.fa-map-marker-edit::after, .fa-duotone.fa-map-marker-edit::after {
  content: '\f607\f607'; }

.fad.fa-cloud-moon::after, .fa-duotone.fa-cloud-moon::after {
  content: '\f6c3\f6c3'; }

.fad.fa-pot-food::after, .fa-duotone.fa-pot-food::after {
  content: '\e43f\e43f'; }

.fad.fa-briefcase::after, .fa-duotone.fa-briefcase::after {
  content: '\f0b1\f0b1'; }

.fad.fa-person-falling::after, .fa-duotone.fa-person-falling::after {
  content: '\e546\e546'; }

.fad.fa-image-portrait::after, .fa-duotone.fa-image-portrait::after {
  content: '\f3e0\f3e0'; }

.fad.fa-portrait::after, .fa-duotone.fa-portrait::after {
  content: '\f3e0\f3e0'; }

.fad.fa-user-tag::after, .fa-duotone.fa-user-tag::after {
  content: '\f507\f507'; }

.fad.fa-rug::after, .fa-duotone.fa-rug::after {
  content: '\e569\e569'; }

.fad.fa-print-slash::after, .fa-duotone.fa-print-slash::after {
  content: '\f686\f686'; }

.fad.fa-earth-europe::after, .fa-duotone.fa-earth-europe::after {
  content: '\f7a2\f7a2'; }

.fad.fa-globe-europe::after, .fa-duotone.fa-globe-europe::after {
  content: '\f7a2\f7a2'; }

.fad.fa-cart-flatbed-suitcase::after, .fa-duotone.fa-cart-flatbed-suitcase::after {
  content: '\f59d\f59d'; }

.fad.fa-luggage-cart::after, .fa-duotone.fa-luggage-cart::after {
  content: '\f59d\f59d'; }

.fad.fa-hand-back-point-ribbon::after, .fa-duotone.fa-hand-back-point-ribbon::after {
  content: '\e1a0\e1a0'; }

.fad.fa-rectangle-xmark::after, .fa-duotone.fa-rectangle-xmark::after {
  content: '\f410\f410'; }

.fad.fa-rectangle-times::after, .fa-duotone.fa-rectangle-times::after {
  content: '\f410\f410'; }

.fad.fa-times-rectangle::after, .fa-duotone.fa-times-rectangle::after {
  content: '\f410\f410'; }

.fad.fa-window-close::after, .fa-duotone.fa-window-close::after {
  content: '\f410\f410'; }

.fad.fa-tire-rugged::after, .fa-duotone.fa-tire-rugged::after {
  content: '\f634\f634'; }

.fad.fa-lightbulb-dollar::after, .fa-duotone.fa-lightbulb-dollar::after {
  content: '\f670\f670'; }

.fad.fa-cowbell::after, .fa-duotone.fa-cowbell::after {
  content: '\f8b3\f8b3'; }

.fad.fa-baht-sign::after, .fa-duotone.fa-baht-sign::after {
  content: '\e0ac\e0ac'; }

.fad.fa-corner::after, .fa-duotone.fa-corner::after {
  content: '\e3fe\e3fe'; }

.fad.fa-chevrons-right::after, .fa-duotone.fa-chevrons-right::after {
  content: '\f324\f324'; }

.fad.fa-chevron-double-right::after, .fa-duotone.fa-chevron-double-right::after {
  content: '\f324\f324'; }

.fad.fa-book-open::after, .fa-duotone.fa-book-open::after {
  content: '\f518\f518'; }

.fad.fa-book-journal-whills::after, .fa-duotone.fa-book-journal-whills::after {
  content: '\f66a\f66a'; }

.fad.fa-journal-whills::after, .fa-duotone.fa-journal-whills::after {
  content: '\f66a\f66a'; }

.fad.fa-inhaler::after, .fa-duotone.fa-inhaler::after {
  content: '\f5f9\f5f9'; }

.fad.fa-handcuffs::after, .fa-duotone.fa-handcuffs::after {
  content: '\e4f8\e4f8'; }

.fad.fa-snake::after, .fa-duotone.fa-snake::after {
  content: '\f716\f716'; }

.fad.fa-triangle-exclamation::after, .fa-duotone.fa-triangle-exclamation::after {
  content: '\f071\f071'; }

.fad.fa-exclamation-triangle::after, .fa-duotone.fa-exclamation-triangle::after {
  content: '\f071\f071'; }

.fad.fa-warning::after, .fa-duotone.fa-warning::after {
  content: '\f071\f071'; }

.fad.fa-note-medical::after, .fa-duotone.fa-note-medical::after {
  content: '\e200\e200'; }

.fad.fa-database::after, .fa-duotone.fa-database::after {
  content: '\f1c0\f1c0'; }

.fad.fa-down-left::after, .fa-duotone.fa-down-left::after {
  content: '\e16a\e16a'; }

.fad.fa-share::after, .fa-duotone.fa-share::after {
  content: '\f064\f064'; }

.fad.fa-arrow-turn-right::after, .fa-duotone.fa-arrow-turn-right::after {
  content: '\f064\f064'; }

.fad.fa-mail-forward::after, .fa-duotone.fa-mail-forward::after {
  content: '\f064\f064'; }

.fad.fa-face-thinking::after, .fa-duotone.fa-face-thinking::after {
  content: '\e39b\e39b'; }

.fad.fa-turn-down-right::after, .fa-duotone.fa-turn-down-right::after {
  content: '\e455\e455'; }

.fad.fa-bottle-droplet::after, .fa-duotone.fa-bottle-droplet::after {
  content: '\e4c4\e4c4'; }

.fad.fa-mask-face::after, .fa-duotone.fa-mask-face::after {
  content: '\e1d7\e1d7'; }

.fad.fa-hill-rockslide::after, .fa-duotone.fa-hill-rockslide::after {
  content: '\e508\e508'; }

.fad.fa-scanner-keyboard::after, .fa-duotone.fa-scanner-keyboard::after {
  content: '\f489\f489'; }

.fad.fa-circle-o::after, .fa-duotone.fa-circle-o::after {
  content: '\e119\e119'; }

.fad.fa-grid-horizontal::after, .fa-duotone.fa-grid-horizontal::after {
  content: '\e307\e307'; }

.fad.fa-message-dollar::after, .fa-duotone.fa-message-dollar::after {
  content: '\f650\f650'; }

.fad.fa-comment-alt-dollar::after, .fa-duotone.fa-comment-alt-dollar::after {
  content: '\f650\f650'; }

.fad.fa-right-left::after, .fa-duotone.fa-right-left::after {
  content: '\f362\f362'; }

.fad.fa-exchange-alt::after, .fa-duotone.fa-exchange-alt::after {
  content: '\f362\f362'; }

.fad.fa-columns-3::after, .fa-duotone.fa-columns-3::after {
  content: '\e361\e361'; }

.fad.fa-paper-plane::after, .fa-duotone.fa-paper-plane::after {
  content: '\f1d8\f1d8'; }

.fad.fa-road-circle-exclamation::after, .fa-duotone.fa-road-circle-exclamation::after {
  content: '\e565\e565'; }

.fad.fa-dungeon::after, .fa-duotone.fa-dungeon::after {
  content: '\f6d9\f6d9'; }

.fad.fa-hand-holding-box::after, .fa-duotone.fa-hand-holding-box::after {
  content: '\f47b\f47b'; }

.fad.fa-input-text::after, .fa-duotone.fa-input-text::after {
  content: '\e1bf\e1bf'; }

.fad.fa-window-flip::after, .fa-duotone.fa-window-flip::after {
  content: '\f40f\f40f'; }

.fad.fa-window-alt::after, .fa-duotone.fa-window-alt::after {
  content: '\f40f\f40f'; }

.fad.fa-align-right::after, .fa-duotone.fa-align-right::after {
  content: '\f038\f038'; }

.fad.fa-scanner-gun::after, .fa-duotone.fa-scanner-gun::after {
  content: '\f488\f488'; }

.fad.fa-scanner::after, .fa-duotone.fa-scanner::after {
  content: '\f488\f488'; }

.fad.fa-tire::after, .fa-duotone.fa-tire::after {
  content: '\f631\f631'; }

.fad.fa-engine::after, .fa-duotone.fa-engine::after {
  content: '\e16e\e16e'; }

.fad.fa-money-bill-1-wave::after, .fa-duotone.fa-money-bill-1-wave::after {
  content: '\f53b\f53b'; }

.fad.fa-money-bill-wave-alt::after, .fa-duotone.fa-money-bill-wave-alt::after {
  content: '\f53b\f53b'; }

.fad.fa-life-ring::after, .fa-duotone.fa-life-ring::after {
  content: '\f1cd\f1cd'; }

.fad.fa-hands::after, .fa-duotone.fa-hands::after {
  content: '\f2a7\f2a7'; }

.fad.fa-sign-language::after, .fa-duotone.fa-sign-language::after {
  content: '\f2a7\f2a7'; }

.fad.fa-signing::after, .fa-duotone.fa-signing::after {
  content: '\f2a7\f2a7'; }

.fad.fa-circle-caret-right::after, .fa-duotone.fa-circle-caret-right::after {
  content: '\f330\f330'; }

.fad.fa-caret-circle-right::after, .fa-duotone.fa-caret-circle-right::after {
  content: '\f330\f330'; }

.fad.fa-wheat::after, .fa-duotone.fa-wheat::after {
  content: '\f72d\f72d'; }

.fad.fa-file-spreadsheet::after, .fa-duotone.fa-file-spreadsheet::after {
  content: '\f65b\f65b'; }

.fad.fa-audio-description-slash::after, .fa-duotone.fa-audio-description-slash::after {
  content: '\e0a8\e0a8'; }

.fad.fa-calendar-day::after, .fa-duotone.fa-calendar-day::after {
  content: '\f783\f783'; }

.fad.fa-water-ladder::after, .fa-duotone.fa-water-ladder::after {
  content: '\f5c5\f5c5'; }

.fad.fa-ladder-water::after, .fa-duotone.fa-ladder-water::after {
  content: '\f5c5\f5c5'; }

.fad.fa-swimming-pool::after, .fa-duotone.fa-swimming-pool::after {
  content: '\f5c5\f5c5'; }

.fad.fa-arrows-up-down::after, .fa-duotone.fa-arrows-up-down::after {
  content: '\f07d\f07d'; }

.fad.fa-arrows-v::after, .fa-duotone.fa-arrows-v::after {
  content: '\f07d\f07d'; }

.fad.fa-chess-pawn-piece::after, .fa-duotone.fa-chess-pawn-piece::after {
  content: '\f444\f444'; }

.fad.fa-chess-pawn-alt::after, .fa-duotone.fa-chess-pawn-alt::after {
  content: '\f444\f444'; }

.fad.fa-face-grimace::after, .fa-duotone.fa-face-grimace::after {
  content: '\f57f\f57f'; }

.fad.fa-grimace::after, .fa-duotone.fa-grimace::after {
  content: '\f57f\f57f'; }

.fad.fa-wheelchair-move::after, .fa-duotone.fa-wheelchair-move::after {
  content: '\e2ce\e2ce'; }

.fad.fa-wheelchair-alt::after, .fa-duotone.fa-wheelchair-alt::after {
  content: '\e2ce\e2ce'; }

.fad.fa-turn-down::after, .fa-duotone.fa-turn-down::after {
  content: '\f3be\f3be'; }

.fad.fa-level-down-alt::after, .fa-duotone.fa-level-down-alt::after {
  content: '\f3be\f3be'; }

.fad.fa-square-s::after, .fa-duotone.fa-square-s::after {
  content: '\e27d\e27d'; }

.fad.fa-rectangle-barcode::after, .fa-duotone.fa-rectangle-barcode::after {
  content: '\f463\f463'; }

.fad.fa-barcode-alt::after, .fa-duotone.fa-barcode-alt::after {
  content: '\f463\f463'; }

.fad.fa-person-walking-arrow-right::after, .fa-duotone.fa-person-walking-arrow-right::after {
  content: '\e552\e552'; }

.fad.fa-square-envelope::after, .fa-duotone.fa-square-envelope::after {
  content: '\f199\f199'; }

.fad.fa-envelope-square::after, .fa-duotone.fa-envelope-square::after {
  content: '\f199\f199'; }

.fad.fa-dice::after, .fa-duotone.fa-dice::after {
  content: '\f522\f522'; }

.fad.fa-unicorn::after, .fa-duotone.fa-unicorn::after {
  content: '\f727\f727'; }

.fad.fa-bowling-ball::after, .fa-duotone.fa-bowling-ball::after {
  content: '\f436\f436'; }

.fad.fa-pompebled::after, .fa-duotone.fa-pompebled::after {
  content: '\e43d\e43d'; }

.fad.fa-brain::after, .fa-duotone.fa-brain::after {
  content: '\f5dc\f5dc'; }

.fad.fa-watch-smart::after, .fa-duotone.fa-watch-smart::after {
  content: '\e2cc\e2cc'; }

.fad.fa-book-user::after, .fa-duotone.fa-book-user::after {
  content: '\f7e7\f7e7'; }

.fad.fa-sensor-cloud::after, .fa-duotone.fa-sensor-cloud::after {
  content: '\e02c\e02c'; }

.fad.fa-sensor-smoke::after, .fa-duotone.fa-sensor-smoke::after {
  content: '\e02c\e02c'; }

.fad.fa-clapperboard-play::after, .fa-duotone.fa-clapperboard-play::after {
  content: '\e132\e132'; }

.fad.fa-bandage::after, .fa-duotone.fa-bandage::after {
  content: '\f462\f462'; }

.fad.fa-band-aid::after, .fa-duotone.fa-band-aid::after {
  content: '\f462\f462'; }

.fad.fa-calendar-minus::after, .fa-duotone.fa-calendar-minus::after {
  content: '\f272\f272'; }

.fad.fa-circle-xmark::after, .fa-duotone.fa-circle-xmark::after {
  content: '\f057\f057'; }

.fad.fa-times-circle::after, .fa-duotone.fa-times-circle::after {
  content: '\f057\f057'; }

.fad.fa-xmark-circle::after, .fa-duotone.fa-xmark-circle::after {
  content: '\f057\f057'; }

.fad.fa-circle-4::after, .fa-duotone.fa-circle-4::after {
  content: '\e0f1\e0f1'; }

.fad.fa-gifts::after, .fa-duotone.fa-gifts::after {
  content: '\f79c\f79c'; }

.fad.fa-album-collection::after, .fa-duotone.fa-album-collection::after {
  content: '\f8a0\f8a0'; }

.fad.fa-hotel::after, .fa-duotone.fa-hotel::after {
  content: '\f594\f594'; }

.fad.fa-earth-asia::after, .fa-duotone.fa-earth-asia::after {
  content: '\f57e\f57e'; }

.fad.fa-globe-asia::after, .fa-duotone.fa-globe-asia::after {
  content: '\f57e\f57e'; }

.fad.fa-id-card-clip::after, .fa-duotone.fa-id-card-clip::after {
  content: '\f47f\f47f'; }

.fad.fa-id-card-alt::after, .fa-duotone.fa-id-card-alt::after {
  content: '\f47f\f47f'; }

.fad.fa-magnifying-glass-plus::after, .fa-duotone.fa-magnifying-glass-plus::after {
  content: '\f00e\f00e'; }

.fad.fa-search-plus::after, .fa-duotone.fa-search-plus::after {
  content: '\f00e\f00e'; }

.fad.fa-thumbs-up::after, .fa-duotone.fa-thumbs-up::after {
  content: '\f164\f164'; }

.fad.fa-cloud-showers::after, .fa-duotone.fa-cloud-showers::after {
  content: '\f73f\f73f'; }

.fad.fa-user-clock::after, .fa-duotone.fa-user-clock::after {
  content: '\f4fd\f4fd'; }

.fad.fa-onion::after, .fa-duotone.fa-onion::after {
  content: '\e427\e427'; }

.fad.fa-clock-twelve-thirty::after, .fa-duotone.fa-clock-twelve-thirty::after {
  content: '\e359\e359'; }

.fad.fa-arrow-down-to-dotted-line::after, .fa-duotone.fa-arrow-down-to-dotted-line::after {
  content: '\e095\e095'; }

.fad.fa-hand-dots::after, .fa-duotone.fa-hand-dots::after {
  content: '\f461\f461'; }

.fad.fa-allergies::after, .fa-duotone.fa-allergies::after {
  content: '\f461\f461'; }

.fad.fa-file-invoice::after, .fa-duotone.fa-file-invoice::after {
  content: '\f570\f570'; }

.fad.fa-window-minimize::after, .fa-duotone.fa-window-minimize::after {
  content: '\f2d1\f2d1'; }

.fad.fa-rectangle-wide::after, .fa-duotone.fa-rectangle-wide::after {
  content: '\f2fc\f2fc'; }

.fad.fa-comment-arrow-up::after, .fa-duotone.fa-comment-arrow-up::after {
  content: '\e144\e144'; }

.fad.fa-garlic::after, .fa-duotone.fa-garlic::after {
  content: '\e40e\e40e'; }

.fad.fa-mug-saucer::after, .fa-duotone.fa-mug-saucer::after {
  content: '\f0f4\f0f4'; }

.fad.fa-coffee::after, .fa-duotone.fa-coffee::after {
  content: '\f0f4\f0f4'; }

.fad.fa-brush::after, .fa-duotone.fa-brush::after {
  content: '\f55d\f55d'; }

.fad.fa-tree-decorated::after, .fa-duotone.fa-tree-decorated::after {
  content: '\f7dc\f7dc'; }

.fad.fa-mask::after, .fa-duotone.fa-mask::after {
  content: '\f6fa\f6fa'; }

.fad.fa-calendar-heart::after, .fa-duotone.fa-calendar-heart::after {
  content: '\e0d3\e0d3'; }

.fad.fa-magnifying-glass-minus::after, .fa-duotone.fa-magnifying-glass-minus::after {
  content: '\f010\f010'; }

.fad.fa-search-minus::after, .fa-duotone.fa-search-minus::after {
  content: '\f010\f010'; }

.fad.fa-flower::after, .fa-duotone.fa-flower::after {
  content: '\f7ff\f7ff'; }

.fad.fa-arrow-down-from-arc::after, .fa-duotone.fa-arrow-down-from-arc::after {
  content: '\e614\e614'; }

.fad.fa-right-left-large::after, .fa-duotone.fa-right-left-large::after {
  content: '\e5e1\e5e1'; }

.fad.fa-ruler-vertical::after, .fa-duotone.fa-ruler-vertical::after {
  content: '\f548\f548'; }

.fad.fa-circles-overlap::after, .fa-duotone.fa-circles-overlap::after {
  content: '\e600\e600'; }

.fad.fa-user-large::after, .fa-duotone.fa-user-large::after {
  content: '\f406\f406'; }

.fad.fa-user-alt::after, .fa-duotone.fa-user-alt::after {
  content: '\f406\f406'; }

.fad.fa-starship-freighter::after, .fa-duotone.fa-starship-freighter::after {
  content: '\e03a\e03a'; }

.fad.fa-train-tram::after, .fa-duotone.fa-train-tram::after {
  content: '\e5b4\e5b4'; }

.fad.fa-bridge-suspension::after, .fa-duotone.fa-bridge-suspension::after {
  content: '\e4cd\e4cd'; }

.fad.fa-trash-check::after, .fa-duotone.fa-trash-check::after {
  content: '\e2af\e2af'; }

.fad.fa-user-nurse::after, .fa-duotone.fa-user-nurse::after {
  content: '\f82f\f82f'; }

.fad.fa-boombox::after, .fa-duotone.fa-boombox::after {
  content: '\f8a5\f8a5'; }

.fad.fa-syringe::after, .fa-duotone.fa-syringe::after {
  content: '\f48e\f48e'; }

.fad.fa-cloud-sun::after, .fa-duotone.fa-cloud-sun::after {
  content: '\f6c4\f6c4'; }

.fad.fa-shield-exclamation::after, .fa-duotone.fa-shield-exclamation::after {
  content: '\e247\e247'; }

.fad.fa-stopwatch-20::after, .fa-duotone.fa-stopwatch-20::after {
  content: '\e06f\e06f'; }

.fad.fa-square-full::after, .fa-duotone.fa-square-full::after {
  content: '\f45c\f45c'; }

.fad.fa-grip-dots::after, .fa-duotone.fa-grip-dots::after {
  content: '\e410\e410'; }

.fad.fa-comment-exclamation::after, .fa-duotone.fa-comment-exclamation::after {
  content: '\f4af\f4af'; }

.fad.fa-pen-swirl::after, .fa-duotone.fa-pen-swirl::after {
  content: '\e214\e214'; }

.fad.fa-falafel::after, .fa-duotone.fa-falafel::after {
  content: '\e40a\e40a'; }

.fad.fa-circle-2::after, .fa-duotone.fa-circle-2::after {
  content: '\e0ef\e0ef'; }

.fad.fa-magnet::after, .fa-duotone.fa-magnet::after {
  content: '\f076\f076'; }

.fad.fa-jar::after, .fa-duotone.fa-jar::after {
  content: '\e516\e516'; }

.fad.fa-gramophone::after, .fa-duotone.fa-gramophone::after {
  content: '\f8bd\f8bd'; }

.fad.fa-dice-d12::after, .fa-duotone.fa-dice-d12::after {
  content: '\f6ce\f6ce'; }

.fad.fa-note-sticky::after, .fa-duotone.fa-note-sticky::after {
  content: '\f249\f249'; }

.fad.fa-sticky-note::after, .fa-duotone.fa-sticky-note::after {
  content: '\f249\f249'; }

.fad.fa-down::after, .fa-duotone.fa-down::after {
  content: '\f354\f354'; }

.fad.fa-arrow-alt-down::after, .fa-duotone.fa-arrow-alt-down::after {
  content: '\f354\f354'; }

.fad.fa-hundred-points::after, .fa-duotone.fa-hundred-points::after {
  content: '\e41c\e41c'; }

.fad.fa-100::after, .fa-duotone.fa-100::after {
  content: '\e41c\e41c'; }

.fad.fa-paperclip-vertical::after, .fa-duotone.fa-paperclip-vertical::after {
  content: '\e3c2\e3c2'; }

.fad.fa-wind-warning::after, .fa-duotone.fa-wind-warning::after {
  content: '\f776\f776'; }

.fad.fa-wind-circle-exclamation::after, .fa-duotone.fa-wind-circle-exclamation::after {
  content: '\f776\f776'; }

.fad.fa-location-pin-slash::after, .fa-duotone.fa-location-pin-slash::after {
  content: '\f60c\f60c'; }

.fad.fa-map-marker-slash::after, .fa-duotone.fa-map-marker-slash::after {
  content: '\f60c\f60c'; }

.fad.fa-face-sad-sweat::after, .fa-duotone.fa-face-sad-sweat::after {
  content: '\e38a\e38a'; }

.fad.fa-bug-slash::after, .fa-duotone.fa-bug-slash::after {
  content: '\e490\e490'; }

.fad.fa-cupcake::after, .fa-duotone.fa-cupcake::after {
  content: '\e402\e402'; }

.fad.fa-light-switch-off::after, .fa-duotone.fa-light-switch-off::after {
  content: '\e018\e018'; }

.fad.fa-toggle-large-off::after, .fa-duotone.fa-toggle-large-off::after {
  content: '\e5b0\e5b0'; }

.fad.fa-pen-fancy-slash::after, .fa-duotone.fa-pen-fancy-slash::after {
  content: '\e210\e210'; }

.fad.fa-truck-container::after, .fa-duotone.fa-truck-container::after {
  content: '\f4dc\f4dc'; }

.fad.fa-boot::after, .fa-duotone.fa-boot::after {
  content: '\f782\f782'; }

.fad.fa-arrow-up-from-water-pump::after, .fa-duotone.fa-arrow-up-from-water-pump::after {
  content: '\e4b6\e4b6'; }

.fad.fa-file-check::after, .fa-duotone.fa-file-check::after {
  content: '\f316\f316'; }

.fad.fa-bone::after, .fa-duotone.fa-bone::after {
  content: '\f5d7\f5d7'; }

.fad.fa-cards-blank::after, .fa-duotone.fa-cards-blank::after {
  content: '\e4df\e4df'; }

.fad.fa-circle-3::after, .fa-duotone.fa-circle-3::after {
  content: '\e0f0\e0f0'; }

.fad.fa-bench-tree::after, .fa-duotone.fa-bench-tree::after {
  content: '\e2e7\e2e7'; }

.fad.fa-keyboard-brightness-low::after, .fa-duotone.fa-keyboard-brightness-low::after {
  content: '\e1c1\e1c1'; }

.fad.fa-ski-boot-ski::after, .fa-duotone.fa-ski-boot-ski::after {
  content: '\e3cd\e3cd'; }

.fad.fa-brain-circuit::after, .fa-duotone.fa-brain-circuit::after {
  content: '\e0c6\e0c6'; }

.fad.fa-user-injured::after, .fa-duotone.fa-user-injured::after {
  content: '\f728\f728'; }

.fad.fa-block-brick-fire::after, .fa-duotone.fa-block-brick-fire::after {
  content: '\e3dc\e3dc'; }

.fad.fa-firewall::after, .fa-duotone.fa-firewall::after {
  content: '\e3dc\e3dc'; }

.fad.fa-face-sad-tear::after, .fa-duotone.fa-face-sad-tear::after {
  content: '\f5b4\f5b4'; }

.fad.fa-sad-tear::after, .fa-duotone.fa-sad-tear::after {
  content: '\f5b4\f5b4'; }

.fad.fa-plane::after, .fa-duotone.fa-plane::after {
  content: '\f072\f072'; }

.fad.fa-tent-arrows-down::after, .fa-duotone.fa-tent-arrows-down::after {
  content: '\e581\e581'; }

.fad.fa-exclamation::after, .fa-duotone.fa-exclamation::after {
  content: '\21\21'; }

.fad.fa-arrows-spin::after, .fa-duotone.fa-arrows-spin::after {
  content: '\e4bb\e4bb'; }

.fad.fa-face-smile-relaxed::after, .fa-duotone.fa-face-smile-relaxed::after {
  content: '\e392\e392'; }

.fad.fa-comment-xmark::after, .fa-duotone.fa-comment-xmark::after {
  content: '\f4b5\f4b5'; }

.fad.fa-comment-times::after, .fa-duotone.fa-comment-times::after {
  content: '\f4b5\f4b5'; }

.fad.fa-print::after, .fa-duotone.fa-print::after {
  content: '\f02f\f02f'; }

.fad.fa-turkish-lira-sign::after, .fa-duotone.fa-turkish-lira-sign::after {
  content: '\e2bb\e2bb'; }

.fad.fa-try::after, .fa-duotone.fa-try::after {
  content: '\e2bb\e2bb'; }

.fad.fa-turkish-lira::after, .fa-duotone.fa-turkish-lira::after {
  content: '\e2bb\e2bb'; }

.fad.fa-face-nose-steam::after, .fa-duotone.fa-face-nose-steam::after {
  content: '\e382\e382'; }

.fad.fa-circle-waveform-lines::after, .fa-duotone.fa-circle-waveform-lines::after {
  content: '\e12d\e12d'; }

.fad.fa-waveform-circle::after, .fa-duotone.fa-waveform-circle::after {
  content: '\e12d\e12d'; }

.fad.fa-dollar-sign::after, .fa-duotone.fa-dollar-sign::after {
  content: '\24\24'; }

.fad.fa-dollar::after, .fa-duotone.fa-dollar::after {
  content: '\24\24'; }

.fad.fa-usd::after, .fa-duotone.fa-usd::after {
  content: '\24\24'; }

.fad.fa-ferris-wheel::after, .fa-duotone.fa-ferris-wheel::after {
  content: '\e174\e174'; }

.fad.fa-computer-speaker::after, .fa-duotone.fa-computer-speaker::after {
  content: '\f8b2\f8b2'; }

.fad.fa-skull-cow::after, .fa-duotone.fa-skull-cow::after {
  content: '\f8de\f8de'; }

.fad.fa-x::after, .fa-duotone.fa-x::after {
  content: '\58\58'; }

.fad.fa-magnifying-glass-dollar::after, .fa-duotone.fa-magnifying-glass-dollar::after {
  content: '\f688\f688'; }

.fad.fa-search-dollar::after, .fa-duotone.fa-search-dollar::after {
  content: '\f688\f688'; }

.fad.fa-users-gear::after, .fa-duotone.fa-users-gear::after {
  content: '\f509\f509'; }

.fad.fa-users-cog::after, .fa-duotone.fa-users-cog::after {
  content: '\f509\f509'; }

.fad.fa-person-military-pointing::after, .fa-duotone.fa-person-military-pointing::after {
  content: '\e54a\e54a'; }

.fad.fa-building-columns::after, .fa-duotone.fa-building-columns::after {
  content: '\f19c\f19c'; }

.fad.fa-bank::after, .fa-duotone.fa-bank::after {
  content: '\f19c\f19c'; }

.fad.fa-institution::after, .fa-duotone.fa-institution::after {
  content: '\f19c\f19c'; }

.fad.fa-museum::after, .fa-duotone.fa-museum::after {
  content: '\f19c\f19c'; }

.fad.fa-university::after, .fa-duotone.fa-university::after {
  content: '\f19c\f19c'; }

.fad.fa-circle-t::after, .fa-duotone.fa-circle-t::after {
  content: '\e124\e124'; }

.fad.fa-sack::after, .fa-duotone.fa-sack::after {
  content: '\f81c\f81c'; }

.fad.fa-grid-2::after, .fa-duotone.fa-grid-2::after {
  content: '\e196\e196'; }

.fad.fa-camera-cctv::after, .fa-duotone.fa-camera-cctv::after {
  content: '\f8ac\f8ac'; }

.fad.fa-cctv::after, .fa-duotone.fa-cctv::after {
  content: '\f8ac\f8ac'; }

.fad.fa-umbrella::after, .fa-duotone.fa-umbrella::after {
  content: '\f0e9\f0e9'; }

.fad.fa-trowel::after, .fa-duotone.fa-trowel::after {
  content: '\e589\e589'; }

.fad.fa-horizontal-rule::after, .fa-duotone.fa-horizontal-rule::after {
  content: '\f86c\f86c'; }

.fad.fa-bed-front::after, .fa-duotone.fa-bed-front::after {
  content: '\f8f7\f8f7'; }

.fad.fa-bed-alt::after, .fa-duotone.fa-bed-alt::after {
  content: '\f8f7\f8f7'; }

.fad.fa-d::after, .fa-duotone.fa-d::after {
  content: '\44\44'; }

.fad.fa-stapler::after, .fa-duotone.fa-stapler::after {
  content: '\e5af\e5af'; }

.fad.fa-masks-theater::after, .fa-duotone.fa-masks-theater::after {
  content: '\f630\f630'; }

.fad.fa-theater-masks::after, .fa-duotone.fa-theater-masks::after {
  content: '\f630\f630'; }

.fad.fa-kip-sign::after, .fa-duotone.fa-kip-sign::after {
  content: '\e1c4\e1c4'; }

.fad.fa-face-woozy::after, .fa-duotone.fa-face-woozy::after {
  content: '\e3a2\e3a2'; }

.fad.fa-cloud-question::after, .fa-duotone.fa-cloud-question::after {
  content: '\e492\e492'; }

.fad.fa-pineapple::after, .fa-duotone.fa-pineapple::after {
  content: '\e31f\e31f'; }

.fad.fa-hand-point-left::after, .fa-duotone.fa-hand-point-left::after {
  content: '\f0a5\f0a5'; }

.fad.fa-gallery-thumbnails::after, .fa-duotone.fa-gallery-thumbnails::after {
  content: '\e3aa\e3aa'; }

.fad.fa-circle-j::after, .fa-duotone.fa-circle-j::after {
  content: '\e112\e112'; }

.fad.fa-eyes::after, .fa-duotone.fa-eyes::after {
  content: '\e367\e367'; }

.fad.fa-handshake-simple::after, .fa-duotone.fa-handshake-simple::after {
  content: '\f4c6\f4c6'; }

.fad.fa-handshake-alt::after, .fa-duotone.fa-handshake-alt::after {
  content: '\f4c6\f4c6'; }

.fad.fa-page-caret-up::after, .fa-duotone.fa-page-caret-up::after {
  content: '\e42a\e42a'; }

.fad.fa-file-caret-up::after, .fa-duotone.fa-file-caret-up::after {
  content: '\e42a\e42a'; }

.fad.fa-jet-fighter::after, .fa-duotone.fa-jet-fighter::after {
  content: '\f0fb\f0fb'; }

.fad.fa-fighter-jet::after, .fa-duotone.fa-fighter-jet::after {
  content: '\f0fb\f0fb'; }

.fad.fa-comet::after, .fa-duotone.fa-comet::after {
  content: '\e003\e003'; }

.fad.fa-square-share-nodes::after, .fa-duotone.fa-square-share-nodes::after {
  content: '\f1e1\f1e1'; }

.fad.fa-share-alt-square::after, .fa-duotone.fa-share-alt-square::after {
  content: '\f1e1\f1e1'; }

.fad.fa-shield-keyhole::after, .fa-duotone.fa-shield-keyhole::after {
  content: '\e248\e248'; }

.fad.fa-barcode::after, .fa-duotone.fa-barcode::after {
  content: '\f02a\f02a'; }

.fad.fa-plus-minus::after, .fa-duotone.fa-plus-minus::after {
  content: '\e43c\e43c'; }

.fad.fa-square-sliders-vertical::after, .fa-duotone.fa-square-sliders-vertical::after {
  content: '\f3f2\f3f2'; }

.fad.fa-sliders-v-square::after, .fa-duotone.fa-sliders-v-square::after {
  content: '\f3f2\f3f2'; }

.fad.fa-video::after, .fa-duotone.fa-video::after {
  content: '\f03d\f03d'; }

.fad.fa-video-camera::after, .fa-duotone.fa-video-camera::after {
  content: '\f03d\f03d'; }

.fad.fa-message-middle::after, .fa-duotone.fa-message-middle::after {
  content: '\e1e1\e1e1'; }

.fad.fa-comment-middle-alt::after, .fa-duotone.fa-comment-middle-alt::after {
  content: '\e1e1\e1e1'; }

.fad.fa-graduation-cap::after, .fa-duotone.fa-graduation-cap::after {
  content: '\f19d\f19d'; }

.fad.fa-mortar-board::after, .fa-duotone.fa-mortar-board::after {
  content: '\f19d\f19d'; }

.fad.fa-hand-holding-medical::after, .fa-duotone.fa-hand-holding-medical::after {
  content: '\e05c\e05c'; }

.fad.fa-person-circle-check::after, .fa-duotone.fa-person-circle-check::after {
  content: '\e53e\e53e'; }

.fad.fa-square-z::after, .fa-duotone.fa-square-z::after {
  content: '\e288\e288'; }

.fad.fa-message-text::after, .fa-duotone.fa-message-text::after {
  content: '\e1e6\e1e6'; }

.fad.fa-comment-alt-text::after, .fa-duotone.fa-comment-alt-text::after {
  content: '\e1e6\e1e6'; }

.fad.fa-turn-up::after, .fa-duotone.fa-turn-up::after {
  content: '\f3bf\f3bf'; }

.fad.fa-level-up-alt::after, .fa-duotone.fa-level-up-alt::after {
  content: '\f3bf\f3bf'; }

